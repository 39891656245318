import { Typography, IconButton, Card, CardHeader, Avatar, CardContent } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Twitter } from '@material-ui/icons';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { FC, Key } from 'react';
import Linkify from 'react-linkify';
import { SecureLink } from 'react-secure-link';
import { TwitterResponseData } from 'HTB-Aircaravan2021/components/Twitter/Container/TwitterArea';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // maxWidth: 345,
      height: '100%',
    },
    description: {
      // margin: theme.spacing(1),
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      '& a': {
        color: theme.palette.secondary.main,
      },
    },
  }),
);

export type Props = {
  /**
   * 表示対象のtweet内容
   */
  tweetContent: TwitterResponseData;
};

/**
 * twitterカード表示用コンポーネント
 */
const TweetCard: FC<Props> = ({ tweetContent }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} style={{ wordBreak: 'break-all' }}>
      <CardHeader
        avatar={
          // <Avatar aria-label="recipe" className={classes.avatar}>
          <Avatar aria-label="recipe" src={tweetContent.profile_image_url_https} />
        }
        action={
          <a
            href={tweetContent.url}
            target="_blank"
            rel="noreferrer noopener"
            // className={classes.link}
          >
            <IconButton aria-label="settings" style={{ color: '#1da1f2' }}>
              <Twitter />
            </IconButton>
          </a>
        }
        titleTypographyProps={{ variant: 'caption' }}
        title={tweetContent.name}
        subheaderTypographyProps={{ variant: 'caption' }}
        subheader={`@${tweetContent.screen_name}`}
        style={{ padding: 10 }}
      />
      <CardContent style={{ paddingTop: 0, paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}>
        <Linkify
          componentDecorator={(decoratedHref: string, decoratedText: string, key: Key) => (
            <SecureLink href={decoratedHref} key={key}>
              {decoratedText}
            </SecureLink>
          )}
        >
          <pre style={{ lineHeight: 0.5 }}>
            <Typography variant="caption" color="inherit" align="left" className={classes.description}>
              {tweetContent.text}
            </Typography>
          </pre>
        </Linkify>
        {/* <Typography variant="caption" color="inherit" align="left" className={classes.description}>
          {tweetContent.created_at}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default TweetCard;
