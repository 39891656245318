import { AppBar, Toolbar, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import React, { FC } from 'react';
import styled from 'styled-components';
import { wpParentContentData } from 'HTB-Videos/utils/WPRequest';

const StyledTicketStatus = styled.div`
  .icon {
    margin-right: 5px;
  }
  // .status {
  //   flex-grow: 1;
  // }
  // .date {
  //   @media screen and (max-width: 751px) {
  //      {
  //       font-size: 0.5rem;
  //     }
  //   }
  // }
`;

export type Props = {
  /**
   * ログイン済みか否かを示す真偽値
   */
  isLogin: boolean;

  /**
   * 購入済みか否かを示す真偽値
   */
  isPurchased: boolean;

  /**
   * 親データ
   */
  parentContent: wpParentContentData;

  /**
   * 国外アクセスかどうかの真偽値
   */
  geoStatusCode403: boolean;
};

/**
 * 視聴チケットの購入・配信状態を表示するコンポーネント
 */
const TicketStatus: FC<Props> = ({ isLogin, isPurchased, parentContent, geoStatusCode403 }) => {
  const getToolbar = () => {
    if (geoStatusCode403 && parentContent.acf.isDomesticRestrictionsContent)
      return (
        <Toolbar>
          <CheckCircleOutlineIcon className="icon" />
          <Typography variant="body1" color="inherit">
            お住まいの地域では購入できません
          </Typography>
        </Toolbar>
      );
    if (parentContent.acf.viewMode === 'close')
      return (
        <Toolbar>
          <CheckCircleOutlineIcon className="icon" />
          {/* <div className="status">配信終了</div> */}
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1 }}>
            配信終了
          </Typography>
        </Toolbar>
      );
    if (!isLogin)
      return (
        <Toolbar>
          <ErrorOutlineIcon className="icon" />
          {/* <div className="status">購入にはログインが必要です</div> */}
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1 }}>
            購入にはログインが必要です
          </Typography>
        </Toolbar>
      );

    if (!isPurchased)
      return (
        <Toolbar>
          <CreditCardIcon className="icon" />
          {/* <div className="status">未購入</div> */}
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1 }}>
            未購入
          </Typography>
          {/* <div className="date"> */}
          <Typography variant="body2" color="inherit">
            {parentContent.acf.salesFinishDate.slice(0, -3)}まで
          </Typography>
          {/* </div> */}
        </Toolbar>
      );

    if (parentContent.acf.viewMode === 'release')
      return (
        <Toolbar>
          <CheckCircleOutlineIcon className="icon" />
          {/* <div className="status">購入済み</div> */}
          {/* <div className="date"> */}
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1 }}>
            購入済み
          </Typography>
          <Typography variant="body2" color="inherit">
            {parentContent.acf.liveStartDate && (
              <>
                {parentContent.acf.liveStartDate.slice(0, -3)}
                から
              </>
            )}
          </Typography>
          {/* </div> */}
        </Toolbar>
      );

    if (parentContent.acf.viewMode === 'live')
      return (
        <Toolbar style={{ paddingTop: 8, paddingBottom: 8 }}>
          <LiveTvIcon className="icon" />
          {/* <div className="status">ライブ配信中</div> */}
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1, marginLeft: 5 }}>
            ライブ配信中
            <br />
            〜”本編再生ページへ進む”をクリックして再生いただけます！〜
          </Typography>
        </Toolbar>
      );

    if (parentContent.acf.viewMode === 'prepare')
      return (
        <Toolbar>
          <CheckCircleOutlineIcon className="icon" />
          {/* <div className="status">LIVE終了 アーカイブ準備中</div> */}
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1 }}>
            LIVE終了 アーカイブ準備中
          </Typography>
        </Toolbar>
      );

    if (parentContent.acf.viewMode === 'archive')
      return (
        <Toolbar style={{ paddingTop: 8, paddingBottom: 8 }}>
          <CheckCircleOutlineIcon className="icon" />
          {/* <div className="status">アーカイブ配信中</div> */}
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1, marginLeft: 5 }}>
            アーカイブ配信中
            <br />
            〜”本編再生ページへ進む”をクリックして再生いただけます！〜
          </Typography>
          {/* <div className="date"> */}
          <Typography variant="body2" color="inherit" style={{ marginLeft: 5 }}>
            {parentContent.acf.salesFinishDate.slice(0, -3)}まで{' '}
          </Typography>
          {/* </div> */}
        </Toolbar>
      );

    if (parentContent.acf.viewMode === 'archiveRelease')
      return (
        <Toolbar>
          <CheckCircleOutlineIcon className="icon" />
          {/* <div className="status">LIVE終了 アーカイブ準備中</div> */}
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1 }}>
            購入済み
          </Typography>
          <Typography variant="body2" color="inherit">
            {parentContent.acf.liveStartDate && (
              <>
                {parentContent.acf.liveStartDate.slice(0, -3)}
                からアーカイブ配信開始
              </>
            )}
          </Typography>
        </Toolbar>
      );

    // if (parentContent.acf.viewMode === 'close')
    //   return (
    //     <Toolbar>
    //       <CheckCircleOutlineIcon className="icon" />
    //       {/* <div className="status">配信終了</div> */}
    //       <Typography variant="body1" color="inherit" style={{ flexGrow: 1 }}>
    //         配信終了
    //       </Typography>
    //     </Toolbar>
    //   );

    return <></>;
  };

  return (
    <>
      <StyledTicketStatus>
        <AppBar position="static">{getToolbar()}</AppBar>
      </StyledTicketStatus>
    </>
  );
};

export default TicketStatus;
