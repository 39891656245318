// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Typography, CircularProgress, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import youtubeCustomPlayList, {
  youtubeCustomData,
} from 'HTB-Videos/components/Youtube/YoutubeAPI';
import initialTheme from 'HTB-Videos/layouts/MuiTheme';

export type Props = {
  /**
   * YouTube動画タイトル
   */
  title: string;
  /**
   * YouTube再生リストID
   */
  playlistId: string;
  /**
   * サムネイル最大取得件数
   */
  Num: string;
};

// カルーセル表示画像css
const ImgStyle = styled.img`
  margin-left: auto;
  margin-right: auto;
  // margin: 10px;
  // width: 100px;
  // min-height: 100px;
  // height: 0,
  // paddingTop: '56.25%',
  width: 100% !important;
`;

// カルーセルコンテナcss
const StyledContainer = styled.div`
  max-width: 75vw;
  // width: 100%;
  margin: 0 auto;
  // overflow: hidden;
  .slick-list {
    padding-top: 10px;
  }

  .slick-arrow {
    // background-color: black;
    z-index: 1;
  }
  .slick-slide img {
    width: 100%;
    margin: 0px 5px;
    padding: 0px 2px;
  }
  .slick-prev {
    // left: -10px;
    left: -0.5rem;
  }
  .slick-next {
    // right: -10px;
    right: -0.5rem;
  }
  .slick-prev:before {
    color: #5353ff;
    font-size: 1.3rem;
  }
  .slick-next:before {
    color: #5353ff;
    font-size: 1.3rem;
  }
`;

const StyledTitle = styled(Typography)`
  border-bottom: 2px solid;
`;

const YouTubeRowCustomComp: FC<Props> = ({ title, playlistId, Num }) => {
  // ReactQueryでyoutubeサムネ一覧取得
  const { data: youtubeDatas, isLoading, isError } = useQuery<
    youtubeCustomData[]
  >(
    ['getyoutubeContent', playlistId],
    async () => {
      const { data } = await youtubeCustomPlayList.get<youtubeCustomData[]>(
        '',
        {
          params: {
            videoId: '',
            playlistId,
            Num,
          },
        },
      );

      return data;
    },
    { staleTime: 300000 },
  );

  // react-slickのパラメーター
  const settings = {
    // dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      // {
      //   breakpoint: initialTheme.breakpoints.values.xl,
      //   settings: {
      //     slidesToShow: 4,
      //     slidesToScroll: 4,
      //   },
      // },
      {
        breakpoint: initialTheme.breakpoints.values.lg,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: initialTheme.breakpoints.values.md,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: initialTheme.breakpoints.values.sm,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <StyledContainer>
        <StyledTitle
          variant="subtitle1"
          color="inherit"
          align="left"
          gutterBottom
        >
          {title}
        </StyledTitle>
        {/* <h3>{title}</h3> */}
        {isError && (
          <Typography gutterBottom variant="body1" align="center" color="error">
            エラーが発生しました
            <br />
          </Typography>
        )}
        {isLoading ? (
          <>
            <div style={{ padding: '1rem' }}>
              {/* <Grid container spacing={3} alignItems="center" justify="center"> */}
              <Grid container spacing={3}>
                {/* <Grid item xs={10} sm={6} md={4}> */}
                <Grid item xs={6} sm={4} md={3}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    style={{ height: 0, paddingTop: '56.25%' }}
                  />
                </Grid>
                {/* <Grid item xs={10} sm={6} md={4}> */}
                <Grid item xs={6} sm={4} md={3}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    style={{ height: 0, paddingTop: '56.25%' }}
                  />
                </Grid>
                {/* <Grid item xs={10} sm={6} md={4}> */}
                {/* <Grid item xs={6} sm={4} md={3}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    style={{ height: 0, paddingTop: '56.25%' }}
                  />
                </Grid> */}
              </Grid>
            </div>
          </>
        ) : (
          youtubeDatas && (
            <Slider
              infinite={settings.infinite}
              speed={settings.speed}
              slidesToShow={settings.slidesToShow}
              slidesToScroll={settings.slidesToScroll}
              initialSlide={settings.initialSlide}
              responsive={settings.responsive}
              // lazyLoad="progressive"
            >
              {youtubeDatas.map((youtubeData) => (
                <Link to={`/YouTube/${playlistId}/${youtubeData.videoId}`}>
                  <img
                    key={youtubeData.videoId}
                    src={youtubeData.thumbnail}
                    alt={youtubeData.videoId}
                    className={ImgStyle}
                  />
                </Link>
              ))}
            </Slider>
          )
        )}
      </StyledContainer>
    </>
  );
};

export default YouTubeRowCustomComp;
