// Sentry動作確認用テストページ

import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getAuthProfile } from 'Auth0LockContext';
import Logger from 'utils/Logger';

const SentryTest: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 氷見以外はトップページへリダイレクト
    if (getAuthProfile().email !== 'takahiro.mth.1209@gmail.com') {
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          throw new Error('Test Error HIMITEST');
        }}
      >
        throw error
      </button>
      <button
        type="button"
        onClick={() => {
          Logger.info({ msg: 'info test' });
        }}
      >
        logger info
      </button>
      <button
        type="button"
        onClick={() => {
          Logger.error({ msg: 'error test' });
        }}
      >
        logger error
      </button>
    </>
  );
};

export default SentryTest;
