import { createMuiTheme } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

/**
 * material-uiのデフォルトテーマ情報を設定
 */
const initialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#23A3F2',
      // light: '#FFFF99',
      // dark: '#23A3F2',
    },
    secondary: {
      // main: '#FFFFFF', // 白はいまいち
      main: '#EDB44B',
      // main: colors.green[500],
      // light: colors.green[900],
      // dark: colors.green[100],
    },
    error: {
      main: colors.red[500],
      // light: colors.red[900],
      // dark: colors.red[100],
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      // xs: 0,
      // sm: 360,
      // md: 720,
      // lg: 1000,
      // xl: 1800,
    },
  },
  props: {
    MuiTextField: {
      variant: 'filled',
      color: 'secondary',
    },
  },
});

initialTheme.typography.h2 = {
  // fontSize: '2rem',
  fontWeight: 700,
  fontSize: 24,
  // borderBottom: '2px solid',
  // padding: '1rem 0',
  [initialTheme.breakpoints.down('xs')]: {
    fontSize: 15,
  },
  [initialTheme.breakpoints.only('sm')]: {
    fontSize: 19,
  },
  [initialTheme.breakpoints.only('md')]: {
    fontSize: 22,
  },
};

//  二番目に大きいフォントサイズ（概要欄タイトルなど）
initialTheme.typography.subtitle1 = {
  fontWeight: 600,
  fontSize: 22,
  [initialTheme.breakpoints.down('xs')]: {
    fontSize: 14,
    lineHeight: '1.4rem',
  },
  [initialTheme.breakpoints.only('sm')]: {
    fontSize: 17,
    lineHeight: '1.5rem',
  },
  [initialTheme.breakpoints.only('md')]: {
    fontSize: 20,
    lineHeight: '1.6rem',
  },
};

// 動画説明文など
initialTheme.typography.body1 = {
  fontSize: 19,
  [initialTheme.breakpoints.down('xs')]: {
    fontSize: 11,
  },
  [initialTheme.breakpoints.only('sm')]: {
    fontSize: 14,
  },
  [initialTheme.breakpoints.only('md')]: {
    fontSize: 17,
  },
};

// 小さいフォント使いたい時 パンクズなど
initialTheme.typography.body2 = {
  fontSize: 15,
  [initialTheme.breakpoints.down('xs')]: {
    fontSize: 9,
  },
  [initialTheme.breakpoints.only('sm')]: {
    fontSize: 11,
  },
  [initialTheme.breakpoints.only('md')]: {
    fontSize: 13,
  },
};

// 一番小さいフォント
initialTheme.typography.caption = {
  fontSize: 13,
  [initialTheme.breakpoints.down('xs')]: {
    fontSize: 11,
  },
  [initialTheme.breakpoints.only('sm')]: {
    fontSize: 12,
  },
  // [initialTheme.breakpoints.only('md')]: {
  //   fontSize: 13,
  // },
};

// カートに入れるボタン用フォント
initialTheme.typography.button = {
  fontSize: 15,
  fontWeight: 'bold',
  [initialTheme.breakpoints.down('xs')]: {
    fontSize: 13,
  },
};

// 最大フォントサイズ
initialTheme.typography.h3 = {
  // fontSize: '2rem',
  fontWeight: 700,
  fontSize: 28,
  // borderBottom: '2px solid',
  // padding: '1rem 0',
  [initialTheme.breakpoints.down('xs')]: {
    fontSize: 15,
  },
  [initialTheme.breakpoints.only('sm')]: {
    fontSize: 22,
  },
  [initialTheme.breakpoints.only('md')]: {
    fontSize: 24,
  },
};

export default initialTheme;
