import Zoom from '@material-ui/core/Zoom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { FC } from 'react';

type Props = {
  window?: () => Window;
  children: React.ReactElement;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      // position: 'relative',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        bottom: theme.spacing(5),
        right: theme.spacing(1),
      },
    },
  }),
);

const ScrollTrigger: FC<Props> = (props) => {
  // const TweetCard: FC<Props> = ({ tweetContent }) => {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 5,
  });
  // const trigger = useScrollTrigger();

  // const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
  //   const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

  //   if (anchor) {
  //     anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  // };

  return (
    <Zoom in={trigger}>
      {/* <div onClick={handleClick} role="presentation" className={classes.root}> */}
      <div role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
};

export default ScrollTrigger;
