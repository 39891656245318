import { Grid, Breadcrumbs as BreadcrumbsMui, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/**
 * パンクズの型定義
 */
type Bread = {
  /**
   * リンク先 未設定の場合はリンク無しのただのテキストになる
   */
  to?: string;

  /**
   * icon material-uiのiconが設定されることを想定
   */
  icon: React.ReactElement;

  /**
   * パンクズのテキスト
   */
  text: string;
};

type Props = {
  /**
   * パンクズリストに設定するクラス名
   */
  className?: string;

  /**
   * パンクズデータ
   */
  breads: Bread[];

  /**
   * パンクズテキストを表示する最大文字数
   */
  substrLength?: number;
};

// 上下中央寄せGrid用のスタイル
const StyledGridCenterJustify = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  // color: white;
`;

// パンクズリスト用のスタイル
// const StyledBreadcrumbs = styled(BreadcrumbsMui)`
//   // color: white;
//   // margin-right: -20px;
//   // margin-left: -20px;

//   // @media screen and (max-width: 1025px) {
//   //   font-size: 0.6rem;
//   // }
// `;

// パンクズリストのテキスト用のスタイル
// const StyledBreadLinkText = styled(Typography)`
//   text-decoration: underline;
// `;

const StyledBreadLink = styled(Link)`
  color: inherit;
`;

/**
 * パンクズリストのコンポーネント
 */
const Breadcrumbs: FC<Props> = ({ className, breads, substrLength = 8 }) => (
  <>
    <BreadcrumbsMui aria-label="breadcrumb" className={className}>
      {breads.map((bread) =>
        bread.to ? (
          <StyledBreadLink to={bread.to}>
            <StyledGridCenterJustify>
              {bread.icon}
              <Typography variant="body2" color="inherit" style={{ textDecoration: 'underline' }}>
                {bread.text.length > substrLength ? `${bread.text.substr(0, substrLength)}…` : bread.text}
              </Typography>
            </StyledGridCenterJustify>
          </StyledBreadLink>
        ) : (
          <StyledGridCenterJustify>
            {bread.icon}
            <Typography variant="body2" color="inherit">
              {bread.text.length > substrLength ? `${bread.text.substr(0, substrLength)}…` : bread.text}
            </Typography>
          </StyledGridCenterJustify>
        ),
      )}
    </BreadcrumbsMui>
  </>
);

export default Breadcrumbs;
