import { useEffect, useState } from 'react';
import { wpChildContentData, wpParentContentData, ViewMode } from 'HTB-Videos/utils/WPRequest';
import WPContent from '../components/Common/WPContentsAxios';

/**
 * 子コンテンツを取得するhooks
 */
const useGetChildContent = (
  childId: string,
): {
  childContent: wpChildContentData | undefined;
  viewMode: ViewMode | undefined;
  isMultiAngleContent: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [childContentState, setChildContentState] = useState<wpChildContentData>();
  const [viewModeState, setViewModeState] = useState<ViewMode>();
  const [isMultiAngleContentState, setIsMultiAngleContentState] = useState(Boolean);

  useEffect(() => {
    const getContent = async () => {
      setIsErrorState(false);
      setIsLoadingState(true);
      try {
        const childResponse = await WPContent.get<wpChildContentData>(`${childId}`, {
          params: {
            _fields: 'acf,id,parent,title',
          },
        });
        const parentResponse = await WPContent.get<wpParentContentData>(`${childResponse.data.parent}`, {
          params: {
            _fields: 'acf,id,title',
          },
        });

        setViewModeState(parentResponse.data.acf.viewMode);
        setIsMultiAngleContentState(parentResponse.data.acf.isMultiAngleContent);

        setChildContentState(childResponse.data);
      } catch (err) {
        setIsErrorState(true);
      }
      setIsLoadingState(false);
    };

    void getContent();
  }, [childId]);

  return {
    childContent: childContentState,
    viewMode: viewModeState,
    isMultiAngleContent: isMultiAngleContentState,
    isLoading: isLoadingState,
    isError: isErrorState,
  };
};

export default useGetChildContent;
