// import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
// import Grid from '@material-ui/core/Grid';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';
// import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
// import { getAuthProfile } from 'Auth0LockContext';
// import { CardBrand, useCreateCardMutation } from 'graphql/appsync/generated';
import { User } from 'graphql/appsync/generated';
import CardAdd from '../Stripe/CardAdd/Container/CardAdd';

type Props = {
  /**
   * 更新対象のユーザー情報
   */
  user: User;

  /**
   * 登録・更新後に実行される関数
   */
  onEdit?: VoidFunction;
};

/* const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer_root: {
      width: '100%',
      '& .MuiTextField-root': {
        margin: theme.spacing(3),
        width: '90%',
      },
    },
  }),
); */

/* const cardBrandList: Array<CardBrand> = [
  'AMEX',
  'Diners',
  'Discover',
  'JCB',
  'Mastercard',
  'Visa',
]; */

const CardEditFrom: FC<Props> = ({ user, onEdit }) => (
  // const classes = useStyles();

  // const [addCard] = useCreateCardMutation();
  /* const sendCardEdit = (event: React.FormEvent<HTMLFormElement>) => {
    // const sendCardEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // リロード防止
     await addCard({
      variables: {
        input: {
          userId: getAuthProfile().sub ?? '',
          stripeCardId: 'test stripe card id',
          brand,
          cardNo: '1234',
          deadline: '11/1111',
          name: 'test test',
        },
      },
    });

    if (onEdit) void onEdit();
  }; */

  /* const [brand, setBrand] = React.useState<CardBrand>('Visa');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const resCardBrand = cardBrandList.filter((output) => {
      return output.includes(event.target.value as string);
    });
    if (resCardBrand.length > 0) {
      setBrand(resCardBrand[0]);
    }

    // cardBrandList.filter(event.target.value as string)
    // const str = event.target.value as string;

    // setBrand(event.target.value as string);
  }; */
  /*
  const [cardNo, setCardNo] = React.useState('');
  const onChangeCardNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardNo(event.target.value);
  };

  const [deadline, setDeadline] = React.useState('');
  const onChangeDeadline = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeadline(event.target.value);
  };

  const [name, setName] = React.useState('');
  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }; */

  <>
    <CardAdd onEdit={onEdit} stripeId={user?.stripeId ?? ''} />
    {/* <form className={classes.drawer_root} onSubmit={sendCardEdit}>
        <div role="presentation">
          <br />
          <Grid container alignItems="center" justify="center">
            <Typography variant="h6" noWrap>
              クレジットカードを追加する
            </Typography>

            <Grid item xs={12}>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={brand}
                onChange={handleChange}
              >
                {cardBrandList.map((val) => (
                  <MenuItem value={val}>{val}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="standard-required"
                label="cardNo"
                defaultValue={cardNo}
                variant="outlined"
                onChange={onChangeCardNo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="standard-required"
                label="deadline"
                defaultValue={name}
                variant="outlined"
                onChange={onChangeName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="standard-required"
                label="deadline"
                defaultValue={deadline}
                variant="outlined"
                onChange={onChangeDeadline}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
            >
              送信
            </Button>
          </Grid>
        </Grid>
      </form>
      <br /> */}
  </>
);
export default CardEditFrom;
