import { Button, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Loading from 'HTB-Videos/components/Common/Loading';
import WPFooter from '../components/Common/WPFooterAxios';
import { wpFooterData } from '../utils/WPRequest';

const StyledEnvironment = styled.div`
  .linkIndex {
    text-align: center;
    margin: 20px auto 0px auto;
  }
  p.system_equirements {
    text-align: center;
    line-height: 1.2rem;
  }
  p.system_annotation {
    line-height: 1.2rem;
    padding-top: 10px;
    color: #f19c9c;
  }
`;

const StyledTable = styled.div`
  h2 {
    text-align: center;
    font-weight: bold;
    margin: 10px 0px;
    font-size: 2rem;
    border-bottom: 5px solid #c69e61;
    padding-bottom: 10px;
    margin-bottom: 20px;

    @media screen and (max-width: 751px) {
       {
        font-size: 1.2rem;
      }
    }
  }

  h3.system_equirements {
    text-align: center;
    margin: 20px auto 10px;
    font-size: 1.2rem;
    font-weight: bold;
    // color: #231815;
    // color: white;
    @media screen and (max-width: 751px) {
       {
        font-size: 1rem;
      }
    }
  }
  table.system_equirements {
    border-collapse: collapse;
    margin: 0 auto 5px;
    padding: 0;
    table-layout: fixed;
  }
  table.system_equirements tr {
    background-color: rgba(198, 158, 97, 0.2);
    padding: 10px;
    border-bottom: 1px solid #fff;
  }
  table.system_equirements th,
  table.system_equirements thead th {
    color: #fff;
    text-align: center;
    vertical-align: middle;
  }
  table.system_equirements th,
  table.system_equirements td {
    padding: 20px;
    border-right: 1px solid #fff;
    vertical-align: middle;
    text-align: center;
    // color: white;
  }
  table.system_equirements td:first-child {
    width: 300px;
    text-align: left;
    // color: white;
    border-left: 1px solid #fff;
  }
  table.system_equirements th {
    font-size: 1.2rem;
    border: 1px solid #fff;
  }
  table.system_equirements thead tr {
    background-color: #c69e61;
    // color: #fff;
  //   color: white;
  // }
  table.system_equirements tbody th {
    // color: #fff;
    // color: white;
  }
  // p.system_equirements {
  //   text-align: center!important;
  //   line-height: 1.2rem!important;
  // }
  // p.system_annotation {
  //   line-height: 1.2rem!important;
  //   padding-top: 10px!important;
  //   color: #f19c9c!important;
  // }
`;

/**
 * 動作環境のページ
 */
const EnvironmentPMF: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [footerText, setFooterText] = useState('');

  useEffect(() => {
    const getContents = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const response: wpFooterData = await WPFooter.get(`1713`);
        setFooterText(response.data.content.rendered ?? '');
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    void getContents();
  }, []);

  return (
    <StyledEnvironment>
      <StyledTable>
        {isError && (
          <Typography gutterBottom variant="body1" align="center" color="error">
            エラーが発生しました
            <br />
          </Typography>
        )}
        {isLoading ? (
          <Loading loadingCode="Load_002" />
        ) : (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: footerText,
              }}
            />
          </>
        )}
      </StyledTable>
      <div className="linkIndex">
        <Button variant="contained" color="primary" component={Link} to="/">
          トップへ戻る
        </Button>
      </div>
    </StyledEnvironment>
  );
};

export default EnvironmentPMF;
