import { TextField, MenuItem, Button } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import Loading from 'HTB-Videos/components/Common/Loading';
import prefLists from 'HTB-Videos/utils/ProvinceList';

/**
 * react-hook-formで使う要素の型宣言
 */
export type FormData = {
  /**
   * 姓
   */
  familyName: string;
  /**
   * 名
   */
  givenName: string;
  /**
   * 郵便番号
   */
  postCode: string;
  /**
   * 都道府県コード
   */
  provinceCode: string;
  /**
   * 市町村
   */
  city: string;
  /**
   * 住所１
   */
  address: string;
  /**
   * 住所２
   */
  subAddress: string;
  /**
   * 電話番号
   */
  phoneNumber: string;
};

const StyledDefaultPagingButton = styled.div`
  text-align: center;
`;

const StyledForm = styled.form`
  // background-color: white;
  padding: 10px;
`;

export type Props = {
  /**
   * Loading中であるか否かを示す真偽値
   */
  isLoading: boolean;
  /**
   *確認画面へを押したときに実行される処理
   */
  pagingFunction: (result: string) => void;
  /**
   *入力した住所情報
   */
  resultInfo: (resultAddressData: FormData) => void;
};

const AddressForm: FC<Props> = ({
  // onSubmit,
  isLoading,
  pagingFunction,
  resultInfo,
}) => {
  // react-hook-formのhooks
  // react-hook-form v6 既知の不具合でeslintエラーが発生するため回避用のコメントを設定
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, formState, watch, reset, control, setValue } = useForm<FormData>({
    mode: 'onChange',
    // ドロップダウンリストでコンソールエラー出るので空のdefaultValueを設定
    defaultValues: {
      provinceCode: '',
      postCode: '',
      address: '',
      city: '',
    },
  });
  // checkboxと入力郵便番号の状態監視
  const inputZipCode = watch('postCode');

  /**
   * 住所情報取得用API responseの型指定
   */
  type zipData = {
    data: {
      data: {
        pref: string;
        city: string;
        town: string;
      };
    };
  };

  // 郵便番号の入力値が変わった際、住所情報取得APIを叩き、取得結果をformに設定する
  useEffect(() => {
    if (inputZipCode.length === 7) {
      const getZipCode = async () => {
        const response: zipData = await axios.get(`https://api.zipaddress.net/?zipcode=${inputZipCode}`);
        // 都道府県(ID)を設定
        setValue('provinceCode', prefLists.find((n) => n.name === response.data.data?.pref ?? '')?.id, {
          shouldValidate: true,
        });
        // 市区町村を設定
        setValue('city', response.data.data?.city ?? '', {
          shouldValidate: true,
        });
        // 町番地号を設定
        setValue('address', response.data.data?.town ?? '', {
          shouldValidate: true,
        });
      };
      void getZipCode();
    }
  }, [inputZipCode, setValue]);

  // submit時に実行する処理
  const submit = (data: FormData) => {
    // void onSubmit(data);
    pagingFunction('AddressInputSuccess');
    resultInfo(data);
    reset();
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(submit)}>
        {isLoading ? (
          <>
            <br />
            <br />
            <Loading loadingCode="Load_001" />
          </>
        ) : (
          <>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography gutterBottom align="left" variant="subtitle1" color="inherit">
                  配送先情報
                </Typography>
                <Typography gutterBottom align="left" variant="body1" color="inherit">
                  *の項目は必須入力になります
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="姓"
                  name="familyName"
                  type="text"
                  placeholder="北海"
                  fullWidth
                  error={Boolean(errors.familyName)}
                  inputRef={register({
                    required: '必須項目です',
                    maxLength: {
                      value: 25,
                      message: '25文字以内で入力してください',
                    },
                  })}
                />
                {errors.familyName && (
                  // <p style={{ color: 'error' }}>{errors.familyName.message}</p>
                  <Typography gutterBottom align="left" variant="body2" color="error">
                    {errors.familyName.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="名"
                  name="givenName"
                  type="text"
                  placeholder="太郎"
                  fullWidth
                  error={Boolean(errors.givenName)}
                  inputRef={register({
                    required: '必須項目です',
                    maxLength: {
                      value: 25,
                      message: '25文字以内で入力してください',
                    },
                  })}
                />
                {/* {errors.givenName && <p style={{ color: 'error' }}>{errors.givenName.message}</p>} */}
                {errors.givenName && (
                  <Typography gutterBottom align="left" variant="body2" color="error">
                    {errors.givenName.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="電話番号"
                  name="phoneNumber"
                  placeholder="080********"
                  type="tel"
                  fullWidth
                  error={Boolean(errors.phoneNumber)}
                  inputRef={register({
                    required: '必須項目です',
                    pattern: {
                      value: /^[0-9]+$/,
                      message: '半角数字(ハイフン不要)で入力してください',
                    },
                    maxLength: {
                      value: 15,
                      message: '15桁以内で入力してください',
                    },
                  })}
                />
                {/* {errors.phoneNumber && <p style={{ color: 'error' }}>{errors.phoneNumber.message}</p>} */}
                {errors.phoneNumber && (
                  <Typography gutterBottom align="left" variant="body2" color="error">
                    {errors.phoneNumber.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="郵便番号"
                  name="postCode"
                  placeholder="0000000"
                  type="tel"
                  fullWidth
                  error={Boolean(errors.postCode)}
                  inputRef={register({
                    required: '必須項目です',
                    pattern: {
                      value: /^\d{7}$/,
                      message: '7桁の半角数字(ハイフン不要)で入力してください',
                    },
                  })}
                />
                {/* {errors.postCode && <p style={{ color: 'error' }}>{errors.postCode.message}</p>} */}
                {errors.postCode && (
                  <Typography gutterBottom align="left" variant="body2" color="error">
                    {errors.postCode.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="provinceCode"
                  control={control}
                  rules={{ required: true }}
                  as={
                    <TextField
                      required
                      id="select"
                      label="都道府県"
                      select
                      fullWidth
                      error={Boolean(errors.provinceCode)}
                      helperText={errors.provinceCode && errors.provinceCode.message}
                    >
                      {prefLists.map((list) => (
                        <MenuItem value={list.id} key={list.name}>
                          {list.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="市区町村"
                  name="city"
                  placeholder="**市"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={Boolean(errors.city)}
                  // helperText={errors.city && errors.city.message}
                  inputRef={register({
                    // required: true,
                    required: '必須項目です',
                    maxLength: {
                      value: 35,
                      message: '35文字以内で入力してください',
                    },
                  })}
                />
                {errors.city && (
                  <Typography gutterBottom align="left" variant="body2" color="error">
                    {errors.city.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="町番地号"
                  name="address"
                  placeholder="○丁目○番地○号"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={Boolean(errors.address)}
                  // helperText={errors.address && errors.address.message}
                  inputRef={register({
                    // required: true,
                    required: '必須項目です',
                    pattern: {
                      value:
                        /^[^!"#$%&'()☆♩ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅪⅫⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅺⅻ☆♪→¥$€%°#○＊・+×÷<=>「」:〒〆^|〜…*+.,/:;<=>?@[\\\]^_`{|}~]+$/,
                      message: '記号・ローマ数字は使えません',
                    },
                    maxLength: {
                      value: 20,
                      message: '20文字以内で入力してください',
                    },
                  })}
                />
                {errors.address && (
                  <Typography gutterBottom align="left" variant="body2" color="error">
                    {errors.address.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="マンション部屋番号"
                  name="subAddress"
                  placeholder="△△アパート○○号室"
                  type="text"
                  fullWidth
                  error={Boolean(errors.subAddress)}
                  inputRef={register({
                    pattern: {
                      value:
                        /^[^!"#$%&'()☆♩ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅪⅫⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅺⅻ☆♪→¥$€%°#○＊・+×÷<=>「」:〒〆^|〜…*+.,/:;<=>?@[\\\]^_`{|}~]+$/,
                      message: '記号・ローマ数字は使えません',
                    },
                    maxLength: {
                      value: 20,
                      message: '20文字以内で入力してください',
                    },
                  })}
                />
                {/* {errors.subAddress && <p style={{ color: 'error' }}>{errors.subAddress.message}</p>} */}
                {errors.subAddress && (
                  <Typography gutterBottom align="left" variant="body2" color="error">
                    {errors.subAddress.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <br />
          </>
        )}
        <br />
        <StyledDefaultPagingButton>
          <Button disabled={!formState.isValid} variant="contained" color="primary" type="submit">
            <Typography align="center" variant="body1">
              確認画面へ
            </Typography>
          </Button>
        </StyledDefaultPagingButton>
      </StyledForm>
    </>
  );
};

export default AddressForm;
