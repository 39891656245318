import {
  Drawer as MuiDrawer,
  // Slide,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  FormControl,
  RadioGroup,
  Grid,
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAuthProfile } from 'Auth0LockContext';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import Message from 'HTB-Aircaravan2021/components/Common/Message';
import TwitterTag from 'HTB-Aircaravan2021/components/Common/TwitterTag';
import CardSelect from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/CardSelect';
// import OrderComplete from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/OrderComplete';
import OrderConfirmation from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/OrderConfirmation';
import { itemList } from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';
import CardEditForm from 'HTB-Aircaravan2021/components/MyPage/CardEditFrom';
import DeliveryAddressEditForm from 'HTB-Aircaravan2021/components/MyPage/DeliveryAddressEditForm';
import DeliveryAddressList from 'HTB-Aircaravan2021/components/MyPage/DeliveryAddressList';
import UserEditForm from 'HTB-Aircaravan2021/components/MyPage/UserEditFrom';
import {
  Card,
  Delivery,
  useGetGoodsCartLazyQuery,
  useGetUserQuery,
  useListSortCardsQuery,
  useListSortDeliverysQuery,
  useUpdateGoodsCartMutation,
} from 'graphql/appsync/generated';

type StepperLabel =
  // '初期登録' | '住所登録' | '住所選択' | '決済登録' | '決済選択' | '注文確定' | '購入済み';
  | 'プロフィール登録'
  | '住所登録'
  | '住所選択'
  | 'クレジットカード登録'
  | 'クレジットカード選択'
  | '注文確定画面'
  | '購入済み';

type Props = {
  /**
   * 決済処理を進めるカートID
   */
  cartId: string;
  /**
   * カートID初期化用関数
   */
  refreshCartId: VoidFunction;
};

// 購入に進む系のボタンの色はオレンジ
const StyledButton = styled(Button)`
  background-color: #ff9100 !important;
`;

/**
 * 入力フォーム付きの縦型Drawerコンポーネント
 */
const FormDrawerVertical: FC<Props> = ({ cartId, refreshCartId }) => {
  // 現在表示されているStepperの番号を管理するState
  const [activeStep, setActiveStep] = useState(0);
  // 住所選択時のDeliveryTableのId
  const [deliverlyId, setDeliverlyId] = useState('');
  // Stepperのラベルを管理するState
  const [stepperLabels, setStepperLabels] = useState<StepperLabel[]>();
  // 送料計算SFのレスポンスを保存
  const [temporaryGoodsOrderId, setTemporaryGoodsOrderId] = useState('');
  // 最終確認画面表示用
  const [card, setCard] = useState<Card>();
  const [delivery, setDelivery] = useState<Delivery>();
  // ドロワー表示し制御
  const [isOpen, setIsOpen] = useState(false);
  const [Items, setItems] = useState<itemList[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  // goodsCart情報
  const [goodsCart, { data: goodsCartData, loading: isGoodsCartDataLoading, refetch: refetchGoodsCart }] =
    useGetGoodsCartLazyQuery({
      variables: { id: cartId },
    });

  // goodsCartテーブルのitemsをJsonパース
  /*
  const Items =
    isGoodsCartDataLoading || (goodsCartData?.getGoodsCart?.items?.length ?? 0) < 1
      ? undefined
      : (JSON.parse(goodsCartData?.getGoodsCart?.items as string) as itemList[]);
  */
  // カート内合計金額計算
  // const totalPrice = Items?.reduce((prev, current) => prev + (current?.cost ?? 0) * (current?.count ?? 0), 0);

  // ログインユーザー情報
  const {
    data: getUserData,
    loading: isGetUserLoading,
    refetch: refetchGetUser,
  } = useGetUserQuery({
    variables: { id: getAuthProfile().sub ?? '' },
  });

  // 登録済みカード情報
  const {
    data: listCardData,
    loading: isGetCardLoading,
    refetch: refetchListCard,
  } = useListSortCardsQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
    },
  });

  // 登録済み配送先情報
  const {
    data: listDeliveryData,
    loading: isGetDeliveryLoading,
    refetch: refetchDeliveryData,
  } = useListSortDeliverysQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
    },
  });

  // カート更新
  const [updateGoodsCartMutation] = useUpdateGoodsCartMutation();

  // ユーザー・配送先・カード情報の登録状態から表示するStepperを決定する
  useEffect(() => {
    if (!isGetUserLoading && !isGetDeliveryLoading && !isGetCardLoading) {
      const labels: StepperLabel[] = [];

      // ユーザー登録が無い場合、プロフィール登録を追加
      if (!getUserData?.getUser) {
        labels.push('プロフィール登録');
        // labels.push('初期登録');
      }

      // 住所登録していない場合、住所登録を追加
      if (!((listDeliveryData?.listSortDeliverys?.items?.length ?? 0) > 0)) {
        labels.push('住所登録');
      }

      labels.push('住所選択');

      // カード登録していない場合、カード登録を追加
      if (!((listCardData?.listSortCards?.items?.length ?? 0) > 0)) {
        labels.push('クレジットカード登録');
        // labels.push('決済登録');
      }

      labels.push('クレジットカード選択', '注文確定画面', '購入済み');
      // labels.push('決済選択', '注文確定', '購入済み');

      setStepperLabels(labels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetUserLoading, isGetDeliveryLoading, isGetCardLoading, isOpen]);

  // DrawerClose時に実行される関数
  const onClose = () => {
    if (refetchGoodsCart) {
      void refetchGoodsCart();
    }
    void setActiveStep(0);
    void refetchGetUser();
    // void setIsOpen(false);
    void refreshCartId();
  };

  // カートIDが設定されたら表示
  useEffect(() => {
    if (cartId !== 'dummy') {
      // カートテーブル取得
      void goodsCart();
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line
  }, [cartId]);

  // カートテーブルを読み込まれたらItems設定
  useEffect(() => {
    if (goodsCartData) {
      const tempItems =
        isGoodsCartDataLoading || (goodsCartData?.getGoodsCart?.items?.length ?? 0) < 1
          ? []
          : (JSON.parse(goodsCartData?.getGoodsCart?.items as string) as itemList[]);
      // Items設定
      setItems(tempItems);
      // TotalPrice設定
      setTotalPrice(tempItems?.reduce((prev, current) => prev + (current?.cost ?? 0) * (current?.count ?? 0), 0));
    }
    // eslint-disable-next-line
  }, [goodsCartData]);

  return (
    <>
      <MuiDrawer
        anchor="bottom"
        open={isOpen}
        onClose={() => {
          void refetchGetUser();
        }}
      >
        {isGetUserLoading || isGetCardLoading || isGetDeliveryLoading || isGoodsCartDataLoading ? (
          <Loading loadingCode="Load_003" />
        ) : (
          <StyledDrawer>
            <Box p={2}>
              <ClearIcon
                aria-label="閉じる"
                fontSize="large"
                onClick={() => {
                  onClose();
                }}
              />
              {/* <StyledTitle>購入</StyledTitle> */}
              <Typography style={{ fontWeight: 800 }} variant="h2" align="center">
                購入
              </Typography>

              {/* <TableContainer component={Paper} style={{ padding: 8 }}> */}

              <Grid container alignItems="center" justify="center" style={{ padding: 8 }}>
                <Grid item xs={12} md={10}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ minWidth: 100 }}>商品名</TableCell>
                          <TableCell align="right">数量</TableCell>
                          <TableCell align="right" style={{ minWidth: 80 }}>
                            単価
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Items?.map((item) => (
                          <>
                            <TableRow key={item.id}>
                              <TableCell component="th" scope="row">
                                {item.title}
                              </TableCell>
                              <TableCell align="right">{item.count}</TableCell>
                              <TableCell align="right">&yen;&ensp;{Number(item.cost).toLocaleString()}</TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography style={{ fontWeight: 800 }} variant="body1">
                              小計（税込）：
                            </Typography>
                          </TableCell>
                          <TableCell align="right" />
                          <TableCell align="right">
                            <Typography style={{ fontWeight: 800 }} variant="body1">
                              &yen;&ensp;{Number(totalPrice).toLocaleString()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      {stepperLabels &&
                      !isGoodsCartDataLoading &&
                      // (activeStep === stepperLabels.indexOf('注文確定') ||
                      (activeStep === stepperLabels.indexOf('注文確定画面') ||
                        activeStep === stepperLabels?.indexOf('購入済み')) ? (
                        <>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography variant="body1" style={{ fontWeight: 800 }}>
                                  送料（税込）:
                                </Typography>
                              </TableCell>
                              <TableCell align="right" />
                              <TableCell align="right">
                                {goodsCartData && goodsCartData?.getGoodsCart?.totalPrice === 0 ? (
                                  <></>
                                ) : (
                                  <Typography style={{ fontWeight: 800 }} variant="body1">
                                    &yen;&ensp;{Number(goodsCartData?.getGoodsCart?.deliveryPrice).toLocaleString()}
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>

                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography style={{ fontWeight: 800 }} variant="body1" color="error">
                                  ご請求額:
                                </Typography>
                              </TableCell>
                              <TableCell align="right" />
                              <TableCell align="right" variant="body">
                                {goodsCartData && goodsCartData?.getGoodsCart?.totalPrice === 0 ? (
                                  <></>
                                ) : (
                                  <Typography style={{ fontWeight: 800 }} variant="body1" color="error">
                                    &yen;&ensp;{Number(goodsCartData?.getGoodsCart?.totalPrice).toLocaleString()}
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} md={10}>
                  <Stepper activeStep={activeStep} orientation="vertical" style={{ padding: '24px 8px' }}>
                    {stepperLabels &&
                      stepperLabels.map((label) => (
                        <Step color="secondary" key={label}>
                          {/* <StepLabel>{label}</StepLabel> */}
                          <StepLabel>
                            <Typography variant="body1">{label}</Typography>
                          </StepLabel>
                          {/* </Step> */}
                          {/* ))} */}
                          {/* </Stepper> */}

                          <StepContent>
                            {/* プロフィール登録 */}
                            {/* {stepperLabels && activeStep === stepperLabels.indexOf('初期登録') && ( */}
                            {stepperLabels && activeStep === stepperLabels.indexOf('プロフィール登録') && (
                              <UserEditForm
                                user={getUserData?.getUser ?? undefined}
                                onEdit={() => {
                                  void refetchGetUser();
                                  void setActiveStep((nowStep) => nowStep + 1);
                                }}
                              />
                            )}

                            {/* 住所登録 */}
                            {stepperLabels && activeStep === stepperLabels?.indexOf('住所登録') && (
                              <div>
                                {getUserData?.getUser ? (
                                  <DeliveryAddressEditForm
                                    // user={getUserData?.getUser}
                                    onEdit={() => {
                                      void refetchDeliveryData();
                                      void setActiveStep((nowStep) => nowStep + 1);
                                    }}
                                  />
                                ) : (
                                  <>ユーザー設定エラーです</>
                                )}
                              </div>
                            )}

                            {/* 住所選択 */}
                            {stepperLabels && activeStep === stepperLabels.indexOf('住所選択') && (
                              <div>
                                {getUserData?.getUser ? (
                                  <>
                                    <FormControl fullWidth component="label">
                                      <RadioGroup
                                        value={deliverlyId}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                          setDeliverlyId((e.target as HTMLInputElement).value)
                                        }
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={12}>
                                            {listDeliveryData?.listSortDeliverys?.items?.map((item) => (
                                              <Grid container spacing={3} alignItems="center" justify="center">
                                                <Grid item xs={12}>
                                                  <DeliveryAddressList delivery={item ?? undefined} />
                                                </Grid>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </Grid>
                                      </RadioGroup>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <Grid container alignItems="center" justify="center">
                                      <Grid item xs={8} md={6}>
                                        <StyledButton
                                          fullWidth
                                          variant="contained"
                                          color="primary"
                                          onClick={async () => {
                                            setDelivery(
                                              listDeliveryData?.listSortDeliverys?.items?.find(
                                                (item) => item && item.id === deliverlyId,
                                              ) ?? undefined,
                                            );

                                            await updateGoodsCartMutation({
                                              variables: {
                                                input: {
                                                  // id: localStorage.getItem('goodsCartId') ?? '',
                                                  id: cartId,
                                                  deliveryId: deliverlyId,
                                                  totalPrice: 0,
                                                },
                                              },
                                            });
                                            void refetchListCard();
                                            void setActiveStep((nowStep) => nowStep + 1);
                                          }}
                                          disabled={!deliverlyId}
                                        >
                                          <Typography align="center" variant="subtitle1">
                                            次へ
                                          </Typography>
                                        </StyledButton>
                                      </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                  </>
                                ) : (
                                  <>ユーザー設定エラーです</>
                                )}
                              </div>
                            )}

                            {/* クレジットカード登録 */}
                            {/* {stepperLabels && activeStep === stepperLabels.indexOf('決済登録') && ( */}
                            {stepperLabels && activeStep === stepperLabels.indexOf('クレジットカード登録') && (
                              <div>
                                {getUserData?.getUser ? (
                                  <CardEditForm
                                    user={getUserData?.getUser}
                                    onEdit={() => {
                                      void refetchListCard();
                                      void setActiveStep((nowStep) => nowStep + 1);
                                    }}
                                  />
                                ) : (
                                  <>ユーザー設定エラーです</>
                                )}
                              </div>
                            )}

                            {/* クレジットカード選択 */}
                            {/* {stepperLabels && activeStep === stepperLabels.indexOf('決済選択') && ( */}
                            {stepperLabels && activeStep === stepperLabels.indexOf('クレジットカード選択') && (
                              <div>
                                {getUserData?.getUser ? (
                                  <CardSelect
                                    onClickNext={() => {
                                      if (refetchGoodsCart) {
                                        void refetchGoodsCart();
                                      }
                                      void setActiveStep((nowStep) => nowStep + 1);
                                    }}
                                    user={getUserData?.getUser}
                                    setCard={setCard}
                                    setTemporaryGoodsOrderId={setTemporaryGoodsOrderId}
                                    cartId={cartId}
                                  />
                                ) : (
                                  <>ユーザー設定エラーです</>
                                )}
                              </div>
                            )}

                            {/* 確認画面 */}
                            {/* {stepperLabels && activeStep === stepperLabels.indexOf('注文確定') && ( */}
                            {stepperLabels && activeStep === stepperLabels.indexOf('注文確定画面') && (
                              <div>
                                {getUserData?.getUser && card && delivery ? (
                                  <OrderConfirmation
                                    onClickNext={() => {
                                      // void refetchGoodsCart();
                                      void setActiveStep((nowStep) => nowStep + 1);
                                    }}
                                    user={getUserData?.getUser}
                                    card={card}
                                    delivery={delivery}
                                    temporaryGoodsOrderId={temporaryGoodsOrderId}
                                  />
                                ) : (
                                  <>ユーザー設定エラーです</>
                                )}
                              </div>
                            )}

                            {/* 購入完了画面 */}
                            {stepperLabels && activeStep === stepperLabels.indexOf('購入済み') && (
                              <div>
                                <Grid container alignItems="center" justify="center">
                                  <Grid item>
                                    <Typography>
                                      <Message messageCode="INFO_000001" />
                                    </Typography>
                                    <TwitterTag />
                                  </Grid>
                                </Grid>
                                <br />
                                <Grid container alignItems="center" justify="center">
                                  <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => void onClose()}>
                                      <Typography align="center" variant="body1">
                                        とじる
                                      </Typography>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          </StepContent>
                        </Step>
                      ))}
                  </Stepper>
                </Grid>
              </Grid>
            </Box>
          </StyledDrawer>
        )}
      </MuiDrawer>
    </>
  );
};

// Drawer表示内容のベーススタイル
const StyledDrawer = styled.div`
  // padding: 2%;
  height: 80vh;
  @media screen and (max-width: 1025px) {
    min-height: 400px;
  }
`;

export default FormDrawerVertical;
