import React, { FC } from 'react';
import Loading from 'HTB-Videos/components/Common/Loading';
import THEOPlayer from 'HTB-Videos/components/Common/THEOPlayer';
import usePallyconDRMconfig from 'HTB-Videos/hooks/usePallyconDRMconfig';
import { ViewMode } from 'HTB-Videos/utils/WPRequest';

export type Props = {
  /**
   * 再生対象のDASHのリソース
   */
  srcDASH: string;
  /**
   * 再生対象のHLSのリソース
   */
  srcHLS: string;
  /**
   * 再生対象動画のViewMode
   */
  viewMode?: ViewMode;
};

/**
 * Pallycon製DRMありの場合の動画Player
 */
const DRMPlayer: FC<Props> = ({ srcDASH, srcHLS, viewMode }) => {
  const { drmconfig } = usePallyconDRMconfig();

  return (
    <>
      {drmconfig.fairplay.headers['pallycon-customdata-v2'] &&
      drmconfig.playready.headers['pallycon-customdata-v2'] &&
      drmconfig.widevine.headers['pallycon-customdata-v2'] ? (
        <THEOPlayer
          source={{
            sources: [
              {
                src: srcDASH,
                type: 'application/dash+xml',
                contentProtection: drmconfig,
              },
              {
                src: srcHLS,
                type: 'application/x-mpegurl',
                contentProtection: drmconfig,
              },
            ],
            poster: '',
          }}
          viewMode={viewMode}
          onPlay={() => {
            // console.log('The player has started playing.');
          }}
        />
      ) : (
        <Loading loadingCode="Load_002" />
      )}
    </>
  );
};

export default DRMPlayer;
