import { Auth0LockPasswordless } from 'auth0-lock';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Auth0LockProvider from 'Auth0LockProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * Auth0 CLientの宣言
 */
const auth0Client = new Auth0LockPasswordless(
  process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  process.env.REACT_APP_AUTH0_DOMAIM ?? '',
  {
    language: 'ja',
    languageDictionary: {
      title: process.env.REACT_APP_AUTH0_LOGIN_TITLE ?? '',
      signUpWithLabel: '%sでログイン',
      socialLoginInstructions: process.env.REACT_APP_AUTH0_MESSEAGE ?? '',
      signUpTerms:
        '・<a href="https://www.htb.co.jp/htb/torikumi/terms.html" target="_blank" rel="noreferrer">利用規約（外部リンク）</a><br />・<a href="https://www.htb.co.jp/htb/privacy/htb_privacy.html" target="_blank" rel="noreferrer">個人情報の取り扱い（外部リンク）</a><br /><br />上記の認証アカウントをお持ちでない方<br />（<a href="https://support.google.com/accounts/answer/27441?hl=ja" target="_blank" rel="noreferrer">Googleアカウントを作成</a>）',
    },
    theme: {
      logo: process.env.REACT_APP_AUTH0_LOGIN_LOGO_URL ?? '',
    },
    auth: {
      responseType: 'token id_token',
      redirect: true,
      redirectUrl: `${window.location.protocol}//${window.location.host}`,
    },
  },
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0LockProvider client={auth0Client}>
        <App />
      </Auth0LockProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
