import React, { FC, useEffect } from 'react';
// import styled from 'styled-components';
// import PlayButtonImg from 'images/playbutton1.png';
// import { PlayerSource } from 'HTB-Aircaravan2021/utils/DRMconfig';
import { PlayerSource } from 'HTB-Aircaravan2021/hooks/usePallyconDRMconfig';
import './THEOPlayer.css';
import { ViewMode } from 'HTB-Aircaravan2021/utils/WPRequest';

/* const StyledTHEOPlayer = styled.div`
`; */

export type Props = {
  /**
   * 再生対象のリソース
   */
  source: PlayerSource;
  /**
   * 再生時に実行される処理
   */
  onPlay: () => void;
  /**
   * 再生対象動画のViewMode
   */
  viewMode?: ViewMode;
};

/**
 * THEOPlayer
 */
const THEOPlayer: FC<Props> = ({ source, onPlay, viewMode }) => {
  const el = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (el.current) {
      const player = window.THEOplayer.Player(el.current, {
        libraryLocation: 'https://cdn.myth.theoplayer.com/d3acc6f8-c54d-41ae-bed1-9b998bba56dc/',
        license:
          'sZP7IYe6T6PgCLbkTQ0o36kKCS4eFSacCla-TDBLTOzo0SRtIDBcCLft3L56FOPlUY3zWokgbgjNIOf9fKCZCohZ0SUKFDfoCSC-3uxeImz_CS4KFDPKIu1l0Lh_Cl3lIOfVfK4_bQgZCYxNWoryIQXzImf90SCZ3uRt3uai0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lB_3uec3SCoTS0ZFOPeWok1dDrLYtA1Ioh6TgV6UQ1gWtAVCYggb6rlWoz6FOPVWo31WQ1qbta6FOPtUtbpdwx6FYINIQ4qbZrJbOfVfG3zIZrEUQf-UKgeIDjLFKNif6i6IQ4oFK1zC6kodDxgWt0pdG56FOP1WYAVdDIrCYAiFK3qWmPUFOPeWok1dDrLYt3qUYPlImf9DZPzdQ4qbQc1sD4ZFK3qWmfVfKcqCoXVdQjLUOfVf6E6Ymi6box7bZf9DZPEUQkV3mPUFOPLIDreYog-bwPgbt3NWo_6TGxZUD4j',
        fluid: true,
        allowNativeFullscreen: true,
        ui: {
          playbackRates: [1],
          language: 'ja',
          languages: {
            ja: {
              Play: '再生',
              Pause: '一時停止',
              Mute: 'ミュート(消音)',
              Unmute: 'ミュート解除',
              Settings: '設定',
              Quality: '画質',
              Speed: '再生速度',
              Fullscreen: '全画面',
              Replay: 'もう一度',
              'Exit fullscreen': '全画面モードの終了',
              'Start Casting': 'テレビで再生',
            },
          },
        },
      });
      player.source = source;
      player.addEventListener('play', onPlay);
      // viewModeがliveの時はプリロードをoffにする
      player.preload = viewMode && viewMode === 'live' ? 'none' : 'auto';
    }
    // }, [el, onPlay, source, viewMode]);

    // 動画プレイヤーの意図しない再レンダリングを防ぐため第二引数は空配列にしてます
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer" ref={el} />;
};

export default THEOPlayer;
