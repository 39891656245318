// import ButtonBase from '@material-ui/core/ButtonBase';
import { createMuiTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import PhoneIcon from '@material-ui/icons/Phone';
import React, { FC, useEffect } from 'react';
import Moment from 'react-moment';
import initialTheme from 'HTB-Aircaravan2021/layouts/MuiTheme';
// import styled from 'styled-components';
// import { Order, BuyStatus } from 'graphql/appsync/generated';
import prefLists from 'HTB-Aircaravan2021/utils/ProvinceList';
import { GoodsOrder, CardBrand } from 'graphql/appsync/generated';
import imgAmex from '../../images/ico_amex.gif';
import imgDiners from '../../images/ico_diners.gif';
import imgDiscover from '../../images/ico_discover.gif';
import imgJcb from '../../images/ico_jcb.gif';
import imgMastercard from '../../images/ico_master.gif';
import imgVisa from '../../images/ico_visa.gif';

// type BuyStatusNote = Record<BuyStatus, string>;
type CardBrandNote = Record<CardBrand, string>;

type Props = {
  /**
   * 更新対象
   */
  order: GoodsOrder;
};

interface GoodsItem {
  id: string;
  title: string;
  cost: number;
  count: number;
  thumbnailUrl: string;
  releaseDate?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      margin: 'auto',
      maxWidth: 800,
    },
    textCenter: {
      textAlign: 'center',
    },
    textRight: {
      textAlign: 'right',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    img: {
      width: '20%',
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
  }),
);

const GoodsOrderList: FC<Props> = ({ order }) => {
  // const classes = useStyles();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [viewMode, setViewMode] = React.useState<'PC' | 'Mobile'>('Mobile');

  // material-ui カスタムテーマ設定
  const theme = createMuiTheme({
    ...initialTheme,
  });
  const isPCLayout = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobileLayout = useMediaQuery(theme.breakpoints.up('xs'));

  const cardBrandImagePath: CardBrandNote = {
    Visa: imgVisa,
    Mastercard: imgMastercard,
    AMEX: imgAmex,
    JCB: imgJcb,
    Diners: imgDiners,
    Discover: imgDiscover,
  };

  // 都道府県コードから都道府県データを取得
  const province = prefLists.find((n) => n.id === order.provinceCode ?? '');

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isPCLayout) {
      setViewMode('PC');
    } else if (isMobileLayout) {
      setViewMode('Mobile');
    }
  }, [isPCLayout, isMobileLayout]);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper} key={order.id} elevation={3}>
          <List className={classes.root}>
            <ListItem>
              <Typography>注文日：</Typography>
              <ListItemText className={classes.textRight}>
                <Moment format="YYYY年MM月DD日">{Date.parse(order.createdAt)}</Moment>
              </ListItemText>
            </ListItem>
            <ListItem>
              <Typography>注文の合計：</Typography>
              <ListItemText className={classes.textRight}>{Number(order.totalPrice).toLocaleString()} 円</ListItemText>
            </ListItem>
            {(order.stateByDate?.lastIndexOf('cancel') ?? -1) >= 0 && (
              <ListItem>
                <ListItemText className={classes.textRight}>（キャンセル済）</ListItemText>
              </ListItem>
            )}
            {order.specialItem === 'reservation' && (
              <ListItem>
                {/**
                <Typography>（予約販売商品が含まれています）</Typography>
                 */}
                <ListItemText className={classes.textRight}>（予約販売商品が含まれています）</ListItemText>
              </ListItem>
            )}
            <Divider />
            <ListItem button onClick={handleClick}>
              <ListItemText primary="注文の詳細を確認" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem className={classes.nested}>
                  <Typography>注文番号：</Typography>
                  <ListItemText className={classes.textRight}>{order.id}</ListItemText>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>注文の小計：</Typography>
                  <ListItemText className={classes.textRight}>
                    {Number(order.totalPrice - order.deliveryPrice + order.discount).toLocaleString()} 円
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>配送料・手数料：</Typography>
                  <ListItemText className={classes.textRight}>{order.deliveryPrice.toLocaleString()} 円</ListItemText>
                </ListItem>
                {order.discount > 0 && (
                  <ListItem className={classes.nested}>
                    <Typography>割引：</Typography>
                    <ListItemText className={classes.textRight}>
                      {Number(-1 * order.discount).toLocaleString()} 円
                    </ListItemText>
                  </ListItem>
                )}
                <ListItem className={classes.nested}>
                  <Typography>注文の合計：</Typography>
                  <ListItemText className={classes.textRight}>{order.totalPrice.toLocaleString()} 円</ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.nested}>
                  <Typography>発送の商品詳細</Typography>
                </ListItem>
                {(JSON.parse(order.items) as Array<GoodsItem>).map((item: GoodsItem) => (
                  <>
                    <Card style={{ margin: '3%' }}>
                      {/**
                      <ListItem className={classes.nested}>
                       */}
                      <ListItem>
                        <img className={classes.img} alt={item.id} src={item.thumbnailUrl} />
                        <ListItemText className={classes.textRight}>
                          {item.title}
                          <br />
                          （単価：{item.cost.toLocaleString()} 円）
                          <br />
                          数量：{item.count}
                          {viewMode === 'PC' && (
                            <>
                              <br />
                              （発売日：<Moment format="YYYY年MM月DD日">{item.releaseDate}</Moment>）
                            </>
                          )}
                        </ListItemText>
                      </ListItem>
                      {item.releaseDate && viewMode === 'Mobile' && (
                        <>
                          <ListItem>
                            <Typography>発売日：</Typography>
                            <ListItemText className={classes.textRight}>
                              <Moment format="YYYY年MM月DD日">{item.releaseDate}</Moment>
                            </ListItemText>
                          </ListItem>
                        </>
                      )}
                    </Card>
                  </>
                ))}

                {/**
                   *
                  <GridList className={classes.gridList} cols={1}>
                    {(JSON.parse(order.items) as Array<GoodsItem>).map((item: GoodsItem) => (
                      <GridListTile key={item.id}>
                        <img src={item.thumbnailUrl} alt={item.thumbnailUrl} />
                        <GridListTileBar
                          title={
                            <Typography variant="body2" style={{ paddingTop: '0.5vw' }}>
                              {item.title}
                            </Typography>
                          }
                          style={{ height: 85 }}
                          subtitle={
                            <Typography variant="body2" gutterBottom>
                              数量：{item.count} 個
                              <br />
                              価格：{item.cost} 円
                            </Typography>
                          }
                          classes={{
                            title: classes.textCenter,
                            subtitle: classes.textCenter,
                          }}
                        />
                      </GridListTile>
                    ))}
                  </GridList>
                </ListItem>
                   */}
                <Divider />
                <ListItem className={classes.nested}>
                  <Typography>お支払い方法</Typography>
                </ListItem>
                {order.cardBrand && (
                  <ListItem className={classes.nested}>
                    <img className={classes.img} alt={order.cardBrand} src={cardBrandImagePath[order.cardBrand]} />
                    <ListItemText className={classes.textRight}>
                      XXXX XXXX XXXX {order.cardNo}
                      <br />
                      {order.cardName}
                    </ListItemText>
                  </ListItem>
                )}
                <Divider />
                <ListItem className={classes.nested}>
                  <Typography>配送先住所</Typography>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>氏名：</Typography>
                  <ListItemText className={classes.textRight}>
                    {order.familyName} {order.givenName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>郵便番号：</Typography>
                  <ListItemText className={classes.textRight}>
                    〒 {order.postCode?.slice(0, 3)}-{order.postCode?.slice(3, 7)}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>住所：</Typography>
                  <ListItemText className={classes.textRight}>
                    {province?.name} {order.city} {order.address} {order.subAddress}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>電話番号：</Typography>
                  <ListItemText className={classes.textRight}>{order.phoneNumber?.replace('+81 ', '')}</ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Paper>
      </div>
    </>
  );
};

export default GoodsOrderList;
