// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Button, ButtonBase, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import React, { FC, useContext, Key } from 'react';
import Linkify from 'react-linkify';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { SecureLink } from 'react-secure-link';
import styled from 'styled-components';
import Auth0LockContext from 'Auth0LockContext';
import { ViewMode, wpChildContentData } from 'HTB-Videos/utils/WPRequest';
import { BuyStatus } from 'graphql/appsync/generated';
// import PlayIcon from './TicketCard.PlayIcon.png';
// import OnPlaybutton from '../../images/playbutton1.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      margin: theme.spacing(1),
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      '& a': {
        color: theme.palette.secondary.main,
      },
    },
  }),
);

// カード全体のスタイル
const StyledCard = styled(Card)`
  margin: 5px;
`;

// カード内コンテンツ全体のスタイル
const StyledCardContent = styled(CardContent)`
  // background-color: #231815;
  // background-color: #575757;
  margin: -10px;
  .button {
    // background-color: #fff;
  }
`;

// 価格文字列のスタイル
const StyledPrice = styled.div`
  color: red;
`;

// 上下中央寄せGrid用のスタイル
const StyledGridCenterJustify = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// 左寄せGrid用のスタイル
const StyledGridLeftJustify = styled(Grid)`
  display: flex;
  align-items: center;
`;

// 右寄せGrid用のスタイル
const StyledGridRightJustify = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

// サムネイル画像用のスタイル
// const StyledImg = styled.img`
//   position: absolute;
//   top: 35%;
//   left: 40%;
//   width: 20%;
// `;

// チケットに重ねる大きいアイコン用
// const StyledTicketImg = styled.img`
//   position: absolute;
//   top: auto;
//   // left: 40%;
//   width: 20%;
//   margin: 10px 0px;
//   min-width: 100px;
// `;

export type Props = {
  /**
   * ログイン済みか否かを示す真偽値
   */
  isLogin: boolean;

  /**
   * viewMode
   */
  viewMode: ViewMode;

  /**
   * 販売終了時間
   */
  saleFinishDate: string;

  /**
   * 子データ
   */
  childContent: wpChildContentData;

  /**
   * 購入状態
   */
  buyState: BuyStatus;

  /**
   * 購入ボタンクリック時に実行される関数
   */
  onClick: VoidFunction;

  /**
   * 国外アクセスかどうかの真偽値
   */
  geoStatusCode403: boolean;

  /**
   * 国内限定コンテンツかどうかの真偽値
   */
  isDomesticRestrict: boolean;
};

/**
 * チケット販売カードコンポーネント
 */
const TicketCard: FC<Props> = ({
  isLogin,
  viewMode,
  saleFinishDate,
  childContent,
  // orderData,
  buyState,
  onClick,
  geoStatusCode403,
  isDomesticRestrict,
}) => {
  const auth0Lock = useContext(Auth0LockContext);
  const { pathname } = useLocation();
  const classes = useStyles();

  // ボタンに表示する内容を取得する関数
  const getButtonContent = () => {
    if (geoStatusCode403 && isDomesticRestrict) {
      // 国内限定コンテンツかつ海外アクセスの場合
      return (
        <>
          <Grid container spacing={2}>
            <StyledGridLeftJustify item xs={12}>
              <CheckCircleOutlineIcon fontSize="small" />
              <Typography variant="body1" color="inherit">
                購入できません
              </Typography>
            </StyledGridLeftJustify>
          </Grid>
        </>
      );
    }

    if (isLogin && buyState === 'Bought') {
      // 購入済みの場合
      return (
        <>
          <Grid container spacing={2}>
            <StyledGridLeftJustify item xs={5}>
              <CheckCircleOutlineIcon fontSize="small" />
              <Typography variant="body1" color="inherit">
                購入済
              </Typography>
            </StyledGridLeftJustify>
            <StyledGridRightJustify item xs={7}>
              <Typography variant="body1" color="inherit">
                {saleFinishDate.slice(0, -3)}まで
              </Typography>
            </StyledGridRightJustify>
          </Grid>
        </>
      );
    }
    if (isLogin && buyState === 'Buying') {
      // 購入処理中の場合
      return (
        <>
          <Grid container spacing={2}>
            <StyledGridLeftJustify item xs={5}>
              <ErrorOutlineIcon fontSize="small" />
              <Typography variant="body1" color="inherit">
                購入処理中
              </Typography>
            </StyledGridLeftJustify>
          </Grid>
        </>
      );
    }
    // if (isLogin && (!childContent.acf.childIsSales || viewMode === 'close')) {
    if (!childContent.acf.childIsSales || viewMode === 'close') {
      // 販売終了している場合
      return (
        <>
          <Grid container spacing={2}>
            <StyledGridLeftJustify item xs={5}>
              <ErrorOutlineIcon fontSize="small" />
              <Typography variant="body1" color="inherit">
                販売終了
              </Typography>
            </StyledGridLeftJustify>
          </Grid>
        </>
      );
    }
    if (!isLogin || buyState === 'NotBought') {
      // 未ログイン、もしくは未購入時の場合
      return (
        <>
          <Grid container spacing={2}>
            <StyledGridLeftJustify item xs={5}>
              <CreditCardIcon fontSize="small" />
              <Typography variant="body1" color="inherit">
                購入
              </Typography>
            </StyledGridLeftJustify>
            <StyledGridRightJustify item xs={7}>
              <Typography variant="body1">
                <StyledPrice>¥{Number(childContent.acf.childPrice).toLocaleString()}</StyledPrice>
              </Typography>
            </StyledGridRightJustify>
          </Grid>
        </>
      );
    }

    // 想定外の場合
    return <></>;
  };

  // 本編再生ボタンに表示する内容を取得する関数
  const getPlayerLinkButtonText = () => {
    if (childContent.acf.isPastContent && !(viewMode === 'close')) {
      // 過去作品の場合
      return (
        <>
          <Typography variant="body1" color="inherit">
            本編再生ページへ進む
          </Typography>
        </>
      );
    }

    if (viewMode === 'live' || viewMode === 'archive') {
      // ライブまたはアーカイブの時
      return (
        <>
          <Typography variant="body1" color="inherit">
            本編再生ページへ進む
          </Typography>
        </>
      );
    }
    if (viewMode === 'release') {
      // リリースの時
      return (
        <>
          <Typography variant="body1" color="inherit">
            ライブ配信開始日時までお待ちください
            <br />
            （※開始時間10分前になりましたら画面の更新をおこなってください）
          </Typography>
        </>
      );
    }
    if (viewMode === 'prepare') {
      // アーカイブ準備中の時
      return (
        <>
          <Typography variant="body1" color="inherit">
            アーカイブ配信準備中です。
          </Typography>
        </>
      );
    }
    if (viewMode === 'close') {
      // クローズの時
      return (
        <>
          <Typography variant="body1" color="inherit">
            配信は終了しました
          </Typography>
        </>
      );
    }

    if (viewMode === 'archiveRelease') {
      // アーカイブの事前販売
      return (
        <>
          <Typography variant="body1" color="inherit">
            アーカイブ配信開始日時までお待ちください
          </Typography>
        </>
      );
    }

    // 想定外の場合
    return <></>;
  };

  // カード画像部に表示する内容を取得する関数
  const getCardMedia = () => {
    const cardMedia = (
      <CardMedia
        component="img"
        alt={childContent.acf.childTitle}
        image={childContent.acf.childThumbnailURL}
        className="media"
      />
    );

    // ライブ中orアーカイブ配信中or過去配信作品かつログイン済みかつ購入済みの場合
    // if (
    //   isLogin &&
    //   buyState === 'Bought' &&
    //   (viewMode === 'live' || viewMode === 'archive' || childContent.acf.isPastContent)
    // ) {
    //   return (
    //     // <Box borderColor="#3AABD2" border={2}>
    //     <Box borderColor="#fcdf52" border={2}>
    //       <ButtonBase component={Link} to={`/Player/${childContent.parent}/${childContent.id}`}>
    //       {cardMedia}
    //       {/* <StyledImg src={PlayIcon} alt="プレイアイコン" /> */}
    //       <StyledImg src={OnPlaybutton} alt="プレイアイコン" />
    //       </ButtonBase>
    //     </Box>
    //   );
    // }

    return cardMedia;
  };

  // 購入後のプロダクトカード概要欄に追加記述をしたい時用の関数
  const getOverviewContent = () => {
    // ログイン済みかつ購入済みの場合
    if (isLogin && buyState === 'Bought') {
      return (
        <>
          <Linkify
            componentDecorator={(decoratedHref: string, decoratedText: string, key: Key) => (
              <SecureLink href={decoratedHref} key={key}>
                {decoratedText}
              </SecureLink>
            )}
          >
            <pre>
              <Typography variant="body2" color="inherit" align="left" className={classes.description}>
                {childContent?.acf.childOverview}
              </Typography>
              <Typography variant="body2" color="inherit" align="left" className={classes.description}>
                {childContent?.acf.afterPurchaseMessage}
              </Typography>
            </pre>
          </Linkify>
        </>
      );
    }

    return (
      <pre>
        <Typography variant="body2" color="inherit" align="left" className={classes.description}>
          {childContent?.acf.childOverview}
        </Typography>
      </pre>
    );
  };

  return (
    <>
      <StyledCard>
        <StyledCardContent>
          <Grid container spacing={1}>
            <StyledGridCenterJustify item xs={3}>
              {getCardMedia()}
            </StyledGridCenterJustify>
            <Grid item xs={9} className="titleContainer" spacing={2}>
              <Typography variant="body1" color="inherit" gutterBottom>
                {childContent.acf.childTitle}
              </Typography>
              <Button
                className="button"
                size="small"
                variant="contained"
                fullWidth
                disabled={
                  // isLogin &&
                  (isDomesticRestrict && geoStatusCode403) ||
                  viewMode === 'close' ||
                  !childContent.acf.childIsSales ||
                  buyState === 'Bought' ||
                  buyState === 'Buying'
                }
                onClick={() => {
                  if (!isLogin) {
                    auth0Lock.client.show({
                      rememberLastLogin: false,
                      auth: {
                        params: {
                          state: `${pathname}`,
                        },
                      },
                    });
                  } else {
                    void onClick();
                  }
                }}
              >
                {getButtonContent()}
              </Button>
              {isLogin &&
              buyState === 'Bought' &&
              (viewMode === 'release' ||
                viewMode === 'prepare' ||
                viewMode === 'live' ||
                viewMode === 'archive' ||
                viewMode === 'close' ||
                childContent.acf.isPastContent) ? (
                <>
                  <Button
                    className="button"
                    size="small"
                    variant="contained"
                    fullWidth
                    disabled={
                      (childContent.acf.isPastContent && viewMode === 'close') ||
                      (!childContent.acf.isPastContent &&
                        (viewMode === 'release' ||
                          viewMode === 'prepare' ||
                          viewMode === 'close' ||
                          viewMode === 'archiveRelease'))
                    }
                    style={{ marginTop: 10, justifyContent: 'left' }}
                    component={Link}
                    to={`/Player/${childContent.parent}/${childContent.id}`}
                  >
                    <>
                      <PlayArrowRoundedIcon fontSize="small" />
                      {getPlayerLinkButtonText()}
                    </>
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Grid>
            {getOverviewContent()}
          </Grid>
        </StyledCardContent>
      </StyledCard>
    </>
  );
};

export default TicketCard;
