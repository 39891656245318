import axios from 'axios';
import { useEffect, useState } from 'react';

/**
 * Pallycon用DRMヘッダーを取得してからDRMconfigを返すhooks
 */
const usePallyconDRMconfig = (): {
  drmconfig: contentProtection;
} => {
  // 取得したdrmHeader情報の型
  type ResponseData = {
    data: {
      playready: string;
      widevine: string;
      fairplay: string;
    };
  };

  // 取得したdrmHeader
  const [drmHeader, setDrmHeader] = useState<ResponseData>();

  useEffect(() => {
    const getDrmHeader = async () => {
      // header情報取得
      try {
        const Response: ResponseData = await axios.get(
          // 'https://lvfehuwmlc.execute-api.ap-northeast-1.amazonaws.com/widevine',
          'https://sa6el4fiz7.execute-api.ap-northeast-1.amazonaws.com/widevine',
        );
        setDrmHeader(Response);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', err);
      }
    };
    void getDrmHeader();
  }, []);

  // プレイヤーに渡すDRM情報
  const drmconfig = {
    playready: {
      headers: {
        'pallycon-customdata-v2': drmHeader?.data?.playready,
      },
      licenseAcquisitionURL: 'https://license.pallycon.com/ri/licenseManager.do',
    },
    widevine: {
      headers: {
        'pallycon-customdata-v2': drmHeader?.data?.widevine,
      },
      licenseAcquisitionURL: 'https://license.pallycon.com/ri/licenseManager.do',
    },
    fairplay: {
      headers: {
        'pallycon-customdata-v2': drmHeader?.data?.fairplay,
      },

      licenseAcquisitionURL: 'https://license.pallycon.com/ri/licenseManager.do',
      certificate:
        'MIIFIDCCBAigAwIBAgIIZ+LeBh6rFKMwDQYJKoZIhvcNAQEFBQAwfzELMAkGA1UEBhMCVVMxEzARBgNVBAoMCkFwcGxlIEluYy4xJjAkBgNVBAsMHUFwcGxlIENlcnRpZmljYXRpb24gQXV0aG9yaXR5MTMwMQYDVQQDDCpBcHBsZSBLZXkgU2VydmljZXMgQ2VydGlmaWNhdGlvbiBBdXRob3JpdHkwHhcNMTkwOTE4MDIzNzA0WhcNMjEwOTE4MDIzNzA0WjCBnjELMAkGA1UEBhMCVVMxMjAwBgNVBAoMKUhPS0tBSURPIFRFTEVWSVNJT04gQlJPQURDQVNUSU5HIENPLixMVEQuMRMwEQYDVQQLDAo5MzRSOEpTOUs2MUYwRAYDVQQDDD1GYWlyUGxheSBTdHJlYW1pbmc6IEhPS0tBSURPIFRFTEVWSVNJT04gQlJPQURDQVNUSU5HIENPLixMVEQuMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDV7DYPH19bcy03gFNv1p2JFo/Oe+EGiEoMHXvxXwrQhRf9756/RMbUgAZkzdLV3VwH3S2CVoUuS4UAEbhAaqHMJkMobIuJx8HGK8ohTI5TpbEWsILXaCXB5sW31OT16tfIHpe3c+/xlvzto1eLU9YwFuFEICRHRReIBiSGkHj6EwIDAQABo4ICAjCCAf4wDAYDVR0TAQH/BAIwADAfBgNVHSMEGDAWgBRj5EdUy4VxWUYsg6zMRDFkZwMsvjCB4gYDVR0gBIHaMIHXMIHUBgkqhkiG92NkBQEwgcYwgcMGCCsGAQUFBwICMIG2DIGzUmVsaWFuY2Ugb24gdGhpcyBjZXJ0aWZpY2F0ZSBieSBhbnkgcGFydHkgYXNzdW1lcyBhY2NlcHRhbmNlIG9mIHRoZSB0aGVuIGFwcGxpY2FibGUgc3RhbmRhcmQgdGVybXMgYW5kIGNvbmRpdGlvbnMgb2YgdXNlLCBjZXJ0aWZpY2F0ZSBwb2xpY3kgYW5kIGNlcnRpZmljYXRpb24gcHJhY3RpY2Ugc3RhdGVtZW50cy4wNQYDVR0fBC4wLDAqoCigJoYkaHR0cDovL2NybC5hcHBsZS5jb20va2V5c2VydmljZXMuY3JsMB0GA1UdDgQWBBQ1eDRi8IGCUGxoU5vjMJ2FzvI1AjAOBgNVHQ8BAf8EBAMCBSAwOAYLKoZIhvdjZAYNAQMBAf8EJgF1b25wNWRoMjAzcnBua2loYjlmN3V2cDJqcGdnb29mZWEwaHdsMEgGCyqGSIb3Y2QGDQEEAQH/BDYBeTRkbGlzYmw5YXB1Z2t0b2tpN2szYzZuMWZ6cHExcG9mYmNrdWVveTByeHhpeW1ndGYxYnowDQYJKoZIhvcNAQEFBQADggEBAClGh0nvGcbHc7Aeow6+1YlyVwW09goZ7V8wd7ngPw60gcL9J/Ig5LPB4inT0VytEzW7X6om1vEos4JdNBcXzXvIjHJtkfYuJbCgUfa1xtspVgdWMP8u73CjQUMBzQy++p+W+laJ28BODsAXIE7VWoMZD6B/7QRf51AVhHOpdd6wRlqKkcZ9edprbD/GVrt0TBR40sjSxAJlPZV5DVLZo5JK66Jc5PSA7Ay+VYbYCIC70ojwuDIvTgwxZohrhD61XG2NMIV/5TtEZ971AXFC3e0fXnWK7C7HLzXZWZM5oDCMUC857EF/csHa1cBXIKQzVQ2sbDOEYv5x0tPtQ0fWPTA=',
    },
  };

  return { drmconfig };
};

export default usePallyconDRMconfig;

/**
 * DRMの型
 */
export type contentProtection = {
  playready: {
    headers: {
      'pallycon-customdata-v2': string | undefined;
    };
    licenseAcquisitionURL: string;
  };
  widevine: {
    headers: {
      'pallycon-customdata-v2': string | undefined;
    };
    licenseAcquisitionURL: string;
  };
  fairplay: {
    headers: {
      'pallycon-customdata-v2': string | undefined;
    };
    licenseAcquisitionURL: string;
    certificate: string;
  };
};

export type PlayerSource = {
  sources: {
    /**
     * 動画ソース
     */
    src: string;
    /**
     * MIME
     */
    type: string;
    /**
     * DRMの型
     */
    contentProtection?: contentProtection;
  }[];
  poster: string;
};
