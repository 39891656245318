import axios from 'axios';

const youtubeCustomPlayList = axios.create({
  baseURL: `${process.env.REACT_APP_YOUTUBE_ENDPOINT ?? ''}`,
});

export default youtubeCustomPlayList;

/**
 * APIgatewayから取得するyoutube再生リストのデータ
 */
export type youtubeCustomData = {
  /**
   * videoID
   */
  videoId: string;
  /**
   * サムネイルURL
   */
  thumbnail: string;
};

export type youtubePlayerData = {
  /**
   * 動画タイトル
   */
  title: string;
  /**
   * 動画概要
   */
  description: string;
  /**
   * サムネイルURL
   */
  thumbnail: string;
};
