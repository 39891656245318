import { Button, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';

export type Props = {
  /**
   * ローディング中であるかを示す真偽値
   */
  isLoading: boolean;

  /**
   * ボタン押下時に実行される処理
   */
  onClick: VoidFunction;
};

/**
 * カートに保存した商品を削除するボタン
 */
const RemoveLineItemButton: FC<Props> = ({ isLoading, onClick }) =>
  isLoading ? (
    <Loading />
  ) : (
    <Button variant="contained" onClick={() => onClick()} size="small">
      {/* <Typography align="center" variant="body1"> */}
      <Typography align="center" variant="caption">
        削除
      </Typography>
    </Button>
  );

export default RemoveLineItemButton;
