import { Typography, Grid, Button } from '@material-ui/core';
import axios from 'axios';
import React, { FC, useState } from 'react';
// import { getAuthProfile } from 'Auth0LockContext';
import styled from 'styled-components';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import Message from 'HTB-Aircaravan2021/components/Common/Message';
import CardList from 'HTB-Aircaravan2021/components/MyPage/CardList';
import DeliveryAddressList from 'HTB-Aircaravan2021/components/MyPage/DeliveryAddressList';
import UserStyle from 'HTB-Aircaravan2021/components/MyPage/UserStyle';
import { User, Card, Delivery } from 'graphql/appsync/generated';
import { MessageCode } from 'utils/Message';

type Props = {
  /**
   * 次へボタンクリック時の処理
   */
  onClickNext: VoidFunction;
  /**
   * 請求先
   */
  user: User;
  /**
   * 支払い
   */
  card: Card;
  /**
   * 配送先
   */
  delivery: Delivery;
  /**
   * temporaryGoodsOrderIdを設定する処理
   */
  temporaryGoodsOrderId: string;
};

type typeResponseData = {
  executionArn: string;
  startDate: number;
};

// 購入に進む系のボタンの色はオレンジ
const StyledButton = styled(Button)`
  background-color: #ff9100 !important;
`;

const OrderConfirmation: FC<Props> = ({ onClickNext, user, card, delivery, temporaryGoodsOrderId }) => {
  // エラーコード
  const [errorCode, setErrorCode] = useState<MessageCode | undefined>();
  // StepFunctionsのLoading状態
  const [isSFLoading, setIsSFLoading] = useState(false);
  // StepFunctionsのエラー回数カウント
  const [SFErrorCount, setSFErrorCount] = useState(0);

  // 次へボタン押下時の処理
  const onSubmit = () => {
    setIsSFLoading(true);
    const submit = async () => {
      // 1. 在庫計算・決済StepFunctions起動
      try {
        await axios
          .post(
            process.env.REACT_APP_GOODS_INVENTORY_CONTROL_START_EXECUTION_URL ?? '',
            {
              input: { temporaryGoodsOrderId },
              stateMachineArn: process.env.REACT_APP_GOODS_INVENTORY_CONTROL_STEP_FUNCTIONS_ARN,
            },
            {
              headers: {
                Authorization: localStorage.getItem('id_token'),
              },
            },
          )
          .then((response) => {
            // console.log(response);
            if (response.status !== 200) {
              // データ送信失敗
              // failureStartApi();
              setErrorCode('ERR_000002');

              return;
            }
            // 初期化
            setSFErrorCount(0);

            // タイマーのsetTimeoutに変更
            setTimeout(() => {
              void getDataPolling(
                response.data,
                process.env.REACT_APP_GOODS_INVENTORY_CONTROL_DESCRIBE_EXECUTION_URL ?? '',
              );
            }, 2000);
          });
        // データ送信失敗
      } catch (e) {
        // console.log(e);
        // failureStartApi();
        setErrorCode('ERR_000002');
      }
    };

    void submit();
  };

  /**
   * レスポンス受け取り用関数
   */
  const getDataPolling = async (responseData: typeResponseData, url: string) => {
    type Response = {
      status: string;
      output: string;
    };

    try {
      // await apolloClient.cache.reset();
      await axios.post<Response>(url, responseData).then((response) => {
        if (response.status !== 200) {
          // 成功以外を返してもループ
          // 1分以上エラーでリトライした場合
          if (SFErrorCount > 12) {
            return;
          }
          setSFErrorCount((nowCount) => nowCount + 1);

          // 再起
          setTimeout(() => {
            void getDataPolling(responseData, url);
          }, 5000);
        } else if (response.data.status === 'SUCCEEDED') {
          // ステータスコードによる分岐あり
          // const reg = new RegExp(/ERR_[0-9]{6}/g);
          // while (reg.exec(response.data.output)) {
          //  const str = RegExp.lastMatch;
          const resArray = response.data.output.match(/ERR_[0-9]{6}/g);
          if (resArray === null || resArray.length === 0) {
            void onClickNext();
            // setErrorCode(resArray[0].toString());
          } else {
            switch (resArray[0]) {
              case 'ERR_000101': {
                setErrorCode('ERR_000101');
                break;
              }
              case 'ERR_000102': {
                setErrorCode('ERR_000102');
                break;
              }
              case 'ERR_000103': {
                setErrorCode('ERR_000103');
                break;
              }
              case 'ERR_000104': {
                setErrorCode('ERR_000104');
                break;
              }
              case 'ERR_000105': {
                setErrorCode('ERR_000105');
                break;
              }
              case 'ERR_000106': {
                setErrorCode('ERR_000106');
                break;
              }
              case 'ERR_000402': {
                setErrorCode('ERR_000402');
                break;
              }
              default: {
                setErrorCode('ERR_000003');
              }
            }
          }
          // 成功、注文完了画面へ
          // void onClickNext();
        } else if (response.data.status === 'FAILED') {
          // 失敗バックエンドでエラー発生
          setErrorCode('ERR_000003');
        } else if (response.data.status === 'RUNNING') {
          // RUNNING
          setTimeout(() => {
            void getDataPolling(responseData, url);
          }, 1000);
        } else {
          // 中断・タイムアウト
          setErrorCode('ERR_000003');
        }
      });
    } catch (e) {
      // 1分以上エラーでリトライした場合
      if (SFErrorCount > 12) {
        setErrorCode('ERR_000003');

        return;
      }
      void setSFErrorCount((nowCount) => nowCount + 1);

      // 再起
      setTimeout(() => {
        void getDataPolling(responseData, url);
      }, 5000);
    }
  };

  return (
    <>
      {errorCode && (
        <>
          <div style={{ padding: 15 }}>
            <Message messageCode={errorCode} />
          </div>
        </>
      )}
      {!errorCode && isSFLoading && <Loading loadingCode="Load_003" />}
      {!errorCode && !isSFLoading && (
        <>
          <Typography>請求書送付先</Typography>
          <UserStyle user={user} />
          <br />
          <Typography>配送先住所</Typography>
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item xs={12}>
              <DeliveryAddressList delivery={delivery} previewMode />
            </Grid>
          </Grid>
          <br />
          <Typography>クレジットカード情報</Typography>
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item xs={12}>
              <CardList card={card} previewMode />
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container alignItems="center" justify="center">
            <Grid item xs={10} md={6}>
              <StyledButton fullWidth onClick={onSubmit}>
                <Typography align="center" variant="subtitle1" color="inherit">
                  購入する
                </Typography>
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </>
      )}
    </>
  );
};

export default OrderConfirmation;
