// import { Grid } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
// import { isIOS, getUA, isTablet, isDesktop, isMobile } from 'react-device-detect';
import { isMobile } from 'react-device-detect';
import Iframe from 'react-iframe';
import styled from 'styled-components';

// PC用横向き動画用スタイル
const StyledFixed = styled.div`
   {
    width: 100%;
    // padding-bottom: 56.25%;
    padding-bottom: 58%;
    height: 0px;
    min-width: 630px;
    position: relative;
    margin-bottom: 1.5rem;
    padding-top: 60px;
    // padding-top: 47px;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

// スマホ・タブレット用縦or横向き動画用スタイル
const StyledVariabled = styled.div`
   {
    width: 100%;
    height: 0px;
    position: relative;
    margin-bottom: 1.5rem;
    padding-top: 60px;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export type Props = {
  /**
   * 再生対象のリソース
   */
  source: string;
  /**
   * 国外アクセスかどうかの真偽値
   */
  geoStatusCode403: boolean;
};

// マルチアングルプレイヤーのコンポーネント
const GnzoPlayer: FC<Props> = ({ source, geoStatusCode403 }) => {
  // スマホ画面が水平になった時の状態を管理
  // const [paddingBottom, setPaddingBottom] = useState('140.625%');
  const [paddingBottom, setPaddingBottom] = useState('142%');
  useEffect(() => {
    // window幅リサイズを監視する関数
    const handleResize = () => {
      if (window.innerWidth > window.innerHeight) {
        // setPaddingBottom('56.25%');
        setPaddingBottom('58%');
      } else {
        // setPaddingBottom('140.625%');
        setPaddingBottom('142%');
      }
      // console.log('window.innerWidth', window.innerWidth);
    };
    const handler = () => isMobile && window.addEventListener('resize', handleResize);
    void handler();
    // window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [paddingBottom]);

  return (
    <>
      {isMobile ? (
        <>
          {/* <h2>{getUA}</h2>
          {isMobile && <h2>MOBILEEEEEE</h2>} */}
          {/* {geoStatusCode403 ? <h2>403だよ</h2> : <h2>403じゃないよ</h2>} */}
          <StyledVariabled style={{ paddingBottom }}>
            {geoStatusCode403 ? (
              <>
                <img alt="sorry" src={source} />
              </>
            ) : (
              <Iframe
                url={source}
                id="myId"
                // scrolling="no"
                // className="myClassname"
                // position="relative"
                // loading="eager"
                allowFullScreen
                // styles={{ height: '25px' }}
              />
            )}
          </StyledVariabled>
        </>
      ) : (
        <>
          {/* <h2>{source}</h2> */}
          {/* {geoStatusCode403 ? <h2>403だよ</h2> : <h2>403じゃないよ</h2>} */}
          <StyledFixed>
            {geoStatusCode403 ? (
              <>
                <img alt="sorry" src={source} />
              </>
            ) : (
              <>
                <Iframe
                  url={source}
                  id="myId"
                  // position="relative"
                  allowFullScreen
                  // scrolling="no"
                  // loading="eager"
                  // styles={{ height: '25px' }}
                />
              </>
            )}
          </StyledFixed>
        </>
      )}
    </>
  );
};

export default GnzoPlayer;
