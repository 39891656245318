import { Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import WPFooter from '../components/Common/WPFooterAxios';
import { wpFooterData } from '../utils/WPRequest';

const StyledCopyright = styled.div`
  padding: 10px;
  h2 {
    text-align: center;
    font-weight: bold;
    margin: 10px 0px;
    font-size: 2rem;
    border-bottom: 5px solid #c69e61;
    padding-bottom: 10px;
    margin-bottom: 20px;

    @media screen and (max-width: 751px) {
       {
        font-size: 1.2rem;
      }
    }
  }

  p {
    line-height: 1.2rem;
  }
`;

/**
 * 著作権についてのページ
 */
const Copyright: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [footerText, setFooterText] = useState('');

  useEffect(() => {
    const getContents = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const response: wpFooterData = await WPFooter.get(`133`);
        setFooterText(response.data.content.rendered ?? '');
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    void getContents();
  }, []);

  return (
    <StyledCopyright>
      {isError && (
        <Typography gutterBottom variant="body1" align="center" color="error">
          エラーが発生しました
          <br />
        </Typography>
      )}
      {isLoading ? (
        <Loading loadingCode="Load_002" />
      ) : (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: footerText,
            }}
          />
        </>
      )}
    </StyledCopyright>
  );
};

export default Copyright;
