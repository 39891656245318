import { AppBar, Toolbar, IconButton, Typography, Badge } from '@material-ui/core';
// import { Redeem, ShoppingCart } from '@material-ui/icons';
import { ShoppingCart } from '@material-ui/icons';
import React, { FC } from 'react';

import styled from 'styled-components';
import Loading from 'HTB-Videos/components/Common/Loading';
import { ViewMode } from 'HTB-Videos/utils/WPRequest';

const StyledAppbar = styled.div`
  flex-grow: 1;
`;

const StyledTitle = styled.div`
  flex-grow: 1;
  text-align: center;
  font-size: 1.6rem;

  // @media screen and (max-width: 751px) {
  //   font-size: 0.6rem;
  //   font-weight: bold;
  // }
  padding: 8px 0px;
`;

export type Props = {
  /**
   * ローディング中であるかを示す真偽値
   */
  isLoading: boolean;

  /**
   * カートボタンのバッジに表示する商品数
   */
  itemCount: number;

  /**
   * 商品ボタンをクリックした時に実行される関数
   */
  productButtonOnClickFunction: VoidFunction;

  /**
   * カートボタンをクリックした時に実行される関数
   */
  cartButtonOnClickFunction: VoidFunction;

  /**
   * セールスエリアヘッダーに表示するタイトル
   */
  title: string;
  /**
   * wp親記事のviewMode
   * カート確認ボタン、ヘッダーアイコンの非活性時のフラグで利用
   */
  viewMode?: ViewMode;
};

const LiveSalesArea: FC<Props> = ({
  isLoading,
  itemCount,
  // productButtonOnClickFunction,
  cartButtonOnClickFunction,
  children,
  title,
  viewMode,
}) => (
  <>
    {isLoading ? (
      <Loading loadingCode="Load_001" />
    ) : (
      <>
        <StyledAppbar>
          <AppBar position="static">
            <Toolbar>
              {/* <StyledTitle>onライン劇場グッズ！</StyledTitle> */}
              <StyledTitle>
                <Typography variant="subtitle1" color="inherit" align="center">
                  {title}
                </Typography>
              </StyledTitle>
              {/* <IconButton onClick={() => productButtonOnClickFunction()} disabled={viewMode && viewMode === 'close'}>
                <Redeem />
              </IconButton> */}
              <IconButton onClick={() => cartButtonOnClickFunction()} disabled={viewMode === 'close'}>
                <Badge badgeContent={itemCount} max={99} color="secondary">
                  {/* <ShoppingCart style={{ color: 'fff' }} /> */}
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </Toolbar>
          </AppBar>
        </StyledAppbar>
        {children}
      </>
    )}
  </>
);

export default LiveSalesArea;
