import { Button, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import Loading from 'HTB-Videos/components/Common/Loading';

export type Props = {
  /**
   * ボタン押下時に実行される処理
   */
  onClick: VoidFunction;

  /**
   * ローディング中であるかを示す真偽値
   */
  isLoading: boolean;

  /**
   * 販売可能であるかを示す真偽値
   */
  isAvailableForSale?: boolean;

  /**
   * ボタンの活性・非活性を示す真偽値
   */
  isAvailable: boolean;

  /**
   * ボタンに表示するテキスト
   */
  cartButtonText: string;
};

/**
 * 商品をカートに追加するボタン
 */
const AddToCartButton: FC<Props> = ({ onClick, isLoading, isAvailableForSale, isAvailable, cartButtonText }) => (
  <>
    {isLoading ? (
      <Loading />
    ) : (
      <Button
        onClick={() => onClick()}
        variant="contained"
        color="primary"
        disabled={!isAvailableForSale || !isAvailable}
      >
        <Typography align="center" variant="body2">
          {cartButtonText}
        </Typography>
      </Button>
    )}
  </>
);

export default AddToCartButton;
