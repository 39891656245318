import { Grid, Typography, Button, Box } from '@material-ui/core/';
import axios from 'axios';
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import Auth0LockContext, { isAuthenticated } from 'Auth0LockContext';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import FormDrawerVertical from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/FormDrawerVertical';
import LineItemCardAirCaravan from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/LineItemCardAirCaravan';
import { itemList } from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';
import { GoodsCart } from 'graphql/appsync/generated';
import { MessageCode } from 'utils/Message';
import Message from 'HTB-Aircaravan2021/components/Common/Message';
import { useGetGoodsCartQuery } from 'graphql/appsync/generated';

export type Props = {
  /**
   * 前のページへ切り替える関数
   */
  previousPagingFunction: VoidFunction;

  /**
   * 表示するカート情報
   */
  goodsCart?: GoodsCart;

  /**
   * ロード中であるかを示す真偽値
   */
  isLoading: boolean;
  /**
   * グッズカート内Items情報
   */
  Items: itemList[];
};

// 購入に進む系のボタンの色はオレンジ
const StyledButton = styled(Button)`
  background-color: #ff9100 !important;
`;

/**
 * カート情報を表示するコンポーネント
 */
const Cart: FC<Props> = ({ previousPagingFunction, goodsCart, isLoading, Items }) => {
  const auth0Lock = useContext(Auth0LockContext);
  localStorage.removeItem('auth_show');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const ItemLists = goodsCart?.items
    ? (JSON.parse(goodsCart?.items) as Array<itemList>).map((item) => ({
        id: item.id,
        count: item.count,
        title: item.title,
        thumbnailUrl: item.thumbnailUrl,
        cost: item.cost,
        maxNumOfPurchase: item.maxNumOfPurchase,
      }))
    : [];

  // カート合計金額計算
  const totalPrice = Items?.reduce((prev, current) => prev + (current?.cost ?? 0) * (current?.count ?? 0), 0);

  const {
    data: goodsCartData,
    loading: isGoodsCartDataLoading,
    refetch: refetchGoodsCart,
  } = useGetGoodsCartQuery({
    variables: { id: localStorage.getItem('goodsCartId') ?? '' },
  });

  // StepFunctionsのエラー回数カウント
  // const [SFErrorCount, setSFErrorCount] = useState(0);
  let sfErrorCount = 0;

  // レジへ進むボタンの表示非表示
  const [cashBtnViewFlag, setCashBtnViewFlag] = useState(true);

  // エラーコード
  const [errorCode, setErrorCode] = useState<MessageCode | undefined>();

  // カート在庫チェックloading
  const [cartCheckLoading, setCartCheckLoading] = useState(false);

  // カート内在庫チェック
  const cartCheck = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_CARTCHECK_START_EXECUTION_URL ?? '',
          {
            // input: { goodsCartId: localStorage.getItem('goodsCartId') ?? '' },
            input: { goodsCartId: goodsCart?.id },
            stateMachineArn: process.env.REACT_APP_CARTCHECK_STEP_FUNCTIONS_ARN,
          },
          {
            headers: {
              Authorization: localStorage.getItem('id_token'),
            },
          },
        )
        .then((response) => {
          if (response.status !== 200) {
            // データ送信失敗
            // setErrorCode('ERR_000002');
            setCashBtnViewFlag(true);
            setCartCheckLoading(false);
            return;
          }
          // 初期化
          // setSFErrorCount(0);
          sfErrorCount = 0;

          // タイマーのsetTimeoutに変更
          setTimeout(() => {
            void getDataPolling(response.data, process.env.REACT_APP_GOODS_PRIECE_DESCRIBE_EXECUTION_URL ?? '');
          }, 2000);
        });
      // データ送信失敗
    } catch (e) {
      // failureStartApi();
      // setErrorCode('ERR_000002');
      setCashBtnViewFlag(true);
      setCartCheckLoading(false);
    }
  };

  /**
   * レスポンス受け取り用関数
   */
  const getDataPolling = async (responseData: unknown, url: string) => {
    type Response = {
      status: string;
      output: string;
    };

    try {
      // await apolloClient.cache.reset();
      await axios.post<Response>(url, responseData).then((response) => {
        if (response.status !== 200) {
          // 成功以外を返してもループ
          // 1分以上エラーでリトライした場合
          // if (SFErrorCount > 12) {
          if (sfErrorCount > 12) {
            void setIsDrawerOpen(true);
            setCashBtnViewFlag(true);
            setCartCheckLoading(false);
            return;
          }
          // setSFErrorCount((nowCount) => nowCount + 1);
          sfErrorCount += 1;

          // 再起
          setTimeout(() => {
            void getDataPolling(responseData, url);
          }, 5000);
        } else if (response.data.status === 'SUCCEEDED') {
          // ステータスコードによる分岐あり
          // 成功、注文完了画面へ
          // const jsonData = JSON.parse(response.data.output) as resJson;
          // console.log(jsonData.paymentIntentId);
          // void setTemporaryGoodsOrderId(jsonData.paymentIntentId);
          // void onClickNext();
          // console.log('SUCCEEDED');
          // console.log(response.data.output);

          // 先頭から1文字を削除
          // let outputData = response.data.output.slice(1);
          // 末尾から1文字を削除
          // outputData = outputData.slice(0, -1);
          // console.log(outputData);

          /* if (outputData == 'True') {
            // 変更なし　正常
            console.log('変更なし　正常');
            void setIsDrawerOpen(true);
          } else if (outputData == 'ERR_000001') {
            // SUCCEEDEDエラー 更新ありの状態
            setErrorCode('ERR_000001');
          }
          setCashBtnViewFlag(true); */

          const resArray = response.data.output.match(/ERR_[0-9]{6}/g);
          if (resArray === null || resArray.length === 0) {
            // 正常
            void setIsDrawerOpen(true);
            // void refetchGoodsCart();
          } else {
            switch (resArray[0]) {
              case 'ERR_000403': {
                setErrorCode('ERR_000403');
                break;
              }
            }
          }

          void refetchGoodsCart();

          setCashBtnViewFlag(true);
          setCartCheckLoading(false);
        } else if (response.data.status === 'FAILED') {
          // 失敗バックエンドでエラー発生
          // setErrorCode('ERR_000002');
          void setIsDrawerOpen(true);

          setCashBtnViewFlag(true);
          setCartCheckLoading(false);
        } else if (response.data.status === 'RUNNING') {
          // RUNNING
          setTimeout(() => {
            void getDataPolling(responseData, url);
          }, 1000);
        } else {
          // 中断・タイムアウト
          // setErrorCode('ERR_000002');
          void setIsDrawerOpen(true);

          setCashBtnViewFlag(true);
          setCartCheckLoading(false);
        }
      });
    } catch (e) {
      // 1分以上エラーでリトライした場合
      // if (SFErrorCount2 > 12) {
      if (sfErrorCount > 12) {
        // setErrorCode('ERR_000002');
        void setIsDrawerOpen(true);
        setCashBtnViewFlag(true);
        setCartCheckLoading(false);
        return;
      }
      // void setSFErrorCount((nowCount) => nowCount + 1);
      sfErrorCount += 1;

      // 再起
      setTimeout(() => {
        void getDataPolling(responseData, url);
      }, 5000);
    }
  };

  return (
    <>
      <br />
      {/* ロード中の場合 */}
      {isLoading && <Loading loadingCode="Load_001" />}
      {/* ロード中では無い場合 */}
      {!isLoading && (
        <>
          {ItemLists && ItemLists.length > 0 ? (
            // カートに商品がある場合
            <>
              {ItemLists.map((item) => (
                <Grid container spacing={2} justify="center" key={item.id}>
                  <Grid item xs={11} sm={7} md={5}>
                    <LineItemCardAirCaravan lineItem={item} Items={Items} />
                  </Grid>
                </Grid>
              ))}
              <br />
              <Grid container justify="center">
                <Typography gutterBottom variant="h2">
                  小計：&ensp;
                  {Number(totalPrice).toLocaleString()}&ensp;円（税込）&ensp;&#043;&ensp;送料
                </Typography>
              </Grid>
            </>
          ) : (
            // カートに商品が無い場合
            <Grid container alignItems="center" justify="center" style={{ height: 200 }}>
              <Grid item>
                <Typography gutterBottom variant="body1" align="center">
                  カートに商品はありません
                </Typography>
              </Grid>
            </Grid>
          )}
          <br />
          <Grid container justify="center" alignItems="center" spacing={2}>
            {cartCheckLoading && (
              <>
                <Loading loadingCode="Load_001" />
              </>
            )}
            {errorCode && (
              <>
                <div style={{ padding: 15 }}>
                  <Message messageCode={errorCode} />
                </div>
              </>
            )}
            {cashBtnViewFlag && ItemLists && ItemLists.length > 0 && (
              <Grid item xs={10}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    if (!isAuthenticated()) {
                      localStorage.setItem('auth_show', 'true');
                      auth0Lock.client.show({ rememberLastLogin: false });
                    } else {
                      setErrorCode(undefined);
                      setCashBtnViewFlag(false);
                      setCartCheckLoading(true);
                      cartCheck();

                      //void setIsDrawerOpen(true);
                    }
                  }}
                  // 通常時
                  // disabled={!ItemLists || ItemLists.length < 1}
                  // グッズ販売エリアクローズ時、念のためdisableにしとく
                  disabled
                >
                  <Typography align="center" variant="subtitle1">
                    レジへ進む
                  </Typography>
                </StyledButton>
              </Grid>
            )}

            <Grid item xs={10} style={{ textAlign: 'center' }}>
              <Button variant="contained" color="primary" onClick={() => previousPagingFunction()}>
                <Typography align="center" variant="body1">
                  商品一覧に戻る
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {/* <FormDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} /> */}
      <FormDrawerVertical isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
    </>
  );
};

export default Cart;
