import { Typography, Grid, Box, AppBar, Toolbar, Fab, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Twitter } from '@material-ui/icons';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { TwitterResponseData } from 'HTB-Aircaravan2021/components/Twitter/Container/TwitterArea';
import TweetCard from 'HTB-Aircaravan2021/components/Twitter/TweetCard';
// import styled from 'styled-components';

const StyledLink = styled.div`
   {
    padding: 10px 0px;
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: `1px solid slategrey`,
    },
    padding: `6px 8px`,
    overflowY: 'scroll',
  },
}));

const useStylesLinerProgress = makeStyles({
  root: {
    width: '100%',
    marginBottom: 5,
  },
});

export type Props = {
  /**
   * PCレイアウト版twitter表示エリアをするか否かの真偽値
   */
  isPCLayout: boolean;
  /**
   * twitter表示エリア高さの最大値
   */
  maxHeightForTwitterArea: number;
  /**
   * twitter取得時ローディングを表す真偽値
   */
  isTwiLoading: boolean;
  /**
   * progressバーの進行状態を表す数値
   */
  progress: number;
  /**
   * 表示用tweet配列
   */
  DisplayTweets: TwitterResponseData[];
  /**
   * TopNavボタンの出現状態を表す真偽値
   */
  isTopNavigation: boolean;
};

/**
 * twitter表示エリアコンポーネント
 */
const TwitterArea: FC<Props> = ({
  isPCLayout,
  maxHeightForTwitterArea,
  isTwiLoading,
  progress,
  DisplayTweets,
  isTopNavigation,
}) => {
  const classes = useStyles();
  const classesLinerProgress = useStylesLinerProgress();

  return (
    // <Box p={1} border={1} height="100%">
    <Box border={1} height="100%" borderColor="rgba(0, 0, 0, 0.3)">
      {isPCLayout ? (
        <AppBar style={{ position: 'static', color: '#1da1f2' }}>
          <Toolbar style={{ minHeight: 40 }}>
            <Twitter style={{ marginRight: 10, color: 'white' }} />
            <Typography variant="body2" style={{ fontWeight: 600, padding: 5, color: 'black' }}>
              ＃どうキャラ2021 で投稿しよう！
            </Typography>
          </Toolbar>
        </AppBar>
      ) : (
        <></>
      )}
      <div className={classesLinerProgress.root}>
        <LinearProgress variant="determinate" color="secondary" value={progress} />
      </div>
      <Box
        pt={3}
        // height="100%"
        maxHeight={maxHeightForTwitterArea}
        // height="450px"
        className={classes.root}
        id="overFlowScrollArea"
        // style={{   position: 'absolute',top: 0,left: 0,width: '100%',height: '100%'}}
      >
        <Grid container spacing={1} id="top">
          {/* loading時の処理 */}
          {!isTwiLoading && DisplayTweets && DisplayTweets.length > 0 ? (
            <>
              {/* <div className={classesLinerProgress.root}>
                <LinearProgress variant="determinate" color="secondary" value={progress} />
              </div> */}
              {DisplayTweets.map((content) => (
                <React.Fragment key={content.url}>
                  <Grid item xs={12} sm={6} md={12}>
                    <TweetCard tweetContent={content} />
                  </Grid>
                </React.Fragment>
              ))}
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6} md={12}>
                <Skeleton animation="wave" variant="rect" style={{ height: 170 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Skeleton animation="wave" variant="rect" style={{ height: 170 }} />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}>
                <Skeleton animation="wave" variant="rect" style={{ height: 170 }} />
              </Grid> */}
            </>
          )}

          {/* ナビゲーションボタン */}
          {isTopNavigation ? (
            <Grid container justify="center">
              <Grid item>
                {/* <IconButton aria-label="arrowDropUp"> */}
                <StyledLink>
                  <Link
                    to="top"
                    activeClass="active"
                    smooth
                    duration={600}
                    // offset={-50}
                    containerId="overFlowScrollArea"
                  >
                    <Fab color="primary" size="small" aria-label="scroll back to top">
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </Link>
                </StyledLink>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default TwitterArea;
