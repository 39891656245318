import { Button, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Loading from 'HTB-Videos/components/Common/Loading';
import WPFooter from '../components/Common/WPFooterAxios';
import { wpFooterData } from '../utils/WPRequest';

const StyledQandA = styled.div`
  h2 {
    text-align: center;
    font-weight: bold;
    margin: 10px 0px;
    font-size: 2rem;
    border-bottom: 5px solid #c69e61;
    padding-bottom: 10px;
    padding-top: 30px;
    margin-bottom: 20px;

    @media screen and (max-width: 751px) {
       {
        font-size: 1.2rem;
      }
    }
  }

  p {
    line-height: 1.2rem;
  }

  dl dt {
    background-color: #98927c;
    color: #fff;
    padding: 10px;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: bold;
    // color: white;
  }

  dl dt h3::before {
    content: 'Q.';
    font-size: 1.2rem;
    font-weight: bold;
    // color: white;
  }

  dl dd {
    padding: 10px;
    // color: white;
  }

  .linkIndex {
    text-align: center;
    margin: 20px auto 0px auto;
    color: white;
  }
`;

/**
 * 購入をお考えの方へページ
 */
const QandA: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [footerText, setFooterText] = useState('');

  useEffect(() => {
    const getContents = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const response: wpFooterData = await WPFooter.get(`136`);
        setFooterText(response.data.content.rendered ?? '');
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    void getContents();
  }, []);

  return (
    <StyledQandA>
      {isError && (
        <Typography gutterBottom variant="body1" align="center" color="error">
          エラーが発生しました
          <br />
        </Typography>
      )}
      {isLoading ? (
        <Loading loadingCode="Load_002" />
      ) : (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: footerText,
            }}
          />
        </>
      )}
      <div className="linkIndex">
        <Button variant="contained" color="primary" component={Link} to="/">
          トップへ戻る
        </Button>
      </div>
    </StyledQandA>
  );
};

export default QandA;
