import { useEffect, useState } from 'react';
import {
  wpChildContentData,
  wpParentContentData,
} from 'HTB-Videos/utils/WPRequest';
import WPContent from '../components/Common/WPContentsAxios';

/**
 * 親コンテンツとそれに紐づく子コンテンツを取得するhooks
 */
const useGetParentAndChildContents = (
  parentId: string,
): {
  parentContent: wpParentContentData | undefined;
  childContents: wpChildContentData[] | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [
    parentContentState,
    setParentContentState,
  ] = useState<wpParentContentData>();
  const [childContentsState, setChildContentsState] = useState<
    wpChildContentData[]
  >([]);

  useEffect(() => {
    const getContent = async () => {
      setIsErrorState(false);
      setIsLoadingState(true);
      try {
        const parentResponse = await WPContent.get(`${parentId}`, {
          params: {
            _fields: 'acf,id,title',
          },
        });
        const childResponse = await WPContent.get(``, {
          params: {
            parent: `${parentId}`,
            _fields: 'acf,id,parent',
          },
        });
        setParentContentState(parentResponse.data);
        setChildContentsState(childResponse.data);
      } catch (err) {
        setIsErrorState(true);
      }
      setIsLoadingState(false);
    };

    void getContent();
  }, [parentId]);

  return {
    parentContent: parentContentState,
    childContents: childContentsState,
    isLoading: isLoadingState,
    isError: isErrorState,
  };
};

export default useGetParentAndChildContents;
