import { AppBar, Toolbar, IconButton, Typography, Badge, Box } from '@material-ui/core';
// import { Redeem, ShoppingCart } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Redeem, ShoppingCart, Twitter } from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Auth0LockContext, { isAuthenticated } from 'Auth0LockContext';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import HomeButtonImg from 'HTB-Aircaravan2021/layouts/HomeLogo.png';
// import { ViewMode } from 'HTB-Aircaravan2021/utils/WPRequest';

// const StyledHomeButton = styled.img`
//   max-width: 200px;
//   width: 100%;
//   height: auto;
//   @media screen and (max-width: 900px) {
//     max-width: 140px;
//     min-width: 90px;
//     width: 100%;
//     height: auto;
//   }
// `;

const StyledIconCaption = styled.span`
  display: inline-block;
  text-align: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    rootAvator: {
      display: 'flex',
      justifyContent: 'inherit',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
    rightButton: {
      marginRight: theme.spacing(5),
      // marginLeft: theme.spacing(2),
      // marginTop: theme.spacing(1),
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
    },
    leftButton: {
      // marginTop: 5,
      // paddingTop: 10,
      // paddingRight: 5,
      // paddingLeft: 15,
      // paddingBottom: 5,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      padding: 0,
    },
    icon: {
      fontSize: '2.25rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6rem',
      },
    },
    Avatar: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
      },
    },
    homeLogo: {
      maxWidth: 180,
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 140,
        minWidth: 90,
        marginRight: theme.spacing(1),
      },
    },
  }),
);

export type Props = {
  /**
   * ローディング中であるかを示す真偽値
   */
  isLoading: boolean;

  /**
   * カートボタンのバッジに表示する商品数
   */
  itemCount: number;

  /**
   * ヘッダーロゴをクリックした時に実行される関数
   */
  logoOnClickFunction: VoidFunction;

  /**
   * カートボタンをクリックした時に実行される関数
   */
  cartButtonOnClickFunction: VoidFunction;

  /**
   * twitterボタンをクリックした時に実行される関数
   */
  twitterButtonOnClickFunction: VoidFunction;

  /**
   * セールスエリアヘッダーに表示するタイトル
   */
  // title: string;
  /**
   * wp親記事のviewMode
   * カート確認ボタン、ヘッダーアイコンの非活性時のフラグで利用
   */
  // viewMode?: ViewMode;
  /**
   * PCレイアウト版twitter表示エリアをするか否かの真偽値
   */
  isPCLayout: boolean;
  /**
   * LiveSalesAreaAppbarにtwitter切り替えiconを表示するか否かの状態
   */
  isTwitter: boolean;
  /**
   * 未ログイン時マイページアイコンをクリックした時に実行される関数
   */
  loginOnClickFunction: VoidFunction;
  /**
   * ログイン済時マイページアイコンをクリックした時に実行される関数
   */
  myPageIconClickFunction: VoidFunction;
  /**
   * homelogo以外のアイコンの活性非活性を示す真偽値
   */
  isIconActive: boolean;
};

const LiveSalesArea: FC<Props> = ({
  isLoading,
  itemCount,
  logoOnClickFunction,
  cartButtonOnClickFunction,
  twitterButtonOnClickFunction,
  children,
  // title,
  // viewMode,
  isPCLayout,
  isTwitter,
  loginOnClickFunction,
  myPageIconClickFunction,
  isIconActive,
}) => {
  const classes = useStyles();
  const auth0Lock = useContext(Auth0LockContext);

  return (
    <>
      {isLoading ? (
        <Loading loadingCode="Load_001" />
      ) : (
        <>
          {/* <StyledAppbar> */}
          <div className={classes.root}>
            {/* <AppBar position="static"> */}
            <AppBar position="fixed" style={{ width: '100%' }}>
              <Toolbar style={{ marginTop: 5, marginBottom: 5 }}>
                {!isPCLayout ? (
                  <>
                    {isTwitter ? (
                      <>
                        {/* <Layout1> */}
                        <IconButton
                          onClick={() => twitterButtonOnClickFunction()}
                          className={classes.leftButton}
                          disabled={!isIconActive}
                          // style={{ padding: 0 }}
                        >
                          <StyledIconCaption>
                            <Redeem className={classes.icon} />
                            <Typography variant="caption" component="p" style={{ marginTop: -5 }}>
                              グッズ
                            </Typography>
                            {/* <Typography variant="caption" component="p" style={{ marginTop: -5 }}>
                              画面切替
                            </Typography> */}
                          </StyledIconCaption>
                        </IconButton>
                        {/* </Layout1> */}
                      </>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => twitterButtonOnClickFunction()}
                          className={classes.leftButton}
                          disabled={!isIconActive}
                          // style={{ padding: 0 }}
                        >
                          <StyledIconCaption>
                            <Twitter className={classes.icon} style={{ color: 'white' }} />
                            <Typography variant="caption" component="p" style={{ marginTop: -3 }}>
                              Twitter
                            </Typography>
                            {/* <Typography variant="caption" component="p" style={{ marginTop: -5 }}>
                              画面切替
                            </Typography> */}
                          </StyledIconCaption>
                        </IconButton>
                      </>
                    )}

                    <Box textAlign="center" className={classes.title}>
                      {/* <div> */}
                      <Link to="/aircaravan2021" onClick={() => logoOnClickFunction()}>
                        {/* <StyledHomeButton src={HomeButtonImg} alt="ホームボタン" /> */}
                        <img src={HomeButtonImg} alt="ホームボタン" className={classes.homeLogo} />
                        {/* <CardMedia image={HomeButtonImg} title="ホームボタン" className={classes.homeLogo} /> */}
                      </Link>
                      {/* </div> */}
                    </Box>
                    {/* </div> */}
                    {/* </Layout2> */}
                  </>
                ) : (
                  <>
                    {/* <StyledTitle>
                    <Typography variant="body1" color="inherit" align="center" style={{ fontWeight: 600 }}>
                      {title}
                    </Typography>
                  </StyledTitle> */}

                    <Box textAlign="center" className={classes.title}>
                      {/* <div> */}
                      <Link to="/aircaravan2021" onClick={() => logoOnClickFunction()}>
                        {/* <StyledHomeButton src={HomeButtonImg} alt="ホームボタン" /> */}
                        <img src={HomeButtonImg} alt="ホームボタン" className={classes.homeLogo} />
                        {/* <CardMedia image={HomeButtonImg} title="ホームボタン" className={classes.homeLogo} /> */}
                      </Link>
                      {/* </div> */}
                    </Box>
                  </>
                )}

                {auth0Lock.isAuth || isAuthenticated() ? (
                  <IconButton
                    component={Link}
                    to="/aircaravan2021/MyPage"
                    onClick={() => myPageIconClickFunction()}
                    disabled={!isIconActive}
                    style={{ padding: 0 }}
                    className={classes.rightButton}
                  >
                    <StyledIconCaption style={{ justifyContent: 'inherit' }}>
                      <div className={classes.rootAvator}>
                        <Avatar src={localStorage.getItem('photo_url') ?? ''} className={classes.Avatar} />
                      </div>
                      {/* <Typography variant="caption" component="p" style={{ marginTop: -5 }}>
                        購入履歴
                      </Typography> */}
                      <Typography variant="caption" align="center" component="p" style={{ marginTop: -5 }}>
                        マイページ
                      </Typography>
                    </StyledIconCaption>
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => loginOnClickFunction()}
                    color="inherit"
                    style={{ padding: 0, marginTop: 7 }}
                    className={classes.rightButton}
                  >
                    <StyledIconCaption>
                      <AccountCircle className={classes.icon} />
                      <Typography variant="caption" component="p" style={{ marginTop: -5 }}>
                        ログイン
                      </Typography>
                    </StyledIconCaption>
                  </IconButton>
                )}

                {/* <IconButton
                  onClick={() => cartButtonOnClickFunction()}
                  className={classes.rightButton}
                  disabled={!isIconActive}
                >
                  <Badge badgeContent={itemCount} max={99} color="secondary">
                    <StyledIconCaption>
                      <ShoppingCart className={classes.icon} />
                      <Typography variant="caption" component="p" style={{ marginTop: -5 }}>
                        カート
                      </Typography>
                    </StyledIconCaption>
                  </Badge>
                </IconButton> */}
              </Toolbar>
            </AppBar>
            {/* </StyledAppbar> */}
          </div>
          {children}
        </>
      )}
    </>
  );
};
export default LiveSalesArea;
