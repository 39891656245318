import { Typography, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import styled from 'styled-components';
import jinBanner from 'HTB-Aircaravan2021/images/ginBanner.jpg';
import lawsonBanner from 'HTB-Aircaravan2021/images/lawsonBanner.png';
import ichibankuji from 'HTB-Aircaravan2021/images/logo_01.png';
import unionCreative from 'HTB-Aircaravan2021/images/logo_03.png';
import unifast from 'HTB-Aircaravan2021/images/logo_04.png';
import yotsuba from 'HTB-Aircaravan2021/images/logo_05.png';
import lawson from 'HTB-Aircaravan2021/images/logo_06.png';
import coleman from 'HTB-Aircaravan2021/images/logo_08.png';
import pokkaSapporo from 'HTB-Aircaravan2021/images/logo_09.png';
import corsoSapporo from 'HTB-Aircaravan2021/images/logo_12.png';
import yotsubaBanner from 'HTB-Aircaravan2021/images/yotsubaBanner.jpg';
import yotsubaBanner2 from 'HTB-Aircaravan2021/images/yotsubaBanner02.jpg';

// YouTube表示ライン用css
const StyledLargeTitle = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 3vw;
  margin-bottom: 1.5vw;

  &::before,
  &::after {
    content: '';
    height: 1px;
    flex-grow: 1;
    // background-color: #666;
    background-color: #666;
  }

  &::before {
    margin-right: 1rem;
  }

  &::after {
    margin-left: 1rem;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    // image: {
    //   width: 64,
    //   height: 36,
    // },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }),
);

/**
 * バナー表示エリア
 */
const CaravanBannerArea: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="center" alignItems="center" style={{ marginTop: 50, padding: 20 }}>
        <Grid item xs={12} sm={6}>
          <StyledLargeTitle align="center" variant="h2">
            協賛
          </StyledLargeTitle>
        </Grid>
        <Grid item container spacing={1} justify="center" alignItems="center">
          <Grid item xs={6} sm={3} md={2}>
            {/* <div className={classes.image}> */}
            <img src={ichibankuji} className={classes.img} alt="logo" />
            {/* </div> */}
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            {/* <div className={classes.image}> */}
            <img src={unionCreative} className={classes.img} alt="logo" />
            {/* </div> */}
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            {/* <div className={classes.image}> */}
            <img src={unifast} className={classes.img} alt="logo" />
            {/* </div> */}
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            {/* <div className={classes.image}> */}
            <img src={yotsuba} className={classes.img} alt="logo" />
            {/* </div> */}
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            {/* <div className={classes.image}> */}
            <img src={lawson} className={classes.img} alt="logo" />
            {/* </div> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledLargeTitle align="center" variant="h2">
            協力
          </StyledLargeTitle>
        </Grid>
        <Grid item container spacing={1} justify="center" alignItems="center">
          <Grid item xs={6} sm={3} md={2}>
            {/* <div className={classes.image}> */}
            <img src={coleman} className={classes.img} alt="logo" />
            {/* </div> */}
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            {/* <div className={classes.image}> */}
            <img src={pokkaSapporo} className={classes.img} alt="logo" />
            {/* </div> */}
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            {/* <div className={classes.image}> */}
            <img src={corsoSapporo} className={classes.img} alt="logo" />
            {/* </div> */}
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <StyledLargeTitle align="center" variant="h2">
            バナー
          </StyledLargeTitle>
        </Grid> */}
        <Grid item container spacing={3} justify="center" alignItems="center" style={{ marginTop: 40 }}>
          <Grid item xs={7} sm={4} md={3} style={{ paddingTop: 10 }}>
            {/* <div className={classes.image}> */}
            <a
              href="https://www.yotsuba-shop.com/"
              target="_blank"
              rel="noreferrer noopener"
              // className={classes.link}
            >
              <img src={yotsubaBanner} className={classes.img} alt="banner" />
            </a>
            {/* </div> */}
          </Grid>
          <Grid item xs={7} sm={4} md={3}>
            {/* <div className={classes.image}> */}
            <a
              href="https://www3.htb.co.jp/cgi-bin2/webform/webform.cgi/aircaravan2021_yotsuba/form"
              target="_blank"
              rel="noreferrer noopener"
              // className={classes.link}
            >
              <img src={yotsubaBanner2} className={classes.img} alt="banner" />
            </a>
            {/* </div> */}
          </Grid>
          <Grid item xs={7} sm={4} md={3}>
            {/* <div className={classes.image}> */}
            <a
              href="https://shop.hlwine.co.jp/items/24198677"
              target="_blank"
              rel="noreferrer noopener"
              // className={classes.link}
            >
              <img src={jinBanner} className={classes.img} alt="banner" />
              {/* </div> */}
            </a>
          </Grid>
          <Grid item xs={7} sm={4} md={3}>
            {/* <div className={classes.image}> */}
            <a
              href="https://www.lawson.co.jp/campaign/lop_suidougoods/"
              target="_blank"
              rel="noreferrer noopener"
              // className={classes.link}
            >
              <img src={lawsonBanner} className={classes.img} alt="banner" />
              {/* </div> */}
            </a>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CaravanBannerArea;
