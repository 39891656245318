// 登録済み住所のカード表示

import { Typography, List, ListItem, ListItemText } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// import PhoneIcon from '@material-ui/icons/Phone';
import React, { FC } from 'react';
import prefLists from 'HTB-Aircaravan2021/utils/ProvinceList';
import { Delivery } from 'graphql/appsync/generated';

type Props = {
  /**
   * 配送先住所情報
   */
  delivery: Delivery | undefined;
  /**
   * プレビューモード（フォームが非表示）
   */
  previewMode?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   flexGrow: 1,
    // },
    paper: {
      padding: theme.spacing(1),
      margin: 'auto',
      maxWidth: 800,
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
    textRight: {
      textAlign: 'right',
      wordBreak: 'break-all',
      marginLeft: 5,
    },
  }),
);

const DeliveryAddressList: FC<Props> = ({ delivery, previewMode }) => {
  const classes = useStyles();
  // 都道府県コードを都道府県名に変換
  const provinceName = prefLists.find((n) => n.id === delivery?.provinceCode ?? '')?.name;

  return (
    <>
      {delivery && (
        // <div className={classes.root}>
        <Paper className={classes.paper} key={delivery.id} elevation={3}>
          <Grid container alignItems="center" justify="center">
            {!previewMode && (
              <Grid item xs={1}>
                <FormControlLabel style={{ paddingLeft: 8 }} value={delivery.id} control={<Radio />} label="" />
              </Grid>
            )}
            <Grid item xs={11}>
              <List>
                <ListItem className={classes.nested}>
                  <Typography>氏名：</Typography>
                  <ListItemText className={classes.textRight}>
                    {delivery.familyName} {delivery.givenName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>郵便番号：</Typography>
                  <ListItemText className={classes.textRight}>
                    〒 {delivery.postCode?.slice(0, 3)}-{delivery.postCode?.slice(3, 7)}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>住所：</Typography>
                  <ListItemText className={classes.textRight}>
                    {provinceName} {delivery.city} {delivery.address} {delivery.subAddress}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.nested}>
                  <Typography>電話番号：</Typography>
                  <ListItemText className={classes.textRight}>{delivery.phoneNumber?.replace('+81 ', '')}</ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
        // </div>
      )}
    </>
  );
};
export default DeliveryAddressList;
