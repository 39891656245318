import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import { Order, BuyStatus } from 'graphql/appsync/generated';

type BuyStatusNote = Record<BuyStatus, string>;

type Props = {
  /**
   * 更新対象
   */
  order: Order | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 800,
    },
    image: {
      width: 160,
      height: 90,
    },
    img: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    // subtitle: {
    //   // margin: theme.spacing(1),
    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: '0.9rem',
    //     // paddingRight: '0.2em',
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '1.1rem',
    //   },
    // },
    // price: {
    //   // margin: theme.spacing(1),
    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: '0.9rem',
    //     paddingLeft: '10px',
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '1.1rem',
    //   },
    // },
    // body2: {
    //   // margin: theme.spacing(1),
    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: '0.7rem',
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '1rem',
    //   },
    // },
  }),
);

const StyledForceBreakword = styled(Typography)`
  word-break: break-all;
`;

const OrderList: FC<Props> = ({ order }) => {
  const classes = useStyles();
  const buyStateLabel: BuyStatusNote = {
    Buying: '購入処理中',
    Bought: '購入済',
    NotBought: '未購入',
  };

  return (
    <>
      {order && order.buyState !== 'NotBought' && (
        <div className={classes.root}>
          <Paper className={classes.paper} key={order.id} elevation={3}>
            <Grid container spacing={2}>
              <Grid item>
                {order.thumbnailUrl ? (
                  <ButtonBase className={classes.image}>
                    <img
                      className={classes.img}
                      alt={order.thumbnailUrl}
                      src={order.thumbnailUrl}
                    />
                  </ButtonBase>
                ) : (
                  <Typography>no image</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <StyledForceBreakword
                      gutterBottom
                      variant="body1"
                      // className={classes.subtitle}
                      color="inherit"
                    >
                      {order.title}
                    </StyledForceBreakword>
                    <StyledForceBreakword
                      variant="body2"
                      color="textSecondary"
                      // className={classes.body2}
                    >
                      {buyStateLabel[order.buyState]}
                      <br />
                      <Moment format="YYYY/MM/DD HH:mm">
                        {Date.parse(order.updatedAt)}
                      </Moment>
                    </StyledForceBreakword>
                  </Grid>
                </Grid>
                <Grid item>
                  <StyledForceBreakword
                    variant="body1"
                    // className={classes.price}
                    style={{ paddingLeft: '2vw' }}
                  >
                    ¥ {Number(order.cost).toLocaleString()}
                  </StyledForceBreakword>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <StyledForceBreakword
              variant="body2"
              gutterBottom
              // className={classes.body2}
            >
              {order.article}
            </StyledForceBreakword>
          </Paper>
        </div>
      )}
    </>
  );
};

export default OrderList;
