import {
  CardMedia,
  Typography,
  Paper,
  Grid,
  MobileStepper,
  Button,
  IconButton,
  CardActions,
  Collapse,
  Modal,
  Backdrop,
  Fade,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import React, { FC, useContext, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { isAuthenticated } from 'Auth0LockContext';
import Loading from 'HTB-Videos/components/Common/Loading';
import AddToCartButton from 'HTB-Videos/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';
import useGetKintoneProducts from 'HTB-Videos/hooks/useGetKintoneProducts';
import refetchQueriesContext from 'RefetchQueriesContext';
import { KintoneProducts, GoodsCart, useListSortKintoneProductssLazyQuery } from 'graphql/appsync/generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // maxWidth: 400,
      // flexGrow: 1,
      fontSize: 12,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      height: 0,
      // paddingTop: '56.25%', // 16:9,
      paddingTop: '100%', // 1:1
      marginTop: '30',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      // transform: 'rotate(180deg)',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '90%',
      maxWidth: 600,
    },
    modalimg: {
      height: 0,
      // paddingTop: '56.25%', // 16:9,
      paddingTop: '100%', // 1:1,
      marginTop: '50',
    },
  }),
);

/**
 * 商品名のスタイル
 */
const StyledTitle = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-right: 8px;
  padding-left: 8px;
`;

/**
 * 商品金額のスタイル
 */
// const StyledPrice = styled.div`
//   padding-left: 10px;
//   padding-bottom: 0.5rem;

//   h5 {
//     font-weight: bold;
//   }

//   @media screen and (max-width: 751px) {
//     padding-left: 10px;
//     padding-bottom: 0.5rem;

//     h5 {
//       font-size: 6vw;
//     }
//   }
// `;

const StyledButton = styled.div`
  text-align: center;
`;

const StyledWrap = styled.div`
  display: 'flex';
  flexwrap: 'wrap';
  justifycontent: 'space-around';
  overflow: 'hidden';
`;

/**
 * shopifyからの商品説明のスタイル
 */
// const StyledText = styled.div`
//   margin: 1em;
//   line-height: 1.5;

//   p {
//     line-height: 1.5;
//   }
// `;
export type Props = {
  /**
   * 表示対象のproductId
   */
  productId: string;
  /**
   * カート情報
   */
  goodsCartData: GoodsCart;
};

export type imagesURL = {
  src: string;
};

/**
 * 販売商品を表示するカード
 */
const ProductCard: FC<Props> = ({ productId, goodsCartData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [displayPrice, setDisplayPrice] = useState<number>();
  const [selectVariation, setSelectVariation] = useState<KintoneProducts>();
  const [kintoneProducts, setKintoneProducts] = useState<KintoneProducts[]>();
  const refetchQueries = useContext(refetchQueriesContext);

  // KintoneProductsTableの情報を取得するhooks
  // 1.Appsyncから取得（ログイン時に使用）
  const [getKintoneProducts, { data: productData }] = useListSortKintoneProductssLazyQuery();
  // 2.APIGatewayから取得（未ログイン時に使用）
  const { kintoneProducts: productDataFromAPIGateway } = useGetKintoneProducts(productId);

  useEffect(() => {
    /*
    // サブスクリプションでリフェッチはアンチパターン
    if (
      refetchQueries.queries.some((query) => query.name === 'KintoneProducts' && query.id === productId) &&
      refetchProductData
    ) {
      void refetchProductData();
      void refetchQueries.removeQuery({ name: 'KintoneProducts', id: productId });
    }
    */
    refetchQueries.queries.forEach((query) => {
      if (query.name === 'KintoneProducts' && kintoneProducts) {
        // eslint-disable-next-line
        if (query.data && query.data.__typename === 'KintoneProducts') {
          const tmpKintoneProducts = kintoneProducts.slice();
          for (let i = 0; i < tmpKintoneProducts.length; i += 1) {
            if (tmpKintoneProducts[i].id === query.data.id) {
              tmpKintoneProducts[i] = query.data;
            }
          }
          void setKintoneProducts(tmpKintoneProducts);
          void refetchQueries.removeQuery({ name: 'KintoneProducts', data: undefined });
          // S|M|Lの場合の描画更新
          if (selectVariation && selectVariation.id === query.data.id) {
            setSelectVariation(query.data);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchQueries]);

  // 表示対象のデータを取得
  useEffect(() => {
    if (isAuthenticated()) {
      void getKintoneProducts({
        variables: {
          productId,
          sortDirection: 'ASC',
        },
      });
      void setKintoneProducts(productData?.listSortKintoneProductss?.items as KintoneProducts[]);
    } else {
      void setKintoneProducts(productDataFromAPIGateway?.length !== 0 ? productDataFromAPIGateway : undefined);
    }

    // variantが一種類のみの場合SelectVariationを設定
    if ((kintoneProducts?.length ?? 0) === 1) {
      void setSelectVariation(kintoneProducts && kintoneProducts[0]);
    }
    // 全てのvariantが同じ値段の場合、displayPriceを設定
    if (!kintoneProducts?.find((product) => product.taxIncludePrice !== kintoneProducts[0].taxIncludePrice)) {
      void setDisplayPrice(kintoneProducts && kintoneProducts[0].taxIncludePrice);
    }
  }, [
    getKintoneProducts,
    kintoneProducts,
    productData?.listSortKintoneProductss?.items,
    productDataFromAPIGateway,
    productId,
  ]);

  /**
   * 商品画像一覧を取得
   */
  const tutorialSteps = kintoneProducts
    ? (JSON.parse(kintoneProducts[0].images as string) as imagesURL[]).map((image) => ({
        label: image.src,
        imgPath: image.src,
      }))
    : undefined;

  const maxSteps = tutorialSteps ? tutorialSteps.length : 0;

  /*
   ** スワイプできるようにする
   */

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // 在庫状況テキスト
  // const inventoryStateText = () => {
  //   if (!selectVariation) {
  //     return '';
  //   }
  //   if (selectVariation.inventoryState === 'enough') {
  //     return '在庫あり';
  //   }
  //   if (selectVariation.inventoryState === 'limitedStock') {
  //     return '在庫あり';
  //   }

  //   return '在庫なし';
  // };

  // 在庫状況テキスト salesStateがonSaleの時のみ表示、その他状態の時は非表示
  // limitedStockは将来的に使う
  const inventoryStateText = () => {
    if (selectVariation && selectVariation.salesState === 'onSale' && selectVariation.inventoryState === 'enough') {
      return '在庫あり';
    }
    // if (
    //   selectVariation &&
    //   selectVariation.salesState === 'onSale' &&
    //   selectVariation.inventoryState === 'limitedStock'
    // ) {
    //   return '残りわずか';
    // }

    if (selectVariation && selectVariation.salesState === 'onSale' && selectVariation.inventoryState === 'outOfStock') {
      return '在庫なし';
    }

    return '';
  };

  return (
    <>
      {!kintoneProducts ? (
        <Loading loadingCode="Load_002" />
      ) : (
        <div className={classes.root}>
          <StyledWrap>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper>
                  <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {tutorialSteps &&
                      tutorialSteps.map((step, index) => (
                        <div key={step.label}>
                          {Math.abs(activeStep - index) <= 2 ? (
                            <CardMedia
                              onClick={handleOpen}
                              className={classes.img}
                              image={tutorialSteps[activeStep].imgPath}
                            />
                          ) : null}
                        </div>
                      ))}
                  </SwipeableViews>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                  >
                    <Fade in={open}>
                      <div className={classes.paper}>
                        <Typography gutterBottom variant="body1" align="center">
                          {kintoneProducts[0].productTitle}
                        </Typography>
                        <SwipeableViews
                          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                          index={activeStep}
                          onChangeIndex={handleStepChange}
                          enableMouseEvents
                        >
                          {tutorialSteps &&
                            tutorialSteps.map((step, index) => (
                              <div key={step.label}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                  <CardMedia
                                    onClick={handleOpen}
                                    className={classes.img}
                                    image={tutorialSteps[activeStep].imgPath}
                                  />
                                ) : null}
                              </div>
                            ))}
                        </SwipeableViews>
                        <MobileStepper
                          steps={maxSteps}
                          position="static"
                          variant="text"
                          activeStep={activeStep}
                          nextButton={
                            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                              Next
                              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                          }
                          backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                              BACK
                              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            </Button>
                          }
                        />
                      </div>
                    </Fade>
                  </Modal>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        BACK
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      </Button>
                    }
                  />
                  <StyledTitle>
                    <Typography gutterBottom variant="body1" align="center" style={{ fontWeight: 700 }}>
                      {kintoneProducts[0].productTitle}
                    </Typography>
                  </StyledTitle>
                  <Typography gutterBottom align="center" variant="body1">
                    ¥{displayPrice ? displayPrice.toLocaleString() : '---'}
                  </Typography>
                  <Typography gutterBottom align="center" variant="body1">
                    (税込)
                  </Typography>

                  <CardActions disableSpacing>
                    <Grid container justify="center" alignItems="center" spacing={2} style={{ padding: 5 }}>
                      {kintoneProducts.length !== 1 ? (
                        <>
                          <Grid item xs={7} container justify="center">
                            <Select
                              value={selectVariation ? selectVariation.id : 'none'}
                              onChange={(event) => {
                                setSelectVariation(
                                  kintoneProducts.find((product) => product.id === (event.target.value as string)),
                                );
                                setDisplayPrice(
                                  kintoneProducts.find((product) => product.id === event.target.value)
                                    ?.taxIncludePrice ?? 0,
                                );
                              }}
                            >
                              <MenuItem key="none" value="none" disabled>
                                <Box marginX={1}>選択してください</Box>
                              </MenuItem>
                              {kintoneProducts.map((product) => (
                                <MenuItem key={product.id} value={product.id}>
                                  <Box marginX={1}>{product.variation}</Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={5} container justify="center">
                            <Typography gutterBottom align="center" variant="body2">
                              {inventoryStateText()}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} container justify="center">
                          <Typography gutterBottom align="center" variant="body2">
                            {inventoryStateText()}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <StyledButton>
                          <AddToCartButton product={selectVariation} goodsCartData={goodsCartData} />
                        </StyledButton>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <IconButton
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                          })}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <Typography gutterBottom variant="body2" align="center">
                            商品説明
                          </Typography>

                          <ExpandMoreIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardActions>

                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {/* <StyledText> */}
                    <Grid container alignItems="center" justify="center" spacing={3}>
                      <Grid item xs={11}>
                        <Typography gutterBottom variant="body2">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: kintoneProducts[0].description ?? '',
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* </StyledText> */}
                  </Collapse>
                </Paper>
              </Grid>
            </Grid>
          </StyledWrap>
        </div>
      )}
    </>
  );
};

export default ProductCard;
