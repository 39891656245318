import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

/**
 * アクセスが国内か否かを取得するhooks
 */
const useGetRegionalRestrictionContent = (): {
  isStatusCode403: boolean;
  isStatusLoading: boolean;
} => {
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  // const [statusCode, setStatusCode] = useState<number>();
  const [isStatusCode403, setIsStatusCode403] = useState(false);

  const statusJudgment = (err: AxiosError) => {
    if (err?.response?.status === 403) {
      return setIsStatusCode403(true);
    }

    return setIsStatusCode403(false);
  };

  useEffect(() => {
    const getContent = async () => {
      setIsStatusLoading(true);
      try {
        // アクセス制限（国内限定）をかけたcloudfrontを叩く（PMF用）
        await axios.get('https://d3lvii84lts5vu.cloudfront.net/sample.html');
        // console.log('200 Response', Response);
        setIsStatusCode403(false);
      } catch (error) {
        statusJudgment(error);
      }
      setIsStatusLoading(false);
    };
    void getContent();
  }, []);

  return {
    isStatusCode403,
    isStatusLoading,
  };
};

export default useGetRegionalRestrictionContent;
