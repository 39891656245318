import React, { FC } from 'react';
import TwitterConvTrkr from 'react-twitter-conversion-tracker-plus';

// twitter の IDを持ってくる
const REACT_APP_TWITTER_CONVERSATION_ID: string = process.env.REACT_APP_TWITTER_CONVERSATION_ID ?? '';

// const Purchase = "'Purchase', { value: '5000', currency: 'JPY', num_items: '1', }";
const TwitterTag: FC = () => {
  TwitterConvTrkr.init(REACT_APP_TWITTER_CONVERSATION_ID);

  TwitterConvTrkr.pageView();

  // TwitterConvTrkr.track(Purchase);

  return <></>;
};

export default TwitterTag;

// p_id=Twitter
// p_user_id=
// txn_id=o6k3l
// events=%5B%5B%22pageview%22%2Cnull%5D%5D
// tw_sale_amount=
// tw_order_quantity

// twq('track','Purchase', {
// 	//required parameters
// 	value: '29.95',
// 	currency: 'USD',
// 	num_items: '3',
// });
