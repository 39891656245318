import { Typography, Grid, CircularProgress } from '@material-ui/core';
import React, { FC } from 'react';
import { LoadingCode } from 'utils/Loading';

type Props = {
  /**
   * ローディングコード
   */
  loadingCode?: LoadingCode;
};

/**
 * Loadingを表示するコンポーネント
 */
const Loading: FC<Props> = ({ loadingCode }) => {
  switch (loadingCode) {
    case 'Load_001': {
      return (
        <>
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item xs={12}>
              <br />
              <br />
              <Typography gutterBottom align="center">
                <CircularProgress color="secondary" size="30px" />
              </Typography>
              <br /> <br />
            </Grid>
          </Grid>
        </>
      );
    }
    case 'Load_002': {
      return (
        <>
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item xs={12}>
              <br />
              <br />
              <Typography gutterBottom align="center" variant="body1">
                Loading...
              </Typography>
              <br />
              <Typography gutterBottom align="center">
                <CircularProgress color="secondary" size="30px" />
              </Typography>
              <br /> <br />
            </Grid>
          </Grid>
        </>
      );
    }
    case 'Load_003': {
      return (
        <>
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item xs={12}>
              <br />
              <br />
              <Typography gutterBottom align="center" variant="body1">
                処理に時間がかかることがございます。
              </Typography>
              <Typography gutterBottom align="center" variant="body1">
                このままの画面で少々お待ちください。
              </Typography>
              <br />
              <Typography gutterBottom align="center">
                <CircularProgress color="secondary" size="30px" />
              </Typography>
              <br /> <br />
            </Grid>
          </Grid>
        </>
      );
    }
    default: {
      return (
        <>
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography gutterBottom align="center">
                <CircularProgress color="secondary" size="30px" />
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }
  }
};

export default Loading;
