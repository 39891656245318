import { InputLabel, Select, MenuItem, Box } from '@material-ui/core';
import React, { FC } from 'react';
import Loading from 'HTB-Videos/components/Common/Loading';

export type Props = {
  /**
   * 現在カートに保存している数量
   */
  quantity: number;

  /**
   * 数量変更時に実行される処理
   */
  handleChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>,
  ) => void;

  /**
   * ローディング中であるかを示す真偽値
   */
  isLoading: boolean;
};

/**
 * カートに保存している数量を表示・変更するセレクター
 */
const UpdateLineItemSelector: FC<Props> = ({ quantity, handleChange, isLoading }) => (
  <>
    {isLoading ? (
      <Loading />
    ) : (
      <>
        <InputLabel shrink>数量</InputLabel>
        <Select value={quantity} onChange={handleChange}>
          {/* セレクタには現在の数量と1～10までの値を表示 */}
          <MenuItem key={`current-quantity: ${String({ quantity })}`} value={quantity}>
            <Box marginX={1}>{quantity}</Box>
          </MenuItem>
          {[...Array(10).keys()].map((index) => (
            <MenuItem key={index + 1} value={index + 1}>
              <Box marginX={1}>{index + 1}</Box>
            </MenuItem>
          ))}
        </Select>
      </>
    )}
  </>
);

export default UpdateLineItemSelector;
