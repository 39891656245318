import { Typography, Grid, Button, Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, Key } from 'react';
import Linkify from 'react-linkify';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { SecureLink } from 'react-secure-link';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import Loading from 'HTB-Videos/components/Common/Loading';
import youtubeCustomPlayList, { youtubePlayerData } from 'HTB-Videos/components/Youtube/YoutubeAPI';

// youtube動画の比固定
const StyledYoutube = styled.div`
   {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0px;
    position: relative;
    margin-bottom: 1.5rem;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

// 前のページに戻るボタン
const StyledPrevButton = styled.div`
   {
    text-align: center;
    margin: 20px auto 0px auto;
  }
`;

// 概要欄用
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      '& a': {
        color: theme.palette.secondary.main,
      },
    },
  }),
);

// YouTube再生ページ
const YouTubePage: FC = () => {
  const { playlistId, videoId } = useParams();
  const classes = useStyles();

  const {
    data: youtubeContent,
    isLoading,
    isError,
  } = useQuery<youtubePlayerData>(
    ['getyoutubePlayerContent', videoId],
    async () => {
      const { data } = await youtubeCustomPlayList.get<youtubePlayerData>('', {
        params: {
          videoId,
          playlistId,
          Num: '',
        },
      });

      return data;
    },
    { staleTime: 300000 },
  );

  return (
    <>
      {isError && (
        <Typography gutterBottom variant="body2" component="p" align="center">
          エラーが発生しました
          <br />
        </Typography>
      )}
      {isLoading ? (
        <Loading loadingCode="Load_002" />
      ) : (
        youtubeContent && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledYoutube>
                <YouTube videoId={videoId} />
              </StyledYoutube>
            </Grid>
            {/* <Box py={6} textAlign="left"> */}
            <Grid item xs={12}>
              <Typography
                gutterBottom
                // className={classes.title}
                variant="h2"
                color="inherit"
                align="center"
              >
                {youtubeContent?.title}
              </Typography>
            </Grid>
            <Box m={2} pb={4}>
              <Grid container spacing={3}>
                <Grid item sm={12}>
                  {/* <Box mb={2} display="flex" alignItems="center"> */}
                  <Box mb={1} display="flex" alignItems="left">
                    <Typography variant="subtitle1" color="inherit">
                      番組情報
                    </Typography>
                  </Box>
                  <Linkify
                    componentDecorator={(decoratedHref: string, decoratedText: string, key: Key) => (
                      <SecureLink href={decoratedHref} key={key}>
                        {decoratedText}
                      </SecureLink>
                    )}
                  >
                    <Typography variant="body1" color="inherit" align="left" className={classes.description}>
                      {youtubeContent?.description ?? ''}
                    </Typography>
                  </Linkify>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )
      )}
      <StyledPrevButton>
        <Button variant="contained" color="primary" component={Link} to="/">
          トップに戻る
        </Button>
      </StyledPrevButton>
    </>
  );
};

export default YouTubePage;
