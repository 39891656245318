import { Grid, ButtonBase, Typography, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import styled from 'styled-components';
import RemoveLineItemButton from 'HTB-Videos/components/LiveSalesArea/pages/Cart/Container/RemoveLineItemButton';
import UpdateLineItemSelector from 'HTB-Videos/components/LiveSalesArea/pages/Cart/Container/UpdateLineItemSelector';
import { itemList } from 'HTB-Videos/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';

/**
 * 商品名のスタイル
 */
const StyledTitle = styled.div`
  width: 100%;
  margin-top: 0.5rem;

  @media screen and (max-width: 751px) {
    h6 {
      font-size: 4vw;
    }
  }
`;

/**
 * 商品金額のスタイル
 */
const StyledPrice = styled.div`
  padding-left: 10px;
  padding-bottom: 0.5rem;

  h5 {
    font-weight: bold;
  }

  @media screen and (max-width: 751px) {
    padding-left: 10px;
    padding-bottom: 0.5rem;

    h5 {
      font-size: 5vw;
    }
  }
`;

/**
 * 商品削除ボタンのスタイル
 */
const StyledRemoveLineItemButton = styled.div`
  margin: 0px 10px;
`;

/**
 * 数量変更セレクタのスタイル
 */
const StyledUpdateLineItemSelector = styled.div`
  margin: 0.3rem;
`;

/**
 * カードのスタイル
 */
const CartItemImg = styled.img`
  max-width: 100%;
`;

const StyledLineItem = styled.div`
  margin: 10px;
`;

export type Props = {
  /**
   * カードに表示するカート中の商品情報
   */
  lineItem: itemList;
  /**
   * グッズカート内Items情報
   */
  Items: itemList[];
};

/**
 * カートに保存中の商品を表示するカード
 */
const LineItemCard: FC<Props> = ({ lineItem, Items }) => (
  <>
    <StyledLineItem>
      <Paper style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={5} style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonBase>
              <CartItemImg
                // src={String(lineItem.variant?.image?.originalSrc)}
                src={String(lineItem?.thumbnailUrl)}
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={7} sm container direction="column" alignItems="flex-start" justify="center">
            <StyledTitle>
              <Typography gutterBottom variant="body1" style={{ fontWeight: 700 }}>
                {lineItem?.title}
                {/* {lineItem.variant?.title === 'Default Title'
                    ? ''
                    : `／${lineItem.variant?.title ?? ''}`} */}
              </Typography>
            </StyledTitle>
            <StyledPrice>
              <Typography variant="body1">
                {/* {(
                    lineItem.quantity * Number(lineItem.variant?.priceV2.amount)
                  ).toLocaleString()} */}
                ¥ {Number(lineItem.cost).toLocaleString()} (税込)
              </Typography>
            </StyledPrice>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <StyledUpdateLineItemSelector>
              <UpdateLineItemSelector lineItemId={lineItem?.id} quantity={lineItem?.count} Items={Items} />
            </StyledUpdateLineItemSelector>
          </Grid>
          <Grid item xs={7} style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledRemoveLineItemButton>
              <RemoveLineItemButton lineItemId={lineItem?.id} Items={Items} />
            </StyledRemoveLineItemButton>
          </Grid>
        </Grid>
      </Paper>
    </StyledLineItem>
  </>
);

export default LineItemCard;
