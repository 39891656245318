import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { User } from 'graphql/appsync/generated';

type Props = {
  /**
   * 更新対象
   */
  user: User;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 800,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    // subtitle: {
    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: '0.9rem',
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '1.1rem',
    //   },
    // },
  }),
);

const UserStyle: FC<Props> = ({ user }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Avatar
                src={localStorage.getItem('photo_url') ?? ''}
                // className={classes.large}
                className={classes.img}
              />
            </Grid>
            <Grid item xs container direction="column">
              <Typography
                gutterBottom
                variant="body1"
                // className={classes.subtitle}
              >
                名前：{user.familyName} {user.givenName}
                <br />
                Mail：{user.mail}
              </Typography>
            </Grid>
          </Grid>
          {/**
        <Typography variant="body2" gutterBottom>
          ID：{user.id}
        </Typography>
        */}
        </Paper>
      </div>
    </>
  );
};

export default UserStyle;
