import Button from '@material-ui/core/Button';
// import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { getAuthProfile } from 'Auth0LockContext';
import { MessageCode } from 'utils/Message';
import { useListSortCardsQuery, useGetUserQuery } from 'graphql/appsync/generated';
import Message from '../Common/Message';
import CardList from '../MyPage/CardList';
import Loading from 'HTB-Videos/components/Common/Loading';

type Props = {
  /**
   * メールアドレス
   */
  mail: string;

  /**
   * WordpressのId(商品id)
   */
  wordpressId: string;

  /**
   * べき等キー(UUID)
   */
  idempotencyKeyUuid: string;

  /**
   * 購入完了後に実行される関数
   */
  onPurchase?: VoidFunction;
};
type typeSendData = {
  input: string;
  stateMachineArn: string;
};

type typeResponseData = {
  executionArn: string;
  startDate: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 800,
      '& a': {
        color: theme.palette.secondary.main,
      },
    },
  }),
);

const StyledCenter = styled.div`
  .centerGroup {
    text-align: center;
    margin: 20px auto 20px;
  }
`;

let retryErrCount = 0;

/**
 * 購入フォーム
 * @returns
 */
const StripeForm: FC<Props> = ({ mail, wordpressId, idempotencyKeyUuid, onPurchase }) => {
  const [cardTableId, setCardTableId] = React.useState('');
  const [error, setError] = useState<string>('');
  const classes = useStyles();
  const [sending, setSending] = useState<boolean>(false);
  const [errorMsgView, setErrorMsgView] = useState<boolean>(false);
  const [messageCode, setMessageCode] = useState<MessageCode>('ERR_000101');
  // const messageCode = 'ERR_000101';

  // Card Table Graphql
  const {
    data: listCardData,
    loading: isListCardLoading,
    error: listCardError,
    // refetch: refetchCardData,
    // } = useListCardsQuery(); // 20210514 不具合修正
  } = useListSortCardsQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
    },
  });

  // レスポンス遅い時に表示される？
  if (isListCardLoading) return <Typography>loading...</Typography>;
  // 未ログインの区別ができない。
  if (listCardError) return <Typography>エラーが発生しました。リロードして再度お試しください</Typography>;

  // 購入実行
  const purchaseProcess = (cardIdSelect: string) => {
    if (!cardIdSelect) {
      setError('クレジットカードを選択してください');

      return;
    }
    setSending(true);
    setErrorMsgView(false);
    sendingPost(
      {
        input: JSON.stringify({
          authId: getAuthProfile().sub ?? '',
          cardId: cardIdSelect,
          wpId: wordpressId,
          idempotencyKey: idempotencyKeyUuid,
        }),
        stateMachineArn: process.env.REACT_APP_CARD_STEP_FUNCTIONS_ARN ?? '',
      },
      process.env.REACT_APP_CARD_START_EXECUTION_URL ?? '',
      process.env.REACT_APP_CARD_DESCRIBE_EXECUTION_URL ?? '',
    );
  };

  /**
   * StepFunctionsへリクエスト送信関数
   * @param sendData
   * @param url_start
   * @param url_describe
   */
  const sendingPost = async (sendData: typeSendData, url_start: string, url_describe: string) => {
    try {
      await axios
        .post(url_start, sendData, {
          headers: {
            Authorization: localStorage.getItem('id_token'),
          },
        })
        .then((response) => {
          if (response.status !== 200) {
            // データ送信失敗
            failureStartApi();

            return;
          }
          // 初期化
          retryErrCount = 0;

          // タイマーのsetTimeoutに変更
          setTimeout(() => {
            getDataPolling(response.data, url_describe);
          }, 5000);
        });
      // データ送信失敗
    } catch (e) {
      failureStartApi();
    }
  };
  /**
   * Apiの実行に失敗した場合
   */
  const failureStartApi = () => {
    setSending(false);
    setErrorMsgView(true);

    // 一律同じエラーメッセージ
    setMessageCode('ERR_000101');
    // console.log("処理失敗");
  };

  /**
   * StepFunctionsレスポンス受け取り用関数
   * @param responseData
   * @param url
   */
  const getDataPolling = async (responseData: typeResponseData, url: string) => {
    try {
      await axios.post(url, responseData).then((response) => {
        if (response.status !== 200) {
          // 成功以外を返してもループ
          // console.log(response);

          // 1分以上エラーでリトライした場合
          if (retryErrCount > 12) {
            return;
          }
          retryErrCount += 1;

          // 再起
          setTimeout(() => {
            getDataPolling(responseData, url);
          }, 5000);
        } else if (response.data.status === 'SUCCEEDED') {
          // console.log('成功');
          // alert('debug通知：購入成功');
          if (onPurchase) void onPurchase();
        } else if (response.data.status === 'FAILED') {
          // 失敗　バックエンドでエラー発生
          // console.log('失敗');
          // alert('debug通知：購入失敗');
          failureStartApi();
        } else {
          setTimeout(() => {
            getDataPolling(responseData, url);
          }, 2000);
        }
      });
    } catch (e) {
      // 1分以上エラーでリトライした場合
      if (retryErrCount > 12) {
        return;
      }
      retryErrCount += 1;

      // 再起
      setTimeout(() => {
        getDataPolling(responseData, url);
      }, 5000);
    }
  };

  return (
    <>
      {!errorMsgView && (
        <>
          領収書送付先
          <br />
          <Paper className={classes.paper}>{mail}</Paper>
          <br />
          {sending && (
            <>
              {/* <StyledCenter>
                <div className="centerGroup">
                  <p>処理に時間がかかることがございます。</p>
                  <p>このままの画面で少々お待ちください。</p>
                  <br />
                  <CircularProgress color="secondary" />
                  <br />
                  <br />
                </div>
              </StyledCenter> */}
              <Loading loadingCode="Load_003" />
            </>
          )}
          {!sending && (
            <>
              <FormControl fullWidth component="label">
                <RadioGroup
                  value={cardTableId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCardTableId((e.target as HTMLInputElement).value)
                  }
                >
                  {listCardData?.listSortCards?.items?.map((card) => (
                    <CardList card={card ?? undefined} />
                  ))}
                </RadioGroup>
              </FormControl>
              <br />
              <br />
              商品・購入情報
              <Paper className={classes.paper}>
                <Typography>
                  決済確定しましたら、以下の期間で視聴が可能になります。
                  <br />
                  購入される商品毎に期間が異なりますので、ご確認ください。
                  <br />
                  <br /> 「通常配信チケット（貴族の宝石箱 6ct.）」　生配信・アーカイブ
                  <br />
                  2022年7月31日(日)20:00〜2022年8月19日(金) 12:00
                  <br />
                  <br />
                  「特製ステッカー1枚付き配信チケット送料込み（貴族の宝石箱 6ct.）」　生配信・アーカイブ
                  <br />
                  2022年7月31日(日)20:00〜2022年8月19日(金) 12:00
                  <br />
                  <br /> 「アーカイブ視聴チケット（貴族の宝石箱 5ct.)」　アーカイブ
                  <br />
                  2022年7月20日(水)12:30〜2022年8月19日(金) 12:00
                  <br />
                  <br />
                  ＊ご購入後、マイページより視聴可能作品をご確認いただけます。
                </Typography>
              </Paper>
              <br />
              <br />
              ご購入に関する注意事項
              <Paper className={classes.paper}>
                <Typography>
                  「解約・返金に関して」
                  <br />
                  <br />
                  デジタルコンテンツの性質上、コンテンツご購入後の解約・キャンセルはできません。必ず「動作環境確認」の箇所にて、サンプル動画の再生を確認していただいたのち、ご購入ください。ご購入後の返金もご対応いたしかねますのでご注意ください。
                  <br />
                  <br />
                  特定商取引に関する法律に基づく表記(デジタルコンテンツ)の詳細は
                  <a href="https://www.htb.co.jp/htb/torikumi/terms.html" target="_blank" rel="noreferrer noopener">
                    こちら
                  </a>
                </Typography>
              </Paper>
              <StyledCenter>
                <div className="centerGroup">
                  {error}
                  <br />
                  <br />
                  <Button
                    type="submit"
                    size="medium"
                    variant="contained"
                    color="primary"
                    // onclick={purchaseProcess(cardId)}
                    onClick={() => {
                      // if (cardTableId) purchaseProcess(cardTableId);
                      purchaseProcess(cardTableId);
                    }}
                  >
                    購入する
                  </Button>
                </div>
              </StyledCenter>
            </>
          )}
        </>
      )}
      {/* <Message messageCode={messageCode} /> */}
      {errorMsgView && (
        <StyledCenter>
          <Message messageCode={messageCode} className="centerGroup" />
        </StyledCenter>
      )}
    </>
  );
};

export default StripeForm;
