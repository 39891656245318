import {
  Typography,
  Grid,
  Box,
  // makeStyles,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { isAuthenticated } from 'Auth0LockContext';
import Breadcrumbs from 'HTB-Videos/components/Common/Breadcrumbs';
import DRMplayer from 'HTB-Videos/components/Common/DRMplayer';
// import GnzoPlayer from 'HTB-Videos/components/Common/GnzoPlayer';
import Loading from 'HTB-Videos/components/Common/Loading';
import THEOPlayer from 'HTB-Videos/components/Common/THEOPlayer';
import TicketSalsesArea from 'HTB-Videos/components/TicketSalesPage/TicketSalsesArea';
import TicketStatus from 'HTB-Videos/components/TicketSalesPage/TicketStatus';
import useGetParentAndChildContents from 'HTB-Videos/hooks/useGetParentAndChildContents';
import useGetPurchasedContents from 'HTB-Videos/hooks/useGetPurchasedContents';
// import useGetRegionalRestrictionContent from 'HTB-Videos/hooks/useGetRegionalRestrictionContent';

// youtube動画の比固定
const StyledYoutube = styled.div`
   {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0px;
    position: relative;
    margin-bottom: 1.5rem;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      '& a': {
        color: theme.palette.secondary.main,
      },
    },
  }),
);

// チケット販売ページ
const TicketSalesPage: FC = () => {
  const { parentId } = useParams();
  const classes = useStyles();

  const {
    parentContent,
    childContents,
    isLoading: isGetParentAndChildLoading,
    isError: isGetParentAndChildError,
  } = useGetParentAndChildContents(parentId);

  const {
    data: purchasedItems,
    isLoading: isGetPurchasedLoading,
    isError: isGetPurchasedError,
  } = useGetPurchasedContents(parentId);

  // const { isStatusCode403, isStatusLoading } = useGetRegionalRestrictionContent();
  const isStatusCode403 = false;

  const samplePlayerText = (link: string) => (
    <>
      <Typography
        gutterBottom
        // className={classes.title}
        variant="h2"
        align="center"
        color="inherit"
      >
        再生確認用サンプル動画
      </Typography>
      <Typography gutterBottom className={classes.link} variant="body2" align="center" color="error">
        ~ こちらの動画は本編動画ではありません。推奨動作環境は
        <Link to={link}>こちら</Link>
        からご確認いただけます~
        <br />
        ライブまたはアーカイブ配信開始日時になりましたら、購入済みチケット内 ”本編再生ページへ進む”
        ボタンをクリックすることで配信ページにお進みいただけます
      </Typography>
    </>
  );

  const samplePlayer = () => {
    if (parentContent?.acf?.isYouTubeContent) {
      return (
        <>
          <Grid item xs={12} sm={12} md={9}>
            <StyledYoutube>
              <YouTube videoId={parentContent.acf.youtubeVideoId} />
            </StyledYoutube>
          </Grid>
        </>
      );
    }
    if (parentContent?.acf?.isMultiAngleContent) {
      return (
        <>
          <Grid item xs={12} sm={12} md={9}>
            {/* {samplePlayerText('/EnvironmentPMF')} */}
            <Typography
              gutterBottom
              // className={classes.title}
              variant="h2"
              align="center"
              color="inherit"
            >
              PMF2021ピクニックコンサート有料配信中止のお知らせ
            </Typography>
            <Typography gutterBottom className={classes.link} variant="body2" color="error">
              PMFパシフィック・ミュージック・フェスティバル札幌（ＰＭＦ）2021について
              PMF組織委員会が、7月28日以降のコンサート全てを中止すると決定いたしました。 今回の中止決定を受け、HTB
              onライン劇場で予定していました、7月31日（土）14：00
              開催のピクニックコンサートの有料ライブ配信、アーカイブ配信をいずれも中止することといたします。
              <br />
              なお、すでにご購入いただいた方への返金につきましては対象の方に8月2日（月）以降、改めてご案内します。
            </Typography>
            {/* <GnzoPlayer source={parentContent?.acf?.MultiAngleSampleSrcURL ?? ''} geoStatusCode403={geoStatusCode403} /> */}
            <StyledYoutube>
              <img alt="pmf2021" src={parentContent.acf.thumbnailURL} />
            </StyledYoutube>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={12} sm={12} md={9}>
          {parentContent?.acf?.sampleIsDRM ? (
            <>
              {samplePlayerText('/Environment')}
              <DRMplayer srcDASH={parentContent?.acf?.sampleSrcDASH ?? ''} srcHLS={parentContent?.acf?.sampleSrcHLS} />
            </>
          ) : (
            <>
              {samplePlayerText('/Environment')}
              <THEOPlayer
                source={{
                  sources: [
                    {
                      src: parentContent?.acf?.sampleSrcHLS ?? '',
                      type: 'application/x-mpegurl',
                    },
                  ],
                  poster: parentContent?.acf?.thumbnailURL ?? '',
                }}
                onPlay={() => {
                  // console.log('The player has started playing.');
                }}
              />
            </>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      {/* パンクズリスト */}
      <Breadcrumbs
        breads={[
          {
            to: '/',
            icon: <HomeIcon />,
            text: 'HOME',
          },
          {
            icon: <ListAltIcon />,
            text: parentContent?.title.rendered ?? '',
          },
        ]}
        substrLength={16}
      />
      <br />
      {(isGetParentAndChildError || isGetPurchasedError) && (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" align="center" color="error">
              エラーが発生しました
              <br />
            </Typography>
          </Grid>
        </Grid>
      )}
      {/* {isGetParentAndChildLoading || isGetPurchasedLoading || isStatusLoading ? ( */}
      {isGetParentAndChildLoading || isGetPurchasedLoading ? (
        <Loading loadingCode="Load_002" />
      ) : (
        <>
          <Grid container spacing={3} alignItems="center" justify="center">
            {/* <Grid item xs={12}>
              <Typography
                gutterBottom
                // className={classes.title}
                variant="h2"
                align="center"
                color="inherit"
              >
                再生確認用サンプル動画
              </Typography>
              <Typography gutterBottom className={classes.link} variant="body2" align="center" color="error">
                ~ こちらの動画は本編動画ではありません。推奨動作環境は
                <Link to="/Environment">こちら</Link>
                からご確認いただけます~
                <br />
                ライブまたはアーカイブ配信開始日時になりましたら、購入済みチケット内 ”本編再生ページへ進む”
                ボタンをクリックすることで配信ページにお進みいただけます
              </Typography>
            </Grid> */}

            {parentContent && (
              <>
                {/* {parentContent.acf.isYouTubeContent ? (
                  <Grid item xs={12}>
                    <StyledYoutube>
                      <YouTube videoId={parentContent.acf.youtubeVideoId} />
                    </StyledYoutube>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {parentContent.acf.sampleIsDRM ? (
                      <DRMplayer
                        srcDASH={parentContent?.acf?.sampleSrcDASH ?? ''}
                        srcHLS={parentContent?.acf?.sampleSrcHLS}
                      />
                    ) : (
                      <THEOPlayer
                        source={{
                          sources: [
                            {
                              src: parentContent.acf.sampleSrcHLS ?? '',
                              type: 'application/x-mpegurl',
                            },
                          ],
                          poster: parentContent.acf.thumbnailURL,
                        }}
                        onPlay={() => {
                          // console.log('The player has started playing.');
                        }}
                      />
                    )}
                  </Grid>
                )} */}

                {/* {samplePlayer(isStatusCode403)} */}
                {samplePlayer()}

                {/* title area （仮） */}
                <Grid item xs={12} sm={12} md={9}>
                  <Typography
                    gutterBottom
                    // className={classes.title}
                    variant="h2"
                    align="center"
                    color="inherit"
                  >
                    {parentContent.title.rendered}
                  </Typography>
                </Grid>
                {/* チケットステータス情報 */}
                {parentContent.acf.isYouTubeContent ? (
                  <></>
                ) : (
                  <Grid item xs={12} sm={12} md={9}>
                    <TicketStatus
                      isLogin={isAuthenticated()}
                      isPurchased={purchasedItems.length > 0}
                      parentContent={parentContent}
                      geoStatusCode403={isStatusCode403}
                    />
                  </Grid>
                )}
                {/* <Box m={2} pb={4} style={{ wordBreak: 'break-word' }}> */}
                <Grid container spacing={4} style={{ margin: 8, wordBreak: 'break-word' }}>
                  {/* チケット販売エリア */}
                  {childContents && (
                    <TicketSalsesArea
                      childContents={childContents}
                      saleFinishDate={parentContent.acf.salesFinishDate}
                      viewMode={parentContent.acf.viewMode}
                      geoStatusCode403={isStatusCode403}
                      isDomesticRestrict={parentContent?.acf?.isDomesticRestrictionsContent ?? false}
                    />
                  )}
                  <Grid item sm={12} md={7}>
                    <Box mb={1} mt={1} display="flex" alignItems="left">
                      <Typography variant="subtitle1" color="inherit">
                        番組情報
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      color="inherit"
                      align="left"
                      dangerouslySetInnerHTML={{
                        __html: parentContent?.acf.overview ?? '',
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <Box mb={1} display="flex" alignItems="left">
                      {/* <Typography variant="h6" className={classes.title}> */}
                      <Typography variant="subtitle1" color="inherit">
                        出演者情報
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      align="left"
                      color="inherit"
                      dangerouslySetInnerHTML={{
                        __html: parentContent?.acf.performer ?? '',
                      }}
                    />
                  </Grid>
                </Grid>
                {/* </Box> */}
                {/* チケット販売エリア */}
                {/* {childContents && (
                  <TicketSalsesArea
                    childContents={childContents}
                    saleFinishDate={parentContent.acf.salesFinishDate}
                    viewMode={parentContent.acf.viewMode}
                    geoStatusCode403={isStatusCode403}
                    isDomesticRestrict={parentContent?.acf?.isDomesticRestrictionsContent ?? false}
                  />
                )} */}
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default TicketSalesPage;
