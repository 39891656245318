import { Button, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Loading from 'HTB-Videos/components/Common/Loading';
import WPFooter from '../components/Common/WPFooterAxios';
import { wpFooterData } from '../utils/WPRequest';

const StyledTermsofuse = styled.div`
  h2 {
    text-align: center;
    font-weight: bold;
    margin: 10px 0px;
    font-size: 2rem;
    border-bottom: 5px solid #c69e61;
    padding-bottom: 10px;
    padding-top: 30px;
    margin-bottom: 20px;

    @media screen and (max-width: 751px) {
       {
        font-size: 1.2rem;
      }
    }
  }

  p {
    line-height: 1.2rem;
  }

  h3 {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 30px 0px 10px;
    // color: white;
  }

  ol li {
    line-height: 1.2rem;
    margin-left: 1.5rem;
    margin-bottom: 10px;
    list-style: decimal;
    // color: white;
  }

  ul li {
    margin-top: 1rem;
    margin-left: 3rem;
    list-style: disc outside;
    // color: white;
  }

  .date {
    line-height: 1.2rem;
    text-align: right;
    font-size: 0.8rem;
  }

  .linkIndex {
    text-align: center;
    margin: 20px auto 0px auto;
  }
`;

/**
 * 利用規約ページ
 */
const Termsofuse: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [footerText, setFooterText] = useState('');

  useEffect(() => {
    const getContents = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const response: wpFooterData = await WPFooter.get(`139`);
        setFooterText(response.data.content.rendered ?? '');
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    void getContents();
  }, []);

  return (
    <>
      <StyledTermsofuse>
        {isError && (
          <Typography gutterBottom variant="body1" align="center" color="error">
            エラーが発生しました
            <br />
          </Typography>
        )}
        {isLoading ? (
          <Loading loadingCode="Load_002" />
        ) : (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: footerText,
              }}
            />
          </>
        )}
        <div className="linkIndex">
          <Button variant="contained" color="primary" component={Link} to="/">
            トップへ戻る
          </Button>
        </div>
      </StyledTermsofuse>
    </>
  );
};

export default Termsofuse;
