import axios from 'axios';

const WPFooter = axios.create({
  baseURL: 'https://dxjmemwogeqvm.cloudfront.net/wp-json/wp/v2/pages',
  params: {
    _fields: 'content',
  },
});

export default WPFooter;
