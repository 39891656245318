import axios from 'axios';
import { useEffect, useState } from 'react';
import { KintoneProducts } from 'graphql/appsync/generated';

/**
 * 引数で指定したproductIdに該当する商品情報を取得するhooks
 */
const useGetKintoneProducts = (
  productId: string,
): {
  kintoneProducts: KintoneProducts[] | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [kintoneProducts, setKintoneProducts] = useState<KintoneProducts[]>([]);

  useEffect(() => {
    const getContent = async () => {
      setIsErrorState(false);
      setIsLoadingState(true);
      try {
        const Response = await axios.get(process.env.REACT_APP_GET_PRODUCT_FROM_APIGATEWAY_URL ?? '', {
          params: {
            productId,
          },
        });
        setKintoneProducts(Response.data);
      } catch (err) {
        setIsErrorState(true);
      }
      setIsLoadingState(false);
    };

    void getContent();
  }, [productId]);

  return {
    kintoneProducts,
    isLoading: isLoadingState,
    isError: isErrorState,
  };
};

export default useGetKintoneProducts;
