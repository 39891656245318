import { Grid, Typography, Button } from '@material-ui/core/';
import React, { FC, useContext, useState } from 'react';
import Auth0LockContext, { isAuthenticated } from 'Auth0LockContext';
import FormDrawer from 'HTB-Videos/components/LiveSalesArea/pages/Cart/FormDrawer';
import FormDrawerVertical from 'HTB-Videos/components/LiveSalesArea/pages/Cart/FormDrawerVertical';
import LineItemCard from 'HTB-Videos/components/LiveSalesArea/pages/Cart/LineItemCard';
import { itemList } from 'HTB-Videos/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';
import { GoodsCart } from 'graphql/appsync/generated';
import Loading from 'HTB-Videos/components/Common/Loading';
import styled from 'styled-components';
import { useLocation } from 'react-router';

export type Props = {
  /**
   * 前のページへ切り替える関数
   */
  previousPagingFunction: VoidFunction;

  /**
   * 表示するカート情報
   */
  goodsCart?: GoodsCart;

  /**
   * ロード中であるかを示す真偽値
   */
  isLoading: boolean;
  /**
   * グッズカート内Items情報
   */
  Items: itemList[];
};

// 購入に進む系のボタンの色はオレンジ
const StyledButton = styled(Button)`
  background-color: #ff9100 !important;
`;

/**
 * カート情報を表示するコンポーネント
 */
const Cart: FC<Props> = ({ previousPagingFunction, goodsCart, isLoading, Items }) => {
  const auth0Lock = useContext(Auth0LockContext);
  const { pathname } = useLocation();
  localStorage.removeItem('auth_show');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const ItemLists = goodsCart?.items
    ? (JSON.parse(goodsCart?.items as string) as Array<itemList>).map((item) => {
        return {
          id: item.id,
          count: item.count,
          title: item.title,
          thumbnailUrl: item.thumbnailUrl,
          cost: item.cost,
        };
      })
    : [];

  // カート合計金額計算
  const totalPrice = Items?.reduce((prev, current) => prev + (current?.cost ?? 0) * (current?.count ?? 0), 0);

  return (
    <>
      <br />
      {/* ロード中の場合 */}
      {isLoading && <Loading loadingCode="Load_001" />}
      {/* ロード中では無い場合 */}
      {!isLoading && (
        <>
          {ItemLists && ItemLists.length > 0 ? (
            // カートに商品がある場合
            <>
              {ItemLists.map((item) => (
                <Grid container justify="center" key={item.id}>
                  <LineItemCard lineItem={item} Items={Items as itemList[]} />
                </Grid>
              ))}
              <br />
              <Grid container justify="center">
                <Typography gutterBottom variant="h2">
                  小計：&ensp;
                  {Number(totalPrice).toLocaleString()}&ensp;円
                </Typography>
              </Grid>
            </>
          ) : (
            // カートに商品が無い場合
            <Grid container justify="center" component="p">
              カートに商品はありません
            </Grid>
          )}
          <br />
          <Grid container justify="center" alignItems="center" spacing={2}>
            {ItemLists && ItemLists.length > 0 && (
              <Grid item xs={10}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    if (!isAuthenticated()) {
                      localStorage.setItem('auth_show', 'true');
                      auth0Lock.client.show({
                        rememberLastLogin: false,
                        auth: {
                          params: {
                            state: `${pathname}`,
                          },
                        },
                      });
                    } else {
                      void setIsDrawerOpen(true);
                    }
                  }}
                  disabled={!ItemLists || ItemLists.length < 1}
                >
                  <Typography align="center" variant="subtitle1">
                    レジへ進む
                  </Typography>
                </StyledButton>
              </Grid>
            )}

            <Grid item xs={10} style={{ textAlign: 'center' }}>
              <Button variant="contained" color="primary" onClick={() => previousPagingFunction()}>
                <Typography align="center" variant="body1">
                  商品一覧に戻る
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {/* <FormDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} /> */}
      <FormDrawerVertical isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
    </>
  );
};

export default Cart;
