import React, { FC } from 'react';
import { getAuthProfile } from 'Auth0LockContext';
import RemoveLineItemButton from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/RemoveLineItemButton';
import { itemList } from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';
import { useUpdateGoodsCartMutation } from 'graphql/appsync/generated';

type Props = {
  /**
   * 削除対象のlineItemId
   */
  lineItemId: string;

  /**
   * グッズカート内Items情報
   */
  Items: itemList[];
};

/**
 * 商品削除ボタン
 */
const EnhancedRemoveLineItemButton: FC<Props> = ({ lineItemId, Items }) => {
  // GoodsCartを更新するhooks
  const [
    goodsCartItemsUpdateMutation,
    {
      loading: isGoodsCartItemsUpdateLoading,
      // error: goodsCartItemsUpdateError,
    },
  ] = useUpdateGoodsCartMutation();

  // 削除ボタンロジック部分
  const cartItemDelete = async () => {
    const deleteItem =
      (Items.length ?? 0) > 0 && Items.find((item) => item.id === lineItemId)
        ? Items.filter((data) => data.id !== lineItemId)
        : [...Items];

    // 該当商品を削除したリストをJSON文字列に変換
    const itemListJson = JSON.stringify(deleteItem);

    // goodsCartテーブルItems情報を更新
    await goodsCartItemsUpdateMutation({
      variables: {
        input: {
          id: localStorage.getItem('goodsCartId') ?? '',
          userId: getAuthProfile().sub ?? '',
          items: itemListJson,
        },
      },
    });
  };

  return <RemoveLineItemButton isLoading={isGoodsCartItemsUpdateLoading} onClick={cartItemDelete} />;
};

export default EnhancedRemoveLineItemButton;
