import { Button, Typography } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { getAuthProfile } from 'Auth0LockContext';
import Loading from 'HTB-Videos/components/Common/Loading';
import { useCreateDeliveryMutation } from 'graphql/appsync/generated';
import Message from '../Common/Message';
import AddressForm, { FormData } from './AddressForm';
import DeliveryConfirmation from './DeliveryConfirmation';

// const StyledCenter = styled.div`
//   .centerGroup {
//     text-align: center;
//     margin: 20px auto 20px;
//   }
// `;

const StyeldConfirmation = styled.div`
  text-align: center;
  padding: 10px;

  .flex {
    display: flex;
  }

  .button {
    margin: 20px auto 20px;
  }

  .font {
    font-size: 1.1rem;
  }
`;

type Props = {
  /**
   * 更新対象のユーザー情報
   */
  // user?: User;

  /**
   * 登録時に実行される関数
   */
  onEdit?: VoidFunction;
};

const useStyles = makeStyles(() =>
  createStyles({
    center: {
      textAlign: 'center',
    },
  }),
);

const DeliveryAddressEditForm: FC<Props> = ({ onEdit }) => {
  const classes = useStyles();
  const [sending, setSending] = useState<boolean>(false);
  const [formView, setFormView] = useState<boolean>(true);
  const [confirmationView, setConfirmationView] = useState<boolean>(false);
  const [addressData, setAddressData] = useState<FormData>();
  // 住所作成ミューテーション
  const [createDeliveryMutation, { error: createDeliveryMutationError, loading: isCreateDeliveryMutationLoading }] =
    useCreateDeliveryMutation();

  const pagingFunction = (result: string) => {
    if (result === 'AddressInputSuccess') {
      // 住所登録終えたら確認画面を出す
      setFormView(false);
      setConfirmationView(true);
    }
  };

  const resultInfo = (resultAddressData: FormData) => {
    setAddressData(resultAddressData);
  };

  // 登録実行 mutation
  const onClickRegistration = async () => {
    setConfirmationView(false);
    setSending(true);
    // 配送先情報設定
    await createDeliveryMutation({
      variables: {
        input: {
          userId: getAuthProfile().sub ?? '',
          familyName: addressData?.familyName ?? '',
          givenName: addressData?.givenName ?? '',
          postCode: addressData?.postCode ?? '',
          phoneNumber: `+81 ${addressData?.phoneNumber ?? ''}`,
          city: addressData?.city ?? '',
          provinceCode: addressData?.provinceCode ?? '',
          address: addressData?.address ?? '',
          subAddress: addressData?.subAddress ?? '',
        },
      },
    });
    if (onEdit) void onEdit();
  };

  // 修正で前の状態へ
  const onClickEditing = () => {
    setFormView(true);
    setConfirmationView(false);
  };

  // Graphqlがエラー

  if (createDeliveryMutationError) {
    return (
      <>
        <br />
        <br />
        <Message messageCode="ERR_000301" className={classes.center} />
        <br />
        {createDeliveryMutationError.message.includes('phoneNumber') && (
          <div className={classes.center}>
            <p>無効な電話番号が入力されました。</p>
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }

  return (
    <>
      {formView && (
        <AddressForm
          isLoading={isCreateDeliveryMutationLoading}
          pagingFunction={pagingFunction}
          resultInfo={resultInfo}
        />
      )}
      {confirmationView && (
        <>
          <StyeldConfirmation>
            <DeliveryConfirmation addressData={addressData} />
            <div className="flex">
              <Button color="primary" variant="contained" onClick={onClickEditing} className="button">
                <Typography align="center" variant="body1">
                  修正する
                </Typography>
              </Button>
              <Button color="primary" variant="contained" onClick={onClickRegistration} className="button">
                <Typography align="center" variant="body1">
                  登録する
                </Typography>
              </Button>
            </div>
          </StyeldConfirmation>
        </>
      )}
      {sending && (
        <>
          <Loading loadingCode="Load_003" />
        </>
      )}
      <br />
    </>
  );
};

export default DeliveryAddressEditForm;
