import { useEffect, useState } from 'react';
import { wpParentContentData } from 'HTB-Videos/utils/WPRequest';
import WPContent from '../components/Common/WPContentsAxios';

/**
 * 親コンテンツを取得するhooks
 */
const useGetParentContent = (
  parentId: string,
): {
  parentContent: wpParentContentData | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [
    parentContentState,
    setParentContentState,
  ] = useState<wpParentContentData>();

  useEffect(() => {
    const getContent = async () => {
      setIsErrorState(false);
      setIsLoadingState(true);
      try {
        // const parentResponse = await axios.get(
        //   `https://dxjmemwogeqvm.cloudfront.net/wp-json/wp/v2/online_theater/${parentId}?_embed=${parentId}&_fields=acf,id,title`,
        // );
        const parentResponse = await WPContent.get(`${parentId}`, {
          params: {
            _fields: 'acf,id,title',
          },
        });
        setParentContentState(parentResponse.data);
      } catch (err) {
        setIsErrorState(true);
      }
      setIsLoadingState(false);
    };

    void getContent();
  }, [parentId]);

  return {
    parentContent: parentContentState,
    isLoading: isLoadingState,
    isError: isErrorState,
  };
};

export default useGetParentContent;
