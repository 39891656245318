import axios from 'axios';

const WPYoutubePlaylist = axios.create({
  baseURL:
    'https://dxjmemwogeqvm.cloudfront.net/wp-json/wp/v2/youtube_playlists',
});

export default WPYoutubePlaylist;

/**
 * WPから取得するyoutube再生リストタイトルとID
 */
export type WPYoutubePlaylistData = {
  /**
   * 再生リストタイトル
   */
  title: {
    rendered: string;
  };
  /**
   * 親記事のID
   */
  parent: string;
  acf: {
    /**
     * 再生リストID
     */
    youtubePlaylistId: string;
    /**
     * サムネイル最大取得件数
     */
    youtubeDisplayResults: string;
  };
};
