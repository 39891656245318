import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};










export type BuyStatus =
  | 'Bought'
  | 'Buying'
  | 'NotBought';

export type Card = {
  __typename?: 'Card';
  brand: CardBrand;
  cardNo: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  deadline: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stripeCardId: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['ID'];
};

export type CardBrand =
  | 'AMEX'
  | 'Diners'
  | 'Discover'
  | 'JCB'
  | 'Mastercard'
  | 'Visa';

export type CreateCardInput = {
  brand: CardBrand;
  cardNo: Scalars['String'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  deadline: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stripeCardId: Scalars['String'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId: Scalars['ID'];
};

export type CreateDeliveryInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  phoneNumber: Scalars['AWSPhone'];
  postCode: Scalars['String'];
  provinceCode: Scalars['String'];
  subAddress?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId: Scalars['String'];
};

export type CreateGoodsCartInput = {
  cardId?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  items?: Maybe<Scalars['AWSJSON']>;
  orderState?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderTypes>;
  paymentId?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type CreateGoodsOrderInput = {
  address?: Maybe<Scalars['String']>;
  buyState: BuyStatus;
  cardBrand?: Maybe<CardBrand>;
  cardName?: Maybe<Scalars['String']>;
  cardNo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  deliveryPrice: Scalars['Int'];
  discount: Scalars['Int'];
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  items: Scalars['AWSJSON'];
  orderType: OrderTypes;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  postCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  specialItem?: Maybe<SpecialItem>;
  stateByDate?: Maybe<Scalars['String']>;
  subAddress?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Int'];
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId: Scalars['String'];
};

export type CreateKintoneCollectionInput = {
  audience?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isDisplayFlg?: Maybe<Scalars['Boolean']>;
  precedence?: Maybe<Scalars['Int']>;
  productId: Scalars['AWSJSON'];
  salesState?: Maybe<Sales>;
  salesType?: Maybe<SalesType>;
  thumbnail?: Maybe<Scalars['AWSURL']>;
  title?: Maybe<Scalars['String']>;
  wpId?: Maybe<Scalars['String']>;
};

export type CreateKintoneProductsInput = {
  audience?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Scalars['AWSJSON']>;
  inventoryState?: Maybe<Inventory>;
  isDisplayFlg: Scalars['Boolean'];
  jancode: Scalars['String'];
  limitOrderBuy?: Maybe<Scalars['Int']>;
  productId: Scalars['String'];
  productTitle: Scalars['String'];
  productType?: Maybe<ProductType>;
  releaseDate?: Maybe<Scalars['AWSDate']>;
  salesState?: Maybe<Sales>;
  sku: Scalars['String'];
  sortNumber: Scalars['Int'];
  tax: Scalars['Int'];
  taxExcludePrice: Scalars['Int'];
  taxInRegularPrice?: Maybe<Scalars['Int']>;
  taxIncludePrice: Scalars['Int'];
  taxRate?: Maybe<TaxEnum>;
  thumbnail?: Maybe<Scalars['AWSURL']>;
  transferPrice?: Maybe<Scalars['Int']>;
  variation?: Maybe<Scalars['String']>;
  variationTitle: Scalars['String'];
};

export type CreateKintoneProductsStockInput = {
  audience?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inventory: Scalars['Int'];
};

export type CreateOrderInput = {
  article?: Maybe<Scalars['String']>;
  buyState: BuyStatus;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['ID']>;
  itemId: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['AWSURL']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId: Scalars['String'];
};

export type CreateTemporaryGoodsOrderInput = {
  LockMode: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<CardBrand>;
  cardId?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNo?: Maybe<Scalars['String']>;
  cartId: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryPrice: Scalars['Int'];
  discount: Scalars['Int'];
  expriesAt?: Maybe<Scalars['AWSTimestamp']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  items: Scalars['AWSJSON'];
  orderType: OrderTypes;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  postCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  specialItem?: Maybe<SpecialItem>;
  subAddress?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Int'];
  userId: Scalars['String'];
};

export type CreateUserInput = {
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  mail: Scalars['String'];
  stripeId: Scalars['String'];
};

export type DeleteCardInput = {
  id: Scalars['ID'];
};

export type DeleteDeliveryInput = {
  id: Scalars['ID'];
};

export type DeleteGoodsCartInput = {
  id: Scalars['ID'];
};

export type DeleteGoodsOrderInput = {
  id: Scalars['ID'];
};

export type DeleteKintoneCollectionInput = {
  id: Scalars['ID'];
};

export type DeleteKintoneProductsInput = {
  id: Scalars['ID'];
};

export type DeleteKintoneProductsStockInput = {
  id: Scalars['ID'];
};

export type DeleteOrderInput = {
  id: Scalars['ID'];
};

export type DeleteTemporaryGoodsOrderInput = {
  id: Scalars['ID'];
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

export type Delivery = {
  __typename?: 'Delivery';
  address: Scalars['String'];
  city: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['ID'];
  phoneNumber: Scalars['AWSPhone'];
  postCode: Scalars['String'];
  provinceCode: Scalars['String'];
  subAddress?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['String'];
};

export type Gender =
  | 'man'
  | 'other'
  | 'woman';

export type GoodsCart = {
  __typename?: 'GoodsCart';
  cardId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  deliveryId?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  items?: Maybe<Scalars['AWSJSON']>;
  orderState?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderTypes>;
  paymentId?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['String'];
};

export type GoodsOrder = {
  __typename?: 'GoodsOrder';
  address?: Maybe<Scalars['String']>;
  buyState: BuyStatus;
  cardBrand?: Maybe<CardBrand>;
  cardName?: Maybe<Scalars['String']>;
  cardNo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  deliveryPrice: Scalars['Int'];
  discount: Scalars['Int'];
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items: Scalars['AWSJSON'];
  orderType: OrderTypes;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  postCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  specialItem?: Maybe<SpecialItem>;
  stateByDate?: Maybe<Scalars['String']>;
  subAddress?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Int'];
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['String'];
};

export type Inventory =
  | 'enough'
  | 'limitedStock'
  | 'outOfStock';

export type KintoneCollection = {
  __typename?: 'KintoneCollection';
  audience?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isDisplayFlg?: Maybe<Scalars['Boolean']>;
  precedence?: Maybe<Scalars['Int']>;
  productId: Scalars['AWSJSON'];
  salesState?: Maybe<Sales>;
  salesType?: Maybe<SalesType>;
  thumbnail?: Maybe<Scalars['AWSURL']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  wpId?: Maybe<Scalars['String']>;
};

export type KintoneProducts = {
  __typename?: 'KintoneProducts';
  audience?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  images?: Maybe<Scalars['AWSJSON']>;
  inventoryState?: Maybe<Inventory>;
  isDisplayFlg: Scalars['Boolean'];
  jancode: Scalars['String'];
  limitOrderBuy?: Maybe<Scalars['Int']>;
  productId: Scalars['String'];
  productTitle: Scalars['String'];
  productType?: Maybe<ProductType>;
  releaseDate?: Maybe<Scalars['AWSDate']>;
  salesState?: Maybe<Sales>;
  sku: Scalars['String'];
  sortNumber: Scalars['Int'];
  tax: Scalars['Int'];
  taxExcludePrice: Scalars['Int'];
  taxInRegularPrice?: Maybe<Scalars['Int']>;
  taxIncludePrice: Scalars['Int'];
  taxRate?: Maybe<TaxEnum>;
  thumbnail?: Maybe<Scalars['AWSURL']>;
  transferPrice?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['AWSDateTime'];
  variation?: Maybe<Scalars['String']>;
  variationTitle: Scalars['String'];
};

export type KintoneProductsStock = {
  __typename?: 'KintoneProductsStock';
  audience?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  inventory: Scalars['Int'];
  updatedAt: Scalars['AWSDateTime'];
};

export type ModelAttributeTypes =
  | '_null'
  | 'binary'
  | 'binarySet'
  | 'bool'
  | 'list'
  | 'map'
  | 'number'
  | 'numberSet'
  | 'string'
  | 'stringSet';

export type ModelBooleanInput = {
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
};

export type ModelBuyStatusInput = {
  eq?: Maybe<BuyStatus>;
  ne?: Maybe<BuyStatus>;
};

export type ModelCardBrandInput = {
  eq?: Maybe<CardBrand>;
  ne?: Maybe<CardBrand>;
};

export type ModelCardConditionInput = {
  and?: Maybe<Array<Maybe<ModelCardConditionInput>>>;
  brand?: Maybe<ModelCardBrandInput>;
  cardNo?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  deadline?: Maybe<ModelStringInput>;
  name?: Maybe<ModelStringInput>;
  not?: Maybe<ModelCardConditionInput>;
  or?: Maybe<Array<Maybe<ModelCardConditionInput>>>;
  stripeCardId?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
};

export type ModelCardConnection = {
  __typename?: 'ModelCardConnection';
  items?: Maybe<Array<Maybe<Card>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCardFilterInput = {
  and?: Maybe<Array<Maybe<ModelCardFilterInput>>>;
  brand?: Maybe<ModelCardBrandInput>;
  cardNo?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  deadline?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  not?: Maybe<ModelCardFilterInput>;
  or?: Maybe<Array<Maybe<ModelCardFilterInput>>>;
  stripeCardId?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelIdInput>;
};

export type ModelDeliveryConditionInput = {
  address?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDeliveryConditionInput>>>;
  city?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  familyName?: Maybe<ModelStringInput>;
  givenName?: Maybe<ModelStringInput>;
  not?: Maybe<ModelDeliveryConditionInput>;
  or?: Maybe<Array<Maybe<ModelDeliveryConditionInput>>>;
  phoneNumber?: Maybe<ModelStringInput>;
  postCode?: Maybe<ModelStringInput>;
  provinceCode?: Maybe<ModelStringInput>;
  subAddress?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
};

export type ModelDeliveryConnection = {
  __typename?: 'ModelDeliveryConnection';
  items?: Maybe<Array<Maybe<Delivery>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDeliveryFilterInput = {
  address?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDeliveryFilterInput>>>;
  city?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  familyName?: Maybe<ModelStringInput>;
  givenName?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  not?: Maybe<ModelDeliveryFilterInput>;
  or?: Maybe<Array<Maybe<ModelDeliveryFilterInput>>>;
  phoneNumber?: Maybe<ModelStringInput>;
  postCode?: Maybe<ModelStringInput>;
  provinceCode?: Maybe<ModelStringInput>;
  subAddress?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
};

export type ModelFloatInput = {
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
  eq?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
};

export type ModelGoodsCartConditionInput = {
  and?: Maybe<Array<Maybe<ModelGoodsCartConditionInput>>>;
  cardId?: Maybe<ModelStringInput>;
  deliveryId?: Maybe<ModelStringInput>;
  deliveryPrice?: Maybe<ModelIntInput>;
  discount?: Maybe<ModelIntInput>;
  items?: Maybe<ModelStringInput>;
  not?: Maybe<ModelGoodsCartConditionInput>;
  or?: Maybe<Array<Maybe<ModelGoodsCartConditionInput>>>;
  orderState?: Maybe<ModelOrderStatusInput>;
  orderType?: Maybe<ModelOrderTypesInput>;
  paymentId?: Maybe<ModelStringInput>;
  totalPrice?: Maybe<ModelIntInput>;
};

export type ModelGoodsCartConnection = {
  __typename?: 'ModelGoodsCartConnection';
  items?: Maybe<Array<Maybe<GoodsCart>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelGoodsCartFilterInput = {
  and?: Maybe<Array<Maybe<ModelGoodsCartFilterInput>>>;
  cardId?: Maybe<ModelStringInput>;
  deliveryId?: Maybe<ModelStringInput>;
  deliveryPrice?: Maybe<ModelIntInput>;
  discount?: Maybe<ModelIntInput>;
  id?: Maybe<ModelIdInput>;
  items?: Maybe<ModelStringInput>;
  not?: Maybe<ModelGoodsCartFilterInput>;
  or?: Maybe<Array<Maybe<ModelGoodsCartFilterInput>>>;
  orderState?: Maybe<ModelOrderStatusInput>;
  orderType?: Maybe<ModelOrderTypesInput>;
  paymentId?: Maybe<ModelStringInput>;
  totalPrice?: Maybe<ModelIntInput>;
  userId?: Maybe<ModelStringInput>;
};

export type ModelGoodsOrderConditionInput = {
  address?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelGoodsOrderConditionInput>>>;
  buyState?: Maybe<ModelBuyStatusInput>;
  cardBrand?: Maybe<ModelCardBrandInput>;
  cardName?: Maybe<ModelStringInput>;
  cardNo?: Maybe<ModelStringInput>;
  city?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  deliveryPrice?: Maybe<ModelIntInput>;
  discount?: Maybe<ModelIntInput>;
  familyName?: Maybe<ModelStringInput>;
  givenName?: Maybe<ModelStringInput>;
  items?: Maybe<ModelStringInput>;
  not?: Maybe<ModelGoodsOrderConditionInput>;
  or?: Maybe<Array<Maybe<ModelGoodsOrderConditionInput>>>;
  orderType?: Maybe<ModelOrderTypesInput>;
  phoneNumber?: Maybe<ModelStringInput>;
  postCode?: Maybe<ModelStringInput>;
  provinceCode?: Maybe<ModelStringInput>;
  specialItem?: Maybe<ModelSpecialItemInput>;
  stateByDate?: Maybe<ModelStringInput>;
  subAddress?: Maybe<ModelStringInput>;
  totalPrice?: Maybe<ModelIntInput>;
  trackingNumber?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
};

export type ModelGoodsOrderConnection = {
  __typename?: 'ModelGoodsOrderConnection';
  items?: Maybe<Array<Maybe<GoodsOrder>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelGoodsOrderFilterInput = {
  address?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelGoodsOrderFilterInput>>>;
  buyState?: Maybe<ModelBuyStatusInput>;
  cardBrand?: Maybe<ModelCardBrandInput>;
  cardName?: Maybe<ModelStringInput>;
  cardNo?: Maybe<ModelStringInput>;
  city?: Maybe<ModelStringInput>;
  createdAt?: Maybe<ModelStringInput>;
  deliveryPrice?: Maybe<ModelIntInput>;
  discount?: Maybe<ModelIntInput>;
  familyName?: Maybe<ModelStringInput>;
  givenName?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  items?: Maybe<ModelStringInput>;
  not?: Maybe<ModelGoodsOrderFilterInput>;
  or?: Maybe<Array<Maybe<ModelGoodsOrderFilterInput>>>;
  orderType?: Maybe<ModelOrderTypesInput>;
  phoneNumber?: Maybe<ModelStringInput>;
  postCode?: Maybe<ModelStringInput>;
  provinceCode?: Maybe<ModelStringInput>;
  specialItem?: Maybe<ModelSpecialItemInput>;
  stateByDate?: Maybe<ModelStringInput>;
  subAddress?: Maybe<ModelStringInput>;
  totalPrice?: Maybe<ModelIntInput>;
  trackingNumber?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
};

export type ModelIdInput = {
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  beginsWith?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contains?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  size?: Maybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  eq?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
};

export type ModelIntKeyConditionInput = {
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  eq?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
};

export type ModelInventoryInput = {
  eq?: Maybe<Inventory>;
  ne?: Maybe<Inventory>;
};

export type ModelKintoneCollectionConditionInput = {
  and?: Maybe<Array<Maybe<ModelKintoneCollectionConditionInput>>>;
  isDisplayFlg?: Maybe<ModelBooleanInput>;
  not?: Maybe<ModelKintoneCollectionConditionInput>;
  or?: Maybe<Array<Maybe<ModelKintoneCollectionConditionInput>>>;
  precedence?: Maybe<ModelIntInput>;
  productId?: Maybe<ModelStringInput>;
  salesState?: Maybe<ModelSalesInput>;
  salesType?: Maybe<ModelSalesTypeInput>;
  thumbnail?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  wpId?: Maybe<ModelStringInput>;
};

export type ModelKintoneCollectionConnection = {
  __typename?: 'ModelKintoneCollectionConnection';
  items?: Maybe<Array<Maybe<KintoneCollection>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelKintoneCollectionFilterInput = {
  and?: Maybe<Array<Maybe<ModelKintoneCollectionFilterInput>>>;
  audience?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  isDisplayFlg?: Maybe<ModelBooleanInput>;
  not?: Maybe<ModelKintoneCollectionFilterInput>;
  or?: Maybe<Array<Maybe<ModelKintoneCollectionFilterInput>>>;
  precedence?: Maybe<ModelIntInput>;
  productId?: Maybe<ModelStringInput>;
  salesState?: Maybe<ModelSalesInput>;
  salesType?: Maybe<ModelSalesTypeInput>;
  thumbnail?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  wpId?: Maybe<ModelStringInput>;
};

export type ModelKintoneProductsConditionInput = {
  and?: Maybe<Array<Maybe<ModelKintoneProductsConditionInput>>>;
  description?: Maybe<ModelStringInput>;
  images?: Maybe<ModelStringInput>;
  inventoryState?: Maybe<ModelInventoryInput>;
  isDisplayFlg?: Maybe<ModelBooleanInput>;
  jancode?: Maybe<ModelStringInput>;
  limitOrderBuy?: Maybe<ModelIntInput>;
  not?: Maybe<ModelKintoneProductsConditionInput>;
  or?: Maybe<Array<Maybe<ModelKintoneProductsConditionInput>>>;
  productId?: Maybe<ModelStringInput>;
  productTitle?: Maybe<ModelStringInput>;
  productType?: Maybe<ModelProductTypeInput>;
  releaseDate?: Maybe<ModelStringInput>;
  salesState?: Maybe<ModelSalesInput>;
  sku?: Maybe<ModelStringInput>;
  sortNumber?: Maybe<ModelIntInput>;
  tax?: Maybe<ModelIntInput>;
  taxExcludePrice?: Maybe<ModelIntInput>;
  taxInRegularPrice?: Maybe<ModelIntInput>;
  taxIncludePrice?: Maybe<ModelIntInput>;
  taxRate?: Maybe<ModelTaxEnumInput>;
  thumbnail?: Maybe<ModelStringInput>;
  transferPrice?: Maybe<ModelIntInput>;
  variation?: Maybe<ModelStringInput>;
  variationTitle?: Maybe<ModelStringInput>;
};

export type ModelKintoneProductsConnection = {
  __typename?: 'ModelKintoneProductsConnection';
  items?: Maybe<Array<Maybe<KintoneProducts>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelKintoneProductsFilterInput = {
  and?: Maybe<Array<Maybe<ModelKintoneProductsFilterInput>>>;
  audience?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  images?: Maybe<ModelStringInput>;
  inventoryState?: Maybe<ModelInventoryInput>;
  isDisplayFlg?: Maybe<ModelBooleanInput>;
  jancode?: Maybe<ModelStringInput>;
  limitOrderBuy?: Maybe<ModelIntInput>;
  not?: Maybe<ModelKintoneProductsFilterInput>;
  or?: Maybe<Array<Maybe<ModelKintoneProductsFilterInput>>>;
  productId?: Maybe<ModelStringInput>;
  productTitle?: Maybe<ModelStringInput>;
  productType?: Maybe<ModelProductTypeInput>;
  releaseDate?: Maybe<ModelStringInput>;
  salesState?: Maybe<ModelSalesInput>;
  sku?: Maybe<ModelStringInput>;
  sortNumber?: Maybe<ModelIntInput>;
  tax?: Maybe<ModelIntInput>;
  taxExcludePrice?: Maybe<ModelIntInput>;
  taxInRegularPrice?: Maybe<ModelIntInput>;
  taxIncludePrice?: Maybe<ModelIntInput>;
  taxRate?: Maybe<ModelTaxEnumInput>;
  thumbnail?: Maybe<ModelStringInput>;
  transferPrice?: Maybe<ModelIntInput>;
  variation?: Maybe<ModelStringInput>;
  variationTitle?: Maybe<ModelStringInput>;
};

export type ModelKintoneProductsStockConditionInput = {
  and?: Maybe<Array<Maybe<ModelKintoneProductsStockConditionInput>>>;
  audience?: Maybe<ModelStringInput>;
  inventory?: Maybe<ModelIntInput>;
  not?: Maybe<ModelKintoneProductsStockConditionInput>;
  or?: Maybe<Array<Maybe<ModelKintoneProductsStockConditionInput>>>;
};

export type ModelKintoneProductsStockConnection = {
  __typename?: 'ModelKintoneProductsStockConnection';
  items?: Maybe<Array<Maybe<KintoneProductsStock>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelKintoneProductsStockFilterInput = {
  and?: Maybe<Array<Maybe<ModelKintoneProductsStockFilterInput>>>;
  audience?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  inventory?: Maybe<ModelIntInput>;
  not?: Maybe<ModelKintoneProductsStockFilterInput>;
  or?: Maybe<Array<Maybe<ModelKintoneProductsStockFilterInput>>>;
};

export type ModelOrderConditionInput = {
  and?: Maybe<Array<Maybe<ModelOrderConditionInput>>>;
  article?: Maybe<ModelStringInput>;
  buyState?: Maybe<ModelBuyStatusInput>;
  cost?: Maybe<ModelIntInput>;
  createdAt?: Maybe<ModelStringInput>;
  itemId?: Maybe<ModelStringInput>;
  not?: Maybe<ModelOrderConditionInput>;
  or?: Maybe<Array<Maybe<ModelOrderConditionInput>>>;
  thumbnailUrl?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
};

export type ModelOrderConnection = {
  __typename?: 'ModelOrderConnection';
  items?: Maybe<Array<Maybe<Order>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelOrderFilterInput = {
  and?: Maybe<Array<Maybe<ModelOrderFilterInput>>>;
  article?: Maybe<ModelStringInput>;
  buyState?: Maybe<ModelBuyStatusInput>;
  cost?: Maybe<ModelIntInput>;
  createdAt?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  itemId?: Maybe<ModelStringInput>;
  not?: Maybe<ModelOrderFilterInput>;
  or?: Maybe<Array<Maybe<ModelOrderFilterInput>>>;
  thumbnailUrl?: Maybe<ModelStringInput>;
  title?: Maybe<ModelStringInput>;
  updatedAt?: Maybe<ModelStringInput>;
  userId?: Maybe<ModelStringInput>;
};

export type ModelOrderStatusInput = {
  eq?: Maybe<OrderStatus>;
  ne?: Maybe<OrderStatus>;
};

export type ModelOrderTypesInput = {
  eq?: Maybe<OrderTypes>;
  ne?: Maybe<OrderTypes>;
};

export type ModelProductTypeInput = {
  eq?: Maybe<ProductType>;
  ne?: Maybe<ProductType>;
};

export type ModelSalesInput = {
  eq?: Maybe<Sales>;
  ne?: Maybe<Sales>;
};

export type ModelSalesTypeInput = {
  eq?: Maybe<SalesType>;
  ne?: Maybe<SalesType>;
};

export type ModelSizeInput = {
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  eq?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
};

export type ModelSortDirection =
  | 'ASC'
  | 'DESC';

export type ModelSpecialItemInput = {
  eq?: Maybe<SpecialItem>;
  ne?: Maybe<SpecialItem>;
};

export type ModelStringInput = {
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  beginsWith?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  size?: Maybe<ModelSizeInput>;
};

export type ModelStringKeyConditionInput = {
  beginsWith?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  eq?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
};

export type ModelTaxEnumInput = {
  eq?: Maybe<TaxEnum>;
  ne?: Maybe<TaxEnum>;
};

export type ModelTemporaryGoodsOrderConditionInput = {
  LockMode?: Maybe<ModelIntInput>;
  address?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTemporaryGoodsOrderConditionInput>>>;
  cardBrand?: Maybe<ModelCardBrandInput>;
  cardId?: Maybe<ModelStringInput>;
  cardName?: Maybe<ModelStringInput>;
  cardNo?: Maybe<ModelStringInput>;
  cartId?: Maybe<ModelStringInput>;
  city?: Maybe<ModelStringInput>;
  deliveryId?: Maybe<ModelStringInput>;
  deliveryPrice?: Maybe<ModelIntInput>;
  discount?: Maybe<ModelIntInput>;
  expriesAt?: Maybe<ModelIntInput>;
  familyName?: Maybe<ModelStringInput>;
  givenName?: Maybe<ModelStringInput>;
  items?: Maybe<ModelStringInput>;
  not?: Maybe<ModelTemporaryGoodsOrderConditionInput>;
  or?: Maybe<Array<Maybe<ModelTemporaryGoodsOrderConditionInput>>>;
  orderType?: Maybe<ModelOrderTypesInput>;
  phoneNumber?: Maybe<ModelStringInput>;
  postCode?: Maybe<ModelStringInput>;
  provinceCode?: Maybe<ModelStringInput>;
  specialItem?: Maybe<ModelSpecialItemInput>;
  subAddress?: Maybe<ModelStringInput>;
  totalPrice?: Maybe<ModelIntInput>;
  userId?: Maybe<ModelStringInput>;
};

export type ModelTemporaryGoodsOrderConnection = {
  __typename?: 'ModelTemporaryGoodsOrderConnection';
  items?: Maybe<Array<Maybe<TemporaryGoodsOrder>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTemporaryGoodsOrderFilterInput = {
  LockMode?: Maybe<ModelIntInput>;
  address?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelTemporaryGoodsOrderFilterInput>>>;
  cardBrand?: Maybe<ModelCardBrandInput>;
  cardId?: Maybe<ModelStringInput>;
  cardName?: Maybe<ModelStringInput>;
  cardNo?: Maybe<ModelStringInput>;
  cartId?: Maybe<ModelStringInput>;
  city?: Maybe<ModelStringInput>;
  deliveryId?: Maybe<ModelStringInput>;
  deliveryPrice?: Maybe<ModelIntInput>;
  discount?: Maybe<ModelIntInput>;
  expriesAt?: Maybe<ModelIntInput>;
  familyName?: Maybe<ModelStringInput>;
  givenName?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  items?: Maybe<ModelStringInput>;
  not?: Maybe<ModelTemporaryGoodsOrderFilterInput>;
  or?: Maybe<Array<Maybe<ModelTemporaryGoodsOrderFilterInput>>>;
  orderType?: Maybe<ModelOrderTypesInput>;
  phoneNumber?: Maybe<ModelStringInput>;
  postCode?: Maybe<ModelStringInput>;
  provinceCode?: Maybe<ModelStringInput>;
  specialItem?: Maybe<ModelSpecialItemInput>;
  subAddress?: Maybe<ModelStringInput>;
  totalPrice?: Maybe<ModelIntInput>;
  userId?: Maybe<ModelStringInput>;
};

export type ModelUserConditionInput = {
  and?: Maybe<Array<Maybe<ModelUserConditionInput>>>;
  familyName?: Maybe<ModelStringInput>;
  givenName?: Maybe<ModelStringInput>;
  mail?: Maybe<ModelStringInput>;
  not?: Maybe<ModelUserConditionInput>;
  or?: Maybe<Array<Maybe<ModelUserConditionInput>>>;
  stripeId?: Maybe<ModelStringInput>;
};

export type ModelUserConnection = {
  __typename?: 'ModelUserConnection';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserFilterInput = {
  and?: Maybe<Array<Maybe<ModelUserFilterInput>>>;
  familyName?: Maybe<ModelStringInput>;
  givenName?: Maybe<ModelStringInput>;
  id?: Maybe<ModelIdInput>;
  mail?: Maybe<ModelStringInput>;
  not?: Maybe<ModelUserFilterInput>;
  or?: Maybe<Array<Maybe<ModelUserFilterInput>>>;
  stripeId?: Maybe<ModelStringInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCard?: Maybe<Card>;
  createDelivery?: Maybe<Delivery>;
  createGoodsCart?: Maybe<GoodsCart>;
  createGoodsOrder?: Maybe<GoodsOrder>;
  createKintoneCollection?: Maybe<KintoneCollection>;
  createKintoneProducts?: Maybe<KintoneProducts>;
  createKintoneProductsStock?: Maybe<KintoneProductsStock>;
  createOrder?: Maybe<Order>;
  createTemporaryGoodsOrder?: Maybe<TemporaryGoodsOrder>;
  createUser?: Maybe<User>;
  deleteCard?: Maybe<Card>;
  deleteDelivery?: Maybe<Delivery>;
  deleteGoodsCart?: Maybe<GoodsCart>;
  deleteGoodsOrder?: Maybe<GoodsOrder>;
  deleteKintoneCollection?: Maybe<KintoneCollection>;
  deleteKintoneProducts?: Maybe<KintoneProducts>;
  deleteKintoneProductsStock?: Maybe<KintoneProductsStock>;
  deleteOrder?: Maybe<Order>;
  deleteTemporaryGoodsOrder?: Maybe<TemporaryGoodsOrder>;
  deleteUser?: Maybe<User>;
  updateCard?: Maybe<Card>;
  updateDelivery?: Maybe<Delivery>;
  updateGoodsCart?: Maybe<GoodsCart>;
  updateGoodsOrder?: Maybe<GoodsOrder>;
  updateKintoneCollection?: Maybe<KintoneCollection>;
  updateKintoneProducts?: Maybe<KintoneProducts>;
  updateKintoneProductsStock?: Maybe<KintoneProductsStock>;
  updateOrder?: Maybe<Order>;
  updateTemporaryGoodsOrder?: Maybe<TemporaryGoodsOrder>;
  updateUser?: Maybe<User>;
};


export type MutationCreateCardArgs = {
  condition?: Maybe<ModelCardConditionInput>;
  input: CreateCardInput;
};


export type MutationCreateDeliveryArgs = {
  condition?: Maybe<ModelDeliveryConditionInput>;
  input: CreateDeliveryInput;
};


export type MutationCreateGoodsCartArgs = {
  condition?: Maybe<ModelGoodsCartConditionInput>;
  input: CreateGoodsCartInput;
};


export type MutationCreateGoodsOrderArgs = {
  condition?: Maybe<ModelGoodsOrderConditionInput>;
  input: CreateGoodsOrderInput;
};


export type MutationCreateKintoneCollectionArgs = {
  condition?: Maybe<ModelKintoneCollectionConditionInput>;
  input: CreateKintoneCollectionInput;
};


export type MutationCreateKintoneProductsArgs = {
  condition?: Maybe<ModelKintoneProductsConditionInput>;
  input: CreateKintoneProductsInput;
};


export type MutationCreateKintoneProductsStockArgs = {
  condition?: Maybe<ModelKintoneProductsStockConditionInput>;
  input: CreateKintoneProductsStockInput;
};


export type MutationCreateOrderArgs = {
  condition?: Maybe<ModelOrderConditionInput>;
  input: CreateOrderInput;
};


export type MutationCreateTemporaryGoodsOrderArgs = {
  condition?: Maybe<ModelTemporaryGoodsOrderConditionInput>;
  input: CreateTemporaryGoodsOrderInput;
};


export type MutationCreateUserArgs = {
  condition?: Maybe<ModelUserConditionInput>;
  input: CreateUserInput;
};


export type MutationDeleteCardArgs = {
  condition?: Maybe<ModelCardConditionInput>;
  input: DeleteCardInput;
};


export type MutationDeleteDeliveryArgs = {
  condition?: Maybe<ModelDeliveryConditionInput>;
  input: DeleteDeliveryInput;
};


export type MutationDeleteGoodsCartArgs = {
  condition?: Maybe<ModelGoodsCartConditionInput>;
  input: DeleteGoodsCartInput;
};


export type MutationDeleteGoodsOrderArgs = {
  condition?: Maybe<ModelGoodsOrderConditionInput>;
  input: DeleteGoodsOrderInput;
};


export type MutationDeleteKintoneCollectionArgs = {
  condition?: Maybe<ModelKintoneCollectionConditionInput>;
  input: DeleteKintoneCollectionInput;
};


export type MutationDeleteKintoneProductsArgs = {
  condition?: Maybe<ModelKintoneProductsConditionInput>;
  input: DeleteKintoneProductsInput;
};


export type MutationDeleteKintoneProductsStockArgs = {
  condition?: Maybe<ModelKintoneProductsStockConditionInput>;
  input: DeleteKintoneProductsStockInput;
};


export type MutationDeleteOrderArgs = {
  condition?: Maybe<ModelOrderConditionInput>;
  input: DeleteOrderInput;
};


export type MutationDeleteTemporaryGoodsOrderArgs = {
  condition?: Maybe<ModelTemporaryGoodsOrderConditionInput>;
  input: DeleteTemporaryGoodsOrderInput;
};


export type MutationDeleteUserArgs = {
  condition?: Maybe<ModelUserConditionInput>;
  input: DeleteUserInput;
};


export type MutationUpdateCardArgs = {
  condition?: Maybe<ModelCardConditionInput>;
  input: UpdateCardInput;
};


export type MutationUpdateDeliveryArgs = {
  condition?: Maybe<ModelDeliveryConditionInput>;
  input: UpdateDeliveryInput;
};


export type MutationUpdateGoodsCartArgs = {
  condition?: Maybe<ModelGoodsCartConditionInput>;
  input: UpdateGoodsCartInput;
};


export type MutationUpdateGoodsOrderArgs = {
  condition?: Maybe<ModelGoodsOrderConditionInput>;
  input: UpdateGoodsOrderInput;
};


export type MutationUpdateKintoneCollectionArgs = {
  condition?: Maybe<ModelKintoneCollectionConditionInput>;
  input: UpdateKintoneCollectionInput;
};


export type MutationUpdateKintoneProductsArgs = {
  condition?: Maybe<ModelKintoneProductsConditionInput>;
  input: UpdateKintoneProductsInput;
};


export type MutationUpdateKintoneProductsStockArgs = {
  condition?: Maybe<ModelKintoneProductsStockConditionInput>;
  input: UpdateKintoneProductsStockInput;
};


export type MutationUpdateOrderArgs = {
  condition?: Maybe<ModelOrderConditionInput>;
  input: UpdateOrderInput;
};


export type MutationUpdateTemporaryGoodsOrderArgs = {
  condition?: Maybe<ModelTemporaryGoodsOrderConditionInput>;
  input: UpdateTemporaryGoodsOrderInput;
};


export type MutationUpdateUserArgs = {
  condition?: Maybe<ModelUserConditionInput>;
  input: UpdateUserInput;
};

export type Order = {
  __typename?: 'Order';
  article?: Maybe<Scalars['String']>;
  buyState: BuyStatus;
  cost?: Maybe<Scalars['Int']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  itemId: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['AWSURL']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['String'];
};

export type OrderStatus =
  | 'Complete'
  | 'Pending';

export type OrderTypes =
  | 'Konbini'
  | 'Kureka';

export type ProductType =
  | 'normal'
  | 'raffle'
  | 'reservation';

export type Query = {
  __typename?: 'Query';
  getCard?: Maybe<Card>;
  getDelivery?: Maybe<Delivery>;
  getGoodsCart?: Maybe<GoodsCart>;
  getGoodsOrder?: Maybe<GoodsOrder>;
  getKintoneCollection?: Maybe<KintoneCollection>;
  getKintoneProducts?: Maybe<KintoneProducts>;
  getKintoneProductsStock?: Maybe<KintoneProductsStock>;
  getOrder?: Maybe<Order>;
  getTemporaryGoodsOrder?: Maybe<TemporaryGoodsOrder>;
  getUser?: Maybe<User>;
  listCards?: Maybe<ModelCardConnection>;
  listDeliverys?: Maybe<ModelDeliveryConnection>;
  listGoodsCarts?: Maybe<ModelGoodsCartConnection>;
  listGoodsOrders?: Maybe<ModelGoodsOrderConnection>;
  listKintoneCollections?: Maybe<ModelKintoneCollectionConnection>;
  listKintoneProductsStocks?: Maybe<ModelKintoneProductsStockConnection>;
  listKintoneProductss?: Maybe<ModelKintoneProductsConnection>;
  listOrders?: Maybe<ModelOrderConnection>;
  listSortCards?: Maybe<ModelCardConnection>;
  listSortDeliverys?: Maybe<ModelDeliveryConnection>;
  listSortGoodsOrders?: Maybe<ModelGoodsOrderConnection>;
  listSortKintoneCollections?: Maybe<ModelKintoneCollectionConnection>;
  listSortKintoneProductss?: Maybe<ModelKintoneProductsConnection>;
  listSortOrders?: Maybe<ModelOrderConnection>;
  listStateByDates?: Maybe<ModelGoodsOrderConnection>;
  listTemporaryGoodsOrders?: Maybe<ModelTemporaryGoodsOrderConnection>;
  listUsers?: Maybe<ModelUserConnection>;
};


export type QueryGetCardArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeliveryArgs = {
  id: Scalars['ID'];
};


export type QueryGetGoodsCartArgs = {
  id: Scalars['ID'];
};


export type QueryGetGoodsOrderArgs = {
  id: Scalars['ID'];
};


export type QueryGetKintoneCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryGetKintoneProductsArgs = {
  id: Scalars['ID'];
};


export type QueryGetKintoneProductsStockArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrderArgs = {
  id: Scalars['ID'];
};


export type QueryGetTemporaryGoodsOrderArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryListCardsArgs = {
  filter?: Maybe<ModelCardFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListDeliverysArgs = {
  filter?: Maybe<ModelDeliveryFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListGoodsCartsArgs = {
  filter?: Maybe<ModelGoodsCartFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListGoodsOrdersArgs = {
  filter?: Maybe<ModelGoodsOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListKintoneCollectionsArgs = {
  filter?: Maybe<ModelKintoneCollectionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListKintoneProductsStocksArgs = {
  filter?: Maybe<ModelKintoneProductsStockFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListKintoneProductssArgs = {
  filter?: Maybe<ModelKintoneProductsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListOrdersArgs = {
  filter?: Maybe<ModelOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListSortCardsArgs = {
  createdAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelCardFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  userId?: Maybe<Scalars['ID']>;
};


export type QueryListSortDeliverysArgs = {
  createdAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelDeliveryFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryListSortGoodsOrdersArgs = {
  createdAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelGoodsOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryListSortKintoneCollectionsArgs = {
  filter?: Maybe<ModelKintoneCollectionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  precedence?: Maybe<ModelIntKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  wpId?: Maybe<Scalars['String']>;
};


export type QueryListSortKintoneProductssArgs = {
  filter?: Maybe<ModelKintoneProductsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  sortNumber?: Maybe<ModelIntKeyConditionInput>;
};


export type QueryListSortOrdersArgs = {
  filter?: Maybe<ModelOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryListStateByDatesArgs = {
  filter?: Maybe<ModelGoodsOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  stateByDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
};


export type QueryListTemporaryGoodsOrdersArgs = {
  filter?: Maybe<ModelTemporaryGoodsOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListUsersArgs = {
  filter?: Maybe<ModelUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Sales =
  | 'onSale'
  | 'onSaleChoice'
  | 'onSaleStop'
  | 'ready';

export type SalesType =
  | 'order'
  | 'preOrder'
  | 'priorityOrder'
  | 'raffleOrder';

export type SpecialItem =
  | 'not'
  | 'reservation';

export type Subscription = {
  __typename?: 'Subscription';
  onCreateCard?: Maybe<Card>;
  onCreateDelivery?: Maybe<Delivery>;
  onCreateGoodsCart?: Maybe<GoodsCart>;
  onCreateGoodsOrder?: Maybe<GoodsOrder>;
  onCreateKintoneCollection?: Maybe<KintoneCollection>;
  onCreateKintoneProducts?: Maybe<KintoneProducts>;
  onCreateKintoneProductsStock?: Maybe<KintoneProductsStock>;
  onCreateOrder?: Maybe<Order>;
  onCreateTemporaryGoodsOrder?: Maybe<TemporaryGoodsOrder>;
  onCreateUser?: Maybe<User>;
  onDeleteCard?: Maybe<Card>;
  onDeleteDelivery?: Maybe<Delivery>;
  onDeleteGoodsCart?: Maybe<GoodsCart>;
  onDeleteGoodsOrder?: Maybe<GoodsOrder>;
  onDeleteKintoneCollection?: Maybe<KintoneCollection>;
  onDeleteKintoneProducts?: Maybe<KintoneProducts>;
  onDeleteKintoneProductsStock?: Maybe<KintoneProductsStock>;
  onDeleteOrder?: Maybe<Order>;
  onDeleteTemporaryGoodsOrder?: Maybe<TemporaryGoodsOrder>;
  onDeleteUser?: Maybe<User>;
  onUpdateCard?: Maybe<Card>;
  onUpdateDelivery?: Maybe<Delivery>;
  onUpdateGoodsCart?: Maybe<GoodsCart>;
  onUpdateGoodsOrder?: Maybe<GoodsOrder>;
  onUpdateKintoneCollection?: Maybe<KintoneCollection>;
  onUpdateKintoneProducts?: Maybe<KintoneProducts>;
  onUpdateKintoneProductsStock?: Maybe<KintoneProductsStock>;
  onUpdateOrder?: Maybe<Order>;
  onUpdateTemporaryGoodsOrder?: Maybe<TemporaryGoodsOrder>;
  onUpdateUser?: Maybe<User>;
};


export type SubscriptionOnCreateCardArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnCreateDeliveryArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnCreateGoodsCartArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnCreateGoodsOrderArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnCreateOrderArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnCreateUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionOnDeleteCardArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnDeleteDeliveryArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnDeleteGoodsCartArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnDeleteGoodsOrderArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnDeleteOrderArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnDeleteUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateCardArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateDeliveryArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateGoodsCartArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateGoodsOrderArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateOrderArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateUserArgs = {
  id?: Maybe<Scalars['String']>;
};

export type TaxEnum =
  | 'R'
  | 'S';

export type TemporaryGoodsOrder = {
  __typename?: 'TemporaryGoodsOrder';
  LockMode: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<CardBrand>;
  cardId?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNo?: Maybe<Scalars['String']>;
  cartId: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  deliveryId?: Maybe<Scalars['String']>;
  deliveryPrice: Scalars['Int'];
  discount: Scalars['Int'];
  expriesAt?: Maybe<Scalars['AWSTimestamp']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items: Scalars['AWSJSON'];
  orderType: OrderTypes;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  postCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  specialItem?: Maybe<SpecialItem>;
  subAddress?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Int'];
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['String'];
};

export type UpdateCardInput = {
  brand?: Maybe<CardBrand>;
  cardNo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  deadline?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stripeCardId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['ID']>;
};

export type UpdateDeliveryInput = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  postCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  subAddress?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateGoodsCartInput = {
  cardId?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  items?: Maybe<Scalars['AWSJSON']>;
  orderState?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderTypes>;
  paymentId?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateGoodsOrderInput = {
  address?: Maybe<Scalars['String']>;
  buyState?: Maybe<BuyStatus>;
  cardBrand?: Maybe<CardBrand>;
  cardName?: Maybe<Scalars['String']>;
  cardNo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  deliveryPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<Scalars['AWSJSON']>;
  orderType?: Maybe<OrderTypes>;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  postCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  specialItem?: Maybe<SpecialItem>;
  stateByDate?: Maybe<Scalars['String']>;
  subAddress?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateKintoneCollectionInput = {
  audience?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDisplayFlg?: Maybe<Scalars['Boolean']>;
  precedence?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['AWSJSON']>;
  salesState?: Maybe<Sales>;
  salesType?: Maybe<SalesType>;
  thumbnail?: Maybe<Scalars['AWSURL']>;
  title?: Maybe<Scalars['String']>;
  wpId?: Maybe<Scalars['String']>;
};

export type UpdateKintoneProductsInput = {
  audience?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Scalars['AWSJSON']>;
  inventoryState?: Maybe<Inventory>;
  isDisplayFlg?: Maybe<Scalars['Boolean']>;
  jancode?: Maybe<Scalars['String']>;
  limitOrderBuy?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
  productType?: Maybe<ProductType>;
  releaseDate?: Maybe<Scalars['AWSDate']>;
  salesState?: Maybe<Sales>;
  sku?: Maybe<Scalars['String']>;
  sortNumber?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  taxExcludePrice?: Maybe<Scalars['Int']>;
  taxInRegularPrice?: Maybe<Scalars['Int']>;
  taxIncludePrice?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<TaxEnum>;
  thumbnail?: Maybe<Scalars['AWSURL']>;
  transferPrice?: Maybe<Scalars['Int']>;
  variation?: Maybe<Scalars['String']>;
  variationTitle?: Maybe<Scalars['String']>;
};

export type UpdateKintoneProductsStockInput = {
  audience?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inventory?: Maybe<Scalars['Int']>;
};

export type UpdateOrderInput = {
  article?: Maybe<Scalars['String']>;
  buyState?: Maybe<BuyStatus>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  itemId?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['AWSURL']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateTemporaryGoodsOrderInput = {
  LockMode?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<CardBrand>;
  cardId?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNo?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  expriesAt?: Maybe<Scalars['AWSTimestamp']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<Scalars['AWSJSON']>;
  orderType?: Maybe<OrderTypes>;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  postCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  specialItem?: Maybe<SpecialItem>;
  subAddress?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mail?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['AWSDateTime'];
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mail: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type GetCardQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCardQuery = (
  { __typename?: 'Query' }
  & { getCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
  )> }
);

export type GetDeliveryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDeliveryQuery = (
  { __typename?: 'Query' }
  & { getDelivery?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
  )> }
);

export type GetGoodsCartQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetGoodsCartQuery = (
  { __typename?: 'Query' }
  & { getGoodsCart?: Maybe<(
    { __typename?: 'GoodsCart' }
    & Pick<GoodsCart, 'cardId' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'id' | 'items' | 'orderState' | 'orderType' | 'paymentId' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type GetGoodsOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetGoodsOrderQuery = (
  { __typename?: 'Query' }
  & { getGoodsOrder?: Maybe<(
    { __typename?: 'GoodsOrder' }
    & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
  )> }
);

export type GetKintoneCollectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetKintoneCollectionQuery = (
  { __typename?: 'Query' }
  & { getKintoneCollection?: Maybe<(
    { __typename?: 'KintoneCollection' }
    & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
  )> }
);

export type GetKintoneProductsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetKintoneProductsQuery = (
  { __typename?: 'Query' }
  & { getKintoneProducts?: Maybe<(
    { __typename?: 'KintoneProducts' }
    & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
  )> }
);

export type GetKintoneProductsStockQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetKintoneProductsStockQuery = (
  { __typename?: 'Query' }
  & { getKintoneProductsStock?: Maybe<(
    { __typename?: 'KintoneProductsStock' }
    & Pick<KintoneProductsStock, 'audience' | 'createdAt' | 'id' | 'inventory' | 'updatedAt'>
  )> }
);

export type GetOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
  )> }
);

export type GetTemporaryGoodsOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTemporaryGoodsOrderQuery = (
  { __typename?: 'Query' }
  & { getTemporaryGoodsOrder?: Maybe<(
    { __typename?: 'TemporaryGoodsOrder' }
    & Pick<TemporaryGoodsOrder, 'LockMode' | 'address' | 'cardBrand' | 'cardId' | 'cardName' | 'cardNo' | 'cartId' | 'city' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'expriesAt' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'subAddress' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'createdAt' | 'familyName' | 'givenName' | 'id' | 'mail' | 'stripeId' | 'updatedAt'>
  )> }
);

export type ListCardsQueryVariables = Exact<{
  filter?: Maybe<ModelCardFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListCardsQuery = (
  { __typename?: 'Query' }
  & { listCards?: Maybe<(
    { __typename?: 'ModelCardConnection' }
    & Pick<ModelCardConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListDeliverysQueryVariables = Exact<{
  filter?: Maybe<ModelDeliveryFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListDeliverysQuery = (
  { __typename?: 'Query' }
  & { listDeliverys?: Maybe<(
    { __typename?: 'ModelDeliveryConnection' }
    & Pick<ModelDeliveryConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListGoodsCartsQueryVariables = Exact<{
  filter?: Maybe<ModelGoodsCartFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListGoodsCartsQuery = (
  { __typename?: 'Query' }
  & { listGoodsCarts?: Maybe<(
    { __typename?: 'ModelGoodsCartConnection' }
    & Pick<ModelGoodsCartConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsCart' }
      & Pick<GoodsCart, 'cardId' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'id' | 'items' | 'orderState' | 'orderType' | 'paymentId' | 'totalPrice' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListGoodsOrdersQueryVariables = Exact<{
  filter?: Maybe<ModelGoodsOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListGoodsOrdersQuery = (
  { __typename?: 'Query' }
  & { listGoodsOrders?: Maybe<(
    { __typename?: 'ModelGoodsOrderConnection' }
    & Pick<ModelGoodsOrderConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsOrder' }
      & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListKintoneCollectionsQueryVariables = Exact<{
  filter?: Maybe<ModelKintoneCollectionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListKintoneCollectionsQuery = (
  { __typename?: 'Query' }
  & { listKintoneCollections?: Maybe<(
    { __typename?: 'ModelKintoneCollectionConnection' }
    & Pick<ModelKintoneCollectionConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'KintoneCollection' }
      & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
    )>>> }
  )> }
);

export type ListKintoneProductsStocksQueryVariables = Exact<{
  filter?: Maybe<ModelKintoneProductsStockFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListKintoneProductsStocksQuery = (
  { __typename?: 'Query' }
  & { listKintoneProductsStocks?: Maybe<(
    { __typename?: 'ModelKintoneProductsStockConnection' }
    & Pick<ModelKintoneProductsStockConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'KintoneProductsStock' }
      & Pick<KintoneProductsStock, 'audience' | 'createdAt' | 'id' | 'inventory' | 'updatedAt'>
    )>>> }
  )> }
);

export type ListKintoneProductssQueryVariables = Exact<{
  filter?: Maybe<ModelKintoneProductsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListKintoneProductssQuery = (
  { __typename?: 'Query' }
  & { listKintoneProductss?: Maybe<(
    { __typename?: 'ModelKintoneProductsConnection' }
    & Pick<ModelKintoneProductsConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'KintoneProducts' }
      & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
    )>>> }
  )> }
);

export type ListOrdersQueryVariables = Exact<{
  filter?: Maybe<ModelOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListOrdersQuery = (
  { __typename?: 'Query' }
  & { listOrders?: Maybe<(
    { __typename?: 'ModelOrderConnection' }
    & Pick<ModelOrderConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListSortCardsQueryVariables = Exact<{
  createdAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelCardFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  userId?: Maybe<Scalars['ID']>;
}>;


export type ListSortCardsQuery = (
  { __typename?: 'Query' }
  & { listSortCards?: Maybe<(
    { __typename?: 'ModelCardConnection' }
    & Pick<ModelCardConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListSortDeliverysQueryVariables = Exact<{
  createdAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelDeliveryFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  userId?: Maybe<Scalars['String']>;
}>;


export type ListSortDeliverysQuery = (
  { __typename?: 'Query' }
  & { listSortDeliverys?: Maybe<(
    { __typename?: 'ModelDeliveryConnection' }
    & Pick<ModelDeliveryConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListSortGoodsOrdersQueryVariables = Exact<{
  createdAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelGoodsOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  userId?: Maybe<Scalars['String']>;
}>;


export type ListSortGoodsOrdersQuery = (
  { __typename?: 'Query' }
  & { listSortGoodsOrders?: Maybe<(
    { __typename?: 'ModelGoodsOrderConnection' }
    & Pick<ModelGoodsOrderConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsOrder' }
      & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListSortKintoneCollectionsQueryVariables = Exact<{
  filter?: Maybe<ModelKintoneCollectionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  precedence?: Maybe<ModelIntKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  wpId?: Maybe<Scalars['String']>;
}>;


export type ListSortKintoneCollectionsQuery = (
  { __typename?: 'Query' }
  & { listSortKintoneCollections?: Maybe<(
    { __typename?: 'ModelKintoneCollectionConnection' }
    & Pick<ModelKintoneCollectionConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'KintoneCollection' }
      & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
    )>>> }
  )> }
);

export type ListSortKintoneProductssQueryVariables = Exact<{
  filter?: Maybe<ModelKintoneProductsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  sortNumber?: Maybe<ModelIntKeyConditionInput>;
}>;


export type ListSortKintoneProductssQuery = (
  { __typename?: 'Query' }
  & { listSortKintoneProductss?: Maybe<(
    { __typename?: 'ModelKintoneProductsConnection' }
    & Pick<ModelKintoneProductsConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'KintoneProducts' }
      & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
    )>>> }
  )> }
);

export type ListSortOrdersQueryVariables = Exact<{
  filter?: Maybe<ModelOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  userId?: Maybe<Scalars['String']>;
}>;


export type ListSortOrdersQuery = (
  { __typename?: 'Query' }
  & { listSortOrders?: Maybe<(
    { __typename?: 'ModelOrderConnection' }
    & Pick<ModelOrderConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListStateByDatesQueryVariables = Exact<{
  filter?: Maybe<ModelGoodsOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<ModelSortDirection>;
  stateByDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
}>;


export type ListStateByDatesQuery = (
  { __typename?: 'Query' }
  & { listStateByDates?: Maybe<(
    { __typename?: 'ModelGoodsOrderConnection' }
    & Pick<ModelGoodsOrderConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsOrder' }
      & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListTemporaryGoodsOrdersQueryVariables = Exact<{
  filter?: Maybe<ModelTemporaryGoodsOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListTemporaryGoodsOrdersQuery = (
  { __typename?: 'Query' }
  & { listTemporaryGoodsOrders?: Maybe<(
    { __typename?: 'ModelTemporaryGoodsOrderConnection' }
    & Pick<ModelTemporaryGoodsOrderConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'TemporaryGoodsOrder' }
      & Pick<TemporaryGoodsOrder, 'LockMode' | 'address' | 'cardBrand' | 'cardId' | 'cardName' | 'cardNo' | 'cartId' | 'city' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'expriesAt' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'subAddress' | 'totalPrice' | 'updatedAt' | 'userId'>
    )>>> }
  )> }
);

export type ListUsersQueryVariables = Exact<{
  filter?: Maybe<ModelUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { listUsers?: Maybe<(
    { __typename?: 'ModelUserConnection' }
    & Pick<ModelUserConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'createdAt' | 'familyName' | 'givenName' | 'id' | 'mail' | 'stripeId' | 'updatedAt'>
    )>>> }
  )> }
);

export type CreateCardMutationVariables = Exact<{
  condition?: Maybe<ModelCardConditionInput>;
  input: CreateCardInput;
}>;


export type CreateCardMutation = (
  { __typename?: 'Mutation' }
  & { createCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
  )> }
);

export type CreateDeliveryMutationVariables = Exact<{
  condition?: Maybe<ModelDeliveryConditionInput>;
  input: CreateDeliveryInput;
}>;


export type CreateDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { createDelivery?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
  )> }
);

export type CreateGoodsCartMutationVariables = Exact<{
  condition?: Maybe<ModelGoodsCartConditionInput>;
  input: CreateGoodsCartInput;
}>;


export type CreateGoodsCartMutation = (
  { __typename?: 'Mutation' }
  & { createGoodsCart?: Maybe<(
    { __typename?: 'GoodsCart' }
    & Pick<GoodsCart, 'cardId' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'id' | 'items' | 'orderState' | 'orderType' | 'paymentId' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type CreateGoodsOrderMutationVariables = Exact<{
  condition?: Maybe<ModelGoodsOrderConditionInput>;
  input: CreateGoodsOrderInput;
}>;


export type CreateGoodsOrderMutation = (
  { __typename?: 'Mutation' }
  & { createGoodsOrder?: Maybe<(
    { __typename?: 'GoodsOrder' }
    & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
  )> }
);

export type CreateKintoneCollectionMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneCollectionConditionInput>;
  input: CreateKintoneCollectionInput;
}>;


export type CreateKintoneCollectionMutation = (
  { __typename?: 'Mutation' }
  & { createKintoneCollection?: Maybe<(
    { __typename?: 'KintoneCollection' }
    & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
  )> }
);

export type CreateKintoneProductsMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneProductsConditionInput>;
  input: CreateKintoneProductsInput;
}>;


export type CreateKintoneProductsMutation = (
  { __typename?: 'Mutation' }
  & { createKintoneProducts?: Maybe<(
    { __typename?: 'KintoneProducts' }
    & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
  )> }
);

export type CreateKintoneProductsStockMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneProductsStockConditionInput>;
  input: CreateKintoneProductsStockInput;
}>;


export type CreateKintoneProductsStockMutation = (
  { __typename?: 'Mutation' }
  & { createKintoneProductsStock?: Maybe<(
    { __typename?: 'KintoneProductsStock' }
    & Pick<KintoneProductsStock, 'audience' | 'createdAt' | 'id' | 'inventory' | 'updatedAt'>
  )> }
);

export type CreateOrderMutationVariables = Exact<{
  condition?: Maybe<ModelOrderConditionInput>;
  input: CreateOrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
  )> }
);

export type CreateTemporaryGoodsOrderMutationVariables = Exact<{
  condition?: Maybe<ModelTemporaryGoodsOrderConditionInput>;
  input: CreateTemporaryGoodsOrderInput;
}>;


export type CreateTemporaryGoodsOrderMutation = (
  { __typename?: 'Mutation' }
  & { createTemporaryGoodsOrder?: Maybe<(
    { __typename?: 'TemporaryGoodsOrder' }
    & Pick<TemporaryGoodsOrder, 'LockMode' | 'address' | 'cardBrand' | 'cardId' | 'cardName' | 'cardNo' | 'cartId' | 'city' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'expriesAt' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'subAddress' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  condition?: Maybe<ModelUserConditionInput>;
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'createdAt' | 'familyName' | 'givenName' | 'id' | 'mail' | 'stripeId' | 'updatedAt'>
  )> }
);

export type DeleteCardMutationVariables = Exact<{
  condition?: Maybe<ModelCardConditionInput>;
  input: DeleteCardInput;
}>;


export type DeleteCardMutation = (
  { __typename?: 'Mutation' }
  & { deleteCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
  )> }
);

export type DeleteDeliveryMutationVariables = Exact<{
  condition?: Maybe<ModelDeliveryConditionInput>;
  input: DeleteDeliveryInput;
}>;


export type DeleteDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { deleteDelivery?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
  )> }
);

export type DeleteGoodsCartMutationVariables = Exact<{
  condition?: Maybe<ModelGoodsCartConditionInput>;
  input: DeleteGoodsCartInput;
}>;


export type DeleteGoodsCartMutation = (
  { __typename?: 'Mutation' }
  & { deleteGoodsCart?: Maybe<(
    { __typename?: 'GoodsCart' }
    & Pick<GoodsCart, 'cardId' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'id' | 'items' | 'orderState' | 'orderType' | 'paymentId' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type DeleteGoodsOrderMutationVariables = Exact<{
  condition?: Maybe<ModelGoodsOrderConditionInput>;
  input: DeleteGoodsOrderInput;
}>;


export type DeleteGoodsOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteGoodsOrder?: Maybe<(
    { __typename?: 'GoodsOrder' }
    & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
  )> }
);

export type DeleteKintoneCollectionMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneCollectionConditionInput>;
  input: DeleteKintoneCollectionInput;
}>;


export type DeleteKintoneCollectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteKintoneCollection?: Maybe<(
    { __typename?: 'KintoneCollection' }
    & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
  )> }
);

export type DeleteKintoneProductsMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneProductsConditionInput>;
  input: DeleteKintoneProductsInput;
}>;


export type DeleteKintoneProductsMutation = (
  { __typename?: 'Mutation' }
  & { deleteKintoneProducts?: Maybe<(
    { __typename?: 'KintoneProducts' }
    & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
  )> }
);

export type DeleteKintoneProductsStockMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneProductsStockConditionInput>;
  input: DeleteKintoneProductsStockInput;
}>;


export type DeleteKintoneProductsStockMutation = (
  { __typename?: 'Mutation' }
  & { deleteKintoneProductsStock?: Maybe<(
    { __typename?: 'KintoneProductsStock' }
    & Pick<KintoneProductsStock, 'audience' | 'createdAt' | 'id' | 'inventory' | 'updatedAt'>
  )> }
);

export type DeleteOrderMutationVariables = Exact<{
  condition?: Maybe<ModelOrderConditionInput>;
  input: DeleteOrderInput;
}>;


export type DeleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
  )> }
);

export type DeleteTemporaryGoodsOrderMutationVariables = Exact<{
  condition?: Maybe<ModelTemporaryGoodsOrderConditionInput>;
  input: DeleteTemporaryGoodsOrderInput;
}>;


export type DeleteTemporaryGoodsOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteTemporaryGoodsOrder?: Maybe<(
    { __typename?: 'TemporaryGoodsOrder' }
    & Pick<TemporaryGoodsOrder, 'LockMode' | 'address' | 'cardBrand' | 'cardId' | 'cardName' | 'cardNo' | 'cartId' | 'city' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'expriesAt' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'subAddress' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  condition?: Maybe<ModelUserConditionInput>;
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'createdAt' | 'familyName' | 'givenName' | 'id' | 'mail' | 'stripeId' | 'updatedAt'>
  )> }
);

export type UpdateCardMutationVariables = Exact<{
  condition?: Maybe<ModelCardConditionInput>;
  input: UpdateCardInput;
}>;


export type UpdateCardMutation = (
  { __typename?: 'Mutation' }
  & { updateCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
  )> }
);

export type UpdateDeliveryMutationVariables = Exact<{
  condition?: Maybe<ModelDeliveryConditionInput>;
  input: UpdateDeliveryInput;
}>;


export type UpdateDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { updateDelivery?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
  )> }
);

export type UpdateGoodsCartMutationVariables = Exact<{
  condition?: Maybe<ModelGoodsCartConditionInput>;
  input: UpdateGoodsCartInput;
}>;


export type UpdateGoodsCartMutation = (
  { __typename?: 'Mutation' }
  & { updateGoodsCart?: Maybe<(
    { __typename?: 'GoodsCart' }
    & Pick<GoodsCart, 'cardId' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'id' | 'items' | 'orderState' | 'orderType' | 'paymentId' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type UpdateGoodsOrderMutationVariables = Exact<{
  condition?: Maybe<ModelGoodsOrderConditionInput>;
  input: UpdateGoodsOrderInput;
}>;


export type UpdateGoodsOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateGoodsOrder?: Maybe<(
    { __typename?: 'GoodsOrder' }
    & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
  )> }
);

export type UpdateKintoneCollectionMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneCollectionConditionInput>;
  input: UpdateKintoneCollectionInput;
}>;


export type UpdateKintoneCollectionMutation = (
  { __typename?: 'Mutation' }
  & { updateKintoneCollection?: Maybe<(
    { __typename?: 'KintoneCollection' }
    & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
  )> }
);

export type UpdateKintoneProductsMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneProductsConditionInput>;
  input: UpdateKintoneProductsInput;
}>;


export type UpdateKintoneProductsMutation = (
  { __typename?: 'Mutation' }
  & { updateKintoneProducts?: Maybe<(
    { __typename?: 'KintoneProducts' }
    & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
  )> }
);

export type UpdateKintoneProductsStockMutationVariables = Exact<{
  condition?: Maybe<ModelKintoneProductsStockConditionInput>;
  input: UpdateKintoneProductsStockInput;
}>;


export type UpdateKintoneProductsStockMutation = (
  { __typename?: 'Mutation' }
  & { updateKintoneProductsStock?: Maybe<(
    { __typename?: 'KintoneProductsStock' }
    & Pick<KintoneProductsStock, 'audience' | 'createdAt' | 'id' | 'inventory' | 'updatedAt'>
  )> }
);

export type UpdateOrderMutationVariables = Exact<{
  condition?: Maybe<ModelOrderConditionInput>;
  input: UpdateOrderInput;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
  )> }
);

export type UpdateTemporaryGoodsOrderMutationVariables = Exact<{
  condition?: Maybe<ModelTemporaryGoodsOrderConditionInput>;
  input: UpdateTemporaryGoodsOrderInput;
}>;


export type UpdateTemporaryGoodsOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateTemporaryGoodsOrder?: Maybe<(
    { __typename?: 'TemporaryGoodsOrder' }
    & Pick<TemporaryGoodsOrder, 'LockMode' | 'address' | 'cardBrand' | 'cardId' | 'cardName' | 'cardNo' | 'cartId' | 'city' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'expriesAt' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'subAddress' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  condition?: Maybe<ModelUserConditionInput>;
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'createdAt' | 'familyName' | 'givenName' | 'id' | 'mail' | 'stripeId' | 'updatedAt'>
  )> }
);

export type OnCreateCardSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnCreateCardSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
  )> }
);

export type OnCreateDeliverySubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnCreateDeliverySubscription = (
  { __typename?: 'Subscription' }
  & { onCreateDelivery?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
  )> }
);

export type OnCreateGoodsCartSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnCreateGoodsCartSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateGoodsCart?: Maybe<(
    { __typename?: 'GoodsCart' }
    & Pick<GoodsCart, 'cardId' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'id' | 'items' | 'orderState' | 'orderType' | 'paymentId' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type OnCreateGoodsOrderSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnCreateGoodsOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateGoodsOrder?: Maybe<(
    { __typename?: 'GoodsOrder' }
    & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
  )> }
);

export type OnCreateKintoneCollectionSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateKintoneCollectionSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateKintoneCollection?: Maybe<(
    { __typename?: 'KintoneCollection' }
    & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
  )> }
);

export type OnCreateKintoneProductsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateKintoneProductsSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateKintoneProducts?: Maybe<(
    { __typename?: 'KintoneProducts' }
    & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
  )> }
);

export type OnCreateKintoneProductsStockSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateKintoneProductsStockSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateKintoneProductsStock?: Maybe<(
    { __typename?: 'KintoneProductsStock' }
    & Pick<KintoneProductsStock, 'audience' | 'createdAt' | 'id' | 'inventory' | 'updatedAt'>
  )> }
);

export type OnCreateOrderSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnCreateOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
  )> }
);

export type OnCreateTemporaryGoodsOrderSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateTemporaryGoodsOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateTemporaryGoodsOrder?: Maybe<(
    { __typename?: 'TemporaryGoodsOrder' }
    & Pick<TemporaryGoodsOrder, 'LockMode' | 'address' | 'cardBrand' | 'cardId' | 'cardName' | 'cardNo' | 'cartId' | 'city' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'expriesAt' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'subAddress' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type OnCreateUserSubscriptionVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type OnCreateUserSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'createdAt' | 'familyName' | 'givenName' | 'id' | 'mail' | 'stripeId' | 'updatedAt'>
  )> }
);

export type OnDeleteCardSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnDeleteCardSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
  )> }
);

export type OnDeleteDeliverySubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnDeleteDeliverySubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteDelivery?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
  )> }
);

export type OnDeleteGoodsCartSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnDeleteGoodsCartSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteGoodsCart?: Maybe<(
    { __typename?: 'GoodsCart' }
    & Pick<GoodsCart, 'cardId' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'id' | 'items' | 'orderState' | 'orderType' | 'paymentId' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type OnDeleteGoodsOrderSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnDeleteGoodsOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteGoodsOrder?: Maybe<(
    { __typename?: 'GoodsOrder' }
    & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
  )> }
);

export type OnDeleteKintoneCollectionSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnDeleteKintoneCollectionSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteKintoneCollection?: Maybe<(
    { __typename?: 'KintoneCollection' }
    & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
  )> }
);

export type OnDeleteKintoneProductsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnDeleteKintoneProductsSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteKintoneProducts?: Maybe<(
    { __typename?: 'KintoneProducts' }
    & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
  )> }
);

export type OnDeleteKintoneProductsStockSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnDeleteKintoneProductsStockSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteKintoneProductsStock?: Maybe<(
    { __typename?: 'KintoneProductsStock' }
    & Pick<KintoneProductsStock, 'audience' | 'createdAt' | 'id' | 'inventory' | 'updatedAt'>
  )> }
);

export type OnDeleteOrderSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnDeleteOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
  )> }
);

export type OnDeleteTemporaryGoodsOrderSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnDeleteTemporaryGoodsOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteTemporaryGoodsOrder?: Maybe<(
    { __typename?: 'TemporaryGoodsOrder' }
    & Pick<TemporaryGoodsOrder, 'LockMode' | 'address' | 'cardBrand' | 'cardId' | 'cardName' | 'cardNo' | 'cartId' | 'city' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'expriesAt' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'subAddress' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type OnDeleteUserSubscriptionVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type OnDeleteUserSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'createdAt' | 'familyName' | 'givenName' | 'id' | 'mail' | 'stripeId' | 'updatedAt'>
  )> }
);

export type OnUpdateCardSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnUpdateCardSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'brand' | 'cardNo' | 'createdAt' | 'deadline' | 'id' | 'name' | 'stripeCardId' | 'updatedAt' | 'userId'>
  )> }
);

export type OnUpdateDeliverySubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnUpdateDeliverySubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateDelivery?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'address' | 'city' | 'createdAt' | 'familyName' | 'givenName' | 'id' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'subAddress' | 'updatedAt' | 'userId'>
  )> }
);

export type OnUpdateGoodsCartSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnUpdateGoodsCartSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateGoodsCart?: Maybe<(
    { __typename?: 'GoodsCart' }
    & Pick<GoodsCart, 'cardId' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'id' | 'items' | 'orderState' | 'orderType' | 'paymentId' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type OnUpdateGoodsOrderSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnUpdateGoodsOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateGoodsOrder?: Maybe<(
    { __typename?: 'GoodsOrder' }
    & Pick<GoodsOrder, 'address' | 'buyState' | 'cardBrand' | 'cardName' | 'cardNo' | 'city' | 'createdAt' | 'deliveryPrice' | 'discount' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'stateByDate' | 'subAddress' | 'totalPrice' | 'trackingNumber' | 'updatedAt' | 'userId'>
  )> }
);

export type OnUpdateKintoneCollectionSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateKintoneCollectionSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateKintoneCollection?: Maybe<(
    { __typename?: 'KintoneCollection' }
    & Pick<KintoneCollection, 'audience' | 'createdAt' | 'id' | 'isDisplayFlg' | 'precedence' | 'productId' | 'salesState' | 'salesType' | 'thumbnail' | 'title' | 'updatedAt' | 'wpId'>
  )> }
);

export type OnUpdateKintoneProductsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateKintoneProductsSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateKintoneProducts?: Maybe<(
    { __typename?: 'KintoneProducts' }
    & Pick<KintoneProducts, 'audience' | 'createdAt' | 'description' | 'id' | 'images' | 'inventoryState' | 'isDisplayFlg' | 'jancode' | 'limitOrderBuy' | 'productId' | 'productTitle' | 'productType' | 'releaseDate' | 'salesState' | 'sku' | 'sortNumber' | 'tax' | 'taxExcludePrice' | 'taxInRegularPrice' | 'taxIncludePrice' | 'taxRate' | 'thumbnail' | 'transferPrice' | 'updatedAt' | 'variation' | 'variationTitle'>
  )> }
);

export type OnUpdateKintoneProductsStockSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateKintoneProductsStockSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateKintoneProductsStock?: Maybe<(
    { __typename?: 'KintoneProductsStock' }
    & Pick<KintoneProductsStock, 'audience' | 'createdAt' | 'id' | 'inventory' | 'updatedAt'>
  )> }
);

export type OnUpdateOrderSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type OnUpdateOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'article' | 'buyState' | 'cost' | 'createdAt' | 'id' | 'itemId' | 'thumbnailUrl' | 'title' | 'updatedAt' | 'userId'>
  )> }
);

export type OnUpdateTemporaryGoodsOrderSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateTemporaryGoodsOrderSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateTemporaryGoodsOrder?: Maybe<(
    { __typename?: 'TemporaryGoodsOrder' }
    & Pick<TemporaryGoodsOrder, 'LockMode' | 'address' | 'cardBrand' | 'cardId' | 'cardName' | 'cardNo' | 'cartId' | 'city' | 'createdAt' | 'deliveryId' | 'deliveryPrice' | 'discount' | 'expriesAt' | 'familyName' | 'givenName' | 'id' | 'items' | 'orderType' | 'phoneNumber' | 'postCode' | 'provinceCode' | 'specialItem' | 'subAddress' | 'totalPrice' | 'updatedAt' | 'userId'>
  )> }
);

export type OnUpdateUserSubscriptionVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type OnUpdateUserSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'createdAt' | 'familyName' | 'givenName' | 'id' | 'mail' | 'stripeId' | 'updatedAt'>
  )> }
);


export const GetCardDocument = gql`
    query GetCard($id: ID!) {
  getCard(id: $id) {
    brand
    cardNo
    createdAt
    deadline
    id
    name
    stripeCardId
    updatedAt
    userId
  }
}
    `;

/**
 * __useGetCardQuery__
 *
 * To run a query within a React component, call `useGetCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardQuery(baseOptions: Apollo.QueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
      }
export function useGetCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export type GetCardQueryHookResult = ReturnType<typeof useGetCardQuery>;
export type GetCardLazyQueryHookResult = ReturnType<typeof useGetCardLazyQuery>;
export type GetCardQueryResult = Apollo.QueryResult<GetCardQuery, GetCardQueryVariables>;
export const GetDeliveryDocument = gql`
    query GetDelivery($id: ID!) {
  getDelivery(id: $id) {
    address
    city
    createdAt
    familyName
    givenName
    id
    phoneNumber
    postCode
    provinceCode
    subAddress
    updatedAt
    userId
  }
}
    `;

/**
 * __useGetDeliveryQuery__
 *
 * To run a query within a React component, call `useGetDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeliveryQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryQuery, GetDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryQuery, GetDeliveryQueryVariables>(GetDeliveryDocument, options);
      }
export function useGetDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryQuery, GetDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryQuery, GetDeliveryQueryVariables>(GetDeliveryDocument, options);
        }
export type GetDeliveryQueryHookResult = ReturnType<typeof useGetDeliveryQuery>;
export type GetDeliveryLazyQueryHookResult = ReturnType<typeof useGetDeliveryLazyQuery>;
export type GetDeliveryQueryResult = Apollo.QueryResult<GetDeliveryQuery, GetDeliveryQueryVariables>;
export const GetGoodsCartDocument = gql`
    query GetGoodsCart($id: ID!) {
  getGoodsCart(id: $id) {
    cardId
    createdAt
    deliveryId
    deliveryPrice
    discount
    id
    items
    orderState
    orderType
    paymentId
    totalPrice
    updatedAt
    userId
  }
}
    `;

/**
 * __useGetGoodsCartQuery__
 *
 * To run a query within a React component, call `useGetGoodsCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodsCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodsCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGoodsCartQuery(baseOptions: Apollo.QueryHookOptions<GetGoodsCartQuery, GetGoodsCartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoodsCartQuery, GetGoodsCartQueryVariables>(GetGoodsCartDocument, options);
      }
export function useGetGoodsCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoodsCartQuery, GetGoodsCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoodsCartQuery, GetGoodsCartQueryVariables>(GetGoodsCartDocument, options);
        }
export type GetGoodsCartQueryHookResult = ReturnType<typeof useGetGoodsCartQuery>;
export type GetGoodsCartLazyQueryHookResult = ReturnType<typeof useGetGoodsCartLazyQuery>;
export type GetGoodsCartQueryResult = Apollo.QueryResult<GetGoodsCartQuery, GetGoodsCartQueryVariables>;
export const GetGoodsOrderDocument = gql`
    query GetGoodsOrder($id: ID!) {
  getGoodsOrder(id: $id) {
    address
    buyState
    cardBrand
    cardName
    cardNo
    city
    createdAt
    deliveryPrice
    discount
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    stateByDate
    subAddress
    totalPrice
    trackingNumber
    updatedAt
    userId
  }
}
    `;

/**
 * __useGetGoodsOrderQuery__
 *
 * To run a query within a React component, call `useGetGoodsOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodsOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodsOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGoodsOrderQuery(baseOptions: Apollo.QueryHookOptions<GetGoodsOrderQuery, GetGoodsOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoodsOrderQuery, GetGoodsOrderQueryVariables>(GetGoodsOrderDocument, options);
      }
export function useGetGoodsOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoodsOrderQuery, GetGoodsOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoodsOrderQuery, GetGoodsOrderQueryVariables>(GetGoodsOrderDocument, options);
        }
export type GetGoodsOrderQueryHookResult = ReturnType<typeof useGetGoodsOrderQuery>;
export type GetGoodsOrderLazyQueryHookResult = ReturnType<typeof useGetGoodsOrderLazyQuery>;
export type GetGoodsOrderQueryResult = Apollo.QueryResult<GetGoodsOrderQuery, GetGoodsOrderQueryVariables>;
export const GetKintoneCollectionDocument = gql`
    query GetKintoneCollection($id: ID!) {
  getKintoneCollection(id: $id) {
    audience
    createdAt
    id
    isDisplayFlg
    precedence
    productId
    salesState
    salesType
    thumbnail
    title
    updatedAt
    wpId
  }
}
    `;

/**
 * __useGetKintoneCollectionQuery__
 *
 * To run a query within a React component, call `useGetKintoneCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKintoneCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKintoneCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKintoneCollectionQuery(baseOptions: Apollo.QueryHookOptions<GetKintoneCollectionQuery, GetKintoneCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKintoneCollectionQuery, GetKintoneCollectionQueryVariables>(GetKintoneCollectionDocument, options);
      }
export function useGetKintoneCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKintoneCollectionQuery, GetKintoneCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKintoneCollectionQuery, GetKintoneCollectionQueryVariables>(GetKintoneCollectionDocument, options);
        }
export type GetKintoneCollectionQueryHookResult = ReturnType<typeof useGetKintoneCollectionQuery>;
export type GetKintoneCollectionLazyQueryHookResult = ReturnType<typeof useGetKintoneCollectionLazyQuery>;
export type GetKintoneCollectionQueryResult = Apollo.QueryResult<GetKintoneCollectionQuery, GetKintoneCollectionQueryVariables>;
export const GetKintoneProductsDocument = gql`
    query GetKintoneProducts($id: ID!) {
  getKintoneProducts(id: $id) {
    audience
    createdAt
    description
    id
    images
    inventoryState
    isDisplayFlg
    jancode
    limitOrderBuy
    productId
    productTitle
    productType
    releaseDate
    salesState
    sku
    sortNumber
    tax
    taxExcludePrice
    taxInRegularPrice
    taxIncludePrice
    taxRate
    thumbnail
    transferPrice
    updatedAt
    variation
    variationTitle
  }
}
    `;

/**
 * __useGetKintoneProductsQuery__
 *
 * To run a query within a React component, call `useGetKintoneProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKintoneProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKintoneProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKintoneProductsQuery(baseOptions: Apollo.QueryHookOptions<GetKintoneProductsQuery, GetKintoneProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKintoneProductsQuery, GetKintoneProductsQueryVariables>(GetKintoneProductsDocument, options);
      }
export function useGetKintoneProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKintoneProductsQuery, GetKintoneProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKintoneProductsQuery, GetKintoneProductsQueryVariables>(GetKintoneProductsDocument, options);
        }
export type GetKintoneProductsQueryHookResult = ReturnType<typeof useGetKintoneProductsQuery>;
export type GetKintoneProductsLazyQueryHookResult = ReturnType<typeof useGetKintoneProductsLazyQuery>;
export type GetKintoneProductsQueryResult = Apollo.QueryResult<GetKintoneProductsQuery, GetKintoneProductsQueryVariables>;
export const GetKintoneProductsStockDocument = gql`
    query GetKintoneProductsStock($id: ID!) {
  getKintoneProductsStock(id: $id) {
    audience
    createdAt
    id
    inventory
    updatedAt
  }
}
    `;

/**
 * __useGetKintoneProductsStockQuery__
 *
 * To run a query within a React component, call `useGetKintoneProductsStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKintoneProductsStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKintoneProductsStockQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKintoneProductsStockQuery(baseOptions: Apollo.QueryHookOptions<GetKintoneProductsStockQuery, GetKintoneProductsStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKintoneProductsStockQuery, GetKintoneProductsStockQueryVariables>(GetKintoneProductsStockDocument, options);
      }
export function useGetKintoneProductsStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKintoneProductsStockQuery, GetKintoneProductsStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKintoneProductsStockQuery, GetKintoneProductsStockQueryVariables>(GetKintoneProductsStockDocument, options);
        }
export type GetKintoneProductsStockQueryHookResult = ReturnType<typeof useGetKintoneProductsStockQuery>;
export type GetKintoneProductsStockLazyQueryHookResult = ReturnType<typeof useGetKintoneProductsStockLazyQuery>;
export type GetKintoneProductsStockQueryResult = Apollo.QueryResult<GetKintoneProductsStockQuery, GetKintoneProductsStockQueryVariables>;
export const GetOrderDocument = gql`
    query GetOrder($id: ID!) {
  getOrder(id: $id) {
    article
    buyState
    cost
    createdAt
    id
    itemId
    thumbnailUrl
    title
    updatedAt
    userId
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetTemporaryGoodsOrderDocument = gql`
    query GetTemporaryGoodsOrder($id: ID!) {
  getTemporaryGoodsOrder(id: $id) {
    LockMode
    address
    cardBrand
    cardId
    cardName
    cardNo
    cartId
    city
    createdAt
    deliveryId
    deliveryPrice
    discount
    expriesAt
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    subAddress
    totalPrice
    updatedAt
    userId
  }
}
    `;

/**
 * __useGetTemporaryGoodsOrderQuery__
 *
 * To run a query within a React component, call `useGetTemporaryGoodsOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemporaryGoodsOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemporaryGoodsOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemporaryGoodsOrderQuery(baseOptions: Apollo.QueryHookOptions<GetTemporaryGoodsOrderQuery, GetTemporaryGoodsOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemporaryGoodsOrderQuery, GetTemporaryGoodsOrderQueryVariables>(GetTemporaryGoodsOrderDocument, options);
      }
export function useGetTemporaryGoodsOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemporaryGoodsOrderQuery, GetTemporaryGoodsOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemporaryGoodsOrderQuery, GetTemporaryGoodsOrderQueryVariables>(GetTemporaryGoodsOrderDocument, options);
        }
export type GetTemporaryGoodsOrderQueryHookResult = ReturnType<typeof useGetTemporaryGoodsOrderQuery>;
export type GetTemporaryGoodsOrderLazyQueryHookResult = ReturnType<typeof useGetTemporaryGoodsOrderLazyQuery>;
export type GetTemporaryGoodsOrderQueryResult = Apollo.QueryResult<GetTemporaryGoodsOrderQuery, GetTemporaryGoodsOrderQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  getUser(id: $id) {
    createdAt
    familyName
    givenName
    id
    mail
    stripeId
    updatedAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const ListCardsDocument = gql`
    query ListCards($filter: ModelCardFilterInput, $limit: Int, $nextToken: String) {
  listCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      brand
      cardNo
      createdAt
      deadline
      id
      name
      stripeCardId
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListCardsQuery__
 *
 * To run a query within a React component, call `useListCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCardsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListCardsQuery(baseOptions?: Apollo.QueryHookOptions<ListCardsQuery, ListCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCardsQuery, ListCardsQueryVariables>(ListCardsDocument, options);
      }
export function useListCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCardsQuery, ListCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCardsQuery, ListCardsQueryVariables>(ListCardsDocument, options);
        }
export type ListCardsQueryHookResult = ReturnType<typeof useListCardsQuery>;
export type ListCardsLazyQueryHookResult = ReturnType<typeof useListCardsLazyQuery>;
export type ListCardsQueryResult = Apollo.QueryResult<ListCardsQuery, ListCardsQueryVariables>;
export const ListDeliverysDocument = gql`
    query ListDeliverys($filter: ModelDeliveryFilterInput, $limit: Int, $nextToken: String) {
  listDeliverys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      address
      city
      createdAt
      familyName
      givenName
      id
      phoneNumber
      postCode
      provinceCode
      subAddress
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListDeliverysQuery__
 *
 * To run a query within a React component, call `useListDeliverysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDeliverysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDeliverysQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListDeliverysQuery(baseOptions?: Apollo.QueryHookOptions<ListDeliverysQuery, ListDeliverysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDeliverysQuery, ListDeliverysQueryVariables>(ListDeliverysDocument, options);
      }
export function useListDeliverysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDeliverysQuery, ListDeliverysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDeliverysQuery, ListDeliverysQueryVariables>(ListDeliverysDocument, options);
        }
export type ListDeliverysQueryHookResult = ReturnType<typeof useListDeliverysQuery>;
export type ListDeliverysLazyQueryHookResult = ReturnType<typeof useListDeliverysLazyQuery>;
export type ListDeliverysQueryResult = Apollo.QueryResult<ListDeliverysQuery, ListDeliverysQueryVariables>;
export const ListGoodsCartsDocument = gql`
    query ListGoodsCarts($filter: ModelGoodsCartFilterInput, $limit: Int, $nextToken: String) {
  listGoodsCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      cardId
      createdAt
      deliveryId
      deliveryPrice
      discount
      id
      items
      orderState
      orderType
      paymentId
      totalPrice
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListGoodsCartsQuery__
 *
 * To run a query within a React component, call `useListGoodsCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGoodsCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGoodsCartsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListGoodsCartsQuery(baseOptions?: Apollo.QueryHookOptions<ListGoodsCartsQuery, ListGoodsCartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGoodsCartsQuery, ListGoodsCartsQueryVariables>(ListGoodsCartsDocument, options);
      }
export function useListGoodsCartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGoodsCartsQuery, ListGoodsCartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGoodsCartsQuery, ListGoodsCartsQueryVariables>(ListGoodsCartsDocument, options);
        }
export type ListGoodsCartsQueryHookResult = ReturnType<typeof useListGoodsCartsQuery>;
export type ListGoodsCartsLazyQueryHookResult = ReturnType<typeof useListGoodsCartsLazyQuery>;
export type ListGoodsCartsQueryResult = Apollo.QueryResult<ListGoodsCartsQuery, ListGoodsCartsQueryVariables>;
export const ListGoodsOrdersDocument = gql`
    query ListGoodsOrders($filter: ModelGoodsOrderFilterInput, $limit: Int, $nextToken: String) {
  listGoodsOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      address
      buyState
      cardBrand
      cardName
      cardNo
      city
      createdAt
      deliveryPrice
      discount
      familyName
      givenName
      id
      items
      orderType
      phoneNumber
      postCode
      provinceCode
      specialItem
      stateByDate
      subAddress
      totalPrice
      trackingNumber
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListGoodsOrdersQuery__
 *
 * To run a query within a React component, call `useListGoodsOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGoodsOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGoodsOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListGoodsOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ListGoodsOrdersQuery, ListGoodsOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGoodsOrdersQuery, ListGoodsOrdersQueryVariables>(ListGoodsOrdersDocument, options);
      }
export function useListGoodsOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGoodsOrdersQuery, ListGoodsOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGoodsOrdersQuery, ListGoodsOrdersQueryVariables>(ListGoodsOrdersDocument, options);
        }
export type ListGoodsOrdersQueryHookResult = ReturnType<typeof useListGoodsOrdersQuery>;
export type ListGoodsOrdersLazyQueryHookResult = ReturnType<typeof useListGoodsOrdersLazyQuery>;
export type ListGoodsOrdersQueryResult = Apollo.QueryResult<ListGoodsOrdersQuery, ListGoodsOrdersQueryVariables>;
export const ListKintoneCollectionsDocument = gql`
    query ListKintoneCollections($filter: ModelKintoneCollectionFilterInput, $limit: Int, $nextToken: String) {
  listKintoneCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      audience
      createdAt
      id
      isDisplayFlg
      precedence
      productId
      salesState
      salesType
      thumbnail
      title
      updatedAt
      wpId
    }
    nextToken
  }
}
    `;

/**
 * __useListKintoneCollectionsQuery__
 *
 * To run a query within a React component, call `useListKintoneCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKintoneCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKintoneCollectionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListKintoneCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<ListKintoneCollectionsQuery, ListKintoneCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKintoneCollectionsQuery, ListKintoneCollectionsQueryVariables>(ListKintoneCollectionsDocument, options);
      }
export function useListKintoneCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKintoneCollectionsQuery, ListKintoneCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKintoneCollectionsQuery, ListKintoneCollectionsQueryVariables>(ListKintoneCollectionsDocument, options);
        }
export type ListKintoneCollectionsQueryHookResult = ReturnType<typeof useListKintoneCollectionsQuery>;
export type ListKintoneCollectionsLazyQueryHookResult = ReturnType<typeof useListKintoneCollectionsLazyQuery>;
export type ListKintoneCollectionsQueryResult = Apollo.QueryResult<ListKintoneCollectionsQuery, ListKintoneCollectionsQueryVariables>;
export const ListKintoneProductsStocksDocument = gql`
    query ListKintoneProductsStocks($filter: ModelKintoneProductsStockFilterInput, $limit: Int, $nextToken: String) {
  listKintoneProductsStocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      audience
      createdAt
      id
      inventory
      updatedAt
    }
    nextToken
  }
}
    `;

/**
 * __useListKintoneProductsStocksQuery__
 *
 * To run a query within a React component, call `useListKintoneProductsStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKintoneProductsStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKintoneProductsStocksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListKintoneProductsStocksQuery(baseOptions?: Apollo.QueryHookOptions<ListKintoneProductsStocksQuery, ListKintoneProductsStocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKintoneProductsStocksQuery, ListKintoneProductsStocksQueryVariables>(ListKintoneProductsStocksDocument, options);
      }
export function useListKintoneProductsStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKintoneProductsStocksQuery, ListKintoneProductsStocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKintoneProductsStocksQuery, ListKintoneProductsStocksQueryVariables>(ListKintoneProductsStocksDocument, options);
        }
export type ListKintoneProductsStocksQueryHookResult = ReturnType<typeof useListKintoneProductsStocksQuery>;
export type ListKintoneProductsStocksLazyQueryHookResult = ReturnType<typeof useListKintoneProductsStocksLazyQuery>;
export type ListKintoneProductsStocksQueryResult = Apollo.QueryResult<ListKintoneProductsStocksQuery, ListKintoneProductsStocksQueryVariables>;
export const ListKintoneProductssDocument = gql`
    query ListKintoneProductss($filter: ModelKintoneProductsFilterInput, $limit: Int, $nextToken: String) {
  listKintoneProductss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      audience
      createdAt
      description
      id
      images
      inventoryState
      isDisplayFlg
      jancode
      limitOrderBuy
      productId
      productTitle
      productType
      releaseDate
      salesState
      sku
      sortNumber
      tax
      taxExcludePrice
      taxInRegularPrice
      taxIncludePrice
      taxRate
      thumbnail
      transferPrice
      updatedAt
      variation
      variationTitle
    }
    nextToken
  }
}
    `;

/**
 * __useListKintoneProductssQuery__
 *
 * To run a query within a React component, call `useListKintoneProductssQuery` and pass it any options that fit your needs.
 * When your component renders, `useListKintoneProductssQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListKintoneProductssQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListKintoneProductssQuery(baseOptions?: Apollo.QueryHookOptions<ListKintoneProductssQuery, ListKintoneProductssQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListKintoneProductssQuery, ListKintoneProductssQueryVariables>(ListKintoneProductssDocument, options);
      }
export function useListKintoneProductssLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListKintoneProductssQuery, ListKintoneProductssQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListKintoneProductssQuery, ListKintoneProductssQueryVariables>(ListKintoneProductssDocument, options);
        }
export type ListKintoneProductssQueryHookResult = ReturnType<typeof useListKintoneProductssQuery>;
export type ListKintoneProductssLazyQueryHookResult = ReturnType<typeof useListKintoneProductssLazyQuery>;
export type ListKintoneProductssQueryResult = Apollo.QueryResult<ListKintoneProductssQuery, ListKintoneProductssQueryVariables>;
export const ListOrdersDocument = gql`
    query ListOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      article
      buyState
      cost
      createdAt
      id
      itemId
      thumbnailUrl
      title
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListOrdersQuery__
 *
 * To run a query within a React component, call `useListOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ListOrdersQuery, ListOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrdersQuery, ListOrdersQueryVariables>(ListOrdersDocument, options);
      }
export function useListOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrdersQuery, ListOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrdersQuery, ListOrdersQueryVariables>(ListOrdersDocument, options);
        }
export type ListOrdersQueryHookResult = ReturnType<typeof useListOrdersQuery>;
export type ListOrdersLazyQueryHookResult = ReturnType<typeof useListOrdersLazyQuery>;
export type ListOrdersQueryResult = Apollo.QueryResult<ListOrdersQuery, ListOrdersQueryVariables>;
export const ListSortCardsDocument = gql`
    query ListSortCards($createdAt: ModelStringKeyConditionInput, $filter: ModelCardFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $userId: ID) {
  listSortCards(
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userId: $userId
  ) {
    items {
      brand
      cardNo
      createdAt
      deadline
      id
      name
      stripeCardId
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListSortCardsQuery__
 *
 * To run a query within a React component, call `useListSortCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSortCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSortCardsQuery({
 *   variables: {
 *      createdAt: // value for 'createdAt'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sortDirection: // value for 'sortDirection'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListSortCardsQuery(baseOptions?: Apollo.QueryHookOptions<ListSortCardsQuery, ListSortCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSortCardsQuery, ListSortCardsQueryVariables>(ListSortCardsDocument, options);
      }
export function useListSortCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSortCardsQuery, ListSortCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSortCardsQuery, ListSortCardsQueryVariables>(ListSortCardsDocument, options);
        }
export type ListSortCardsQueryHookResult = ReturnType<typeof useListSortCardsQuery>;
export type ListSortCardsLazyQueryHookResult = ReturnType<typeof useListSortCardsLazyQuery>;
export type ListSortCardsQueryResult = Apollo.QueryResult<ListSortCardsQuery, ListSortCardsQueryVariables>;
export const ListSortDeliverysDocument = gql`
    query ListSortDeliverys($createdAt: ModelStringKeyConditionInput, $filter: ModelDeliveryFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $userId: String) {
  listSortDeliverys(
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userId: $userId
  ) {
    items {
      address
      city
      createdAt
      familyName
      givenName
      id
      phoneNumber
      postCode
      provinceCode
      subAddress
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListSortDeliverysQuery__
 *
 * To run a query within a React component, call `useListSortDeliverysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSortDeliverysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSortDeliverysQuery({
 *   variables: {
 *      createdAt: // value for 'createdAt'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sortDirection: // value for 'sortDirection'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListSortDeliverysQuery(baseOptions?: Apollo.QueryHookOptions<ListSortDeliverysQuery, ListSortDeliverysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSortDeliverysQuery, ListSortDeliverysQueryVariables>(ListSortDeliverysDocument, options);
      }
export function useListSortDeliverysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSortDeliverysQuery, ListSortDeliverysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSortDeliverysQuery, ListSortDeliverysQueryVariables>(ListSortDeliverysDocument, options);
        }
export type ListSortDeliverysQueryHookResult = ReturnType<typeof useListSortDeliverysQuery>;
export type ListSortDeliverysLazyQueryHookResult = ReturnType<typeof useListSortDeliverysLazyQuery>;
export type ListSortDeliverysQueryResult = Apollo.QueryResult<ListSortDeliverysQuery, ListSortDeliverysQueryVariables>;
export const ListSortGoodsOrdersDocument = gql`
    query ListSortGoodsOrders($createdAt: ModelStringKeyConditionInput, $filter: ModelGoodsOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $userId: String) {
  listSortGoodsOrders(
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userId: $userId
  ) {
    items {
      address
      buyState
      cardBrand
      cardName
      cardNo
      city
      createdAt
      deliveryPrice
      discount
      familyName
      givenName
      id
      items
      orderType
      phoneNumber
      postCode
      provinceCode
      specialItem
      stateByDate
      subAddress
      totalPrice
      trackingNumber
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListSortGoodsOrdersQuery__
 *
 * To run a query within a React component, call `useListSortGoodsOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSortGoodsOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSortGoodsOrdersQuery({
 *   variables: {
 *      createdAt: // value for 'createdAt'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sortDirection: // value for 'sortDirection'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListSortGoodsOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ListSortGoodsOrdersQuery, ListSortGoodsOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSortGoodsOrdersQuery, ListSortGoodsOrdersQueryVariables>(ListSortGoodsOrdersDocument, options);
      }
export function useListSortGoodsOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSortGoodsOrdersQuery, ListSortGoodsOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSortGoodsOrdersQuery, ListSortGoodsOrdersQueryVariables>(ListSortGoodsOrdersDocument, options);
        }
export type ListSortGoodsOrdersQueryHookResult = ReturnType<typeof useListSortGoodsOrdersQuery>;
export type ListSortGoodsOrdersLazyQueryHookResult = ReturnType<typeof useListSortGoodsOrdersLazyQuery>;
export type ListSortGoodsOrdersQueryResult = Apollo.QueryResult<ListSortGoodsOrdersQuery, ListSortGoodsOrdersQueryVariables>;
export const ListSortKintoneCollectionsDocument = gql`
    query ListSortKintoneCollections($filter: ModelKintoneCollectionFilterInput, $limit: Int, $nextToken: String, $precedence: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $wpId: String) {
  listSortKintoneCollections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    precedence: $precedence
    sortDirection: $sortDirection
    wpId: $wpId
  ) {
    items {
      audience
      createdAt
      id
      isDisplayFlg
      precedence
      productId
      salesState
      salesType
      thumbnail
      title
      updatedAt
      wpId
    }
    nextToken
  }
}
    `;

/**
 * __useListSortKintoneCollectionsQuery__
 *
 * To run a query within a React component, call `useListSortKintoneCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSortKintoneCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSortKintoneCollectionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      precedence: // value for 'precedence'
 *      sortDirection: // value for 'sortDirection'
 *      wpId: // value for 'wpId'
 *   },
 * });
 */
export function useListSortKintoneCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<ListSortKintoneCollectionsQuery, ListSortKintoneCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSortKintoneCollectionsQuery, ListSortKintoneCollectionsQueryVariables>(ListSortKintoneCollectionsDocument, options);
      }
export function useListSortKintoneCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSortKintoneCollectionsQuery, ListSortKintoneCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSortKintoneCollectionsQuery, ListSortKintoneCollectionsQueryVariables>(ListSortKintoneCollectionsDocument, options);
        }
export type ListSortKintoneCollectionsQueryHookResult = ReturnType<typeof useListSortKintoneCollectionsQuery>;
export type ListSortKintoneCollectionsLazyQueryHookResult = ReturnType<typeof useListSortKintoneCollectionsLazyQuery>;
export type ListSortKintoneCollectionsQueryResult = Apollo.QueryResult<ListSortKintoneCollectionsQuery, ListSortKintoneCollectionsQueryVariables>;
export const ListSortKintoneProductssDocument = gql`
    query ListSortKintoneProductss($filter: ModelKintoneProductsFilterInput, $limit: Int, $nextToken: String, $productId: String, $sortDirection: ModelSortDirection, $sortNumber: ModelIntKeyConditionInput) {
  listSortKintoneProductss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    productId: $productId
    sortDirection: $sortDirection
    sortNumber: $sortNumber
  ) {
    items {
      audience
      createdAt
      description
      id
      images
      inventoryState
      isDisplayFlg
      jancode
      limitOrderBuy
      productId
      productTitle
      productType
      releaseDate
      salesState
      sku
      sortNumber
      tax
      taxExcludePrice
      taxInRegularPrice
      taxIncludePrice
      taxRate
      thumbnail
      transferPrice
      updatedAt
      variation
      variationTitle
    }
    nextToken
  }
}
    `;

/**
 * __useListSortKintoneProductssQuery__
 *
 * To run a query within a React component, call `useListSortKintoneProductssQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSortKintoneProductssQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSortKintoneProductssQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      productId: // value for 'productId'
 *      sortDirection: // value for 'sortDirection'
 *      sortNumber: // value for 'sortNumber'
 *   },
 * });
 */
export function useListSortKintoneProductssQuery(baseOptions?: Apollo.QueryHookOptions<ListSortKintoneProductssQuery, ListSortKintoneProductssQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSortKintoneProductssQuery, ListSortKintoneProductssQueryVariables>(ListSortKintoneProductssDocument, options);
      }
export function useListSortKintoneProductssLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSortKintoneProductssQuery, ListSortKintoneProductssQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSortKintoneProductssQuery, ListSortKintoneProductssQueryVariables>(ListSortKintoneProductssDocument, options);
        }
export type ListSortKintoneProductssQueryHookResult = ReturnType<typeof useListSortKintoneProductssQuery>;
export type ListSortKintoneProductssLazyQueryHookResult = ReturnType<typeof useListSortKintoneProductssLazyQuery>;
export type ListSortKintoneProductssQueryResult = Apollo.QueryResult<ListSortKintoneProductssQuery, ListSortKintoneProductssQueryVariables>;
export const ListSortOrdersDocument = gql`
    query ListSortOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $updatedAt: ModelStringKeyConditionInput, $userId: String) {
  listSortOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    updatedAt: $updatedAt
    userId: $userId
  ) {
    items {
      article
      buyState
      cost
      createdAt
      id
      itemId
      thumbnailUrl
      title
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListSortOrdersQuery__
 *
 * To run a query within a React component, call `useListSortOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSortOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSortOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sortDirection: // value for 'sortDirection'
 *      updatedAt: // value for 'updatedAt'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListSortOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ListSortOrdersQuery, ListSortOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSortOrdersQuery, ListSortOrdersQueryVariables>(ListSortOrdersDocument, options);
      }
export function useListSortOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSortOrdersQuery, ListSortOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSortOrdersQuery, ListSortOrdersQueryVariables>(ListSortOrdersDocument, options);
        }
export type ListSortOrdersQueryHookResult = ReturnType<typeof useListSortOrdersQuery>;
export type ListSortOrdersLazyQueryHookResult = ReturnType<typeof useListSortOrdersLazyQuery>;
export type ListSortOrdersQueryResult = Apollo.QueryResult<ListSortOrdersQuery, ListSortOrdersQueryVariables>;
export const ListStateByDatesDocument = gql`
    query ListStateByDates($filter: ModelGoodsOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $stateByDate: String, $updatedAt: ModelStringKeyConditionInput) {
  listStateByDates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    stateByDate: $stateByDate
    updatedAt: $updatedAt
  ) {
    items {
      address
      buyState
      cardBrand
      cardName
      cardNo
      city
      createdAt
      deliveryPrice
      discount
      familyName
      givenName
      id
      items
      orderType
      phoneNumber
      postCode
      provinceCode
      specialItem
      stateByDate
      subAddress
      totalPrice
      trackingNumber
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListStateByDatesQuery__
 *
 * To run a query within a React component, call `useListStateByDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStateByDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStateByDatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sortDirection: // value for 'sortDirection'
 *      stateByDate: // value for 'stateByDate'
 *      updatedAt: // value for 'updatedAt'
 *   },
 * });
 */
export function useListStateByDatesQuery(baseOptions?: Apollo.QueryHookOptions<ListStateByDatesQuery, ListStateByDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStateByDatesQuery, ListStateByDatesQueryVariables>(ListStateByDatesDocument, options);
      }
export function useListStateByDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStateByDatesQuery, ListStateByDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStateByDatesQuery, ListStateByDatesQueryVariables>(ListStateByDatesDocument, options);
        }
export type ListStateByDatesQueryHookResult = ReturnType<typeof useListStateByDatesQuery>;
export type ListStateByDatesLazyQueryHookResult = ReturnType<typeof useListStateByDatesLazyQuery>;
export type ListStateByDatesQueryResult = Apollo.QueryResult<ListStateByDatesQuery, ListStateByDatesQueryVariables>;
export const ListTemporaryGoodsOrdersDocument = gql`
    query ListTemporaryGoodsOrders($filter: ModelTemporaryGoodsOrderFilterInput, $limit: Int, $nextToken: String) {
  listTemporaryGoodsOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      LockMode
      address
      cardBrand
      cardId
      cardName
      cardNo
      cartId
      city
      createdAt
      deliveryId
      deliveryPrice
      discount
      expriesAt
      familyName
      givenName
      id
      items
      orderType
      phoneNumber
      postCode
      provinceCode
      specialItem
      subAddress
      totalPrice
      updatedAt
      userId
    }
    nextToken
  }
}
    `;

/**
 * __useListTemporaryGoodsOrdersQuery__
 *
 * To run a query within a React component, call `useListTemporaryGoodsOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTemporaryGoodsOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTemporaryGoodsOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListTemporaryGoodsOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ListTemporaryGoodsOrdersQuery, ListTemporaryGoodsOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTemporaryGoodsOrdersQuery, ListTemporaryGoodsOrdersQueryVariables>(ListTemporaryGoodsOrdersDocument, options);
      }
export function useListTemporaryGoodsOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTemporaryGoodsOrdersQuery, ListTemporaryGoodsOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTemporaryGoodsOrdersQuery, ListTemporaryGoodsOrdersQueryVariables>(ListTemporaryGoodsOrdersDocument, options);
        }
export type ListTemporaryGoodsOrdersQueryHookResult = ReturnType<typeof useListTemporaryGoodsOrdersQuery>;
export type ListTemporaryGoodsOrdersLazyQueryHookResult = ReturnType<typeof useListTemporaryGoodsOrdersLazyQuery>;
export type ListTemporaryGoodsOrdersQueryResult = Apollo.QueryResult<ListTemporaryGoodsOrdersQuery, ListTemporaryGoodsOrdersQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      createdAt
      familyName
      givenName
      id
      mail
      stripeId
      updatedAt
    }
    nextToken
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const CreateCardDocument = gql`
    mutation CreateCard($condition: ModelCardConditionInput, $input: CreateCardInput!) {
  createCard(condition: $condition, input: $input) {
    brand
    cardNo
    createdAt
    deadline
    id
    name
    stripeCardId
    updatedAt
    userId
  }
}
    `;
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, options);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const CreateDeliveryDocument = gql`
    mutation CreateDelivery($condition: ModelDeliveryConditionInput, $input: CreateDeliveryInput!) {
  createDelivery(condition: $condition, input: $input) {
    address
    city
    createdAt
    familyName
    givenName
    id
    phoneNumber
    postCode
    provinceCode
    subAddress
    updatedAt
    userId
  }
}
    `;
export type CreateDeliveryMutationFn = Apollo.MutationFunction<CreateDeliveryMutation, CreateDeliveryMutationVariables>;

/**
 * __useCreateDeliveryMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryMutation, { data, loading, error }] = useCreateDeliveryMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeliveryMutation, CreateDeliveryMutationVariables>(CreateDeliveryDocument, options);
      }
export type CreateDeliveryMutationHookResult = ReturnType<typeof useCreateDeliveryMutation>;
export type CreateDeliveryMutationResult = Apollo.MutationResult<CreateDeliveryMutation>;
export type CreateDeliveryMutationOptions = Apollo.BaseMutationOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>;
export const CreateGoodsCartDocument = gql`
    mutation CreateGoodsCart($condition: ModelGoodsCartConditionInput, $input: CreateGoodsCartInput!) {
  createGoodsCart(condition: $condition, input: $input) {
    cardId
    createdAt
    deliveryId
    deliveryPrice
    discount
    id
    items
    orderState
    orderType
    paymentId
    totalPrice
    updatedAt
    userId
  }
}
    `;
export type CreateGoodsCartMutationFn = Apollo.MutationFunction<CreateGoodsCartMutation, CreateGoodsCartMutationVariables>;

/**
 * __useCreateGoodsCartMutation__
 *
 * To run a mutation, you first call `useCreateGoodsCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoodsCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoodsCartMutation, { data, loading, error }] = useCreateGoodsCartMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGoodsCartMutation(baseOptions?: Apollo.MutationHookOptions<CreateGoodsCartMutation, CreateGoodsCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGoodsCartMutation, CreateGoodsCartMutationVariables>(CreateGoodsCartDocument, options);
      }
export type CreateGoodsCartMutationHookResult = ReturnType<typeof useCreateGoodsCartMutation>;
export type CreateGoodsCartMutationResult = Apollo.MutationResult<CreateGoodsCartMutation>;
export type CreateGoodsCartMutationOptions = Apollo.BaseMutationOptions<CreateGoodsCartMutation, CreateGoodsCartMutationVariables>;
export const CreateGoodsOrderDocument = gql`
    mutation CreateGoodsOrder($condition: ModelGoodsOrderConditionInput, $input: CreateGoodsOrderInput!) {
  createGoodsOrder(condition: $condition, input: $input) {
    address
    buyState
    cardBrand
    cardName
    cardNo
    city
    createdAt
    deliveryPrice
    discount
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    stateByDate
    subAddress
    totalPrice
    trackingNumber
    updatedAt
    userId
  }
}
    `;
export type CreateGoodsOrderMutationFn = Apollo.MutationFunction<CreateGoodsOrderMutation, CreateGoodsOrderMutationVariables>;

/**
 * __useCreateGoodsOrderMutation__
 *
 * To run a mutation, you first call `useCreateGoodsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoodsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoodsOrderMutation, { data, loading, error }] = useCreateGoodsOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGoodsOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateGoodsOrderMutation, CreateGoodsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGoodsOrderMutation, CreateGoodsOrderMutationVariables>(CreateGoodsOrderDocument, options);
      }
export type CreateGoodsOrderMutationHookResult = ReturnType<typeof useCreateGoodsOrderMutation>;
export type CreateGoodsOrderMutationResult = Apollo.MutationResult<CreateGoodsOrderMutation>;
export type CreateGoodsOrderMutationOptions = Apollo.BaseMutationOptions<CreateGoodsOrderMutation, CreateGoodsOrderMutationVariables>;
export const CreateKintoneCollectionDocument = gql`
    mutation CreateKintoneCollection($condition: ModelKintoneCollectionConditionInput, $input: CreateKintoneCollectionInput!) {
  createKintoneCollection(condition: $condition, input: $input) {
    audience
    createdAt
    id
    isDisplayFlg
    precedence
    productId
    salesState
    salesType
    thumbnail
    title
    updatedAt
    wpId
  }
}
    `;
export type CreateKintoneCollectionMutationFn = Apollo.MutationFunction<CreateKintoneCollectionMutation, CreateKintoneCollectionMutationVariables>;

/**
 * __useCreateKintoneCollectionMutation__
 *
 * To run a mutation, you first call `useCreateKintoneCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKintoneCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKintoneCollectionMutation, { data, loading, error }] = useCreateKintoneCollectionMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKintoneCollectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateKintoneCollectionMutation, CreateKintoneCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKintoneCollectionMutation, CreateKintoneCollectionMutationVariables>(CreateKintoneCollectionDocument, options);
      }
export type CreateKintoneCollectionMutationHookResult = ReturnType<typeof useCreateKintoneCollectionMutation>;
export type CreateKintoneCollectionMutationResult = Apollo.MutationResult<CreateKintoneCollectionMutation>;
export type CreateKintoneCollectionMutationOptions = Apollo.BaseMutationOptions<CreateKintoneCollectionMutation, CreateKintoneCollectionMutationVariables>;
export const CreateKintoneProductsDocument = gql`
    mutation CreateKintoneProducts($condition: ModelKintoneProductsConditionInput, $input: CreateKintoneProductsInput!) {
  createKintoneProducts(condition: $condition, input: $input) {
    audience
    createdAt
    description
    id
    images
    inventoryState
    isDisplayFlg
    jancode
    limitOrderBuy
    productId
    productTitle
    productType
    releaseDate
    salesState
    sku
    sortNumber
    tax
    taxExcludePrice
    taxInRegularPrice
    taxIncludePrice
    taxRate
    thumbnail
    transferPrice
    updatedAt
    variation
    variationTitle
  }
}
    `;
export type CreateKintoneProductsMutationFn = Apollo.MutationFunction<CreateKintoneProductsMutation, CreateKintoneProductsMutationVariables>;

/**
 * __useCreateKintoneProductsMutation__
 *
 * To run a mutation, you first call `useCreateKintoneProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKintoneProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKintoneProductsMutation, { data, loading, error }] = useCreateKintoneProductsMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKintoneProductsMutation(baseOptions?: Apollo.MutationHookOptions<CreateKintoneProductsMutation, CreateKintoneProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKintoneProductsMutation, CreateKintoneProductsMutationVariables>(CreateKintoneProductsDocument, options);
      }
export type CreateKintoneProductsMutationHookResult = ReturnType<typeof useCreateKintoneProductsMutation>;
export type CreateKintoneProductsMutationResult = Apollo.MutationResult<CreateKintoneProductsMutation>;
export type CreateKintoneProductsMutationOptions = Apollo.BaseMutationOptions<CreateKintoneProductsMutation, CreateKintoneProductsMutationVariables>;
export const CreateKintoneProductsStockDocument = gql`
    mutation CreateKintoneProductsStock($condition: ModelKintoneProductsStockConditionInput, $input: CreateKintoneProductsStockInput!) {
  createKintoneProductsStock(condition: $condition, input: $input) {
    audience
    createdAt
    id
    inventory
    updatedAt
  }
}
    `;
export type CreateKintoneProductsStockMutationFn = Apollo.MutationFunction<CreateKintoneProductsStockMutation, CreateKintoneProductsStockMutationVariables>;

/**
 * __useCreateKintoneProductsStockMutation__
 *
 * To run a mutation, you first call `useCreateKintoneProductsStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKintoneProductsStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKintoneProductsStockMutation, { data, loading, error }] = useCreateKintoneProductsStockMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKintoneProductsStockMutation(baseOptions?: Apollo.MutationHookOptions<CreateKintoneProductsStockMutation, CreateKintoneProductsStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKintoneProductsStockMutation, CreateKintoneProductsStockMutationVariables>(CreateKintoneProductsStockDocument, options);
      }
export type CreateKintoneProductsStockMutationHookResult = ReturnType<typeof useCreateKintoneProductsStockMutation>;
export type CreateKintoneProductsStockMutationResult = Apollo.MutationResult<CreateKintoneProductsStockMutation>;
export type CreateKintoneProductsStockMutationOptions = Apollo.BaseMutationOptions<CreateKintoneProductsStockMutation, CreateKintoneProductsStockMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($condition: ModelOrderConditionInput, $input: CreateOrderInput!) {
  createOrder(condition: $condition, input: $input) {
    article
    buyState
    cost
    createdAt
    id
    itemId
    thumbnailUrl
    title
    updatedAt
    userId
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreateTemporaryGoodsOrderDocument = gql`
    mutation CreateTemporaryGoodsOrder($condition: ModelTemporaryGoodsOrderConditionInput, $input: CreateTemporaryGoodsOrderInput!) {
  createTemporaryGoodsOrder(condition: $condition, input: $input) {
    LockMode
    address
    cardBrand
    cardId
    cardName
    cardNo
    cartId
    city
    createdAt
    deliveryId
    deliveryPrice
    discount
    expriesAt
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    subAddress
    totalPrice
    updatedAt
    userId
  }
}
    `;
export type CreateTemporaryGoodsOrderMutationFn = Apollo.MutationFunction<CreateTemporaryGoodsOrderMutation, CreateTemporaryGoodsOrderMutationVariables>;

/**
 * __useCreateTemporaryGoodsOrderMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryGoodsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryGoodsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryGoodsOrderMutation, { data, loading, error }] = useCreateTemporaryGoodsOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemporaryGoodsOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemporaryGoodsOrderMutation, CreateTemporaryGoodsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemporaryGoodsOrderMutation, CreateTemporaryGoodsOrderMutationVariables>(CreateTemporaryGoodsOrderDocument, options);
      }
export type CreateTemporaryGoodsOrderMutationHookResult = ReturnType<typeof useCreateTemporaryGoodsOrderMutation>;
export type CreateTemporaryGoodsOrderMutationResult = Apollo.MutationResult<CreateTemporaryGoodsOrderMutation>;
export type CreateTemporaryGoodsOrderMutationOptions = Apollo.BaseMutationOptions<CreateTemporaryGoodsOrderMutation, CreateTemporaryGoodsOrderMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($condition: ModelUserConditionInput, $input: CreateUserInput!) {
  createUser(condition: $condition, input: $input) {
    createdAt
    familyName
    givenName
    id
    mail
    stripeId
    updatedAt
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteCardDocument = gql`
    mutation DeleteCard($condition: ModelCardConditionInput, $input: DeleteCardInput!) {
  deleteCard(condition: $condition, input: $input) {
    brand
    cardNo
    createdAt
    deadline
    id
    name
    stripeCardId
    updatedAt
    userId
  }
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, options);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutation>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<DeleteCardMutation, DeleteCardMutationVariables>;
export const DeleteDeliveryDocument = gql`
    mutation DeleteDelivery($condition: ModelDeliveryConditionInput, $input: DeleteDeliveryInput!) {
  deleteDelivery(condition: $condition, input: $input) {
    address
    city
    createdAt
    familyName
    givenName
    id
    phoneNumber
    postCode
    provinceCode
    subAddress
    updatedAt
    userId
  }
}
    `;
export type DeleteDeliveryMutationFn = Apollo.MutationFunction<DeleteDeliveryMutation, DeleteDeliveryMutationVariables>;

/**
 * __useDeleteDeliveryMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryMutation, { data, loading, error }] = useDeleteDeliveryMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeliveryMutation, DeleteDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeliveryMutation, DeleteDeliveryMutationVariables>(DeleteDeliveryDocument, options);
      }
export type DeleteDeliveryMutationHookResult = ReturnType<typeof useDeleteDeliveryMutation>;
export type DeleteDeliveryMutationResult = Apollo.MutationResult<DeleteDeliveryMutation>;
export type DeleteDeliveryMutationOptions = Apollo.BaseMutationOptions<DeleteDeliveryMutation, DeleteDeliveryMutationVariables>;
export const DeleteGoodsCartDocument = gql`
    mutation DeleteGoodsCart($condition: ModelGoodsCartConditionInput, $input: DeleteGoodsCartInput!) {
  deleteGoodsCart(condition: $condition, input: $input) {
    cardId
    createdAt
    deliveryId
    deliveryPrice
    discount
    id
    items
    orderState
    orderType
    paymentId
    totalPrice
    updatedAt
    userId
  }
}
    `;
export type DeleteGoodsCartMutationFn = Apollo.MutationFunction<DeleteGoodsCartMutation, DeleteGoodsCartMutationVariables>;

/**
 * __useDeleteGoodsCartMutation__
 *
 * To run a mutation, you first call `useDeleteGoodsCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoodsCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoodsCartMutation, { data, loading, error }] = useDeleteGoodsCartMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGoodsCartMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGoodsCartMutation, DeleteGoodsCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGoodsCartMutation, DeleteGoodsCartMutationVariables>(DeleteGoodsCartDocument, options);
      }
export type DeleteGoodsCartMutationHookResult = ReturnType<typeof useDeleteGoodsCartMutation>;
export type DeleteGoodsCartMutationResult = Apollo.MutationResult<DeleteGoodsCartMutation>;
export type DeleteGoodsCartMutationOptions = Apollo.BaseMutationOptions<DeleteGoodsCartMutation, DeleteGoodsCartMutationVariables>;
export const DeleteGoodsOrderDocument = gql`
    mutation DeleteGoodsOrder($condition: ModelGoodsOrderConditionInput, $input: DeleteGoodsOrderInput!) {
  deleteGoodsOrder(condition: $condition, input: $input) {
    address
    buyState
    cardBrand
    cardName
    cardNo
    city
    createdAt
    deliveryPrice
    discount
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    stateByDate
    subAddress
    totalPrice
    trackingNumber
    updatedAt
    userId
  }
}
    `;
export type DeleteGoodsOrderMutationFn = Apollo.MutationFunction<DeleteGoodsOrderMutation, DeleteGoodsOrderMutationVariables>;

/**
 * __useDeleteGoodsOrderMutation__
 *
 * To run a mutation, you first call `useDeleteGoodsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoodsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoodsOrderMutation, { data, loading, error }] = useDeleteGoodsOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGoodsOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGoodsOrderMutation, DeleteGoodsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGoodsOrderMutation, DeleteGoodsOrderMutationVariables>(DeleteGoodsOrderDocument, options);
      }
export type DeleteGoodsOrderMutationHookResult = ReturnType<typeof useDeleteGoodsOrderMutation>;
export type DeleteGoodsOrderMutationResult = Apollo.MutationResult<DeleteGoodsOrderMutation>;
export type DeleteGoodsOrderMutationOptions = Apollo.BaseMutationOptions<DeleteGoodsOrderMutation, DeleteGoodsOrderMutationVariables>;
export const DeleteKintoneCollectionDocument = gql`
    mutation DeleteKintoneCollection($condition: ModelKintoneCollectionConditionInput, $input: DeleteKintoneCollectionInput!) {
  deleteKintoneCollection(condition: $condition, input: $input) {
    audience
    createdAt
    id
    isDisplayFlg
    precedence
    productId
    salesState
    salesType
    thumbnail
    title
    updatedAt
    wpId
  }
}
    `;
export type DeleteKintoneCollectionMutationFn = Apollo.MutationFunction<DeleteKintoneCollectionMutation, DeleteKintoneCollectionMutationVariables>;

/**
 * __useDeleteKintoneCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteKintoneCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKintoneCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKintoneCollectionMutation, { data, loading, error }] = useDeleteKintoneCollectionMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteKintoneCollectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKintoneCollectionMutation, DeleteKintoneCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKintoneCollectionMutation, DeleteKintoneCollectionMutationVariables>(DeleteKintoneCollectionDocument, options);
      }
export type DeleteKintoneCollectionMutationHookResult = ReturnType<typeof useDeleteKintoneCollectionMutation>;
export type DeleteKintoneCollectionMutationResult = Apollo.MutationResult<DeleteKintoneCollectionMutation>;
export type DeleteKintoneCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteKintoneCollectionMutation, DeleteKintoneCollectionMutationVariables>;
export const DeleteKintoneProductsDocument = gql`
    mutation DeleteKintoneProducts($condition: ModelKintoneProductsConditionInput, $input: DeleteKintoneProductsInput!) {
  deleteKintoneProducts(condition: $condition, input: $input) {
    audience
    createdAt
    description
    id
    images
    inventoryState
    isDisplayFlg
    jancode
    limitOrderBuy
    productId
    productTitle
    productType
    releaseDate
    salesState
    sku
    sortNumber
    tax
    taxExcludePrice
    taxInRegularPrice
    taxIncludePrice
    taxRate
    thumbnail
    transferPrice
    updatedAt
    variation
    variationTitle
  }
}
    `;
export type DeleteKintoneProductsMutationFn = Apollo.MutationFunction<DeleteKintoneProductsMutation, DeleteKintoneProductsMutationVariables>;

/**
 * __useDeleteKintoneProductsMutation__
 *
 * To run a mutation, you first call `useDeleteKintoneProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKintoneProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKintoneProductsMutation, { data, loading, error }] = useDeleteKintoneProductsMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteKintoneProductsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKintoneProductsMutation, DeleteKintoneProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKintoneProductsMutation, DeleteKintoneProductsMutationVariables>(DeleteKintoneProductsDocument, options);
      }
export type DeleteKintoneProductsMutationHookResult = ReturnType<typeof useDeleteKintoneProductsMutation>;
export type DeleteKintoneProductsMutationResult = Apollo.MutationResult<DeleteKintoneProductsMutation>;
export type DeleteKintoneProductsMutationOptions = Apollo.BaseMutationOptions<DeleteKintoneProductsMutation, DeleteKintoneProductsMutationVariables>;
export const DeleteKintoneProductsStockDocument = gql`
    mutation DeleteKintoneProductsStock($condition: ModelKintoneProductsStockConditionInput, $input: DeleteKintoneProductsStockInput!) {
  deleteKintoneProductsStock(condition: $condition, input: $input) {
    audience
    createdAt
    id
    inventory
    updatedAt
  }
}
    `;
export type DeleteKintoneProductsStockMutationFn = Apollo.MutationFunction<DeleteKintoneProductsStockMutation, DeleteKintoneProductsStockMutationVariables>;

/**
 * __useDeleteKintoneProductsStockMutation__
 *
 * To run a mutation, you first call `useDeleteKintoneProductsStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKintoneProductsStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKintoneProductsStockMutation, { data, loading, error }] = useDeleteKintoneProductsStockMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteKintoneProductsStockMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKintoneProductsStockMutation, DeleteKintoneProductsStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKintoneProductsStockMutation, DeleteKintoneProductsStockMutationVariables>(DeleteKintoneProductsStockDocument, options);
      }
export type DeleteKintoneProductsStockMutationHookResult = ReturnType<typeof useDeleteKintoneProductsStockMutation>;
export type DeleteKintoneProductsStockMutationResult = Apollo.MutationResult<DeleteKintoneProductsStockMutation>;
export type DeleteKintoneProductsStockMutationOptions = Apollo.BaseMutationOptions<DeleteKintoneProductsStockMutation, DeleteKintoneProductsStockMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation DeleteOrder($condition: ModelOrderConditionInput, $input: DeleteOrderInput!) {
  deleteOrder(condition: $condition, input: $input) {
    article
    buyState
    cost
    createdAt
    id
    itemId
    thumbnailUrl
    title
    updatedAt
    userId
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const DeleteTemporaryGoodsOrderDocument = gql`
    mutation DeleteTemporaryGoodsOrder($condition: ModelTemporaryGoodsOrderConditionInput, $input: DeleteTemporaryGoodsOrderInput!) {
  deleteTemporaryGoodsOrder(condition: $condition, input: $input) {
    LockMode
    address
    cardBrand
    cardId
    cardName
    cardNo
    cartId
    city
    createdAt
    deliveryId
    deliveryPrice
    discount
    expriesAt
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    subAddress
    totalPrice
    updatedAt
    userId
  }
}
    `;
export type DeleteTemporaryGoodsOrderMutationFn = Apollo.MutationFunction<DeleteTemporaryGoodsOrderMutation, DeleteTemporaryGoodsOrderMutationVariables>;

/**
 * __useDeleteTemporaryGoodsOrderMutation__
 *
 * To run a mutation, you first call `useDeleteTemporaryGoodsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemporaryGoodsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemporaryGoodsOrderMutation, { data, loading, error }] = useDeleteTemporaryGoodsOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemporaryGoodsOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemporaryGoodsOrderMutation, DeleteTemporaryGoodsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemporaryGoodsOrderMutation, DeleteTemporaryGoodsOrderMutationVariables>(DeleteTemporaryGoodsOrderDocument, options);
      }
export type DeleteTemporaryGoodsOrderMutationHookResult = ReturnType<typeof useDeleteTemporaryGoodsOrderMutation>;
export type DeleteTemporaryGoodsOrderMutationResult = Apollo.MutationResult<DeleteTemporaryGoodsOrderMutation>;
export type DeleteTemporaryGoodsOrderMutationOptions = Apollo.BaseMutationOptions<DeleteTemporaryGoodsOrderMutation, DeleteTemporaryGoodsOrderMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($condition: ModelUserConditionInput, $input: DeleteUserInput!) {
  deleteUser(condition: $condition, input: $input) {
    createdAt
    familyName
    givenName
    id
    mail
    stripeId
    updatedAt
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateCardDocument = gql`
    mutation UpdateCard($condition: ModelCardConditionInput, $input: UpdateCardInput!) {
  updateCard(condition: $condition, input: $input) {
    brand
    cardNo
    createdAt
    deadline
    id
    name
    stripeCardId
    updatedAt
    userId
  }
}
    `;
export type UpdateCardMutationFn = Apollo.MutationFunction<UpdateCardMutation, UpdateCardMutationVariables>;

/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardMutation, UpdateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardMutation, UpdateCardMutationVariables>(UpdateCardDocument, options);
      }
export type UpdateCardMutationHookResult = ReturnType<typeof useUpdateCardMutation>;
export type UpdateCardMutationResult = Apollo.MutationResult<UpdateCardMutation>;
export type UpdateCardMutationOptions = Apollo.BaseMutationOptions<UpdateCardMutation, UpdateCardMutationVariables>;
export const UpdateDeliveryDocument = gql`
    mutation UpdateDelivery($condition: ModelDeliveryConditionInput, $input: UpdateDeliveryInput!) {
  updateDelivery(condition: $condition, input: $input) {
    address
    city
    createdAt
    familyName
    givenName
    id
    phoneNumber
    postCode
    provinceCode
    subAddress
    updatedAt
    userId
  }
}
    `;
export type UpdateDeliveryMutationFn = Apollo.MutationFunction<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>;

/**
 * __useUpdateDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryMutation, { data, loading, error }] = useUpdateDeliveryMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>(UpdateDeliveryDocument, options);
      }
export type UpdateDeliveryMutationHookResult = ReturnType<typeof useUpdateDeliveryMutation>;
export type UpdateDeliveryMutationResult = Apollo.MutationResult<UpdateDeliveryMutation>;
export type UpdateDeliveryMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>;
export const UpdateGoodsCartDocument = gql`
    mutation UpdateGoodsCart($condition: ModelGoodsCartConditionInput, $input: UpdateGoodsCartInput!) {
  updateGoodsCart(condition: $condition, input: $input) {
    cardId
    createdAt
    deliveryId
    deliveryPrice
    discount
    id
    items
    orderState
    orderType
    paymentId
    totalPrice
    updatedAt
    userId
  }
}
    `;
export type UpdateGoodsCartMutationFn = Apollo.MutationFunction<UpdateGoodsCartMutation, UpdateGoodsCartMutationVariables>;

/**
 * __useUpdateGoodsCartMutation__
 *
 * To run a mutation, you first call `useUpdateGoodsCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoodsCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoodsCartMutation, { data, loading, error }] = useUpdateGoodsCartMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGoodsCartMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoodsCartMutation, UpdateGoodsCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGoodsCartMutation, UpdateGoodsCartMutationVariables>(UpdateGoodsCartDocument, options);
      }
export type UpdateGoodsCartMutationHookResult = ReturnType<typeof useUpdateGoodsCartMutation>;
export type UpdateGoodsCartMutationResult = Apollo.MutationResult<UpdateGoodsCartMutation>;
export type UpdateGoodsCartMutationOptions = Apollo.BaseMutationOptions<UpdateGoodsCartMutation, UpdateGoodsCartMutationVariables>;
export const UpdateGoodsOrderDocument = gql`
    mutation UpdateGoodsOrder($condition: ModelGoodsOrderConditionInput, $input: UpdateGoodsOrderInput!) {
  updateGoodsOrder(condition: $condition, input: $input) {
    address
    buyState
    cardBrand
    cardName
    cardNo
    city
    createdAt
    deliveryPrice
    discount
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    stateByDate
    subAddress
    totalPrice
    trackingNumber
    updatedAt
    userId
  }
}
    `;
export type UpdateGoodsOrderMutationFn = Apollo.MutationFunction<UpdateGoodsOrderMutation, UpdateGoodsOrderMutationVariables>;

/**
 * __useUpdateGoodsOrderMutation__
 *
 * To run a mutation, you first call `useUpdateGoodsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoodsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoodsOrderMutation, { data, loading, error }] = useUpdateGoodsOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGoodsOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoodsOrderMutation, UpdateGoodsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGoodsOrderMutation, UpdateGoodsOrderMutationVariables>(UpdateGoodsOrderDocument, options);
      }
export type UpdateGoodsOrderMutationHookResult = ReturnType<typeof useUpdateGoodsOrderMutation>;
export type UpdateGoodsOrderMutationResult = Apollo.MutationResult<UpdateGoodsOrderMutation>;
export type UpdateGoodsOrderMutationOptions = Apollo.BaseMutationOptions<UpdateGoodsOrderMutation, UpdateGoodsOrderMutationVariables>;
export const UpdateKintoneCollectionDocument = gql`
    mutation UpdateKintoneCollection($condition: ModelKintoneCollectionConditionInput, $input: UpdateKintoneCollectionInput!) {
  updateKintoneCollection(condition: $condition, input: $input) {
    audience
    createdAt
    id
    isDisplayFlg
    precedence
    productId
    salesState
    salesType
    thumbnail
    title
    updatedAt
    wpId
  }
}
    `;
export type UpdateKintoneCollectionMutationFn = Apollo.MutationFunction<UpdateKintoneCollectionMutation, UpdateKintoneCollectionMutationVariables>;

/**
 * __useUpdateKintoneCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateKintoneCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKintoneCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKintoneCollectionMutation, { data, loading, error }] = useUpdateKintoneCollectionMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKintoneCollectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKintoneCollectionMutation, UpdateKintoneCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKintoneCollectionMutation, UpdateKintoneCollectionMutationVariables>(UpdateKintoneCollectionDocument, options);
      }
export type UpdateKintoneCollectionMutationHookResult = ReturnType<typeof useUpdateKintoneCollectionMutation>;
export type UpdateKintoneCollectionMutationResult = Apollo.MutationResult<UpdateKintoneCollectionMutation>;
export type UpdateKintoneCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateKintoneCollectionMutation, UpdateKintoneCollectionMutationVariables>;
export const UpdateKintoneProductsDocument = gql`
    mutation UpdateKintoneProducts($condition: ModelKintoneProductsConditionInput, $input: UpdateKintoneProductsInput!) {
  updateKintoneProducts(condition: $condition, input: $input) {
    audience
    createdAt
    description
    id
    images
    inventoryState
    isDisplayFlg
    jancode
    limitOrderBuy
    productId
    productTitle
    productType
    releaseDate
    salesState
    sku
    sortNumber
    tax
    taxExcludePrice
    taxInRegularPrice
    taxIncludePrice
    taxRate
    thumbnail
    transferPrice
    updatedAt
    variation
    variationTitle
  }
}
    `;
export type UpdateKintoneProductsMutationFn = Apollo.MutationFunction<UpdateKintoneProductsMutation, UpdateKintoneProductsMutationVariables>;

/**
 * __useUpdateKintoneProductsMutation__
 *
 * To run a mutation, you first call `useUpdateKintoneProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKintoneProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKintoneProductsMutation, { data, loading, error }] = useUpdateKintoneProductsMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKintoneProductsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKintoneProductsMutation, UpdateKintoneProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKintoneProductsMutation, UpdateKintoneProductsMutationVariables>(UpdateKintoneProductsDocument, options);
      }
export type UpdateKintoneProductsMutationHookResult = ReturnType<typeof useUpdateKintoneProductsMutation>;
export type UpdateKintoneProductsMutationResult = Apollo.MutationResult<UpdateKintoneProductsMutation>;
export type UpdateKintoneProductsMutationOptions = Apollo.BaseMutationOptions<UpdateKintoneProductsMutation, UpdateKintoneProductsMutationVariables>;
export const UpdateKintoneProductsStockDocument = gql`
    mutation UpdateKintoneProductsStock($condition: ModelKintoneProductsStockConditionInput, $input: UpdateKintoneProductsStockInput!) {
  updateKintoneProductsStock(condition: $condition, input: $input) {
    audience
    createdAt
    id
    inventory
    updatedAt
  }
}
    `;
export type UpdateKintoneProductsStockMutationFn = Apollo.MutationFunction<UpdateKintoneProductsStockMutation, UpdateKintoneProductsStockMutationVariables>;

/**
 * __useUpdateKintoneProductsStockMutation__
 *
 * To run a mutation, you first call `useUpdateKintoneProductsStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKintoneProductsStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKintoneProductsStockMutation, { data, loading, error }] = useUpdateKintoneProductsStockMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKintoneProductsStockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKintoneProductsStockMutation, UpdateKintoneProductsStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKintoneProductsStockMutation, UpdateKintoneProductsStockMutationVariables>(UpdateKintoneProductsStockDocument, options);
      }
export type UpdateKintoneProductsStockMutationHookResult = ReturnType<typeof useUpdateKintoneProductsStockMutation>;
export type UpdateKintoneProductsStockMutationResult = Apollo.MutationResult<UpdateKintoneProductsStockMutation>;
export type UpdateKintoneProductsStockMutationOptions = Apollo.BaseMutationOptions<UpdateKintoneProductsStockMutation, UpdateKintoneProductsStockMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($condition: ModelOrderConditionInput, $input: UpdateOrderInput!) {
  updateOrder(condition: $condition, input: $input) {
    article
    buyState
    cost
    createdAt
    id
    itemId
    thumbnailUrl
    title
    updatedAt
    userId
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const UpdateTemporaryGoodsOrderDocument = gql`
    mutation UpdateTemporaryGoodsOrder($condition: ModelTemporaryGoodsOrderConditionInput, $input: UpdateTemporaryGoodsOrderInput!) {
  updateTemporaryGoodsOrder(condition: $condition, input: $input) {
    LockMode
    address
    cardBrand
    cardId
    cardName
    cardNo
    cartId
    city
    createdAt
    deliveryId
    deliveryPrice
    discount
    expriesAt
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    subAddress
    totalPrice
    updatedAt
    userId
  }
}
    `;
export type UpdateTemporaryGoodsOrderMutationFn = Apollo.MutationFunction<UpdateTemporaryGoodsOrderMutation, UpdateTemporaryGoodsOrderMutationVariables>;

/**
 * __useUpdateTemporaryGoodsOrderMutation__
 *
 * To run a mutation, you first call `useUpdateTemporaryGoodsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemporaryGoodsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemporaryGoodsOrderMutation, { data, loading, error }] = useUpdateTemporaryGoodsOrderMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemporaryGoodsOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemporaryGoodsOrderMutation, UpdateTemporaryGoodsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemporaryGoodsOrderMutation, UpdateTemporaryGoodsOrderMutationVariables>(UpdateTemporaryGoodsOrderDocument, options);
      }
export type UpdateTemporaryGoodsOrderMutationHookResult = ReturnType<typeof useUpdateTemporaryGoodsOrderMutation>;
export type UpdateTemporaryGoodsOrderMutationResult = Apollo.MutationResult<UpdateTemporaryGoodsOrderMutation>;
export type UpdateTemporaryGoodsOrderMutationOptions = Apollo.BaseMutationOptions<UpdateTemporaryGoodsOrderMutation, UpdateTemporaryGoodsOrderMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($condition: ModelUserConditionInput, $input: UpdateUserInput!) {
  updateUser(condition: $condition, input: $input) {
    createdAt
    familyName
    givenName
    id
    mail
    stripeId
    updatedAt
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      condition: // value for 'condition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const OnCreateCardDocument = gql`
    subscription OnCreateCard($userId: String) {
  onCreateCard(userId: $userId) {
    brand
    cardNo
    createdAt
    deadline
    id
    name
    stripeCardId
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnCreateCardSubscription__
 *
 * To run a query within a React component, call `useOnCreateCardSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateCardSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateCardSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnCreateCardSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateCardSubscription, OnCreateCardSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateCardSubscription, OnCreateCardSubscriptionVariables>(OnCreateCardDocument, options);
      }
export type OnCreateCardSubscriptionHookResult = ReturnType<typeof useOnCreateCardSubscription>;
export type OnCreateCardSubscriptionResult = Apollo.SubscriptionResult<OnCreateCardSubscription>;
export const OnCreateDeliveryDocument = gql`
    subscription OnCreateDelivery($userId: String) {
  onCreateDelivery(userId: $userId) {
    address
    city
    createdAt
    familyName
    givenName
    id
    phoneNumber
    postCode
    provinceCode
    subAddress
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnCreateDeliverySubscription__
 *
 * To run a query within a React component, call `useOnCreateDeliverySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateDeliverySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateDeliverySubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnCreateDeliverySubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateDeliverySubscription, OnCreateDeliverySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateDeliverySubscription, OnCreateDeliverySubscriptionVariables>(OnCreateDeliveryDocument, options);
      }
export type OnCreateDeliverySubscriptionHookResult = ReturnType<typeof useOnCreateDeliverySubscription>;
export type OnCreateDeliverySubscriptionResult = Apollo.SubscriptionResult<OnCreateDeliverySubscription>;
export const OnCreateGoodsCartDocument = gql`
    subscription OnCreateGoodsCart($userId: String) {
  onCreateGoodsCart(userId: $userId) {
    cardId
    createdAt
    deliveryId
    deliveryPrice
    discount
    id
    items
    orderState
    orderType
    paymentId
    totalPrice
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnCreateGoodsCartSubscription__
 *
 * To run a query within a React component, call `useOnCreateGoodsCartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateGoodsCartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateGoodsCartSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnCreateGoodsCartSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateGoodsCartSubscription, OnCreateGoodsCartSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateGoodsCartSubscription, OnCreateGoodsCartSubscriptionVariables>(OnCreateGoodsCartDocument, options);
      }
export type OnCreateGoodsCartSubscriptionHookResult = ReturnType<typeof useOnCreateGoodsCartSubscription>;
export type OnCreateGoodsCartSubscriptionResult = Apollo.SubscriptionResult<OnCreateGoodsCartSubscription>;
export const OnCreateGoodsOrderDocument = gql`
    subscription OnCreateGoodsOrder($userId: String) {
  onCreateGoodsOrder(userId: $userId) {
    address
    buyState
    cardBrand
    cardName
    cardNo
    city
    createdAt
    deliveryPrice
    discount
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    stateByDate
    subAddress
    totalPrice
    trackingNumber
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnCreateGoodsOrderSubscription__
 *
 * To run a query within a React component, call `useOnCreateGoodsOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateGoodsOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateGoodsOrderSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnCreateGoodsOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateGoodsOrderSubscription, OnCreateGoodsOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateGoodsOrderSubscription, OnCreateGoodsOrderSubscriptionVariables>(OnCreateGoodsOrderDocument, options);
      }
export type OnCreateGoodsOrderSubscriptionHookResult = ReturnType<typeof useOnCreateGoodsOrderSubscription>;
export type OnCreateGoodsOrderSubscriptionResult = Apollo.SubscriptionResult<OnCreateGoodsOrderSubscription>;
export const OnCreateKintoneCollectionDocument = gql`
    subscription OnCreateKintoneCollection {
  onCreateKintoneCollection {
    audience
    createdAt
    id
    isDisplayFlg
    precedence
    productId
    salesState
    salesType
    thumbnail
    title
    updatedAt
    wpId
  }
}
    `;

/**
 * __useOnCreateKintoneCollectionSubscription__
 *
 * To run a query within a React component, call `useOnCreateKintoneCollectionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateKintoneCollectionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateKintoneCollectionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateKintoneCollectionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateKintoneCollectionSubscription, OnCreateKintoneCollectionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateKintoneCollectionSubscription, OnCreateKintoneCollectionSubscriptionVariables>(OnCreateKintoneCollectionDocument, options);
      }
export type OnCreateKintoneCollectionSubscriptionHookResult = ReturnType<typeof useOnCreateKintoneCollectionSubscription>;
export type OnCreateKintoneCollectionSubscriptionResult = Apollo.SubscriptionResult<OnCreateKintoneCollectionSubscription>;
export const OnCreateKintoneProductsDocument = gql`
    subscription OnCreateKintoneProducts {
  onCreateKintoneProducts {
    audience
    createdAt
    description
    id
    images
    inventoryState
    isDisplayFlg
    jancode
    limitOrderBuy
    productId
    productTitle
    productType
    releaseDate
    salesState
    sku
    sortNumber
    tax
    taxExcludePrice
    taxInRegularPrice
    taxIncludePrice
    taxRate
    thumbnail
    transferPrice
    updatedAt
    variation
    variationTitle
  }
}
    `;

/**
 * __useOnCreateKintoneProductsSubscription__
 *
 * To run a query within a React component, call `useOnCreateKintoneProductsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateKintoneProductsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateKintoneProductsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateKintoneProductsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateKintoneProductsSubscription, OnCreateKintoneProductsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateKintoneProductsSubscription, OnCreateKintoneProductsSubscriptionVariables>(OnCreateKintoneProductsDocument, options);
      }
export type OnCreateKintoneProductsSubscriptionHookResult = ReturnType<typeof useOnCreateKintoneProductsSubscription>;
export type OnCreateKintoneProductsSubscriptionResult = Apollo.SubscriptionResult<OnCreateKintoneProductsSubscription>;
export const OnCreateKintoneProductsStockDocument = gql`
    subscription OnCreateKintoneProductsStock {
  onCreateKintoneProductsStock {
    audience
    createdAt
    id
    inventory
    updatedAt
  }
}
    `;

/**
 * __useOnCreateKintoneProductsStockSubscription__
 *
 * To run a query within a React component, call `useOnCreateKintoneProductsStockSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateKintoneProductsStockSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateKintoneProductsStockSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateKintoneProductsStockSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateKintoneProductsStockSubscription, OnCreateKintoneProductsStockSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateKintoneProductsStockSubscription, OnCreateKintoneProductsStockSubscriptionVariables>(OnCreateKintoneProductsStockDocument, options);
      }
export type OnCreateKintoneProductsStockSubscriptionHookResult = ReturnType<typeof useOnCreateKintoneProductsStockSubscription>;
export type OnCreateKintoneProductsStockSubscriptionResult = Apollo.SubscriptionResult<OnCreateKintoneProductsStockSubscription>;
export const OnCreateOrderDocument = gql`
    subscription OnCreateOrder($userId: String) {
  onCreateOrder(userId: $userId) {
    article
    buyState
    cost
    createdAt
    id
    itemId
    thumbnailUrl
    title
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnCreateOrderSubscription__
 *
 * To run a query within a React component, call `useOnCreateOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateOrderSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnCreateOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateOrderSubscription, OnCreateOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateOrderSubscription, OnCreateOrderSubscriptionVariables>(OnCreateOrderDocument, options);
      }
export type OnCreateOrderSubscriptionHookResult = ReturnType<typeof useOnCreateOrderSubscription>;
export type OnCreateOrderSubscriptionResult = Apollo.SubscriptionResult<OnCreateOrderSubscription>;
export const OnCreateTemporaryGoodsOrderDocument = gql`
    subscription OnCreateTemporaryGoodsOrder {
  onCreateTemporaryGoodsOrder {
    LockMode
    address
    cardBrand
    cardId
    cardName
    cardNo
    cartId
    city
    createdAt
    deliveryId
    deliveryPrice
    discount
    expriesAt
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    subAddress
    totalPrice
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnCreateTemporaryGoodsOrderSubscription__
 *
 * To run a query within a React component, call `useOnCreateTemporaryGoodsOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateTemporaryGoodsOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateTemporaryGoodsOrderSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateTemporaryGoodsOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateTemporaryGoodsOrderSubscription, OnCreateTemporaryGoodsOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateTemporaryGoodsOrderSubscription, OnCreateTemporaryGoodsOrderSubscriptionVariables>(OnCreateTemporaryGoodsOrderDocument, options);
      }
export type OnCreateTemporaryGoodsOrderSubscriptionHookResult = ReturnType<typeof useOnCreateTemporaryGoodsOrderSubscription>;
export type OnCreateTemporaryGoodsOrderSubscriptionResult = Apollo.SubscriptionResult<OnCreateTemporaryGoodsOrderSubscription>;
export const OnCreateUserDocument = gql`
    subscription OnCreateUser($id: String) {
  onCreateUser(id: $id) {
    createdAt
    familyName
    givenName
    id
    mail
    stripeId
    updatedAt
  }
}
    `;

/**
 * __useOnCreateUserSubscription__
 *
 * To run a query within a React component, call `useOnCreateUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateUserSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnCreateUserSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateUserSubscription, OnCreateUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateUserSubscription, OnCreateUserSubscriptionVariables>(OnCreateUserDocument, options);
      }
export type OnCreateUserSubscriptionHookResult = ReturnType<typeof useOnCreateUserSubscription>;
export type OnCreateUserSubscriptionResult = Apollo.SubscriptionResult<OnCreateUserSubscription>;
export const OnDeleteCardDocument = gql`
    subscription OnDeleteCard($userId: String) {
  onDeleteCard(userId: $userId) {
    brand
    cardNo
    createdAt
    deadline
    id
    name
    stripeCardId
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnDeleteCardSubscription__
 *
 * To run a query within a React component, call `useOnDeleteCardSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteCardSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteCardSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnDeleteCardSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteCardSubscription, OnDeleteCardSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteCardSubscription, OnDeleteCardSubscriptionVariables>(OnDeleteCardDocument, options);
      }
export type OnDeleteCardSubscriptionHookResult = ReturnType<typeof useOnDeleteCardSubscription>;
export type OnDeleteCardSubscriptionResult = Apollo.SubscriptionResult<OnDeleteCardSubscription>;
export const OnDeleteDeliveryDocument = gql`
    subscription OnDeleteDelivery($userId: String) {
  onDeleteDelivery(userId: $userId) {
    address
    city
    createdAt
    familyName
    givenName
    id
    phoneNumber
    postCode
    provinceCode
    subAddress
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnDeleteDeliverySubscription__
 *
 * To run a query within a React component, call `useOnDeleteDeliverySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteDeliverySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteDeliverySubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnDeleteDeliverySubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteDeliverySubscription, OnDeleteDeliverySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteDeliverySubscription, OnDeleteDeliverySubscriptionVariables>(OnDeleteDeliveryDocument, options);
      }
export type OnDeleteDeliverySubscriptionHookResult = ReturnType<typeof useOnDeleteDeliverySubscription>;
export type OnDeleteDeliverySubscriptionResult = Apollo.SubscriptionResult<OnDeleteDeliverySubscription>;
export const OnDeleteGoodsCartDocument = gql`
    subscription OnDeleteGoodsCart($userId: String) {
  onDeleteGoodsCart(userId: $userId) {
    cardId
    createdAt
    deliveryId
    deliveryPrice
    discount
    id
    items
    orderState
    orderType
    paymentId
    totalPrice
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnDeleteGoodsCartSubscription__
 *
 * To run a query within a React component, call `useOnDeleteGoodsCartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteGoodsCartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteGoodsCartSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnDeleteGoodsCartSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteGoodsCartSubscription, OnDeleteGoodsCartSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteGoodsCartSubscription, OnDeleteGoodsCartSubscriptionVariables>(OnDeleteGoodsCartDocument, options);
      }
export type OnDeleteGoodsCartSubscriptionHookResult = ReturnType<typeof useOnDeleteGoodsCartSubscription>;
export type OnDeleteGoodsCartSubscriptionResult = Apollo.SubscriptionResult<OnDeleteGoodsCartSubscription>;
export const OnDeleteGoodsOrderDocument = gql`
    subscription OnDeleteGoodsOrder($userId: String) {
  onDeleteGoodsOrder(userId: $userId) {
    address
    buyState
    cardBrand
    cardName
    cardNo
    city
    createdAt
    deliveryPrice
    discount
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    stateByDate
    subAddress
    totalPrice
    trackingNumber
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnDeleteGoodsOrderSubscription__
 *
 * To run a query within a React component, call `useOnDeleteGoodsOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteGoodsOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteGoodsOrderSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnDeleteGoodsOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteGoodsOrderSubscription, OnDeleteGoodsOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteGoodsOrderSubscription, OnDeleteGoodsOrderSubscriptionVariables>(OnDeleteGoodsOrderDocument, options);
      }
export type OnDeleteGoodsOrderSubscriptionHookResult = ReturnType<typeof useOnDeleteGoodsOrderSubscription>;
export type OnDeleteGoodsOrderSubscriptionResult = Apollo.SubscriptionResult<OnDeleteGoodsOrderSubscription>;
export const OnDeleteKintoneCollectionDocument = gql`
    subscription OnDeleteKintoneCollection {
  onDeleteKintoneCollection {
    audience
    createdAt
    id
    isDisplayFlg
    precedence
    productId
    salesState
    salesType
    thumbnail
    title
    updatedAt
    wpId
  }
}
    `;

/**
 * __useOnDeleteKintoneCollectionSubscription__
 *
 * To run a query within a React component, call `useOnDeleteKintoneCollectionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteKintoneCollectionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteKintoneCollectionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDeleteKintoneCollectionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteKintoneCollectionSubscription, OnDeleteKintoneCollectionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteKintoneCollectionSubscription, OnDeleteKintoneCollectionSubscriptionVariables>(OnDeleteKintoneCollectionDocument, options);
      }
export type OnDeleteKintoneCollectionSubscriptionHookResult = ReturnType<typeof useOnDeleteKintoneCollectionSubscription>;
export type OnDeleteKintoneCollectionSubscriptionResult = Apollo.SubscriptionResult<OnDeleteKintoneCollectionSubscription>;
export const OnDeleteKintoneProductsDocument = gql`
    subscription OnDeleteKintoneProducts {
  onDeleteKintoneProducts {
    audience
    createdAt
    description
    id
    images
    inventoryState
    isDisplayFlg
    jancode
    limitOrderBuy
    productId
    productTitle
    productType
    releaseDate
    salesState
    sku
    sortNumber
    tax
    taxExcludePrice
    taxInRegularPrice
    taxIncludePrice
    taxRate
    thumbnail
    transferPrice
    updatedAt
    variation
    variationTitle
  }
}
    `;

/**
 * __useOnDeleteKintoneProductsSubscription__
 *
 * To run a query within a React component, call `useOnDeleteKintoneProductsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteKintoneProductsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteKintoneProductsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDeleteKintoneProductsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteKintoneProductsSubscription, OnDeleteKintoneProductsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteKintoneProductsSubscription, OnDeleteKintoneProductsSubscriptionVariables>(OnDeleteKintoneProductsDocument, options);
      }
export type OnDeleteKintoneProductsSubscriptionHookResult = ReturnType<typeof useOnDeleteKintoneProductsSubscription>;
export type OnDeleteKintoneProductsSubscriptionResult = Apollo.SubscriptionResult<OnDeleteKintoneProductsSubscription>;
export const OnDeleteKintoneProductsStockDocument = gql`
    subscription OnDeleteKintoneProductsStock {
  onDeleteKintoneProductsStock {
    audience
    createdAt
    id
    inventory
    updatedAt
  }
}
    `;

/**
 * __useOnDeleteKintoneProductsStockSubscription__
 *
 * To run a query within a React component, call `useOnDeleteKintoneProductsStockSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteKintoneProductsStockSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteKintoneProductsStockSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDeleteKintoneProductsStockSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteKintoneProductsStockSubscription, OnDeleteKintoneProductsStockSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteKintoneProductsStockSubscription, OnDeleteKintoneProductsStockSubscriptionVariables>(OnDeleteKintoneProductsStockDocument, options);
      }
export type OnDeleteKintoneProductsStockSubscriptionHookResult = ReturnType<typeof useOnDeleteKintoneProductsStockSubscription>;
export type OnDeleteKintoneProductsStockSubscriptionResult = Apollo.SubscriptionResult<OnDeleteKintoneProductsStockSubscription>;
export const OnDeleteOrderDocument = gql`
    subscription OnDeleteOrder($userId: String) {
  onDeleteOrder(userId: $userId) {
    article
    buyState
    cost
    createdAt
    id
    itemId
    thumbnailUrl
    title
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnDeleteOrderSubscription__
 *
 * To run a query within a React component, call `useOnDeleteOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteOrderSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnDeleteOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteOrderSubscription, OnDeleteOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteOrderSubscription, OnDeleteOrderSubscriptionVariables>(OnDeleteOrderDocument, options);
      }
export type OnDeleteOrderSubscriptionHookResult = ReturnType<typeof useOnDeleteOrderSubscription>;
export type OnDeleteOrderSubscriptionResult = Apollo.SubscriptionResult<OnDeleteOrderSubscription>;
export const OnDeleteTemporaryGoodsOrderDocument = gql`
    subscription OnDeleteTemporaryGoodsOrder {
  onDeleteTemporaryGoodsOrder {
    LockMode
    address
    cardBrand
    cardId
    cardName
    cardNo
    cartId
    city
    createdAt
    deliveryId
    deliveryPrice
    discount
    expriesAt
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    subAddress
    totalPrice
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnDeleteTemporaryGoodsOrderSubscription__
 *
 * To run a query within a React component, call `useOnDeleteTemporaryGoodsOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteTemporaryGoodsOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteTemporaryGoodsOrderSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDeleteTemporaryGoodsOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteTemporaryGoodsOrderSubscription, OnDeleteTemporaryGoodsOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteTemporaryGoodsOrderSubscription, OnDeleteTemporaryGoodsOrderSubscriptionVariables>(OnDeleteTemporaryGoodsOrderDocument, options);
      }
export type OnDeleteTemporaryGoodsOrderSubscriptionHookResult = ReturnType<typeof useOnDeleteTemporaryGoodsOrderSubscription>;
export type OnDeleteTemporaryGoodsOrderSubscriptionResult = Apollo.SubscriptionResult<OnDeleteTemporaryGoodsOrderSubscription>;
export const OnDeleteUserDocument = gql`
    subscription OnDeleteUser($id: String) {
  onDeleteUser(id: $id) {
    createdAt
    familyName
    givenName
    id
    mail
    stripeId
    updatedAt
  }
}
    `;

/**
 * __useOnDeleteUserSubscription__
 *
 * To run a query within a React component, call `useOnDeleteUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteUserSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnDeleteUserSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteUserSubscription, OnDeleteUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteUserSubscription, OnDeleteUserSubscriptionVariables>(OnDeleteUserDocument, options);
      }
export type OnDeleteUserSubscriptionHookResult = ReturnType<typeof useOnDeleteUserSubscription>;
export type OnDeleteUserSubscriptionResult = Apollo.SubscriptionResult<OnDeleteUserSubscription>;
export const OnUpdateCardDocument = gql`
    subscription OnUpdateCard($userId: String) {
  onUpdateCard(userId: $userId) {
    brand
    cardNo
    createdAt
    deadline
    id
    name
    stripeCardId
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnUpdateCardSubscription__
 *
 * To run a query within a React component, call `useOnUpdateCardSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateCardSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateCardSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUpdateCardSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateCardSubscription, OnUpdateCardSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateCardSubscription, OnUpdateCardSubscriptionVariables>(OnUpdateCardDocument, options);
      }
export type OnUpdateCardSubscriptionHookResult = ReturnType<typeof useOnUpdateCardSubscription>;
export type OnUpdateCardSubscriptionResult = Apollo.SubscriptionResult<OnUpdateCardSubscription>;
export const OnUpdateDeliveryDocument = gql`
    subscription OnUpdateDelivery($userId: String) {
  onUpdateDelivery(userId: $userId) {
    address
    city
    createdAt
    familyName
    givenName
    id
    phoneNumber
    postCode
    provinceCode
    subAddress
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnUpdateDeliverySubscription__
 *
 * To run a query within a React component, call `useOnUpdateDeliverySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateDeliverySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateDeliverySubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUpdateDeliverySubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateDeliverySubscription, OnUpdateDeliverySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateDeliverySubscription, OnUpdateDeliverySubscriptionVariables>(OnUpdateDeliveryDocument, options);
      }
export type OnUpdateDeliverySubscriptionHookResult = ReturnType<typeof useOnUpdateDeliverySubscription>;
export type OnUpdateDeliverySubscriptionResult = Apollo.SubscriptionResult<OnUpdateDeliverySubscription>;
export const OnUpdateGoodsCartDocument = gql`
    subscription OnUpdateGoodsCart($userId: String) {
  onUpdateGoodsCart(userId: $userId) {
    cardId
    createdAt
    deliveryId
    deliveryPrice
    discount
    id
    items
    orderState
    orderType
    paymentId
    totalPrice
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnUpdateGoodsCartSubscription__
 *
 * To run a query within a React component, call `useOnUpdateGoodsCartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateGoodsCartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateGoodsCartSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUpdateGoodsCartSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateGoodsCartSubscription, OnUpdateGoodsCartSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateGoodsCartSubscription, OnUpdateGoodsCartSubscriptionVariables>(OnUpdateGoodsCartDocument, options);
      }
export type OnUpdateGoodsCartSubscriptionHookResult = ReturnType<typeof useOnUpdateGoodsCartSubscription>;
export type OnUpdateGoodsCartSubscriptionResult = Apollo.SubscriptionResult<OnUpdateGoodsCartSubscription>;
export const OnUpdateGoodsOrderDocument = gql`
    subscription OnUpdateGoodsOrder($userId: String) {
  onUpdateGoodsOrder(userId: $userId) {
    address
    buyState
    cardBrand
    cardName
    cardNo
    city
    createdAt
    deliveryPrice
    discount
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    stateByDate
    subAddress
    totalPrice
    trackingNumber
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnUpdateGoodsOrderSubscription__
 *
 * To run a query within a React component, call `useOnUpdateGoodsOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateGoodsOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateGoodsOrderSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUpdateGoodsOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateGoodsOrderSubscription, OnUpdateGoodsOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateGoodsOrderSubscription, OnUpdateGoodsOrderSubscriptionVariables>(OnUpdateGoodsOrderDocument, options);
      }
export type OnUpdateGoodsOrderSubscriptionHookResult = ReturnType<typeof useOnUpdateGoodsOrderSubscription>;
export type OnUpdateGoodsOrderSubscriptionResult = Apollo.SubscriptionResult<OnUpdateGoodsOrderSubscription>;
export const OnUpdateKintoneCollectionDocument = gql`
    subscription OnUpdateKintoneCollection {
  onUpdateKintoneCollection {
    audience
    createdAt
    id
    isDisplayFlg
    precedence
    productId
    salesState
    salesType
    thumbnail
    title
    updatedAt
    wpId
  }
}
    `;

/**
 * __useOnUpdateKintoneCollectionSubscription__
 *
 * To run a query within a React component, call `useOnUpdateKintoneCollectionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateKintoneCollectionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateKintoneCollectionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateKintoneCollectionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateKintoneCollectionSubscription, OnUpdateKintoneCollectionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateKintoneCollectionSubscription, OnUpdateKintoneCollectionSubscriptionVariables>(OnUpdateKintoneCollectionDocument, options);
      }
export type OnUpdateKintoneCollectionSubscriptionHookResult = ReturnType<typeof useOnUpdateKintoneCollectionSubscription>;
export type OnUpdateKintoneCollectionSubscriptionResult = Apollo.SubscriptionResult<OnUpdateKintoneCollectionSubscription>;
export const OnUpdateKintoneProductsDocument = gql`
    subscription OnUpdateKintoneProducts {
  onUpdateKintoneProducts {
    audience
    createdAt
    description
    id
    images
    inventoryState
    isDisplayFlg
    jancode
    limitOrderBuy
    productId
    productTitle
    productType
    releaseDate
    salesState
    sku
    sortNumber
    tax
    taxExcludePrice
    taxInRegularPrice
    taxIncludePrice
    taxRate
    thumbnail
    transferPrice
    updatedAt
    variation
    variationTitle
  }
}
    `;

/**
 * __useOnUpdateKintoneProductsSubscription__
 *
 * To run a query within a React component, call `useOnUpdateKintoneProductsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateKintoneProductsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateKintoneProductsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateKintoneProductsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateKintoneProductsSubscription, OnUpdateKintoneProductsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateKintoneProductsSubscription, OnUpdateKintoneProductsSubscriptionVariables>(OnUpdateKintoneProductsDocument, options);
      }
export type OnUpdateKintoneProductsSubscriptionHookResult = ReturnType<typeof useOnUpdateKintoneProductsSubscription>;
export type OnUpdateKintoneProductsSubscriptionResult = Apollo.SubscriptionResult<OnUpdateKintoneProductsSubscription>;
export const OnUpdateKintoneProductsStockDocument = gql`
    subscription OnUpdateKintoneProductsStock {
  onUpdateKintoneProductsStock {
    audience
    createdAt
    id
    inventory
    updatedAt
  }
}
    `;

/**
 * __useOnUpdateKintoneProductsStockSubscription__
 *
 * To run a query within a React component, call `useOnUpdateKintoneProductsStockSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateKintoneProductsStockSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateKintoneProductsStockSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateKintoneProductsStockSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateKintoneProductsStockSubscription, OnUpdateKintoneProductsStockSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateKintoneProductsStockSubscription, OnUpdateKintoneProductsStockSubscriptionVariables>(OnUpdateKintoneProductsStockDocument, options);
      }
export type OnUpdateKintoneProductsStockSubscriptionHookResult = ReturnType<typeof useOnUpdateKintoneProductsStockSubscription>;
export type OnUpdateKintoneProductsStockSubscriptionResult = Apollo.SubscriptionResult<OnUpdateKintoneProductsStockSubscription>;
export const OnUpdateOrderDocument = gql`
    subscription OnUpdateOrder($userId: String) {
  onUpdateOrder(userId: $userId) {
    article
    buyState
    cost
    createdAt
    id
    itemId
    thumbnailUrl
    title
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnUpdateOrderSubscription__
 *
 * To run a query within a React component, call `useOnUpdateOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateOrderSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUpdateOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateOrderSubscription, OnUpdateOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateOrderSubscription, OnUpdateOrderSubscriptionVariables>(OnUpdateOrderDocument, options);
      }
export type OnUpdateOrderSubscriptionHookResult = ReturnType<typeof useOnUpdateOrderSubscription>;
export type OnUpdateOrderSubscriptionResult = Apollo.SubscriptionResult<OnUpdateOrderSubscription>;
export const OnUpdateTemporaryGoodsOrderDocument = gql`
    subscription OnUpdateTemporaryGoodsOrder {
  onUpdateTemporaryGoodsOrder {
    LockMode
    address
    cardBrand
    cardId
    cardName
    cardNo
    cartId
    city
    createdAt
    deliveryId
    deliveryPrice
    discount
    expriesAt
    familyName
    givenName
    id
    items
    orderType
    phoneNumber
    postCode
    provinceCode
    specialItem
    subAddress
    totalPrice
    updatedAt
    userId
  }
}
    `;

/**
 * __useOnUpdateTemporaryGoodsOrderSubscription__
 *
 * To run a query within a React component, call `useOnUpdateTemporaryGoodsOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateTemporaryGoodsOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateTemporaryGoodsOrderSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateTemporaryGoodsOrderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateTemporaryGoodsOrderSubscription, OnUpdateTemporaryGoodsOrderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateTemporaryGoodsOrderSubscription, OnUpdateTemporaryGoodsOrderSubscriptionVariables>(OnUpdateTemporaryGoodsOrderDocument, options);
      }
export type OnUpdateTemporaryGoodsOrderSubscriptionHookResult = ReturnType<typeof useOnUpdateTemporaryGoodsOrderSubscription>;
export type OnUpdateTemporaryGoodsOrderSubscriptionResult = Apollo.SubscriptionResult<OnUpdateTemporaryGoodsOrderSubscription>;
export const OnUpdateUserDocument = gql`
    subscription OnUpdateUser($id: String) {
  onUpdateUser(id: $id) {
    createdAt
    familyName
    givenName
    id
    mail
    stripeId
    updatedAt
  }
}
    `;

/**
 * __useOnUpdateUserSubscription__
 *
 * To run a query within a React component, call `useOnUpdateUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateUserSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnUpdateUserSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateUserSubscription, OnUpdateUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateUserSubscription, OnUpdateUserSubscriptionVariables>(OnUpdateUserDocument, options);
      }
export type OnUpdateUserSubscriptionHookResult = ReturnType<typeof useOnUpdateUserSubscription>;
export type OnUpdateUserSubscriptionResult = Apollo.SubscriptionResult<OnUpdateUserSubscription>;