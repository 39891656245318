import { Typography, Grid, Hidden, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';
import axios from 'axios';
import React, { FC, useState, useEffect } from 'react';
// import { useParams } from 'react-router';
// import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Timeline } from 'react-twitter-widgets';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import Breadcrumbs from 'HTB-Aircaravan2021/components/Common/Breadcrumbs';
import DRMplayer from 'HTB-Aircaravan2021/components/Common/DRMplayer';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import THEOPlayer from 'HTB-Aircaravan2021/components/Common/THEOPlayer';
// import LiveSalesArea from 'HTB-Aircaravan2021/components/LiveSalesArea/Container/LiveSalesArea';
// import { isAuthenticated } from 'Auth0LockContext';
import TwitterArea from 'HTB-Aircaravan2021/components/Twitter/Container/TwitterArea';
import bg from 'HTB-Aircaravan2021/images/bg.gif';
import { wpParentContentData } from 'HTB-Aircaravan2021/utils/WPRequest';

// youtube動画の比固定
const StyledYoutube = styled.div`
   {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0px;
    position: relative;
    margin-bottom: 1.5rem;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const StyledBox = styled(Box)`
  background: #ffffff url(${bg}) space repeat;
`;

type ResponseData = {
  callsign: string;
};

// チケット販売ページ
const EventPage: FC = () => {
  // const { parentId } = useParams();

  const [isEventLoading, setIsEventLoading] = useState(false);
  const [isEventError, setIsEventError] = useState(false);
  const [eventContents, setEventContents] = useState<wpParentContentData>();
  const theme = useTheme();
  const isPCLayout = useMediaQuery(theme.breakpoints.up('md'));
  const refetchInterval = Number(process.env.REACT_APP_VIEWS_COUNT_POLLING_INTERVAL ?? 60000);
  const TwitterTimelineDisplayFlg: string = process.env.REACT_APP_TWITTER_TIMELINE_DISPLAY_FLG ?? '1';

  // イベントデータをWPから取得
  useEffect(() => {
    const getEventContents = async () => {
      setIsEventError(false);
      setIsEventLoading(true);
      try {
        const response = await axios.get(
          `https://dxjmemwogeqvm.cloudfront.net/wp-json/wp/v2/${process.env.REACT_APP_WP_AIRCARAVAN ?? ''}/${
            process.env.REACT_APP_WP_AIRCARAVAN_ARTICLE_ID ?? ''
          }`,
          {
            params: {
              _fields: 'acf,id,title',
            },
          },
        );
        setEventContents(response.data ?? '');
      } catch (err) {
        setIsEventError(true);
      }
      setIsEventLoading(false);
    };
    void getEventContents();
  }, []);

  // 再生数カウント用データ取得
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: testContent } = useQuery<ResponseData>(
    ['getTest', 'all'],
    async () => {
      const { data } = await axios.get<ResponseData>(`https://d251kven8s8ayd.cloudfront.net/6ch.json`);

      return data;
    },
    { refetchInterval, refetchOnWindowFocus: false, staleTime: 50000 },
  );

  return (
    <>
      {/* パンクズリスト */}
      <Breadcrumbs
        breads={[
          {
            to: '/',
            icon: <HomeIcon />,
            text: 'HOME',
          },
          {
            icon: <ListAltIcon />,
            text: eventContents?.title.rendered ?? '',
          },
        ]}
        substrLength={16}
      />
      <br />
      {isEventError && (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" align="center" color="error">
              エラーが発生しました
              <br />
            </Typography>
          </Grid>
        </Grid>
      )}
      {isEventLoading ? (
        <>
          <Loading loadingCode="Load_002" />
        </>
      ) : (
        <>
          <Grid container spacing={2} alignItems="center" justify="center">
            {eventContents && (
              <>
                {/* <Grid item xs={12} sm={12} md={10}>
                  <Typography gutterBottom variant="h2" align="center" color="inherit">
                    {eventContents.title.rendered}
                  </Typography>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12} lg={11}>
                  <Grid container spacing={3} style={{ paddingBottom: 10 }}>
                    {eventContents.acf.viewMode === 'live' || eventContents.acf.viewMode === 'archive' ? (
                      <>
                        {/* <Grid item xs={12}> */}
                        {eventContents.acf.isYouTubeContent ? (
                          <Grid item xs={12} sm={12} md={8}>
                            <StyledYoutube>
                              <YouTube videoId={eventContents.acf.youtubeVideoId} />
                            </StyledYoutube>
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs={12} sm={12} md={8} style={{ overflow: 'hidden' }}>
                              {eventContents.acf.sampleIsDRM ? (
                                <DRMplayer
                                  srcDASH={eventContents?.acf?.sampleSrcDASH ?? ''}
                                  srcHLS={eventContents?.acf?.sampleSrcHLS}
                                />
                              ) : (
                                <THEOPlayer
                                  source={{
                                    sources: [
                                      {
                                        src: eventContents.acf.sampleSrcHLS ?? '',
                                        type: 'application/x-mpegurl',
                                      },
                                    ],
                                    poster: eventContents.acf.thumbnailURL,
                                  }}
                                  onPlay={() => {
                                    // console.log('The player has started playing.');
                                  }}
                                />
                              )}
                            </Grid>
                          </>
                        )}
                        <Hidden smDown>
                          {TwitterTimelineDisplayFlg === '0' ? (
                            <Grid item xs={4}>
                              <TwitterArea isPCLayout={isPCLayout} maxHeightForTwitterArea={450} />
                              {/* <TwitterArea3Test isPCLayout={isPCLayout} /> */}
                            </Grid>
                          ) : (
                            <>
                              <Grid item xs={4}>
                                <StyledBox
                                  border={1}
                                  height="100%"
                                  borderColor="#CDF3F9"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  // style={{ backgroundColor: '#CDF3F9' }}
                                >
                                  <Box flexGrow={1}>
                                    <Timeline
                                      dataSource={{
                                        sourceType: 'profile',
                                        screenName: 'SD_UNITE2013',
                                      }}
                                      options={{
                                        height: '400',
                                        // width: '100%',
                                      }}
                                    />
                                  </Box>
                                </StyledBox>
                              </Grid>
                            </>
                          )}
                        </Hidden>
                        {/* </Grid> */}
                      </>
                    ) : (
                      <StyledYoutube>
                        <img alt="event" src={eventContents.acf.thumbnailURL} />
                      </StyledYoutube>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {/* 商品表示エリア */}
            {/* <Grid item xs={12}>
              <LiveSalesArea
                title={eventContents?.title?.rendered ?? ''}
                viewMode={eventContents?.acf?.viewMode ?? undefined}
                isPCLayout={isPCLayout}
              />
            </Grid> */}
          </Grid>
        </>
      )}
    </>
  );
};

export default EventPage;
