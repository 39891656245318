// 入力した住所の確認画面
import { Grid, TextField, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import prefLists from 'HTB-Aircaravan2021/utils/ProvinceList';
import { FormData } from './AddressForm';

export type Props = {
  /**
   * 入力した住所情報
   */
  addressData?: FormData;
};

const DeliveryConfirmation: FC<Props> = ({ addressData }) => {
  // 都道府県コードを都道府県名に変換
  const provinceName = prefLists.find((n) => n.id === addressData?.provinceCode ?? '')?.name;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom align="left" variant="h2" color="inherit">
            配送先情報内容確認画面
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="姓"
            value={addressData?.familyName}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="名"
            value={addressData?.givenName}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="電話番号"
            value={addressData?.phoneNumber}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="郵便番号"
            value={addressData?.postCode}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="都道府県"
            value={provinceName}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="市区町村"
            value={addressData?.city}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="町番地号"
            value={addressData?.address}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="マンション部屋番号"
            value={addressData?.subAddress}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DeliveryConfirmation;
