import jwtDecode from 'jwt-decode';
import { createContext } from 'react';

interface profileJson {
  email?: string;
  picture?: string;
  name?: string;
  sub?: string;
  aud?: string;
  // eslint-disable-next-line camelcase
  family_name?: string;
  // eslint-disable-next-line camelcase
  given_name?: string;
}

type client = {
  client: Auth0LockPasswordlessStatic;
  isAuth: boolean;
  lockActionId: string;
  setLockActionId: (lockAcionId: string) => void;
};

/**
 * Auth0Clientを返す
 */
const auth0LockContext = createContext<client>({} as client);
export default auth0LockContext;

/**
 * localstorageよりセッション状態を取得
 *   true = ログイン中
 */
export const isAuthenticated = (): boolean => {
  const expiresAt = Number(localStorage.getItem('expires_at') ?? '0');

  return new Date().getTime() < expiresAt;
};

/**
 * Auth0 Profileを取得
 */
export const getAuthProfile = (): profileJson => {
  try {
    return jwtDecode<profileJson>(localStorage.getItem('id_token') ?? '');
  } catch {
    return {};
  }
};
