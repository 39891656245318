import jwtDecode from 'jwt-decode';
import React, { FC, useState } from 'react';
import Logger from 'utils/Logger';
import Auth0LockContext from './Auth0LockContext';

type Props = {
  client: Auth0LockPasswordlessStatic;
};

interface profileJson {
  email?: string;
  picture?: string;
  name?: string;
  exp?: string;
}

const Auth0LockProvider: FC<Props> = ({ client, children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [lockActionId, setLockActionId] = useState('');
  /**
   * Auth0のイベント登録
   * ログイン後セッションデータをlocalstorageに格納
   */
  client.on('authenticated', (authResult) => {
    // ユーザデータを使う場合
    /*
    client.getUserInfo(authResult.accessToken, (error, profileResult) => {
      if (error) {
        return;
      }
    });
    */
    // ルーティング処理
    // console.log(authResult.state);
    if (authResult.state) setLockActionId(authResult.state);
    // console.log('state');
    // console.log(authResult.state);

    // プロフィール取得
    const profile = jwtDecode<profileJson>(authResult.idToken);
    // console.log(profile.email);
    // console.log(profile.name);
    // console.log('profile.exp');
    // console.log(profile.exp);

    // localstorage保存
    const expiresAt = `${profile.exp ?? ''}000`;
    /*
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime(),
    );
    */

    // localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('photo_url', profile.picture ?? '');
    setIsAuth(true);

    Logger.debug({ msg: 'User logged in', data: authResult });
  });

  return (
    <Auth0LockContext.Provider value={{ client, isAuth, lockActionId, setLockActionId }}>
      {children}
    </Auth0LockContext.Provider>
  );
};

export default Auth0LockProvider;
