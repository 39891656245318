import { Grid, Button, Typography } from '@material-ui/core/';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { getAuthProfile, isAuthenticated } from 'Auth0LockContext';
import Message from 'HTB-Videos/components/Common/Message';
import LiveSalesArea from 'HTB-Videos/components/LiveSalesArea/LiveSalesArea';
import Cart from 'HTB-Videos/components/LiveSalesArea/pages/Cart/Cart';
import { itemList } from 'HTB-Videos/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';
import ProductsListKintone from 'HTB-Videos/components/LiveSalesArea/pages/ProductList/ProductsList';
import useGetKintoneCollection from 'HTB-Videos/hooks/useGetKintoneCollection';
import { ViewMode } from 'HTB-Videos/utils/WPRequest';
import {
  useCreateGoodsCartMutation,
  GoodsCart,
  useGetKintoneCollectionLazyQuery,
  useGetGoodsCartLazyQuery,
} from 'graphql/appsync/generated';

/**
 *  SalesArea表示内容
 * products:        販売商品を表示
 * cart:            カート情報を表示
 */
export type PageStatesType = 'products' | 'cart';

/**
 * コレクションテーブル内のプロダクトID
 */
export type ProductIdJson = {
  productId: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    center: {
      textAlign: 'center',
    },
  }),
);

export type Props = {
  /**
   * セールスエリアヘッダーに表示するタイトル
   */
  title: string;
  /**
   * wp親記事のviewMode
   * salesAreaの販売終了メッセージ表示
   * カート確認ボタン、ヘッダーアイコンの非活性時のフラグで利用
   */
  viewMode?: ViewMode;
};

// 購入に進む系のボタンの色はオレンジ
const StyledButton = styled(Button)`
  background-color: #ff9100 !important;
`;

/**
 * 商品販売に関する一連のフォームを表示するコンポーネント
 * pageStateの値により表示する内容を切り替える
 */
const EnhancedLiveSalesArea: FC<Props> = ({ title, viewMode }) => {
  const { parentId, childId } = useParams();
  const classes = useStyles();
  // 現在の表示内容を保持するState
  // Auth0ログイン画面からのコールバック時、初期表示をカート画面にする
  const [pageState, setPageState] = useState<PageStatesType>(localStorage.getItem('auth_show') ? 'cart' : 'products');
  // LocalStorageにGoodsCartIDが設定されたか否かを保持するState
  const [isLocalCartIdStorageSetting, setIsLocalCartIdStorageSetting] = useState(false);
  // 表示する商品のリスト
  const [productIds, setProductIds] = useState<string[]>([]);

  // 一時的に未認証処理部分をコメントアウト
  // --------------------------- APIGateway経由でデータを更新・取得するhooks(未認証 (未ログイン時) 用) -------------------
  // const {
  //   kintoneCollection: collectionFromAPIGateway,
  //   isError: isAPIGatewayError,
  //   isLoading: isAPIGatewayLoading,
  // } = useGetKintoneCollection(parentId);

  // -------------------------------------------------------------------------------------------------------------

  // --------------------------- Appsync経由でデータを更新・取得するhooks(認証済み（ログイン時）用) -----------------------
  // GoodsCartIDを発行するhooks
  const [
    goodsCartIdMutation,
    { data: goodsCartIdMutationData, loading: isGoodsCartIdLoading, error: goodsCartIdMutationError },
  ] = useCreateGoodsCartMutation({
    variables: {
      input: {
        userId: getAuthProfile().sub ?? '',
        // items: '[]',
      },
    },
  });

  // kintoneCollectionテーブルの情報を取得するhooks
  const [getCollection, { data: collectionData, loading: collectionDataLoading }] = useGetKintoneCollectionLazyQuery({
    variables: {
      // id: `${parentId}`,
      id: `${childId}`,
    },
  });

  // GoodsCartテーブルの情報を取得するhooks
  // localstrageにgoodsCartIDなければgoodsCartIdMutationDataからID取得、それでもなければ空で渡す
  const [getGoodsCart, { data: goodsCartData, loading: isGoodsCartDataLoading, error: goodsCartDataError }] =
    useGetGoodsCartLazyQuery({
      variables: { id: localStorage.getItem('goodsCartId') ?? goodsCartIdMutationData?.createGoodsCart?.id ?? '' },
    });
  useEffect(() => {
    // ApolloErrorが発生しネットワークが起因じゃない場合は再発番
    if (goodsCartDataError && goodsCartDataError.networkError === null) {
      void goodsCartIdMutation().then((response) => {
        if (response?.data?.createGoodsCart?.id) {
          localStorage.setItem('goodsCartId', response?.data?.createGoodsCart?.id);
        }
      });
    }
  }, [goodsCartDataError, goodsCartIdMutation]);

  // -------------------------------------------------------------------------------------------------------------

  // --------------------------- レンダリング時に実行される処理 -------------------------------------------------------
  // goodsCartIdを発行する処理
  useEffect(() => {
    // 以下の二つの条件を満たす場合カートIDを発行する
    // ※goodsCartIdCreateStart: カートIDの発行を開始した時刻 カートID作成処理が正常終了した場合LocasStarageから削除される
    // 1. ログイン済みである
    // 2. LocasStrageにgoodsCartIdとgoodsCartIdCreateStartが無い（初めてこのページに訪れたユーザー）
    //    または現在LocalStrageに設定されているカートIDの決済状態がCompleteである（決済を終えて戻ってきたユーザー）
    //    またはgoodsCartIdCreateStartが現在時刻と比較して5秒以上前である（一度カートID作成処理を実行したが失敗に終わったユーザー）
    //    またはLocalStrageに保持してあるカートIDでカート情報を取得できない（カートID発行後ログアウトして別アカウントでログインしたユーザー）
    if (
      isAuthenticated() &&
      ((!localStorage.getItem('goodsCartId') && !localStorage.getItem('goodsCartIdCreateStart')) ||
        goodsCartData?.getGoodsCart?.orderState === 'Complete' ||
        (localStorage.getItem('goodsCartIdCreateStart') &&
          Date.now() - Number(localStorage.getItem('goodsCartIdCreateStart')) > 5000) ||
        (localStorage.getItem('goodsCartId') && goodsCartDataError))
    ) {
      void localStorage.setItem('goodsCartIdCreateStart', Date.now().toString());
      void localStorage.removeItem('goodsCartId');
      void goodsCartIdMutation().then((response) => {
        if (response?.data?.createGoodsCart?.id) {
          localStorage.setItem('goodsCartId', response?.data?.createGoodsCart?.id);
          localStorage.removeItem('goodsCartIdCreateStart');
        }
      });
    } else {
      setIsLocalCartIdStorageSetting(true);
    }
  }, [goodsCartIdMutation, goodsCartData?.getGoodsCart?.orderState, goodsCartDataError]);

  // 一時的に未認証処理部分をコメントアウト
  // 表示する商品のリストを認証状態に合わせて設定する
  // useEffect(() => {
  //   if (isAuthenticated()) {
  //     setProductIds(
  //       (JSON.parse(collectionData?.getKintoneCollection?.productId ?? '[]') as { productId: string }[]).map(
  //         (id) => id.productId,
  //       ),
  //     );
  //   } else {
  //     setProductIds(collectionFromAPIGateway ?? []);
  //   }
  // }, [collectionData, collectionFromAPIGateway]);

  useEffect(() => {
    // if (isAuthenticated()) {
    setProductIds(
      (JSON.parse(collectionData?.getKintoneCollection?.productId ?? '[]') as { productId: string }[]).map(
        (id) => id.productId,
      ),
    );
    // }
  }, [collectionData]);

  // ログイン済みの場合、カート情報とコレクション情報を取得する
  useEffect(() => {
    if (isAuthenticated()) {
      void getCollection();
      void getGoodsCart();
    }
  }, [getCollection, getGoodsCart]);

  // --------------------------------------------------------------------------------------------------------------

  // goodsCartテーブルのitemsをJsonパース
  const Items =
    isGoodsCartDataLoading || (goodsCartData?.getGoodsCart?.items?.length ?? 0) < 1
      ? undefined
      : (JSON.parse(goodsCartData?.getGoodsCart?.items as string) as itemList[]);

  const salesArea = () => {
    if (pageState === 'products') {
      return (
        <>
          {viewMode && viewMode === 'close' && (
            <>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={6} md={3}>
                  <Typography
                    align="center"
                    variant="subtitle1"
                    color="error"
                    style={{ marginTop: '1vw', paddingTop: '1vw', borderBottom: '2px solid' }}
                  >
                    販売を終了しました
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <ProductsListKintone
            productIds={productIds}
            isLoading={collectionDataLoading}
            goodsCartData={goodsCartData?.getGoodsCart as GoodsCart}
          />

          {/* <Button variant="contained" color="primary" onClick={() => setPageState('cart')} size="large"> */}
          <Grid container justify="center" alignItems="center">
            <Grid item xs={10} md={6}>
              <StyledButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setPageState('cart')}
                disabled={viewMode === 'close'}
              >
                <Typography align="center" variant="subtitle1">
                  カートを確認
                </Typography>
              </StyledButton>
            </Grid>
          </Grid>
          {/* </Button> */}
        </>
      );
    }
    if (pageState === 'cart') {
      return (
        <Cart
          previousPagingFunction={() => setPageState('products')}
          goodsCart={isGoodsCartDataLoading ? undefined : (goodsCartData?.getGoodsCart as GoodsCart)}
          isLoading={collectionDataLoading}
          Items={Items as itemList[]}
        />
      );
    }

    return <></>;
  };

  // Graphqlがエラー
  // 一時的に未認証処理部分をコメントアウト
  // if (goodsCartDataError || goodsCartIdMutationError || isAPIGatewayError)
  if (goodsCartDataError || goodsCartIdMutationError)
    return <Message messageCode="ERR_000301" className={classes.center} />;

  return (
    <>
      {(productIds?.length ?? 0) > 0 && (
        <LiveSalesArea
          isLoading={
            // 一時的に未認証処理部分をコメントアウト
            // collectionDataLoading || isGoodsCartIdLoading || !isLocalCartIdStorageSetting || isAPIGatewayLoading
            collectionDataLoading || isGoodsCartIdLoading || !isLocalCartIdStorageSetting
          }
          itemCount={
            goodsCartData && (Items?.length ?? 0) > 0 && viewMode && viewMode !== 'close'
              ? (Items?.length as number)
              : 0
          }
          productButtonOnClickFunction={() => setPageState('products')}
          cartButtonOnClickFunction={() => setPageState('cart')}
          title={title}
          viewMode={viewMode}
        >
          {salesArea()}
        </LiveSalesArea>
      )}
    </>
  );
};

export default EnhancedLiveSalesArea;
