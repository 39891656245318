import { ThemeProvider, createMuiTheme, Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LinkHref from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import ClearIcon from '@material-ui/icons/Clear';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import React, { FC, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Auth0LockContext, { isAuthenticated } from 'Auth0LockContext';
// import BorderLineImg from './BaseLayout.borderLine.png';
import LogoutIcon from '../images/ico_logout.png';
import HomeButtonImg from './HomeLogo.png';
import initialTheme from './MuiTheme';

// StoryBookからも読み込むためexport
export const GlobalCSS = styled.div`
  font-size: 16px;
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.5;

  a {
    text-decoration: none;
  }

  a:hover {
    opacity: 0.5;
    transition: 0.5s;
  }
`;

const Wrap = styled.div`
  // background-color: #ffe113;
  // background-color: #111;
  min-height: 100vh;
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 96%;
  // max-width: 1095px;
  max-width: 1600px;
  margin: 0px auto;
  // border-top: #fff solid 25px;
  // border-top: #111 solid 25px;
`;

const StyledHomeButton = styled.img`
  max-width: 160px;
  //max-height: 120%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  justify-content: center;
  //text-align: center;
  //margin-left: auto;
  //margin-right: auto;
  //margin: 0 auto;
`;

const StyledLogoutIcon = styled.img`
  margin-bottom: 16px;
  height: 60px;
  justify-content: center;
`;

const StyleClearIcon = styled.div`
  margin-left: 145px;
`;

const Section = styled.div`
  // background: #fff;
  // background: #111;
  padding: 20px;
  @media screen and (max-width: 751px) {
    padding: 10px;
  }
`;

const Layout1 = styled.div`
  width: 20%;
  margin-left: auto;
`;

const Layout2 = styled.div`
  width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
`;

const Layout3 = styled.div`
  width: 20%;
  //text-aligin: center;
`;

const InnerBorder = styled.div`
  // background: #fff;
  // max-width: 1095px;
  // background: #111;
  max-width: 1600px;
  margin: 20px auto 20px;
  // border: 10px solid #c69e61;
  // border: 10px solid #111;
  padding: 20px;

  @media screen and (max-width: 751px) {
     {
      padding: 10px;
    }
  }
`;

const drawerWidth = 180;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
    },
    appBar: {
      width: `100%`,
    },
    humanButton: {
      width: `100%`,
      fontSize: 'large',
    },
    menuButton: {
      width: `100%`,
      fontSize: 'large',
      marginRight: 'auto',
      marginTop: '3px',
    },
    AvatarButton: {
      width: `100%`,
      fontSize: 'large',
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      // background: '#111',
      // color: '#fff',
      textDecoration: 'none',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      marginLeft: 'auto',
      marginRight: '18%',
      width: '30%',
      height: 'auto',
      cursor: 'pointer',
      top: '10px',
    },
    footer: {
      margin: 'auto',
      height: '40px',
      width: '100%',
      textAlign: 'center',
      // color: 'inherit',
      // backgroundColor: '#333',
    },
    footerText: {
      lineHeight: '40px',
      fontSize: 'small',
      color: 'inherit',
      // color: '#fafafa',
    },
    paper: {
      display: 'block',
      marginRight: '-50%',
      position: 'absolute',
      maxWidth: '300px',
      WebkitJustifyContent: 'center',
      justifyContent: 'center',
      WebkitAlignItems: 'center',
      // backgroundColor: '#252d32',
      backgroundColor: theme.palette.primary.main,
      border: '2px solid #fff',
      boxShadow: theme.shadows[2],
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      outline: 'none',
      paddingBottom: '32px',
      paddingTop: '32px',
      paddingLeft: '20px',
      paddingRight: '20px',
      borderRadius: '16px',
    },
    modalLayout: {
      textAlign: 'center',
      marginLeft: '10px',
      marginRight: '10px',
      color: theme.palette.secondary.main,
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  }),
);

/**
 * ベースとなるレイアウトを提供するコンポーネント
 */
const BaseLayout: FC = ({ children }) => {
  const { pathname } = useLocation();

  // Auth0関係
  const auth0Lock = useContext(Auth0LockContext);
  const login = () => {
    auth0Lock.client.show({
      auth: {
        params: {
          state: `${pathname}`,
        },
      },
    });
  };

  const logout = () => {
    // localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('photo_url');
    auth0Lock.client.logout({
      returnTo: `${window.location.protocol}//${window.location.host}`,
    });
  };

  // フッターとヘッダー関係（マテリアルUI）
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handlecloceDrawer = () => {
    setMobileOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
    setMobileOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Material-ui モード設定（ロジック）
  const [darkMode, setDarkMode] = useState(false);

  const handleDarkModeOn = () => {
    localStorage.setItem('darkMode', 'on');
    setDarkMode(true);
  };

  const handleDarkModeOff = () => {
    localStorage.setItem('darkMode', 'off');
    setDarkMode(false);
  };

  useEffect(() => {
    if (localStorage.getItem('darkMode') === 'on') {
      setDarkMode(true);
    } else if (localStorage.getItem('darkMode') === 'off') {
      setDarkMode(false);
    }
    // OSのテーマ設定をみてモード切り替える
    else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  // material-ui カスタムテーマ設定
  const theme = createMuiTheme({
    ...initialTheme,
    // 色の設定 ライト・ダークモードで色出し分ける
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? initialTheme.palette.primary.dark : initialTheme.palette.primary.light,
      },
      secondary: {
        main: darkMode ? initialTheme.palette.secondary.dark : initialTheme.palette.secondary.light,
      },
      error: {
        main: darkMode ? initialTheme.palette.error.dark : initialTheme.palette.error.light,
      },
    },
  });

  const drawer = (
    <div>
      <StyleClearIcon>
        <div>
          <ClearIcon
            aria-label="閉じる"
            fontSize="large"
            aria-controls="tag-x"
            aria-haspopup="false"
            onClick={handleDrawerToggle}
            color="inherit"
            cursor="pointer"
          />
        </div>
      </StyleClearIcon>
      <Divider />
      <List>
        <ListItem component={Link} to="/qa" onClick={handlecloceDrawer} className={classes.link}>
          <ListItemText primary="購入をお考えの方へ" />
        </ListItem>
      </List>
      <List>
        <ListItem onClick={handlecloceDrawer}>
          <a
            href="https://www.htb.co.jp/htb/torikumi/terms.html"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.link}
          >
            <ListItemText primary="利用規約" text-decoration="none" />
          </a>
        </ListItem>
      </List>
      <List>
        <ListItem component={Link} to="/Environment" onClick={handlecloceDrawer} className={classes.link}>
          <ListItemText primary="動作環境について" />
        </ListItem>
      </List>
      <List>
        <ListItem onClick={handlecloceDrawer}>
          <a
            href="https://www.htb.co.jp/htb/torikumi/businessdeal.html"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.link}
          >
            <ListItemText primary="特定商取引に関する法律に基づく表記" text-decoration="none" />
          </a>
        </ListItem>
      </List>
      <List>
        <ListItem onClick={handlecloceDrawer}>
          <a
            href="https://www.htb.co.jp/htb/torikumi/copyright.html"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.link}
          >
            <ListItemText primary="知的財産権に関して" text-decoration="none" />
          </a>
        </ListItem>
      </List>
      <List>
        <ListItem onClick={handlecloceDrawer}>
          <a
            href="https://www.htb.co.jp/htb/privacy/htb_privacy.html"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.link}
          >
            <ListItemText primary="個人情報の取り扱いについて" text-decoration="none" />
          </a>
        </ListItem>
      </List>
      <List>
        <ListItem component={Link} to="/Company" onClick={handlecloceDrawer} className={classes.link}>
          <ListItemText primary="運営会社について" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem onClick={handlecloceDrawer}>
          <a
            href="https://www3.htb.co.jp/cgi-bin2/webform/webform.cgi/online_theater/form"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.link}
          >
            <ListItemText primary="問い合わせ" text-decoration="none" />
          </a>
        </ListItem>
      </List>
      {auth0Lock.isAuth || isAuthenticated() ? (
        <div>
          <Divider />
          <List>
            <ListItem onClick={handleOpen} className={classes.link} style={{ cursor: 'pointer' }}>
              <ExitToAppIcon />
              <ListItemText primary="&emsp;ログアウト" text-decoration="none" />
            </ListItem>
          </List>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modalLayout}
          >
            <div className={classes.paper}>
              <Typography gutterBottom variant="body1" align="center" style={{ wordBreak: 'break-word' }}>
                <StyledLogoutIcon alt="Iconimg" src={LogoutIcon} />
                <p id="simple-modal-title" className={classes.modalLayout}>
                  {process.env.REACT_APP_AUTH0_LOGIN_TITLE}
                  <br />
                  からログアウトしますか？
                </p>
                <br />

                <Button variant="contained" color="primary" onClick={handleClose} className={classes.modalLayout}>
                  キャンセル
                </Button>
                <Button variant="contained" color="primary" onClick={logout} className={classes.modalLayout}>
                  ログアウト
                </Button>
              </Typography>
            </div>
          </Modal>
        </div>
      ) : null}
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      {/* <ThemeProvider theme={(outerTheme) => ({ theme, ...outerTheme })}> */}
      <div className={classes.root}>
        <CssBaseline />
        {/* <ThemeProvider theme={(outerTheme) => ({ theme, ...outerTheme })}> */}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Layout1>
              <div>
                <IconButton
                  color="inherit"
                  aria-controls="hamburger_menu"
                  aria-haspopup="false"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Layout1>
            <Layout2>
              <div>
                <Link to="/">
                  <StyledHomeButton src={HomeButtonImg} alt="ホームボタン" />
                </Link>
              </div>
            </Layout2>
            <Layout3>
              <div>
                {auth0Lock.isAuth || isAuthenticated() ? (
                  <IconButton
                    aria-label="my page of current user"
                    aria-controls="iconButton"
                    aria-haspopup="false"
                    // src={localStorage.getItem('photo_url') ?? ''}
                    className={classes.AvatarButton}
                    component={Link}
                    to="/MyPage"
                  >
                    {/*  <HeadTextAvatarButton>MyPage</HeadTextAvatarButton> */}
                    <Avatar src={localStorage.getItem('photo_url') ?? ''} />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="humanButton"
                    aria-haspopup="false"
                    onClick={login}
                    color="inherit"
                    className={classes.humanButton}
                  >
                    {/*  <HeadTextLoginButton> ログイン</HeadTextLoginButton> */}
                    <AccountCircle />
                  </IconButton>
                )}
              </div>
            </Layout3>
            {darkMode ? (
              <Button color="inherit" onClick={handleDarkModeOff}>
                <Brightness7Icon />
              </Button>
            ) : (
              <Button color="inherit" onClick={handleDarkModeOn}>
                <Brightness4Icon />
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {/* </ThemeProvider> */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <GlobalCSS>
            <Wrap>
              <Container>
                <Section>
                  <InnerBorder>{children}</InnerBorder>
                </Section>
              </Container>
              <div className={classes.footer}>
                <LinkHref className={classes.footerText} href="https://www.htb.co.jp">
                  © HTB 北海道テレビ放送株式会社
                </LinkHref>
              </div>
            </Wrap>
          </GlobalCSS>
        </main>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        {/* </ThemeProvider> */}
      </div>
    </ThemeProvider>
  );
};

export default BaseLayout;
