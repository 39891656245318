import { Button, TextField } from '@material-ui/core';
// import Divider from '@material-ui/core/Divider';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
// import styled from 'styled-components';
import { getAuthProfile } from 'Auth0LockContext';
import { useCreateUserMutation, User, useUpdateUserMutation } from 'graphql/appsync/generated';
import { MessageCode } from 'utils/Message';
import Message from '../Common/Message';
import Loading from 'HTB-Videos/components/Common/Loading';

// const StyledCenter = styled.div`
//   .centerGroup {
//     text-align: center;
//     margin: 20px auto 20px;
//   }
// `;
type Props = {
  /**
   * 更新対象のユーザー情報
   */
  user?: User;

  /**
   * 登録・更新後に実行される関数
   */
  onEdit?: VoidFunction;
};

type typeSendDataCusCreate = {
  email: string;
  name: string;
};
type typeSendDataCusUpdate = {
  customerId: string;
  email: string;
  name: string;
};

// react-hook-formで使う要素の型宣言
type FormData = {
  familyName: string;
  givenName: string;
  email: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer_root: {
      width: '100%',
      '& .MuiTextField-root': {
        margin: theme.spacing(3),
        width: '90%',
      },
    },
    center: {
      textAlign: 'center',
    },
  }),
);

const UserEditFrom: FC<Props> = ({ user, onEdit }) => {
  const classes = useStyles();

  const [createUserMutation, { error: createUserError }] = useCreateUserMutation();
  const [updateUserMutation, { error: updateUserError }] = useUpdateUserMutation();

  const [familyName, setFamilyName] = useState<string | undefined>(
    user?.familyName ? user.familyName : getAuthProfile().family_name,
  );
  const [givenName, setGivenName] = useState<string | undefined>(
    user?.givenName ? user.givenName : getAuthProfile().given_name,
  );
  const [mail, setMail] = useState<string | undefined>(user?.mail ? user.mail : getAuthProfile().email);

  const { register, handleSubmit, errors } = useForm<FormData>({
    // バリデーション実行タイミングのmode
    // mode: 'onChange',
    mode: 'onSubmit',
    // mode: 'onTouched',
  });
  // console.log(errors);

  const [errorMsgView, setErrorMsgView] = useState<boolean>(false);
  const [messageCode, setMessageCode] = useState<MessageCode>('ERR_000001');
  const [sending, setSending] = useState<boolean>(false);
  const [formView, setFormView] = useState<boolean>(true);

  const onChangeFamilyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFamilyName(event.target.value);
  };
  const onChangeGivenName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGivenName(event.target.value);
  };
  const onChangeMail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMail(event.target.value);
  };

  /**
   * 新規ユーザー作成（すでに作成済みだとエラー）
   */
  const sendUserEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    // event.preventDefault(); // リロード防止
    setSending(true);
    setFormView(false);

    // 更新
    if (user) {
      // console.log(user?.stripeId);
      // 送信内容
      const sendDataCusUpdate: typeSendDataCusUpdate = {
        customerId: user?.stripeId,
        email: mail ?? '',
        name: (familyName ?? '') + (givenName ?? ''),
      };
      const CUS_UPDATE_URL: string = process.env.REACT_APP_CUS_UPDATE_URL ?? '';

      try {
        await axios
          // .post(URL_SEND, sendData)
          .post(CUS_UPDATE_URL, sendDataCusUpdate, {
            headers: {
              Authorization: localStorage.getItem('id_token'),
            },
          })
          .then(async (response) => {
            console.log(response.status);
            if (response.status !== 200) {
              // データ送信失敗
              // console.log(`データ送信 失敗: ${response.status}`);

              setMessageCode('ERR_000002');
              setErrorMsgView(true);
              setSending(false);
            } else if (response.data.success === false) {
              // 失敗

              setMessageCode('ERR_000002');
              setErrorMsgView(true);
              setSending(false);
            } else {
              // appsync
              await updateUserMutation({
                variables: {
                  input: {
                    id: getAuthProfile().sub ?? '',
                    mail: mail ?? '',
                    familyName: familyName ?? '',
                    givenName: givenName ?? '',
                  },
                },
              });

              // console.log('成功');
              if (onEdit) void onEdit();
            }
          });
      } catch (e) {
        setMessageCode('ERR_000002');
        setErrorMsgView(true);
        setSending(false);
      }

      // 初回
    } else {
      const sendDataCusCreate: typeSendDataCusCreate = {
        email: mail ?? '',
        name: (familyName ?? '') + (givenName ?? ''),
      };
      const CUS_CREATE_URL: string = process.env.REACT_APP_CUS_CREATE_URL ?? '';

      try {
        await axios
          // .post(URL_SEND, sendData)
          .post(CUS_CREATE_URL, sendDataCusCreate, {
            headers: {
              Authorization: localStorage.getItem('id_token'),
            },
          })
          .then(async (response) => {
            if (response.status !== 200) {
              // データ送信失敗
              // console.log(`データ送信 失敗: ${response.status}`);
              setMessageCode('ERR_000002');
              setErrorMsgView(true);
              setSending(false);
            } else if (response?.data?.customerId === '') {
              // 失敗
              // console.log('作成失敗');
              setMessageCode('ERR_000002');
              setErrorMsgView(true);
              setSending(false);
            } else {
              // appsync
              await createUserMutation({
                variables: {
                  input: {
                    id: getAuthProfile().sub ?? '',
                    mail: mail ?? '',
                    familyName: familyName ?? '',
                    givenName: givenName ?? '',
                    stripeId: response?.data?.customerId,
                  },
                },
              });
              // console.log('成功');
              if (onEdit) void onEdit();
            }
          });
      } catch (e) {
        setMessageCode('ERR_000002');
        setErrorMsgView(true);
        setSending(false);
      }
    }

    // if (onEdit) void onEdit();
  };

  // Graphqlがエラー
  if (createUserError || updateUserError)
    return (
      <>
        <br />
        <br />
        <Message messageCode="ERR_000301" className={classes.center} />
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );

  return (
    <>
      {/* <form className={classes.drawer_root} onSubmit={sendUserEdit} onSubmit={handleSubmit(onSubmit)}> */}
      {formView && (
        <form className={classes.drawer_root} onSubmit={handleSubmit(sendUserEdit)}>
          <div role="presentation">
            <br />
            <Grid container alignItems="center" justify="center">
              {user ? (
                <Typography variant="h6" noWrap>
                  プロフィール編集
                </Typography>
              ) : (
                <Typography variant="h6" noWrap>
                  プロフィール登録
                </Typography>
              )}
            </Grid>
            <TextField
              required
              fullWidth
              id="standard-required"
              name="familyName"
              label="姓"
              defaultValue={familyName}
              variant="outlined"
              onChange={onChangeFamilyName}
              inputRef={register({
                maxLength: {
                  value: 128,
                  message: '姓が長すぎます',
                },
              })}
            />
            {errors.familyName && <p style={{ color: 'red' }}>{errors.familyName.message}</p>}
            <br />
            <br />
            <TextField
              required
              fullWidth
              id="standard-required"
              name="givenName"
              label="名"
              defaultValue={givenName}
              variant="outlined"
              onChange={onChangeGivenName}
              inputRef={register({
                maxLength: {
                  value: 128,
                  message: '名が長すぎます',
                },
              })}
            />
            {errors.givenName && <p style={{ color: 'red' }}>{errors.givenName.message}</p>}
            <br />
            <br />
            <TextField
              required
              fullWidth
              id="standard-required"
              name="email"
              label="メールアドレス"
              defaultValue={mail}
              variant="outlined"
              onChange={onChangeMail}
              inputRef={register({
                // required: '必須項目です',
                pattern: {
                  value: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
                  message: 'メールアドレスの形式が不正です',
                },
                maxLength: {
                  value: 512,
                  message: 'メールアドレスが長すぎます',
                },
              })}
            />
            {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
          </div>
          <br />
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Button type="submit" size="medium" variant="contained" color="primary">
                送信
              </Button>
            </Grid>
          </Grid>
          <br />
        </form>
      )}
      {sending && (
        <>
          {/* <StyledCenter>
            <div className="centerGroup">
              <p>処理に時間がかかることがございます。</p>
              <p>このままの画面で少々お待ちください。</p>
              <br />
              <CircularProgress color="secondary" />
              <br />
              <br />
            </div>
          </StyledCenter> */}
          <Loading loadingCode="Load_003" />
        </>
      )}
      <br />
      {errorMsgView && <Message messageCode={messageCode} />}
    </>
  );
};

export default UserEditFrom;
