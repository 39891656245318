import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
// import styled from 'styled-components';
import RemoveLineItemButton from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/Container/RemoveLineItemButton';
import UpdateLineItemSelector from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/Container/UpdateLineItemSelector';
import { itemList } from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // height: '100%',
      padding: 10,
    },
    img: {
      height: 0,
      paddingTop: '56.25%', // 16:9,
      // paddingTop: '100%', // 1:1
      // marginTop: '30',
      objectFit: 'contain',
    },

    item: {
      display: 'block',
      height: 0,
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      paddingTop: '100%',
      backgroundColor: '#eee',
      border: '1px solid',
      borderColor: '#eee',
    },

    itemImage: {
      objectFit: 'contain',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
  }),
);

export type Props = {
  /**
   * カードに表示するカート中の商品情報
   */
  lineItem: itemList;
  /**
   * グッズカート内Items情報
   */
  Items: itemList[];
};

/**
 * カートに保存中の商品を表示するカード
 */
const LineItemCard: FC<Props> = ({ lineItem, Items }) => {
  const classes = useStyles();

  return (
    <>
      <Paper style={{ padding: 10 }}>
        <Grid item container spacing={2} justify="center" alignItems="center">
          <Grid item xs={4}>
            <div className={classes.item}>
              <img className={classes.itemImage} src={lineItem?.thumbnailUrl} alt={lineItem?.thumbnailUrl} />
            </div>
          </Grid>
          <Grid item xs={8} container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="button">
                {lineItem?.title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 800, fontSize: 16, marginBottom: 10 }}>
                ¥ {Number(lineItem.cost).toLocaleString()}
              </Typography>
            </Grid>

            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={5}>
                <UpdateLineItemSelector
                  lineItemId={lineItem?.id}
                  quantity={lineItem?.count}
                  Items={Items}
                  maxNumOfPurchase={lineItem.maxNumOfPurchase}
                />
              </Grid>
              <Grid item xs={7} style={{ marginTop: 10 }}>
                <RemoveLineItemButton lineItemId={lineItem?.id} Items={Items} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* </StyledLineItem> */}
    </>
  );
};

export default LineItemCard;
