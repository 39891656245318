import React, { FC, useEffect, useState } from 'react';
import refetchPagesContext, { Query } from 'RefetchQueriesContext';
import {
  useOnUpdateKintoneProductsSubscription,
  useOnCreateKintoneCollectionSubscription,
  useOnDeleteKintoneCollectionSubscription,
} from 'graphql/appsync/generated';

const RefetchQueriesProvider: FC = ({ children }) => {
  // refetch対象のQueryを管理するState
  const [refetchQueries, setRefetchQueries] = useState<Query[]>([]);
  // refetch実行後にrefetchQueriesから該当Queryを削除するための関数
  const removeQuery = (query: Query) => {
    setRefetchQueries((prev) => prev.filter((item) => item.name !== query.name && item.data !== query.data));
  };

  // KintoneProductサブスクリプション
  const { data: productDataSubscription } = useOnUpdateKintoneProductsSubscription();
  useEffect(() => {
    if (productDataSubscription && productDataSubscription.onUpdateKintoneProducts) {
      setRefetchQueries((prev) => [
        ...prev,
        { name: 'KintoneProducts', data: productDataSubscription.onUpdateKintoneProducts ?? undefined },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDataSubscription]);

  // KintoneProductサブスクリプション
  const { data: createCollectionDataSubscription } = useOnCreateKintoneCollectionSubscription();
  useEffect(() => {
    if (createCollectionDataSubscription && createCollectionDataSubscription.onCreateKintoneCollection) {
      setRefetchQueries((prev) => [
        ...prev,
        {
          name: 'CreateKintoneCollection',
          data: createCollectionDataSubscription.onCreateKintoneCollection ?? undefined,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCollectionDataSubscription]);
  const { data: deleteCollectionDataSubscription } = useOnDeleteKintoneCollectionSubscription();
  useEffect(() => {
    if (deleteCollectionDataSubscription && deleteCollectionDataSubscription.onDeleteKintoneCollection) {
      setRefetchQueries((prev) => [
        ...prev,
        {
          name: 'DeleteKintoneCollection',
          data: deleteCollectionDataSubscription.onDeleteKintoneCollection ?? undefined,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCollectionDataSubscription]);

  return (
    <refetchPagesContext.Provider value={{ queries: refetchQueries, removeQuery }}>
      {children}
    </refetchPagesContext.Provider>
  );
};

export default RefetchQueriesProvider;
