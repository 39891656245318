import { Grid } from '@material-ui/core/';
import React, { FC } from 'react';
import styled from 'styled-components';
import Loading from 'HTB-Videos/components/Common/Loading';
import ProductCard from 'HTB-Videos/components/LiveSalesArea/pages/ProductList/ProductCard';
import { GoodsCart } from 'graphql/appsync/generated';

export type Props = {
  /**
   * 表示対象のproductId
   */
  productIds: string[];
  /**
   * ローディング中であるかを示す真偽値
   */
  isLoading: boolean;
  /**
   * カート情報
   */
  goodsCartData: GoodsCart;
};

const StyledGrid = styled.div`
  margin: 5%;
`;

// const StyledCard = styled.div`
//   margin: 10px;
// `;

/**
 * 販売商品を表示するコンポーネント
 */
// const ProductsList: FC<Props> = ({ productId, isLoading, onEdit, goodsCartData }) => {
const ProductsList: FC<Props> = ({ productIds, isLoading, goodsCartData }) => (
  <>
    <StyledGrid>
      {/* <Grid container spacing={5} alignItems="flex-start" justify="center"> */}
      {/* <Grid container spacing={5} justify="center"> */}
      <Grid container spacing={5}>
        {isLoading ? (
          <Loading loadingCode="Load_001" />
        ) : (
          <>
            {productIds.map((productId) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                {/* <StyledCard> */}
                <ProductCard productId={productId} goodsCartData={goodsCartData} />
                {/* </StyledCard> */}
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </StyledGrid>
  </>
);

export default ProductsList;
