import { Grid, Drawer, Slide, Stepper, Step, StepLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { FC, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { getAuthProfile, isAuthenticated } from 'Auth0LockContext';
import Loading from 'HTB-Videos/components/Common/Loading';
import Message from 'HTB-Videos/components/Common/Message';
import CardEditForm from 'HTB-Videos/components/MyPage/CardEditFrom';
import UserEditForm from 'HTB-Videos/components/MyPage/UserEditFrom';
import StripeForm from 'HTB-Videos/components/TicketSalesPage/StripeForm';
import TicketCard from 'HTB-Videos/components/TicketSalesPage/TicketCard';
import { ViewMode, wpChildContentData } from 'HTB-Videos/utils/WPRequest';
import {
  useGetUserQuery,
  // useListCardsQuery,
  useListSortCardsQuery,
  useListSortOrdersQuery,
} from 'graphql/appsync/generated';

export type Props = {
  // 表示対象の子データ
  childContents: wpChildContentData[];

  // ViweMode
  viewMode: ViewMode;

  // 販売終了日
  saleFinishDate: string;

  /**
   * 国外アクセスかどうかの真偽値
   */
  geoStatusCode403: boolean;

  /**
   * 国内限定コンテンツかどうかの真偽値
   */
  isDomesticRestrict: boolean;
};

// Drawer表示内容のベーススタイル
const StyledDrawer = styled.div`
  padding: 2%;
  height: 80vh;

  @media screen and (max-width: 1025px) {
     {
      min-height: 400px;
    }
  }
`;

// 「購入」文字列のスタイル
const StyledBuy = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -30px;

  @media screen and (max-width: 1025px) {
     {
      font-size: 1.2rem;
    }
  }
`;

// Drawer内のタイトル文字列のスタイル
const StyledTitleDrawer = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 10px;

  @media screen and (max-width: 1025px) {
     {
      font-size: 1rem;
    }
  }
`;

// 値段のスタイル
const StyledPriceDrawer = styled.div`
  font-size: 1.6rem;
  color: red;
`;

// Drawer内のGridのスタイル
const StyledGridDrawer = styled(Grid)`
  margin-bottom: 20px;
`;

// 右下寄せGrid用のスタイル
const StyledGridRightBottomJustify = styled(Grid)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

// 上下中央寄せGrid用のスタイル
const StyledGridCenterJustify = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// サムネイル画像用のスタイル
const StyledChildThumbnail = styled.img`
  max-height: 100px;

  @media screen and (max-width: 1025px) {
     {
      max-height: 60px;
    }
  }
`;

// Stepperラベル用のスタイル
const StyledStepperLabel = styled.div`
  @media screen and (max-width: 1025px) {
     {
      font-size: 0.6rem;
    }
  }
`;

// エラーメッセージ用のスタイル
const StyledErrorMessage = styled.div`
  color: white;
  font-weight: bold;
`;

type StepperLabel = 'プロフィール登録' | 'クレジットカード登録' | '確認画面';

// Reducerで管理するState
type State = {
  // 購入するchildContent
  buyContent: wpChildContentData | undefined;

  // Drawerが開いているかどうかを管理するState
  isOpenDrawer: boolean;

  // StepperのStepを管理するState
  activeStep: number;

  // StepperのLabelを管理するState
  stepperLabel: StepperLabel[];

  // UUIDv4を管理するState
  UUID: string;
};

// Stateの初期値
const initialState = {
  buyContent: undefined,
  isOpenDrawer: false,
  activeStep: 0,
  stepperLabel: [],
  UUID: v4(),
} as State;

// Dispatchアクションの種類
type Action =
  | {
      type: 'createUUID';
    }
  | {
      type: 'onBuyButtonClick';
      isUserExist: boolean;
      isCardEXist: boolean;
      content: wpChildContentData;
    }
  | {
      type: 'onDrawerClose';
    }
  | {
      type: 'stepIncrement';
    };

// Dispatchアクション
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // UUID発行処理
    case 'createUUID': {
      return {
        ...state,
        UUID: v4(),
      };
    }
    // 購入ボタンクリック時の処理
    case 'onBuyButtonClick': {
      const label = (): StepperLabel[] => {
        if (!action.isUserExist) {
          return ['プロフィール登録', 'クレジットカード登録', '確認画面'];
        }
        if (!action.isCardEXist) {
          return ['クレジットカード登録', '確認画面'];
        }

        return [];
      };

      return {
        ...state,
        buyContent: action.content,
        isOpenDrawer: true,
        stepperLabel: label(),
      };
    }
    // Drawerが閉じる時の処理
    case 'onDrawerClose': {
      return {
        ...state,
        buyContent: undefined,
        isOpenDrawer: false,
        activeStep: 0,
        stepperLabel: [],
        UUID: v4(),
      };
    }
    // stepperを進める処理
    case 'stepIncrement': {
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    }
    default: {
      return state;
    }
  }
};

/**
 * チケット販売エリアのコンポーネント
 */
const TicketSalsesArea: FC<Props> = ({
  childContents,
  viewMode,
  saleFinishDate,
  geoStatusCode403,
  isDomesticRestrict,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'createUUID' });
  }, []);

  // ログインユーザー情報
  const {
    data: getUserData,
    loading: isGetUserLoading,
    error: getUserError,
    refetch: refetchGetUser,
  } = useGetUserQuery({
    variables: { id: getAuthProfile().sub ?? '' },
  });
  // 登録済みカード情報
  const {
    data: listCardData,
    loading: isListCardLoading,
    error: listCardError,
    refetch: refetchListCard,
    // } = useListCardsQuery(); // 20210514 不具合修正
  } = useListSortCardsQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
    },
  });
  // 購入済み商品情報
  const {
    data: listOrderData,
    loading: isListOrderLoading,
    error: listOrderError,
    refetch: refetchOrderData,
  } = useListSortOrdersQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
      filter: {
        buyState: {
          ne: 'NotBought',
        },
      },
    },
  });

  return (
    <>
      {isAuthenticated() && (getUserError || listCardError || listOrderError) ? (
        <Grid container justify="center">
          <StyledErrorMessage>
            <Message messageCode="ERR_000301" />
          </StyledErrorMessage>
        </Grid>
      ) : (
        <>
          {isGetUserLoading || isListCardLoading || isListOrderLoading ? (
            // <Grid container justify="center">
            //   <CircularProgress />
            // </Grid>
            <Loading />
          ) : (
            <>
              {childContents.map((content) => (
                <Grid key={content.id} container alignItems="center" justify="center" spacing={4}>
                  <Grid item sm={11} md={8}>
                    <TicketCard
                      key={content.id}
                      isLogin={isAuthenticated()}
                      viewMode={viewMode}
                      saleFinishDate={saleFinishDate}
                      childContent={content}
                      geoStatusCode403={geoStatusCode403}
                      isDomesticRestrict={isDomesticRestrict}
                      buyState={
                        listOrderData?.listSortOrders?.items
                          ? listOrderData.listSortOrders.items.find(
                              (item) => item?.itemId.toString() === content.id.toString(),
                            )?.buyState ?? 'NotBought'
                          : 'NotBought'
                      }
                      onClick={() => {
                        void dispatch({
                          type: 'onBuyButtonClick',
                          isUserExist: !!getUserData?.getUser,
                          isCardEXist: (listCardData?.listSortCards?.items?.length ?? 0) > 0,
                          content,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </>
      )}

      {/* -------------------- Drawer -------------------- */}
      {state.buyContent && (
        <Drawer
          anchor="bottom"
          open={state.isOpenDrawer}
          onClose={() => {
            void refetchGetUser();
            void refetchListCard();
            void refetchOrderData();
            void dispatch({ type: 'onDrawerClose' });
          }}
        >
          <ClearIcon
            aria-label="閉じる"
            fontSize="large"
            aria-controls="tag-x"
            aria-haspopup="false"
            onClick={() => {
              void dispatch({ type: 'onDrawerClose' });
            }}
            color="inherit"
          />
          <StyledDrawer>
            {/* 購入商品情報 */}
            <StyledGridDrawer container spacing={1}>
              <StyledGridCenterJustify item xs={12}>
                <StyledBuy>購入</StyledBuy>
              </StyledGridCenterJustify>
              <Grid item xs={12}>
                <StyledTitleDrawer>{state.buyContent.acf.childTitle}</StyledTitleDrawer>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={6}>
                <StyledChildThumbnail
                  alt={state.buyContent.acf.childTitle}
                  src={state.buyContent.acf.childThumbnailURL}
                />
              </Grid>
              <StyledGridRightBottomJustify item xs={4}>
                <StyledPriceDrawer>
                  ¥{Number(state.buyContent.acf.childPrice).toLocaleString()}(税込) ✖︎ 1
                </StyledPriceDrawer>
              </StyledGridRightBottomJustify>
              <Grid item xs={1} />
              <Grid item xs={12}>
                {state.stepperLabel.length > 0 && (
                  <Stepper alternativeLabel activeStep={state.activeStep}>
                    {state.stepperLabel.map((label) => (
                      <Step key={label}>
                        <StepLabel>
                          <StyledStepperLabel>{label}</StyledStepperLabel>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
              </Grid>
            </StyledGridDrawer>
            {/* 初期登録が済んで無い場合に表示されるSlide */}
            <Slide direction="left" in={!getUserData?.getUser} mountOnEnter unmountOnExit appear={false} exit={false}>
              <div>
                <UserEditForm
                  user={getUserData?.getUser ?? undefined}
                  onEdit={() => {
                    void dispatch({ type: 'stepIncrement' });
                    void refetchGetUser();
                  }}
                />
              </div>
            </Slide>
            {/* ユーザー登録済みであり、クレジットカードが登録されていない場合に表示されるSlide */}
            <Slide
              direction="left"
              in={!!getUserData?.getUser && (listCardData?.listSortCards?.items?.length ?? 0) < 1}
              mountOnEnter
              unmountOnExit
              exit={false}
            >
              <div>
                {getUserData?.getUser ? (
                  <CardEditForm
                    user={getUserData?.getUser}
                    onEdit={() => {
                      void dispatch({ type: 'stepIncrement' });
                      void refetchListCard();
                    }}
                  />
                ) : (
                  <>ユーザー設定エラーです</>
                )}
              </div>
            </Slide>
            {/* 商品購入Slide */}
            <Slide
              direction="left"
              in={!!getUserData?.getUser && (listCardData?.listSortCards?.items?.length ?? 0) > 0}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <StripeForm
                  mail={getUserData?.getUser?.mail ?? ''}
                  wordpressId={state.buyContent.id.toString()}
                  idempotencyKeyUuid={state.UUID}
                  onPurchase={() => {
                    void dispatch({ type: 'onDrawerClose' });
                    void refetchOrderData();
                  }}
                />
              </div>
            </Slide>
            <br />
            <br />
            <br />
            <br />
            <br />
          </StyledDrawer>
        </Drawer>
      )}
      {/* -------------------- Drawer -------------------- */}
    </>
  );
};

export default TicketSalsesArea;
