// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
// import Stepper from '@material-ui/core/Stepper';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
// import jwtDecode from 'jwt-decode';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { getAuthProfile } from 'Auth0LockContext';
import { MessageCode } from 'utils/Message';
import {
  useCreateCardMutation,
  //  useListCardsQuery,
} from '../../../../../graphql/appsync/generated';
import Message from '../../../Common/Message';
import Confirmation from '../Confirmation';
import StripeForm from '../StripeForm';
import Loading from 'HTB-Videos/components/Common/Loading';

// src/components/SalesArea/pages/CardAdd/Container/CardAdd.tsx
// '../../graphql/appsync/generated'
// src/graphql/appsync/generated.tsx

/*
interface profileJson {
  email?: string;
  picture?: string;
  name?: string;
  sub?: string;
} */

/**
 * Api GatewayへPOSTするデータtype
 */
type typeSendData = {
  customerId: string;
  tokenId: string;
};

export type Props = {
  // onEdit:  VoidFunction | undefined;
  onEdit?: VoidFunction;
  stripeId: string;
};

/* type CardBrand ={
  cardBrand: Visa | Mastercard | AMEX |JCB |Diners|Discover;
} */
// Dynamoへ格納するCardBrandのtype
type typeCardBrand = 'AMEX' | 'Diners' | 'Discover' | 'JCB' | 'Mastercard' | 'Visa';

// Stripeからのレスポンス内容 メモ
// Visa
// MasterCard
// JCB
// Discover
// Diners Club
// American Express

// material-ui Stepperの設定
/*
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

// material-ui Stepperの設定
const getSteps = () => {
  return ['クレジットカード入力', '確認画面'];
}; */

const StyledSendingText = styled.div`
  text-align: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      textAlign: 'center',
    },
  }),
);

const CardAdd: FC<Props> = ({ onEdit, stripeId }) => {
  const classes = useStyles();
  const [stripeFormView, setStripeFormView] = useState<boolean>(true); // Stripeフォームの表示非表示制御
  const [confirmationView, setConfirmationView] = useState<boolean>(false); // 確認画面の表示非表示制御
  // const [processingResult, setProcessingResult] = useState<number>(0); // 処理結果 0処理前 1成功 2失敗
  // const [cardBrand, setCardBrand] = useState<CardBrand>('Visa'); //カードブランド
  const [cardBrand, setCardBrand] = useState<string>('');
  const [cardLast4, setCardLast4] = useState<string>('');
  const [cardName, setCardName] = useState<string>('');
  const [cardExpMonth, setCardExpMonth] = useState<string>('');
  const [cardExpYear, setCardExpYear] = useState<string>('');
  const [stripeTokenid, setStripeTokenid] = useState<string>('');
  // Appsync 関連
  // const limit = 101;
  // const { data, refetch } = useListCardsQuery({ variables: { limit } });
  const [createCardMutation, { error: createCardError }] = useCreateCardMutation();

  const [errorMsgView, setErrorMsgView] = useState<boolean>(false);
  const [messageCode, setMessageCode] = useState<MessageCode>('ERR_000104');

  const [sending, setSending] = useState<boolean>(false);

  const pagingFunction = (result: string) => {
    if (result === 'StripeFormSuccess') {
      // トークン発行成功 確認画面へ
      setStripeFormView(false);
      setConfirmationView(true);
      // handleNext();
    }
  };

  const resultSetTokenInfo = (
    resultCardBrand: string,
    resultCardLast4: string,
    resultCardName: string,
    resultCardExpMonth: string,
    resultCardExpYear: string,
    resultStripeTokenid: string,
  ) => {
    setCardBrand(resultCardBrand);
    setCardLast4(resultCardLast4);
    setCardName(resultCardName);
    setCardExpMonth(resultCardExpMonth);
    setCardExpYear(resultCardExpYear);
    setStripeTokenid(resultStripeTokenid);
  };

  // material-ui Stepper 設定 start
  /*
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const steps = getSteps();

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);

      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  */
  // material-ui Stepper 設定 end

  // 登録実行
  const onClickRegistration = () => {
    setSending(true);
    const sendData: typeSendData = {
      // "customerId":"cus_J7QXly9XUYCJaJ",
      customerId: stripeId,
      // "tokenId": localStorage.getItem("stripe_tokenId") ?? ''
      tokenId: stripeTokenid,
    };
    // const URL_SEND:string = 'https://n1ue37iko0.execute-api.ap-northeast-1.amazonaws.com/card-add';
    const URL_SEND: string = process.env.REACT_APP_CARD_ADD_URL ?? '';
    // ApiGatewayへPOSTリクエスト
    sendingPost(sendData, URL_SEND);

    setConfirmationView(false);
    // handleNext();
  };

  // 修正で前の状態へ
  const onClickEditing = () => {
    // handleBack();
    setStripeFormView(true);
    setConfirmationView(false);
  };

  // POSTリクエスト
  const sendingPost = async (sendData: typeSendData, sendUrl: string) => {
    try {
      await axios
        // .post(URL_SEND, sendData)
        .post(sendUrl, sendData, {
          headers: {
            Authorization: localStorage.getItem('id_token'),
          },
        })
        .then((response) => {
          // console.log(response.data);
          if (response.status !== 200) {
            // データ送信失敗
            // console.log(`データ送信 失敗: ${response.status}`);
            // setProcessingResult(2);
            setSending(false);
            setErrorMsgView(true);
          } else if (response.data.cardId === '') {
            switch (response.data.errorCode) {
              case 'incorrect_cvc':
                // console.log('cvcエラー');
                setMessageCode('ERR_000102');
                break;
              case 'expired_card':
                // console.log('有効期限切れエラー');
                setMessageCode('ERR_000103');
                break;
              default:
                // 失敗
                // setProcessingResult(2);
                setMessageCode('ERR_000104');
                break;
            }
            setSending(false);
            setErrorMsgView(true);
          } else {
            // appsync でDynamoへカード追加
            appsyncAddCard(response.data.cardId ?? '');

            // setProcessingResult(1);
          }
        });
      // データ送信失敗
    } catch (e) {
      // console.log(`データ送信 失敗: ${e}`);
      // setProcessingResult(2);
      setSending(false);
      setErrorMsgView(true);
    }
  };

  // AppsyncCreate addCard
  const appsyncAddCard = async (setStripeCardId: string) => {
    try {
      /* const profile = jwtDecode<profileJson>(
        localStorage.getItem('id_token') ?? '',
      ); */
      // const deadline = (localStorage.getItem('stripe_token_card_exp_month') ?? '')+'/'+(localStorage.getItem('stripe_token_card_exp_year')?? '');
      const deadline = `${cardExpMonth}/${cardExpYear}`;

      // カードブランド設定
      let appsyncCardBrand: typeCardBrand = 'Visa';
      // const localStorageCardBrand = localStorage.getItem('stripe_token_card_brand') ?? '';

      // switch(localStorageCardBrand){
      switch (cardBrand) {
        case 'Visa':
          appsyncCardBrand = 'Visa';
          break;
        case 'MasterCard':
          appsyncCardBrand = 'Mastercard';
          break;
        case 'JCB':
          appsyncCardBrand = 'JCB';
          break;
        case 'Discover':
          appsyncCardBrand = 'Discover';
          break;
        case 'Diners Club':
          appsyncCardBrand = 'Diners';
          break;
        case 'American Express':
          appsyncCardBrand = 'AMEX';
          break;
        default:
          appsyncCardBrand = 'Visa';
          break;
      }

      await createCardMutation({
        variables: {
          input: {
            // userId: profile.sub ?? '',
            userId: getAuthProfile().sub ?? '',
            stripeCardId: setStripeCardId,
            brand: appsyncCardBrand,
            // cardNo: localStorage.getItem('stripe_token_card_last4') ?? '',
            cardNo: cardLast4,
            deadline,
            name: cardName,
          },
        },
      });
      // FIXME: リフェッチせずにキャッシュを書き換えるべき
      // await refetch();

      if (onEdit) void onEdit();
    } catch (e) {
      // console.log(`apps 失敗: ${e}`);
      // setProcessingResult(2);
      setSending(false);
      setErrorMsgView(true);
    }
  };

  // Graphqlがエラー
  if (createCardError)
    return (
      <>
        <br />
        <br />
        <Message messageCode="ERR_000301" className={classes.center} />
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );

  return (
    <>
      {/* <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div> */}
      {stripeFormView === true && (
        <StripeForm pagingFunction={pagingFunction} resultSetTokenInfo={resultSetTokenInfo} />
      )}
      {confirmationView === true && (
        <Confirmation
          onClickRegistration={onClickRegistration}
          onClickEditing={onClickEditing}
          cardBrand={cardBrand}
          cardLast4={cardLast4}
          cardName={cardName}
          cardExpMonth={cardExpMonth}
          cardExpYear={cardExpYear}
        />
      )}
      {sending && (
        <>
          {/* <StyledSendingText>
            <p>処理に時間がかかることがございます。</p>
            <p>このままの画面で少々お待ちください。</p>
            <br />
            <CircularProgress color="secondary" />
            <br />
            <br />
          </StyledSendingText> */}
          <Loading loadingCode="Load_003" />
        </>
      )}
      {/* {processingResult === 1 && <div>登録が成功しました。</div>} */}
      {/* processingResult === 2 && <div>登録が失敗しました。</div> */}
      {errorMsgView && <Message messageCode={messageCode} />}
    </>
  );
};

export default CardAdd;
