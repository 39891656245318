import { Button, Grid } from '@material-ui/core';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
} from '@stripe/stripe-js';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

export type Props = {
  /**
   * 「確認画面へ」を押下した時に実行される処理
   */
  pagingFunction: (result: string) => void;

  /**
   * 発行したトークン情報を設定する処理
   */
  resultSetTokenInfo: (
    resultCardBrand: string,
    resultCardLast4: string,
    resultCardName: string,
    resultCardExpMonth: string,
    resultCardExpYear: string,
    resultStripeTokenid: string,
  ) => void;
};

// Stripe.jsとElementsのプロバイダを設定する
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

/**
 * Stripeフォーム用ダミーコンポーネント
 */
const StripeFormPagingAction: FC<Props> = ({ pagingFunction, resultSetTokenInfo }) => (
  <>
    <Elements stripe={stripePromise}>
      <CheckoutForm pagingFunction={pagingFunction} resultSetTokenInfo={resultSetTokenInfo} />
    </Elements>
  </>
);

const StyledFormLabel = styled.div`
  margin: 10px;
  text-align: center;
  font-size: 1.3rem;
`;

const StyledCardForm = styled.div`
  text-align: center;

  .card-form {
    font-size: 16px;
    border: none;
    height: 40px;
    padding: 10px 1px;
    border-bottom: 1px solid #000000;
    transition: box-shadow 150ms ease;
    margin: 10px;

    :focus {
      outline: none;
      border-bottom: 2px solid #000000;
    }

    ::placeholder {
      color: #a3a3a3;
    }
  }

  // ダークモード用の背景色・文字色
  .card-form.dark {
    background-color: #535353;
    color: #fff;

    // autoComplete実行時に背景色と文字色が変わってしまう問題への対応
    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #535353 inset;
      -webkit-text-fill-color: #fff;
    }
  }
  // autoComplete実行時に背景色と文字色が変わってしまう問題への対応
  .card-form.dark.StripeElement--webkit-autofill {
    background-color: #535353 !important;
    color: #fff;
  }

  // ライトモード用の背景色・文字色
  .card-form.light {
    background-color: #ffffff;
    color: #32325d;

    // autoComplete実行時に背景色と文字色が変わってしまう問題への対応
    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
      -webkit-text-fill-color: #32325d;
    }
  }
  // autoComplete実行時に背景色と文字色が変わってしまう問題への対応
  .card-form.light.StripeElement--webkit-autofill {
    background-color: #ffffff !important;
    color: #32325d;
  }

  .card-form.card-name {
    width: 100%;
  }

  .button {
    margin: 20px auto 20px;
  }
`;

// Stripeカード情報登録用コンポーネント
const CheckoutForm: FC<Props> = ({ pagingFunction, resultSetTokenInfo }) => {
  const stripe = useStripe();
  const elements = useElements();

  // 入力内容にエラーがあった際にその内容を保持するState
  const [error, setError] = useState<null | string>(null);
  // カード所有者名の入力値を保持するState
  const [cardName, setCardName] = useState('');
  // ダークモードか否かを保持するState
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    setIsDarkMode(localStorage.getItem('darkMode') !== 'off');
  }, []);

  // elementsを作成する際に渡すオプション。
  const STRIPE_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: isDarkMode ? '#FFFFFF' : '#32325D',
        color: isDarkMode ? '#FFFFFF' : '#32325D',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#a3a3a3',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  // カード要素からのリアルタイムの検証エラーを処理
  const handleChange = (
    event: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const cardNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardName(event.target.value);
  };

  // トークンID発行
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // if (radioState === 'card') {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // マウントされたElementを参照
    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      return;
    }

    const optionData = { name: cardName };

    // カードからトークンを作成する
    const result = await stripe.createToken(cardNumberElement, optionData);

    if (result.error) {
      // エラーが発生した場合は、ユーザーに通知します。
      if (result.error.message !== undefined) {
        setError(result.error.message);
      }
    } else {
      setError(null);
      // トークンID発行完了
      let cardExpMonth = '';
      if (result.token?.card?.exp_month !== undefined) {
        cardExpMonth = (result.token?.card?.exp_month).toString();
      }

      let cardExpYear = '';
      if (result.token?.card?.exp_year !== undefined) {
        cardExpYear = (result.token?.card?.exp_year).toString();
      }

      resultSetTokenInfo(
        result.token?.card?.brand ?? '',
        result.token?.card?.last4 ?? '',
        result.token?.card?.name ?? '',
        cardExpMonth,
        cardExpYear,
        result.token?.id ?? '',
      );

      pagingFunction('StripeFormSuccess');
    }
  };

  return (
    <>
      <StyledFormLabel>クレジットカード登録</StyledFormLabel>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <StyledCardForm>
            <CardNumberElement
              id="card-number-element"
              options={{
                ...STRIPE_ELEMENT_OPTIONS,
                showIcon: true,
                placeholder: 'カード番号 1234 1234 1234 1234',
              }}
              onChange={handleChange}
              className={`card-form ${isDarkMode ? 'dark' : 'light'}`}
            />
            <br />
            <CardExpiryElement
              id="card-expiry-element"
              options={{
                ...STRIPE_ELEMENT_OPTIONS,
                placeholder: '有効期限  MM(月)/YY(年)',
              }}
              onChange={handleChange}
              className={`card-form ${isDarkMode ? 'dark' : 'light'}`}
            />
            <br />
            <CardCvcElement
              id="card-cvc-element"
              options={{
                ...STRIPE_ELEMENT_OPTIONS,
                placeholder: 'セキュリティコード',
              }}
              onChange={handleChange}
              className={`card-form ${isDarkMode ? 'dark' : 'light'}`}
            />
            <br />
            <Grid container>
              <input
                id="card-name"
                className={`card-form card-name  ${isDarkMode ? 'dark' : 'light'}`}
                type="text"
                placeholder="カード所有者名"
                required
                value={cardName}
                onChange={cardNameChange}
              />
            </Grid>

            <div className="card-errors" role="alert">
              {error}
            </div>
            <Button color="primary" variant="contained" type="submit" className="button">
              確認画面へ
            </Button>
          </StyledCardForm>
        </div>
      </form>
    </>
  );
};

export default StripeFormPagingAction;
