/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

export interface LoggingContext {
  msg?: string;
  data?: unknown;
  isStringified?: boolean;
  user?: Sentry.User;
}
export interface ErrorLoggingContext<T extends Error> extends LoggingContext {
  err?: T;
}
export interface SentryContext {
  user: Sentry.User;
  tags?: { [key: string]: string | number | boolean };
}
export default class Logger {
  public static setContext(ctx: LoggingContext & SentryContext): void {
    Sentry.setUser(ctx.user);
    ctx.tags && Sentry.setTags(ctx.tags);
  }

  public static debug(args: LoggingContext & { msg: string }): void {
    const { msg, data, isStringified } = args;
    data
      ? console.debug(
          `%c${msg}`,
          'color: green; font-weight: bold',
          isStringified ? `\n${JSON.stringify(data, undefined, 2)}` : data,
        )
      : console.debug(`%c${msg}`, 'color: green; font-weight: bold');
  }

  public static info(args: LoggingContext & { msg: string }): void {
    const { msg, data } = args;
    console.info(msg, data);
    Sentry.captureMessage(msg, { level: Sentry.Severity.Info });
  }

  public static warn<T extends Error>(
    args: ErrorLoggingContext<T> & { msg: string },
  ): void {
    const { msg, data } = args;
    console.warn(msg, data);
    // Sentry.captureMessage(msg, { tags: { logLevel: 'warn' } });
    Sentry.captureMessage(msg, { level: Sentry.Severity.Warning });
  }

  public static error<T extends Error>(args: ErrorLoggingContext<T>): void {
    const { err, data } = args;
    console.error(err, data);
    // Sentry.captureException(err, { tags: { logLevel: "error" }});
    Sentry.captureException(err, { level: Sentry.Severity.Error });
  }
}
