import React, { FC } from 'react';
import { getAuthProfile } from 'Auth0LockContext';
import UpdateLineItemButton from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/UpdateLineItemSelector';
import { itemList } from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';
import { useUpdateGoodsCartMutation } from 'graphql/appsync/generated';

type Props = {
  /**
   * 数量変更対象のlineItemId
   */
  lineItemId: string;

  /**
   * 変更前の数量
   */
  quantity: number;

  /**
   * グッズカート内Items情報
   */
  Items: itemList[];

  /**
   * 商品ごとに設定されている一度に購入できる数
   */
  maxNumOfPurchase: number;
};

/**
 * 数量変更セレクターのロジック部
 */
const EnhancedUpdateLineItemSelector: FC<Props> = ({ lineItemId, quantity, Items, maxNumOfPurchase }) => {
  // GoodsCartを更新するhooks
  const [
    goodsCartItemsUpdateMutation,
    {
      loading: isGoodsCartItemsUpdateLoading,
      // error: goodsCartItemsUpdateError,
    },
  ] = useUpdateGoodsCartMutation();

  // 数量変更ロジック部分
  // 引数でセレクターで選択した値を受け取る
  const changeItemCount = (changedCount: number) =>
    (Items.length ?? 0) > 0 && Items.find((item) => item.id === lineItemId)
      ? Items.map((data) => {
          if (data.id === lineItemId) {
            return {
              id: data.id,
              count: changedCount,
              title: data.title,
              thumbnailUrl: data.thumbnailUrl,
              cost: data.cost,
              maxNumOfPurchase: data.maxNumOfPurchase,
            };
          }

          return data;
        })
      : [...Items];

  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    changeItemCount(event.target.value as number);

    // 該当商品の数量を変更したリストをJSON文字列に変換
    const itemListJson = JSON.stringify(changeItemCount(event.target.value as number));

    // goodsCartテーブルItems情報を更新
    await goodsCartItemsUpdateMutation({
      variables: {
        input: {
          id: localStorage.getItem('goodsCartId') ?? '',
          userId: getAuthProfile().sub ?? '',
          items: itemListJson,
        },
      },
    });
  };

  return (
    <UpdateLineItemButton
      quantity={quantity}
      handleChange={handleChange}
      isLoading={isGoodsCartItemsUpdateLoading}
      maxNumOfPurchase={maxNumOfPurchase}
    />
  );
};

export default EnhancedUpdateLineItemSelector;
