/* eslint-disable camelcase */
import axios from 'axios';
import React, { FC, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import TwitterArea from 'HTB-Aircaravan2021/components/Twitter/TwitterArea';

export type TwitterResponseData = {
  created_at: string;
  id_str: string;
  name: string;
  profile_image_url_https: string;
  screen_name: string;
  text: string;
  url: string;
};

export type Props = {
  /**
   * PCレイアウト版twitter表示エリアをするか否かの真偽値
   */
  isPCLayout: boolean;
  /**
   * twitter表示エリア高さの最大値
   */
  maxHeightForTwitterArea: number;
};

/**
 * twitter表示エリアコンポーネントのロジック部分
 */
const TwitterAreaContainer: FC<Props> = ({ isPCLayout, maxHeightForTwitterArea }) => {
  // 画面に表示する最大ツイート数
  const MaxDisplayTweets = 40;
  // TopNavアイコンが出現する閾値のツイート数
  const TopNavAppearanceTweeets = 7;
  // ツイート取得時のポーリング間隔
  // const refetchInterval = 20000;
  const refetchInterval = Number(process.env.REACT_APP_TWITTER_TIMELINE_POLLING_INTERVAL ?? 20000);
  const [progress, setProgress] = useState(0);
  const [DisplayTweets, setDisplayTweets] = useState<TwitterResponseData[]>([]);
  const [isTopNavigation, setIsTopNavigation] = useState(false);

  // ReactQueryでTweet取得
  const {
    data: twitterContent,
    isLoading: isTwiLoading,
    // isError,
  } = useQuery<TwitterResponseData[]>(
    ['getTwiContents', 'all'],
    async () => {
      const { data } = await axios.get<TwitterResponseData[]>(`${process.env.REACT_APP_TWITTER_URL ?? ''}`);

      return data;
    },
    // { cacheTime: 30000, refetchOnWindowFocus: false },
    // { staleTime: 300000, refetchInterval: 30000, refetchOnWindowFocus: false },
    // アイコンボタン連打などで短時間に何度もツイート取得されるのを防ぐため、staleTimeを少しだけ設定
    { refetchInterval, refetchOnWindowFocus: false, staleTime: 5000 },
  );

  // 取得したtweetsを配列に格納
  useEffect(() => {
    if (twitterContent && DisplayTweets.length + twitterContent.length < MaxDisplayTweets) {
      const temp = twitterContent.slice(0).concat(DisplayTweets);
      setDisplayTweets(temp);
      setProgress(0);
      if (DisplayTweets.length + twitterContent.length > TopNavAppearanceTweeets) {
        setIsTopNavigation(true);
      }
    } else if (twitterContent && DisplayTweets.length + twitterContent.length >= MaxDisplayTweets) {
      const temp = twitterContent.slice(0).concat(DisplayTweets);
      temp.splice(MaxDisplayTweets);
      setDisplayTweets(temp);
      // setIsTopNavigation(true);
      setProgress(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twitterContent]);

  // twiiterArea上部に出すlinerProgressBarの設定
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }

        return Math.min(oldProgress + 10, 100);
      });
    }, refetchInterval / 10);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TwitterArea
      isPCLayout={isPCLayout}
      maxHeightForTwitterArea={maxHeightForTwitterArea}
      isTwiLoading={isTwiLoading}
      progress={progress}
      DisplayTweets={DisplayTweets}
      isTopNavigation={isTopNavigation}
    />
  );
};

export default TwitterAreaContainer;
