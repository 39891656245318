import { Button } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const StyeldConfirmation = styled.div`
  text-align: center;

  .flex {
    display: flex;
  }

  .button {
    margin: 20px auto 20px;
  }

  .font {
    font-size: 1.1rem;
  }
`;
const StyledFormLabel = styled.div`
  margin: 10px;
  font-size: 1.3rem;
`;

export type Props = {
  onClickRegistration: VoidFunction; // CardAdd/Container/CardAdd の登録実行
  onClickEditing: VoidFunction; // CardAdd/Container/CardAdd の修正実行
  cardBrand: string;
  cardLast4: string;
  cardName: string;
  cardExpMonth: string;
  cardExpYear: string;
};

const Confirmation: FC<Props> = ({
  onClickRegistration,
  onClickEditing,
  cardBrand,
  cardLast4,
  cardName,
  cardExpMonth,
  cardExpYear,
}) => {
  // const [cardBrand, setCardBrand] = useState<string>('');
  // const [cardLast4, setCardLast4] = useState<string>('');
  // const [cardName, setCardName] = useState<string>('');
  // const [cardExpMonth, setCardExpMonth] = useState<string>('');
  // const [cardExpYear, setCardExpYear] = useState<string>('');

  useEffect(() => {
    // ローカルストレージより値取得
    // setCardBrand(localStorage.getItem('stripe_token_card_brand') ?? '');
    // setCardLast4(localStorage.getItem('stripe_token_card_last4') ?? '');
    // setCardName(localStorage.getItem('stripe_token_card_name') ?? '');
    // setCardExpMonth(localStorage.getItem('stripe_token_card_exp_month') ?? '');
    // setCardExpYear(localStorage.getItem('stripe_token_card_exp_year') ?? '');
  }, []);

  return (
    <>
      <StyeldConfirmation>
        <StyledFormLabel>クレジットカード確認</StyledFormLabel>
        <p className="font">
          {cardBrand}
          <br />
          **** **** **** {cardLast4}
          <br />
          {cardName}
          <br />
          有効期限 {cardExpMonth} / {cardExpYear} <br />
        </p>
        <br />
        <div className="flex">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={onClickEditing}
            className="button"
          >
            修正する
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={onClickRegistration}
            className="button"
          >
            登録する
          </Button>
        </div>
      </StyeldConfirmation>
    </>
  );
};

export default Confirmation;
