import { Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import WPFooter from '../components/Common/WPFooterAxios';
import { wpFooterData } from '../utils/WPRequest';

const StyledPrivacyPolicy = styled.div`
  padding: 10px;
  h2 {
    text-align: center;
    font-weight: bold;
    margin: 10px 0px;
    font-size: 2rem;
    border-bottom: 5px solid #c69e61;
    padding-bottom: 10px;
    padding-top: 30px;
    margin-bottom: 20px;

    @media screen and (max-width: 751px) {
       {
        font-size: 1.2rem;
      }
    }
  }

  h2:first-child {
    padding-top: 0px;
    // color: white;
  }

  h3 {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 30px 0px 10px;
    // color: #231815;
    // color: white;
  }

  h4 {
    font-weight: bold;
    padding: 20px 0px 10px;
    // color: white;
  }

  p {
    line-height: 1.2rem;
  }

  ol li {
    line-height: 1.2rem;
    margin-left: 1rem;
    margin-bottom: 10px;
    list-style: decimal;
    // color: white;
  }

  ul li {
    margin-top: 1rem;
    margin-left: 3rem;
    // color: white;
  }

  @media screen and (max-width: 751px) {
    #Wrap #privacy ul li {
      margin-left: 1.5rem;
      // color: white;
    }
  }
`;

/**
 * 個人情報保護に関するページ
 */
const PrivacyPolicy: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [footerText, setFooterText] = useState('');

  useEffect(() => {
    const getContents = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const response: wpFooterData = await WPFooter.get(`149`);
        setFooterText(response.data.content.rendered ?? '');
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    void getContents();
  }, []);

  return (
    <StyledPrivacyPolicy>
      {isError && (
        <Typography gutterBottom variant="body1" align="center" color="error">
          エラーが発生しました
          <br />
        </Typography>
      )}
      {isLoading ? (
        <Loading loadingCode="Load_002" />
      ) : (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: footerText,
            }}
          />
        </>
      )}
    </StyledPrivacyPolicy>
  );
};

export default PrivacyPolicy;
