import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FC, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Auth0LockContext, { getAuthProfile, isAuthenticated } from 'Auth0LockContext';
import Loading from 'HTB-Videos/components/Common/Loading';
import initialTheme from 'HTB-Videos/layouts/MuiTheme';
import {
  useGetUserQuery,
  // useGetOrderQuery,
  // useListCardsQuery,
  useListSortCardsQuery,
  // useListOrdersQuery,
  useListSortOrdersQuery,
  useDeleteUserMutation,
  useDeleteCardMutation,
  // useDeleteOrderMutation,
  useListSortGoodsOrdersQuery,
  useListSortDeliverysQuery,
  useDeleteDeliveryMutation,
} from 'graphql/appsync/generated';
import Message from '../components/Common/Message';
import CardEditFrom from '../components/MyPage/CardEditFrom';
import CardList from '../components/MyPage/CardList';
import DeliveryAddressEditForm from '../components/MyPage/DeliveryAddressEditForm';
import DeliveryAddressList from '../components/MyPage/DeliveryAddressList';
import GoodsOrderList from '../components/MyPage/GoodsOrderList';
import OrderList from '../components/MyPage/OrderList';
import UserEditForm from '../components/MyPage/UserEditFrom';
import UserStyle from '../components/MyPage/UserStyle';
import WithdrawalForm from '../components/MyPage/WithdrawalForm';
import LogoutIcon from '../images/ico_logout.png';

/**
 * マテリアルUIのスタイルシート
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    // heading: {
    //   fontSize: theme.typography.pxToRem(15),
    //   fontWeight: theme.typography.fontWeightRegular,
    // },
    center: {
      textAlign: 'center',
      // color: '#fff',
    },
    modalLayout: {
      // color: '#fff',
      textAlign: 'center',
      marginLeft: '10px',
      marginRight: '10px',
      color: initialTheme.palette.secondary.main,
    },
    paper: {
      display: 'block',
      marginRight: '-50%',
      position: 'absolute',
      maxWidth: '300px',
      WebkitJustifyContent: 'center',
      justifyContent: 'center',
      WebkitAlignItems: 'center',
      // backgroundColor: '#fff3b8',
      backgroundColor: initialTheme.palette.primary.main,
      border: '2px solid #fff',
      boxShadow: theme.shadows[2],
      // color: '#fff',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      outline: 'none',
      paddingBottom: '32px',
      paddingTop: '32px',
      paddingLeft: '20px',
      paddingRight: '20px',
      borderRadius: '16px',
    },
  }),
);

// Drawer表示内容のベーススタイル
const StyledDrawer = styled.div`
  padding: 2%;
  height: 80vh;
  @media screen and (max-width: 1025px) {
     {
      min-height: 400px;
    }
  }
`;

const StyledColor = styled.div`
  // p {
  //   color: black !important;
  // }

  .linkIndex {
    text-align: center;
    margin: 20px auto 0px auto;
    // color: white;
  }
`;

const StyledMyPageWrapper = styled.div`
  max-width: 900px;
  margin: 0px auto;
`;

const StyledTitle = styled(Typography)`
  border-bottom: 2px solid;
  padding-bottom: 0.3rem;
`;

const StyledLogoutIcon = styled.img`
  margin-bottom: 16px;
  height: 60px;
  justify-content: center;
`;

/**
 * マイページ
 */
const MyPage: FC = () => {
  const auth0Lock = useContext(Auth0LockContext);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [isWithdrawalForm, setIsWithdrawalForm] = React.useState(false);
  const [isUserEditFrom, setIsUserEditFrom] = React.useState(false);
  const [isCardEditFrom, setIsCardEditFrom] = React.useState(false);
  const [cardId, setCardId] = React.useState('');
  const [deliverlyId, setDeliverlyId] = React.useState('');
  const [isDeliveryEditFrom, setIsDeliveryEditFrom] = React.useState(false);

  // User Table Graphql
  const {
    data: getUserData,
    loading: isGetUserLoading,
    error: getUserError,
    refetch: refetchUserData,
  } = useGetUserQuery({
    variables: { id: getAuthProfile().sub ?? '' },
  });

  const logout = () => {
    // localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('photo_url');
    auth0Lock.client.logout({
      returnTo: `${window.location.protocol}//${window.location.host}`,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const [
    deleteUserMutation,
    {
      data: deleteUserData,
      // loading: isDeleteUserLoading, // 画面がちらつくので何もしない
      // error: deleteUserError,
    },
  ] = useDeleteUserMutation();
  useEffect(() => {
    void refetchUserData();
  }, [deleteUserData, refetchUserData]);

  // Card Table Graphql
  const {
    data: listCardData,
    loading: isListCardLoading,
    error: listCardError,
    refetch: refetchCardData,
    // } = useListCardsQuery(); // 20210514 不具合修正
  } = useListSortCardsQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
    },
  });
  const [
    deleteCardMutation,
    {
      data: deleteCardData,
      // loading: isDeleteCardLoading, // 画面がちらつくので何もしない
      error: deleteCardError,
    },
  ] = useDeleteCardMutation();
  useEffect(() => {
    void refetchCardData();
  }, [deleteCardData, refetchCardData]);

  // Order Table Graphql
  const {
    data: listOrderData,
    loading: isListOrderLoading,
    // error: listOrderError,       // 使わない
    // refetch: refetchOrderData, // 使わない
  } = useListSortOrdersQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
      filter: {
        buyState: {
          ne: 'NotBought',
        },
      },
    },
  });
  /*
  const [
    deleteOrderMutation,
    {
      data: deleteOrderData,
      // loading: isDeleteCardLoading, // 画面がちらつくので何もしない
      // error: deleteOrderError,
    },
  ] = useDeleteOrderMutation();
  useEffect(() => {
    void refetchOrderData();
  }, [deleteOrderData, refetchOrderData]);
  */

  /*
  const {
    data: getOrderData,
    loading: getOrderLoading,
    error: getOrderError,
  } = useGetOrderQuery({
    variables: { id: getAuthProfile().sub ?? '' },
  });
  */

  // Goods Order Table Graphql
  const {
    data: listGoodsOrderData,
    // loading: isListGoodsOrderLoading,
  } = useListSortGoodsOrdersQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
    },
  });
  // 住所取得
  const {
    data: listDeliveryData,
    loading: isListDeliveryDataLoading,
    error: listDeliveryDataError,
    refetch: refetchDeliveryData,
  } = useListSortDeliverysQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
    },
  });

  // 住所削除ß
  const [DeleteDeliveryMutation, { data: deleteDeliveryData, error: deleteDeliveryDataError }] =
    useDeleteDeliveryMutation();
  useEffect(() => {
    void refetchDeliveryData();
  }, [deleteDeliveryData, refetchDeliveryData]);

  // セッション切れの画面
  if (!isAuthenticated()) return <Message messageCode="ERR_000201" className={classes.center} />;

  // レスポンス遅い時に表示される？
  if (isGetUserLoading || isListCardLoading || isListOrderLoading || isListDeliveryDataLoading)
    // return <Message messageCode="ERR_000302" />;
    return <Loading />;

  // Graphqlがエラー
  if (getUserError || listCardError || deleteCardError || listDeliveryDataError || deleteDeliveryDataError)
    return <Message messageCode="ERR_000301" className={classes.center} />;

  return (
    <StyledMyPageWrapper>
      <div className={classes.root}>
        <StyledColor>
          <StyledTitle variant="h2" color="inherit" align="center" gutterBottom>
            マイページ
          </StyledTitle>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel0a-content" id="panel0a-header">
              <Typography
                // className={classes.heading}
                variant="body1"
                color="inherit"
              >
                プロフィール
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {getUserData?.getUser ? (
                <UserStyle user={getUserData.getUser} />
              ) : (
                <Typography
                  // className={classes.heading}
                  variant="body1"
                  color="inherit"
                >
                  未登録
                </Typography>
              )}
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button size="small" onClick={handleOpen}>
                <Typography variant="body1" color="inherit">
                  ログアウト
                </Typography>
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modalLayout}
              >
                <div className={classes.paper}>
                  {/* <Typography gutterBottom variant="body1" align="center">
                    <StyledLogoutIcon alt="Iconimg" src={LogoutIcon} />
                    <p id="simple-modal-title" className={classes.modalLayout}>
                      <b>
                        {process.env.REACT_APP_AUTH0_LOGIN_TITLE}
                        <br />
                        からログアウトしますか？
                      </b>
                    </p>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClose}
                      className={classes.modalLayout}
                    >
                      キャンセル
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={logout}
                      className={classes.modalLayout}
                    >
                      ログアウト
                    </Button>
                  </Typography> */}
                  <Typography gutterBottom variant="body1" align="center" style={{ wordBreak: 'break-word' }}>
                    <StyledLogoutIcon alt="Iconimg" src={LogoutIcon} />
                    <p id="simple-modal-title" className={classes.modalLayout}>
                      {process.env.REACT_APP_AUTH0_LOGIN_TITLE}
                      <br />
                      からログアウトしますか？
                    </p>
                    <br />

                    <Button variant="contained" color="primary" onClick={handleClose} className={classes.modalLayout}>
                      キャンセル
                    </Button>
                    <Button variant="contained" color="primary" onClick={logout} className={classes.modalLayout}>
                      ログアウト
                    </Button>
                  </Typography>
                </div>
              </Modal>
              <Button size="small" onClick={() => setIsWithdrawalForm(true)}>
                <Typography variant="body1" color="inherit">
                  退会
                </Typography>
              </Button>
              <Button size="small" onClick={() => setIsUserEditFrom(true)}>
                <Typography variant="body1" color="inherit">
                  {getUserData?.getUser ? '編集する' : '登録する'}
                </Typography>
              </Button>
            </AccordionActions>
          </Accordion>
          {getUserData?.getUser && (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography
                    // className={classes.heading}
                    variant="body1"
                    color="inherit"
                  >
                    クレジットカード情報
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {(listCardData?.listSortCards?.items?.length ?? 0) > 0 ? (
                    <FormControl fullWidth component="label">
                      <RadioGroup
                        value={cardId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setCardId((e.target as HTMLInputElement).value)
                        }
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {listCardData?.listSortCards?.items?.map((card) => (
                              <Grid container spacing={3} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                  <CardList card={card ?? undefined} />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <Typography variant="body1" color="inherit">
                      未登録
                    </Typography>
                  )}
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button size="small" onClick={() => setIsCardEditFrom(true)}>
                    <Typography variant="body1" color="inherit">
                      カードを追加する
                    </Typography>
                  </Button>
                  <Button
                    size="small"
                    disabled={cardId.length === 0}
                    onClick={() => {
                      if (cardId)
                        void deleteCardMutation({
                          variables: { input: { id: cardId } },
                        });
                    }}
                  >
                    <Typography variant="body1" color="inherit">
                      カードを削除する
                    </Typography>
                  </Button>
                </AccordionActions>
              </Accordion>

              {/* 住所追加ここから */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography
                    // className={classes.heading}
                    variant="body1"
                    color="inherit"
                  >
                    配送先住所情報
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {(listDeliveryData?.listSortDeliverys?.items?.length ?? 0) > 0 ? (
                    <FormControl fullWidth component="label">
                      <RadioGroup
                        value={deliverlyId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setDeliverlyId((e.target as HTMLInputElement).value)
                        }
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {listDeliveryData?.listSortDeliverys?.items?.map((delivery) => (
                              <Grid container spacing={3} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                  <DeliveryAddressList delivery={delivery ?? undefined} />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <Typography variant="body1" color="inherit">
                      未登録
                    </Typography>
                  )}
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button size="small" onClick={() => setIsDeliveryEditFrom(true)}>
                    <Typography variant="body1" color="inherit">
                      住所を追加する
                    </Typography>
                  </Button>
                  <Button
                    size="small"
                    disabled={deliverlyId.length === 0}
                    onClick={() => {
                      if (deliverlyId)
                        void DeleteDeliveryMutation({
                          variables: { input: { id: deliverlyId } },
                        });
                    }}
                  >
                    <Typography variant="body1" color="inherit">
                      住所を削除する
                    </Typography>
                  </Button>
                </AccordionActions>
              </Accordion>
              {/* 住所追加ここまで */}

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography
                    // className={classes.heading}
                    variant="body1"
                    color="inherit"
                  >
                    購入履歴
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {(listOrderData?.listSortOrders?.items?.length ?? 0) > 0 ? (
                        <>
                          {listOrderData?.listSortOrders?.items?.map((order) => (
                            <Grid container spacing={3} alignItems="center" justify="center">
                              <Grid item xs={12}>
                                <OrderList order={order ?? undefined} />
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <Typography variant="body1" color="inherit">
                          購入履歴はありません。
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography
                    // className={classes.heading}
                    variant="body1"
                    color="inherit"
                  >
                    グッズ購入履歴
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {(listGoodsOrderData?.listSortGoodsOrders?.items?.length ?? 0) > 0 ? (
                        <>
                          {listGoodsOrderData?.listSortGoodsOrders?.items?.map((order) => (
                            <Grid container spacing={3} alignItems="center" justify="center">
                              <Grid item xs={12}>
                                {order && <GoodsOrderList order={order} />}
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <Typography variant="body1" color="inherit">
                          購入履歴はありません。
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          <Drawer anchor="bottom" open={isUserEditFrom} onClose={() => setIsUserEditFrom(false)}>
            <StyledDrawer>
              <ClearIcon
                aria-label="閉じる"
                fontSize="large"
                aria-controls="tag-x"
                aria-haspopup="false"
                onClick={() => {
                  setIsUserEditFrom(false);
                }}
                color="inherit"
              />
              <UserEditForm
                user={getUserData?.getUser ?? undefined}
                onEdit={() => {
                  void refetchUserData();
                  void setIsUserEditFrom(false);
                }}
              />
            </StyledDrawer>
          </Drawer>

          {getUserData?.getUser && (
            <Drawer anchor="bottom" open={isWithdrawalForm} onClose={() => setIsWithdrawalForm(false)}>
              <StyledDrawer>
                <ClearIcon
                  aria-label="閉じる"
                  fontSize="large"
                  aria-controls="tag-x"
                  aria-haspopup="false"
                  onClick={() => {
                    setIsWithdrawalForm(false);
                  }}
                  color="inherit"
                />
                <WithdrawalForm
                  user={getUserData?.getUser}
                  onCancel={() => {
                    // ドロワー終了
                    void setIsWithdrawalForm(false);
                  }}
                  onConsent={() => {
                    // カード削除
                    if (listCardData?.listSortCards?.items) {
                      listCardData.listSortCards.items.forEach((card) => {
                        if (card)
                          void deleteCardMutation({
                            variables: { input: { id: card.id } },
                          });
                      });
                    }
                    // 住所削除
                    if (listDeliveryData?.listSortDeliverys?.items) {
                      listDeliveryData.listSortDeliverys.items.forEach((delivery) => {
                        if (delivery)
                          void DeleteDeliveryMutation({
                            variables: { input: { id: delivery.id } },
                          });
                      });
                    }
                    // ユーザー削除
                    if (getUserData?.getUser) {
                      void deleteUserMutation({
                        variables: { input: { id: getUserData.getUser.id } },
                      });
                    }
                    // ローカルストレージからチェックアウトID削除
                    localStorage.removeItem('goodsCartId');
                    // logout処理
                    void logout();
                    // ドロワー終了
                    void setIsWithdrawalForm(false);
                  }}
                />
              </StyledDrawer>
            </Drawer>
          )}

          {getUserData?.getUser && (
            <Drawer anchor="bottom" open={isCardEditFrom} onClose={() => setIsCardEditFrom(false)}>
              <StyledDrawer>
                <ClearIcon
                  aria-label="閉じる"
                  fontSize="large"
                  aria-controls="tag-x"
                  aria-haspopup="false"
                  onClick={() => {
                    setIsCardEditFrom(false);
                  }}
                  color="inherit"
                />
                <CardEditFrom
                  user={getUserData?.getUser}
                  onEdit={() => {
                    void refetchCardData();
                    void setIsCardEditFrom(false);
                  }}
                />
              </StyledDrawer>
            </Drawer>
          )}

          {getUserData?.getUser && (
            <Drawer anchor="bottom" open={isDeliveryEditFrom} onClose={() => setIsDeliveryEditFrom(false)}>
              <StyledDrawer>
                <ClearIcon
                  aria-label="閉じる"
                  fontSize="large"
                  aria-controls="tag-x"
                  aria-haspopup="false"
                  onClick={() => {
                    setIsDeliveryEditFrom(false);
                  }}
                  color="inherit"
                />
                <DeliveryAddressEditForm
                  // user={getUserData?.getUser}
                  onEdit={() => {
                    void refetchDeliveryData();
                    void setIsDeliveryEditFrom(false);
                  }}
                />
              </StyledDrawer>
            </Drawer>
          )}
          <div className="linkIndex">
            <Button variant="contained" color="primary" component={Link} to="/">
              <Typography variant="body1" color="inherit">
                トップへ戻る
              </Typography>
            </Button>
          </div>
        </StyledColor>
      </div>
    </StyledMyPageWrapper>
  );
};

export default MyPage;
