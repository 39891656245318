import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { MessageCode } from 'utils/Message';

type Props = {
  /**
   * メッセージコード
   */
  messageCode: MessageCode;

  /**
   * クラス名
   */
  className?: string;
};

/**
 * メッセージを表示するコンポーネント
 */
const Message: FC<Props> = ({ messageCode, className }) => {
  switch (messageCode) {
    // ---------- INFO汎用 ----------
    case 'INFO_000001': {
      return (
        <div className={className}>
          ありがとうございました！
          <br />
          購入履歴の確認は
          <Typography component={Link} to="/MyPage" color="secondary">
            こちら
          </Typography>
          から
          <br />
          （反映にお時間を頂く場合がございます）
        </div>
      );
    }
    // ---------- ERROR汎用 ----------
    case 'ERR_000001': {
      return <div className={className}>予期せぬエラーが発生しました。</div>;
    }
    case 'ERR_000002': {
      return <div className={className}>エラーが発生しました。時間をおいて、もう一度お試しください。</div>;
    }
    case 'ERR_000003': {
      return <div className={className}>エラーが発生しました。リロードして再度お試しください。</div>;
    }

    // ---------- Stripe ----------
    case 'ERR_000101': {
      // 支払い失敗
      return (
        <div className={className}>
          {/* ご注文中にエラーが発生しました。
          時間をおいて、もう一度お試しください。 */}
          ご注文中にエラーが発生しました。時間をおいて、もう一度お試しください。
          <br />
          クレジットカードの有効期限切れやセキュリティコードが誤っている可能性もございます。
          <br />
          恐れ入りますが、マイページにて、ご確認いただきますようお願いいたします。
          <br />
        </div>
      );
    }
    case 'ERR_000102': {
      // カード追加時、CVCエラー
      return (
        <div className={className}>
          ご入力いただいたクレジットカードのセキュリティコードが誤っております。
          <br />
          セキュリティコードは、クレジットカード裏面（カード会社によっては表面）に記載の3桁もしくは4桁の数字になります。
          <br />
          半角数字で再度入力をお願いします。
          <br />
        </div>
      );
    }
    case 'ERR_000103': {
      // カード追加時、有効期限切れ
      return (
        <div className={className}>
          {/* ご入力いただいたクレジットカードの有効期限が切れており決済完了できませんでした。 */}
          ご入力いただいたクレジットカードの有効期限が切れております。
        </div>
      );
    }
    case 'ERR_000104': {
      // カード追加時、例外処理エラー
      return (
        <div className={className}>
          {/* ご注文中にエラーが発生しました。 もう一度お試しください。 */}
          エラーが発生しました。別のカードでお試しいただくか、時間をおいて、もう一度お試しください。
        </div>
      );
    }
    /* case 'ERR_000105': {
      return (
        <div className={className}>
          ご注文中にエラーが発生しました。
          恐れ入りますが、エラーが発生したクレジットカードについて問題が無いか、ご利用のクレジットカード会社様にご確認いただきますようお願いいたします。
        </div>
      );
    }
    case 'ERR_000106': {
      return (
        <div className={className}>
          エラーが発生しました。別のカードでお試しいただくか、時間をおいて、もう一度お試しください。
        </div>
      );
    } */

    // ---------- Auth0 ----------
    case 'ERR_000201': {
      return <div className={className}>セッションが無効です。再ログインしてください。</div>;
    }

    // ---------- Appsync ----------
    case 'ERR_000301': {
      return <div className={className}>エラーが発生しました。リロードして再度お試しください</div>;
    }
    case 'ERR_000302': {
      return <div className={className}>loading...</div>;
    }
    // ---------- StepFunctions ----------
    case 'ERR_000401': {
      return (
        <div className={className}>
          すでに注文を受け付けております。
          <br />
          マイページの注文履歴からご確認ください
          <br />
          （反映にお時間を頂く場合がございます）
          <br />
          マイページは(
          <Typography component={Link} to="/MyPage" color="secondary">
            こちら
          </Typography>
          )
        </div>
      );
    }
    case 'ERR_000402': {
      return (
        <div className={className}>
          購入希望の商品の中に在庫が不足しているものが含まれておりました。今回の注文はキャンセルさせていただきますので、改めて商品をご確認の上、再度お試しくださいますようお願いいたします。
        </div>
      );
    }
    // ---------- メッセージ未実装 ----------
    default: {
      return <div className={className}>メッセージが設定されていません。</div>;
    }
  }
};

export default Message;
