import { Typography, Grid, Box } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';
import axios from 'axios';
import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router';
// import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import styled from 'styled-components';
// import { isAuthenticated } from 'Auth0LockContext';
import Breadcrumbs from 'HTB-Videos/components/Common/Breadcrumbs';
import DRMplayer from 'HTB-Videos/components/Common/DRMplayer';
import Loading from 'HTB-Videos/components/Common/Loading';
// import THEOPlayer from 'HTB-Videos/components/Common/THEOPlayer';
// import LiveSalesArea from 'HTB-Videos/components/LiveSalesArea/Container/LiveSalesArea';
import { wpParentContentData } from 'HTB-Videos/utils/WPRequest';

// youtube動画の比固定
const StyledYoutube = styled.div`
   {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0px;
    position: relative;
    margin-bottom: 1.5rem;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

// チケット販売ページ
const EventPage: FC = () => {
  const { parentId } = useParams();

  const [isEventLoading, setIsEventLoading] = useState(false);
  const [isEventError, setIsEventError] = useState(false);
  const [eventContents, setEventContents] = useState<wpParentContentData>();

  // イベントデータをWPから取得
  useEffect(() => {
    const getEventContents = async () => {
      setIsEventError(false);
      setIsEventLoading(true);
      try {
        const response = await axios.get(
          `https://dxjmemwogeqvm.cloudfront.net/wp-json/wp/v2/${process.env.REACT_APP_WP_EVENT ?? ''}/${parentId}`,
          {
            params: {
              _fields: 'acf,id,title',
            },
          },
        );
        setEventContents(response.data ?? '');
      } catch (err) {
        setIsEventError(true);
      }
      setIsEventLoading(false);
    };
    void getEventContents();
  }, [parentId]);

  return (
    <>
      {/* パンクズリスト */}
      <Breadcrumbs
        breads={[
          {
            to: '/',
            icon: <HomeIcon />,
            text: 'HOME',
          },
          {
            icon: <ListAltIcon />,
            text: eventContents?.title.rendered ?? '',
          },
        ]}
        substrLength={16}
      />
      <br />
      {isEventError && (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" align="center" color="error">
              エラーが発生しました
              <br />
            </Typography>
          </Grid>
        </Grid>
      )}
      {isEventLoading ? (
        <>
          <Loading loadingCode="Load_002" />
        </>
      ) : (
        <>
          <Grid container spacing={3} alignItems="center" justify="center">
            {eventContents && (
              <>
                {/* 動画表示エリア */}
                {eventContents.acf.isYouTubeContent ? (
                  <Grid item xs={12}>
                    <StyledYoutube>
                      <YouTube videoId={eventContents.acf.youtubeVideoId} />
                    </StyledYoutube>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {eventContents.acf.sampleIsDRM ? (
                      <DRMplayer
                        srcDASH={eventContents?.acf?.sampleSrcDASH ?? ''}
                        srcHLS={eventContents?.acf?.sampleSrcHLS}
                      />
                    ) : (
                      // <THEOPlayer
                      //   source={{
                      //     sources: [
                      //       {
                      //         src: eventContents.acf.sampleSrcHLS ?? '',
                      //         type: 'application/x-mpegurl',
                      //       },
                      //     ],
                      //     poster: eventContents.acf.thumbnailURL,
                      //   }}
                      //   onPlay={() => {
                      //     // console.log('The player has started playing.');
                      //   }}
                      // />
                      <StyledYoutube>
                        <img alt="event" src={eventContents.acf.thumbnailURL} />
                      </StyledYoutube>
                    )}
                  </Grid>
                )}
                {/* タイトル */}
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h2" align="center" color="inherit">
                    {eventContents.title.rendered}
                  </Typography>
                </Grid>
                {/* イベント概要 */}
                <Box m={2} pb={4} style={{ wordBreak: 'break-word' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box mb={1} display="flex" alignItems="left">
                        {/* <Typography variant="subtitle1" color="inherit">
                          販売セット概要
                        </Typography> */}
                        <Typography variant="subtitle1" color="inherit">
                          Comming soon ...
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        color="inherit"
                        align="left"
                        dangerouslySetInnerHTML={{
                          __html: eventContents?.acf.overview ?? '',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            {/* 商品表示エリア */}
            {/* <Grid item xs={12}>
              <LiveSalesArea
                title={eventContents?.title?.rendered ?? ''}
                viewMode={eventContents?.acf?.viewMode ?? undefined}
              />
            </Grid> */}
          </Grid>
        </>
      )}
    </>
  );
};

export default EventPage;
