import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { FC, useState } from 'react';
import Loading from 'HTB-Videos/components/Common/Loading';
import { User } from 'graphql/appsync/generated';
import { MessageCode } from 'utils/Message';
import Message from '../Common/Message';

type Props = {
  /**
   * 更新対象のユーザー情報
   */
  user: User;
  /**
   * キャンセル時に実行される関数
   */
  onCancel?: VoidFunction;
  /**
   * 最終確認後に実行される関数
   */
  onConsent?: VoidFunction;
};

/*
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer_root: {
      width: '100%',
      '& .MuiTextField-root': {
        margin: theme.spacing(3),
        width: '90%',
      },
    },
    center: {
      textAlign: 'center',
    },
  }),
);
*/

const WithdrawalForm: FC<Props> = ({ user, onCancel, onConsent }) => {
  // const classes = useStyles();

  const [errorMsgView, setErrorMsgView] = useState<boolean>(false);
  const [messageCode, setMessageCode] = useState<MessageCode>('ERR_000001');
  const [sending, setSending] = useState<boolean>(false);
  const [formView, setFormView] = useState<boolean>(true);

  /**
   * 退会処理SF実行
   */
  const onClick = async () => {
    // event.preventDefault(); // リロード防止
    setSending(true);
    setFormView(false);

    try {
      const today = new Date();
      await axios
        // .post(URL_SEND, sendData)
        .post(
          process.env.REACT_APP_WITHDRAWAL_START_EXECUTION_URL ?? '',
          {
            input: {
              authType: 'OIDC',
              auth: localStorage.getItem('id_token'),
              userId: user.id,
              date: today.getFullYear() * 10000 + today.getMonth() * 100 + today.getDay(),
            },
            stateMachineArn: process.env.REACT_APP_WITHDRAWAL_STEP_FUNCTIONS_ARN,
          },
          {
            headers: {
              Authorization: localStorage.getItem('id_token'),
            },
          },
        )
        .then((response) => {
          if (response.status !== 200) {
            // データ送信失敗
            // console.log(`データ送信 失敗: ${response.status}`);
            setMessageCode('ERR_000002');
            setErrorMsgView(true);
            setSending(false);

            return;
          }

          // console.log('成功');
          if (onConsent) {
            void onConsent();
          }
        });
    } catch (e) {
      // console.log('エラー発生');
      // console.log(e);

      setMessageCode('ERR_000002');
      setErrorMsgView(true);
      setSending(false);
    }
  };

  return (
    <>
      {/* <form className={classes.drawer_root} onSubmit={sendUserEdit} onSubmit={handleSubmit(onSubmit)}> */}
      {formView && (
        <div>
          <div role="presentation">
            <br />
            <Grid container alignItems="center" justify="center">
              <Typography variant="h6" noWrap>
                本当に退会してよろしいですか
              </Typography>
            </Grid>
          </div>
          <br />
          <br />
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Button size="medium" variant="contained" color="primary" onClick={onClick}>
                退会します
              </Button>
            </Grid>
          </Grid>
          <br />
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Button size="medium" variant="contained" color="primary" onClick={onCancel}>
                キャンセル
              </Button>
            </Grid>
          </Grid>
          <br />
        </div>
      )}
      {sending && (
        <>
          <Loading loadingCode="Load_003" />
        </>
      )}
      <br />
      {errorMsgView && <Message messageCode={messageCode} />}
    </>
  );
};

export default WithdrawalForm;
