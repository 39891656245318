import { useApolloClient } from '@apollo/client';
import {
  ThemeProvider,
  createMuiTheme,
  Grid,
  Button,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Box,
  Paper,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinkHref from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import * as colors from '@material-ui/core/colors';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { TabContext, TabPanel } from '@material-ui/lab';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import React, { FC, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Timeline } from 'react-twitter-widgets';
import styled from 'styled-components';
import Auth0LockContext, { getAuthProfile, isAuthenticated } from 'Auth0LockContext';
import CaravanBannerArea from 'HTB-Aircaravan2021/components/Common/CaravanBannerArea';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import Message from 'HTB-Aircaravan2021/components/Common/Message';
import Zoom from 'HTB-Aircaravan2021/components/Common/Zoom';
import FormDrawerVertical from 'HTB-Aircaravan2021/components/LiveSalesArea/FormDrawerVertical';
import LiveSalesArea from 'HTB-Aircaravan2021/components/LiveSalesArea/LiveSalesArea';
import Cart from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/Cart/Cart';
import { itemList } from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/ProductList/Container/AddToCartButton';
import ProductsListKintone from 'HTB-Aircaravan2021/components/LiveSalesArea/pages/ProductList/ProductsList';
import TwitterArea from 'HTB-Aircaravan2021/components/Twitter/Container/TwitterArea';
// import useGetKintoneCollections from 'HTB-Aircaravan2021/hooks/useGetKintoneCollections';
// import decoration from 'HTB-Aircaravan2021/images/decoration.png';
import useLazyGetKintoneCollections from 'HTB-Aircaravan2021/hooks/useLazyGetKintoneCollections';
import decoration from 'HTB-Aircaravan2021/images/decoration3.png';
import decorationSP from 'HTB-Aircaravan2021/images/decoration4.png';
import refetchQueriesContext from 'RefetchQueriesContext';
import {
  useCreateGoodsCartMutation,
  GoodsCart,
  KintoneCollection,
  // useGetKintoneCollectionLazyQuery,
  useListSortKintoneCollectionsLazyQuery,
  useGetGoodsCartLazyQuery,
  SalesType,
  Sales,
} from 'graphql/appsync/generated';
import initialTheme from './MuiTheme';

// StoryBookからも読み込むためexport
export const GlobalCSS = styled.div`
  font-size: 16px;
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.5;

  a {
    text-decoration: none;
  }

  a:hover {
    opacity: 0.5;
    transition: 0.5s;
  }
`;

const Wrap = styled.div`
  // background-color: #ffe113;
  background-color: #efefef;
  min-height: 100vh;
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 96%;
  // max-width: 1095px;
  max-width: 1600px;
  margin: 0px auto;
  // border-top: #fff solid 25px;
  // border-top: #111 solid 25px;
`;

const Section = styled.div`
  // background: #fff;
  // background: #111;
  background: #fcf4eb;
  padding: 20px 0px;
  @media screen and (max-width: 751px) {
    // padding: 10px;
    padding: 10px 0px;
  }
`;

const InnerBorder = styled.div`
  // background: #fff;
  // max-width: 1095px;
  // background: #111;
  max-width: 1600px;
  margin: 20px auto 20px;
  // border: 10px solid #c69e61;
  // border: 10px solid #111;
  padding: 20px 5px;

  @media screen and (max-width: 751px) {
     {
      // padding: 10px;
      padding: 5px;
    }
  }
`;

const StyledTitle = styled.div`
  flex-grow: 1;
  text-align: center;
  padding: 10px;
`;

// 購入に進む系のボタンの色はオレンジ
const StyledButton = styled(Button)`
  background-color: #ff9100 !important;
`;

const StyledTabTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 0px 5px;
  overflow: hidden;
`;

// const StyledAreaTitle = styled(Typography)`
//   // background: url('https://www.htb.co.jp/caravan2021/common/img/title_bg.png') no-repeat center 10px;
//   // background: url('../images/decoration.png') no-repeat center 10px;
//   background: url(${decoration}) no-repeat repeat 10px;
//   font-size: 1.7em;
//   text-align: center;
//   padding-top: 150px;
//   padding-bottom: 10px;
//   width: 98%;
//   max-width: 1095px;
//   letter-spacing: 5px;
//   margin-right: auto;
//   margin-bottom: auto;
//   margin-left: auto;
//   color: #fff;
//   min-height: 200px;
//   @media screen and (max-width: 600px) {
//     // background: url(../img/title_bg.png) no-repeat center 10px;
//     font-size: 1.2em;
//     padding-top: 62px;
//     padding-bottom: 10px;
//     min-height: 70px;
//   }
// `;

const StyledTestt = styled(Typography)`
  position: absolute;
  // top: 74%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  color: white;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    tabPanel: {
      padding: 10,
    },
    appBar: {
      width: `100%`,
    },
    center: {
      textAlign: 'center',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      marginLeft: 'auto',
      marginRight: '18%',
      width: '30%',
      height: 'auto',
      cursor: 'pointer',
      top: '10px',
    },
    footer: {
      margin: 'auto',
      height: '40px',
      width: '100%',
      textAlign: 'center',
      // color: 'inherit',
      // backgroundColor: '#333',
    },
    footerText: {
      lineHeight: '40px',
      fontSize: 'small',
      color: 'inherit',
      // color: '#fafafa',
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    image: {
      width: 100,
      height: 100,
      margin: '0px 5px',
      border: 'inherit',
    },
    img: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    selected: {
      backgroundColor: colors.orange[100],
      color: colors.orange[700],
      border: '1px inset orange',
      '&:hover': {
        backgroundColor: colors.orange[100],
        color: colors.orange[700],
      },
    },
    item2: {
      display: 'block',
      height: 0,
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      paddingTop: '12.625%',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '18%',
      },
    },
    itemImage2: {
      objectFit: 'contain',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
  }),
);

/**
 *  SalesArea表示内容
 * products:        販売商品を表示
 * cart:            カート情報を表示
 */
export type PageStatesType = 'products' | 'cart' | 'twitter' | 'notDisplayProducts';

/**
 * コレクションテーブル内のプロダクトID
 */
export type ProductIdJson = {
  productId: string;
};

/**
 * ベースとなるレイアウトを提供するコンポーネント
 */
const BaseLayout: FC = ({ children }) => {
  const { pathname } = useLocation();
  const TwitterTimelineDisplayFlg: string = process.env.REACT_APP_TWITTER_TIMELINE_DISPLAY_FLG ?? '1';

  // Auth0関係
  const auth0Lock = useContext(Auth0LockContext);
  const login = () => {
    auth0Lock.client.show({
      auth: {
        params: {
          state: `${pathname}`,
        },
      },
    });
  };

  /**
   * TOPページ描画時のキャッシュ最新化用にclientを用意
   */
  const apolloClient = useApolloClient();

  // フッターとヘッダー関係（マテリアルUI）
  const classes = useStyles();
  // const viewMode = 'live';
  // const title = 'test';

  // material-ui カスタムテーマ設定
  const theme = createMuiTheme({
    ...initialTheme,
    // 色の設定 ライト・ダークモードで色出し分ける
  });
  const isPCLayout = useMediaQuery(theme.breakpoints.up('md'));
  const isProductCardPCLayout = useMediaQuery(theme.breakpoints.up('sm'));

  // Auth0ログイン画面からのコールバック時、初期表示をカート画面にする
  const [pageState, setPageState] = useState<PageStatesType>(localStorage.getItem('auth_show') ? 'cart' : 'products');
  // LocalStorageにGoodsCartIDが設定されたか否かを保持するState
  const [isLocalCartIdStorageSetting, setIsLocalCartIdStorageSetting] = useState(false);
  // 表示する商品のリスト
  // const [productIds, setProductIds] = useState<string[]>([]);
  const [collections, setCollections] = useState<KintoneCollection[]>([]);
  const [priorityCollections, setPriorityCollections] = useState<KintoneCollection[]>([]);

  // タブ表示制御
  const [collectionId, setCollectionId] = React.useState('dummy');
  // サブスクリプション取得用
  const refetchQueries = useContext(refetchQueriesContext);
  // ドロワー用
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cartId, setCartId] = useState('dummy');

  // eslint-disable-next-line
  const handleChange = (event: React.ChangeEvent<any>, newValue: string) => {
    setCollectionId(newValue);
  };

  // --------------------------- APIGateway経由でデータを更新・取得するhooks(未認証 (未ログイン時) 用) -------------------
  const {
    getKintoneCollections: getPriorityCollectionsFromAPIGateway,
    kintoneCollection: priorityCollectionFromAPIGateway,
  } = useLazyGetKintoneCollections(`${process.env.REACT_APP_WP_AIRCARAVAN_ARTICLE_ID ?? ''}_priority`);

  const {
    getKintoneCollections: getCollectionsFromAPIGateway,
    kintoneCollection: collectionFromAPIGateway,
    isError: isAPIGatewayError,
    isLoading: isAPIGatewayLoading,
  } = useLazyGetKintoneCollections(process.env.REACT_APP_WP_AIRCARAVAN_ARTICLE_ID ?? '');

  // ------------------------------------LiveSalesArea(Container)からの移植---------------------------------

  // --------------------------- Appsync経由でデータを更新・取得するhooks(認証済み（ログイン時）用) -----------------------

  // TOPページ描画時はキャッシュを最新化（一度キャッシュを保持した後にマイページ等の別ページでサブスクリプションが発生した場合の対応）
  useEffect(() => {
    void (async () => {
      if (isAuthenticated()) {
        await apolloClient.resetStore();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // GoodsCartIDを発行するhooks
  const [
    goodsCartIdMutation,
    { data: goodsCartIdMutationData, loading: isGoodsCartIdLoading, error: goodsCartIdMutationError },
  ] = useCreateGoodsCartMutation({
    variables: {
      input: {
        userId: getAuthProfile().sub ?? '',
        // items: '[]',
      },
    },
  });

  // kintoneCollectionテーブルの情報を取得するhooks
  /*
  const [getCollection, { data: collectionData, loading: collectionDataLoading }] = useGetKintoneCollectionLazyQuery({
    variables: {
      id: `${parentId}`,
    },
  });
  */

  // kintoneCollectionテーブルの情報を取得するhooks
  // const [listPriorityCollection, { data: listPriorityCollectionData, refetch: refetchPriorityCollectionData }] =
  const [listPriorityCollection, { data: listPriorityCollectionData }] = useListSortKintoneCollectionsLazyQuery({
    variables: {
      wpId: `${process.env.REACT_APP_WP_AIRCARAVAN_ARTICLE_ID ?? ''}_priority`,
      sortDirection: 'ASC',
    },
  });

  // Graphqlコレクション取得（優先）のサブスクリプション
  useEffect(() => {
    refetchQueries.queries.forEach((query) => {
      if (
        query.data &&
        // eslint-disable-next-line
        query.data.__typename === 'KintoneCollection' &&
        query.data.wpId === `${process.env.REACT_APP_WP_AIRCARAVAN_ARTICLE_ID ?? ''}_priority`
      ) {
        // 優先表示エリアに商品表示
        if (query.name === 'CreateKintoneCollection') {
          void setPriorityCollections([query.data]);
          void refetchQueries.removeQuery({ name: 'CreateKintoneCollection', data: undefined });

          // 優先表示エリアに商品非表示
        } else if (query.name === 'DeleteKintoneCollection') {
          void setPriorityCollections([]);
          void refetchQueries.removeQuery({ name: 'DeleteKintoneCollection', data: undefined });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchQueries]);

  // kintoneCollectionテーブルの情報を取得するhooks
  const [listCollection, { data: listCollectionData, loading: listCollectionDataLoading }] =
    useListSortKintoneCollectionsLazyQuery({
      variables: {
        wpId: process.env.REACT_APP_WP_AIRCARAVAN_ARTICLE_ID ?? '',
        sortDirection: 'ASC',
      },
    });

  // GoodsCartテーブルの情報を取得するhooks
  // localstrageにgoodsCartIDなければgoodsCartIdMutationDataからID取得、それでもなければ空で渡す
  const [getGoodsCart, { data: goodsCartData, loading: isGoodsCartDataLoading, error: goodsCartDataError }] =
    useGetGoodsCartLazyQuery({
      variables: { id: localStorage.getItem('goodsCartId') ?? goodsCartIdMutationData?.createGoodsCart?.id ?? '' },
    });

  useEffect(() => {
    // ApolloErrorが発生しネットワークが起因じゃない場合は再発番
    if (goodsCartDataError && goodsCartDataError.networkError === null) {
      void goodsCartIdMutation().then((response) => {
        if (response?.data?.createGoodsCart?.id) {
          localStorage.setItem('goodsCartId', response?.data?.createGoodsCart?.id);
        }
      });
    }
  }, [goodsCartDataError, goodsCartIdMutation]);

  // -------------------------------------------------------------------------------------------------------------

  // --------------------------- レンダリング時に実行される処理 -------------------------------------------------------

  // 未ログインの場合の商品情報取得処理
  useEffect(() => {
    if (!isAuthenticated()) {
      getPriorityCollectionsFromAPIGateway();
      getCollectionsFromAPIGateway();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // goodsCartIdを発行する処理
  useEffect(() => {
    // 以下の二つの条件を満たす場合カートIDを発行する
    // ※goodsCartIdCreateStart: カートIDの発行を開始した時刻 カートID作成処理が正常終了した場合LocasStarageから削除される
    // 1. ログイン済みである
    // 2. LocasStrageにgoodsCartIdとgoodsCartIdCreateStartが無い（初めてこのページに訪れたユーザー）
    //    または現在LocalStrageに設定されているカートIDの決済状態がCompleteである（決済を終えて戻ってきたユーザー）
    //    またはgoodsCartIdCreateStartが現在時刻と比較して5秒以上前である（一度カートID作成処理を実行したが失敗に終わったユーザー）
    //    またはLocalStrageに保持してあるカートIDでカート情報を取得できない（カートID発行後ログアウトして別アカウントでログインしたユーザー）
    //    またはLocalStrageに保持してあるカートIDでカート情報を取得できない（なんらかの条件でカートテーブルがデータが消失）
    if (
      isAuthenticated() &&
      ((!localStorage.getItem('goodsCartId') && !localStorage.getItem('goodsCartIdCreateStart')) ||
        goodsCartData?.getGoodsCart?.orderState === 'Complete' ||
        (localStorage.getItem('goodsCartIdCreateStart') &&
          Date.now() - Number(localStorage.getItem('goodsCartIdCreateStart')) > 5000) ||
        (localStorage.getItem('goodsCartId') && goodsCartDataError) ||
        (localStorage.getItem('goodsCartId') && goodsCartData?.getGoodsCart === null))
    ) {
      void localStorage.setItem('goodsCartIdCreateStart', Date.now().toString());
      void localStorage.removeItem('goodsCartId');
      void goodsCartIdMutation().then((response) => {
        if (response?.data?.createGoodsCart?.id) {
          localStorage.setItem('goodsCartId', response?.data?.createGoodsCart?.id);
          localStorage.removeItem('goodsCartIdCreateStart');
        }
      });
    } else {
      setIsLocalCartIdStorageSetting(true);
    }
  }, [goodsCartIdMutation, goodsCartData, goodsCartDataError]);

  // 表示する商品のリストを認証状態に合わせて設定する
  useEffect(() => {
    if (isAuthenticated() && listCollectionData?.listSortKintoneCollections?.items) {
      setCollections(listCollectionData.listSortKintoneCollections.items as KintoneCollection[]);
      setCollectionId(listCollectionData.listSortKintoneCollections.items[0]?.id ?? 'dummy');
    }
  }, [listCollectionData]);
  useEffect(() => {
    if (!isAuthenticated() && collectionFromAPIGateway && collectionFromAPIGateway.length > 0) {
      setCollections(collectionFromAPIGateway);
      setCollectionId(collectionFromAPIGateway[0].id);
    }
  }, [collectionFromAPIGateway]);
  // 表示する優先商品のリストを認証状態に合わせて設定する
  useEffect(() => {
    if (isAuthenticated() && listPriorityCollectionData?.listSortKintoneCollections?.items) {
      setPriorityCollections(listPriorityCollectionData.listSortKintoneCollections.items as KintoneCollection[]);
    }
  }, [listPriorityCollectionData]);
  useEffect(() => {
    if (!isAuthenticated() && priorityCollectionFromAPIGateway) {
      setPriorityCollections(priorityCollectionFromAPIGateway);
    }
  }, [priorityCollectionFromAPIGateway]);

  // ログイン済みの場合、カート情報とコレクション情報を取得する
  useEffect(() => {
    if (isAuthenticated()) {
      void listCollection();
      void listPriorityCollection();
      void getGoodsCart();
    }
  }, [listCollection, listPriorityCollection, getGoodsCart]);

  // TwitterIconとカートIconの活性・非活性状態
  const [isIconActive, setIsIconActive] = useState(true);
  // /aircaravan2021以外のページでは商品販売エリアを表示しない
  useEffect(() => {
    if (pathname !== '/aircaravan2021') {
      setPageState('notDisplayProducts');
      setIsIconActive(false);
    }
  }, [pathname]);

  // --------------------------------------------------------------------------------------------------------------

  // goodsCartテーブルのitemsをJsonパース
  const Items =
    isGoodsCartDataLoading || (goodsCartData?.getGoodsCart?.items?.length ?? 0) < 1
      ? undefined
      : (JSON.parse(goodsCartData?.getGoodsCart?.items as string) as itemList[]);

  // LiveSalesAreaAppbarにtwitter切り替えiconを表示するか否かの状態
  const [isTwitter, setIsTwitter] = useState(false);

  // PCサイズならtwitter切り替えiconは出さない
  useEffect(() => {
    if (isPCLayout) {
      setIsTwitter(false);
    }
  }, [isPCLayout]);

  // twitterIconをクリックした時に実行する関数
  const handleClickTwitterIcon = () => {
    setIsTwitter(!isTwitter);
    if (pageState === 'twitter') setPageState('products');
    else {
      setPageState('twitter');
    }
  };

  // 商品一覧へ戻るボタンをクリックした時に実行する関数
  const BackToProductsPage = () => {
    setPageState('products');
    // setIsTwitter(!isTwitter);
    setIsTwitter(false);
    setIsIconActive(true);
  };

  // ログイン済時MypageIconをクリックした時に実行する関数
  const handleClickMypageIcon = () => {
    setPageState('notDisplayProducts');
  };

  // speedDialの設定
  const actions = [
    {
      icon: (
        <IconButton component={Link} to="/aircaravan2021/Termsofuse" onClick={() => setPageState('notDisplayProducts')}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      ),
      name: '利用規約',
    },
    // {
    //   icon: (
    //     <IconButton
    //       component={Link}
    //       to="/aircaravan2021/Environment"
    //       onClick={() => setPageState('notDisplayProducts')}
    //     >
    //       <FileCopyIcon fontSize="small" />
    //     </IconButton>
    //   ),
    //   name: '動作環境について',
    // },
    // {
    //   icon: (
    //     <IconButton component={Link} to="/aircaravan2021/Copyright" onClick={() => setPageState('notDisplayProducts')}>
    //       <FileCopyIcon fontSize="small" />
    //     </IconButton>
    //   ),
    //   name: '著作権について',
    // },
    {
      icon: (
        <IconButton
          component={Link}
          to="/aircaravan2021/businessDeal"
          onClick={() => setPageState('notDisplayProducts')}
        >
          <FileCopyIcon fontSize="small" />
        </IconButton>
      ),
      name: '特定商取引に関する法律に基づく表記',
    },
    {
      icon: (
        <IconButton
          component={Link}
          to="/aircaravan2021/PrivacyPolicy"
          onClick={() => setPageState('notDisplayProducts')}
        >
          <FileCopyIcon fontSize="small" />
        </IconButton>
      ),
      name: 'プライバシーポリシー',
    },
    // {
    //   icon: (
    //     <IconButton component={Link} to="/aircaravan2021/Company" onClick={() => setPageState('notDisplayProducts')}>
    //       <FileCopyIcon fontSize="small" />
    //     </IconButton>
    //   ),
    //   name: '運営会社について',
    // },
    {
      icon: (
        <a
          href="https://www3.htb.co.jp/cgi-bin2/webform/webform.cgi/aircaravan2021/form"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FileCopyIcon fontSize="small" />
        </a>
      ),
      name: 'お問い合わせ',
    },
    {
      icon: (
        <IconButton component={Link} to="/aircaravan2021/qa" onClick={() => setPageState('notDisplayProducts')}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      ),
      name: 'よくある質問',
    },
  ];

  const [open, setOpen] = React.useState(false);
  // const [hidden, setHidden] = React.useState(false);

  // const handleVisibility = () => {
  //   setHidden((prevHidden) => !prevHidden);
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const salesArea = () => {
    // ローディング中は動画を表示するため移動
    if (isGoodsCartIdLoading) {
      <Loading loadingCode="Load_001" />;
      // graphqlからデータの取得失敗
    } else if (isAuthenticated()) {
      if (goodsCartDataError || goodsCartIdMutationError) {
        return <Message messageCode="ERR_000301" className={classes.center} />;
      }
    } else if (isAPIGatewayError) {
      return <Message messageCode="ERR_000301" className={classes.center} />;
    }

    // データが存在しない場合
    if (collectionId === 'dummy') {
      return <></>;
    }

    // if (pageState === 'products') {
    //   return (
    //     <>
    //       <Box style={{ backgroundColor: '#EDB44B' }} pb={2} mt={2}>
    //         <div className={classes.item2}>
    //           {isPCLayout ? (
    //             <>
    //               <img src={decoration} alt="decoration" className={classes.itemImage2} />
    //               <StyledTestt variant="h3" style={{ top: '74%' }}>
    //                 グッズ販売はこちら
    //               </StyledTestt>
    //             </>
    //           ) : (
    //             <>
    //               <img src={decorationSP} alt="decorationSP" className={classes.itemImage2} />
    //               <StyledTestt variant="h3" style={{ top: '70%' }}>
    //                 グッズ販売はこちら
    //               </StyledTestt>
    //             </>
    //           )}
    //         </div>
    //       </Box>

    //       {/** 優先販売 */}
    //       {priorityCollections.length > 0 ? (
    //         <>
    //           {priorityCollections.map((collection) => (
    //             <ProductsListKintone
    //               productIds={(JSON.parse(collection?.productId ?? '[]') as { productId: string }[]).map(
    //                 (id) => id.productId,
    //               )}
    //               isLoading={false}
    //               goodsCartData={goodsCartData?.getGoodsCart as GoodsCart}
    //               salesType={collection?.salesType as SalesType}
    //               collectionSalesState={collection?.salesState as Sales}
    //               setCartId={setCartId}
    //               isProductCardPCLayout={isProductCardPCLayout}
    //               isPriorityCollection
    //             />
    //           ))}
    //         </>
    //       ) : (
    //         <></>
    //       )}

    //       {/** 通常販売 */}
    //       <Box pb={2} style={{ backgroundColor: '#fcf6ca' }}>
    //         <StyledTitle>
    //           <Typography variant="h2" color="inherit" style={{ marginTop: 10 }}>
    //             グッズカテゴリから探す
    //           </Typography>
    //         </StyledTitle>
    //         {collections.length > 0 && (
    //           <TabContext value={collectionId}>
    //             <AppBar position="static">
    //               <Tabs
    //                 value={collectionId}
    //                 onChange={handleChange}
    //                 indicatorColor="secondary"
    //                 textColor="secondary"
    //                 variant="scrollable"
    //                 // scrollButtons="desktop"
    //                 // aria-label="scrollable auto tabs example"
    //                 scrollButtons="on"
    //                 aria-label="scrollable auto tabs"
    //               >
    //                 {collections.map((collection) => (
    //                   // <Tab label={collection?.title} value={collection?.id} />
    //                   <Tab
    //                     icon={
    //                       <Paper style={{ overflow: 'hidden', width: 110, height: 140, color: 'inherit' }}>
    //                         <div className={classes.image}>
    //                           <img src={collection?.thumbnail ?? ''} alt={collection?.id} className={classes.img} />
    //                         </div>
    //                         <StyledTabTitle>{collection?.title}</StyledTabTitle>
    //                       </Paper>
    //                     }
    //                     value={collection?.id}
    //                     style={{ minWidth: 100 }}
    //                     classes={{
    //                       // root: classes.Tabroot,
    //                       selected: classes.selected,
    //                     }}
    //                   />
    //                 ))}
    //               </Tabs>
    //             </AppBar>
    //             {collections.map((collection) => (
    //               <TabPanel value={collection?.id ?? 'dummy'} className={classes.tabPanel}>
    //                 <ProductsListKintone
    //                   productIds={(JSON.parse(collection?.productId ?? '[]') as { productId: string }[]).map(
    //                     (id) => id.productId,
    //                   )}
    //                   isLoading={listCollectionDataLoading}
    //                   goodsCartData={goodsCartData?.getGoodsCart as GoodsCart}
    //                   salesType={collection?.salesType as SalesType}
    //                   collectionSalesState={collection?.salesState as Sales}
    //                   setCartId={setCartId}
    //                   isProductCardPCLayout={isProductCardPCLayout}
    //                   collectionTitle={collection?.title ?? ''}
    //                   isPriorityCollection={false}
    //                 />
    //               </TabPanel>
    //             ))}
    //           </TabContext>
    //         )}

    //         {/* <Button variant="contained" color="primary" onClick={() => setPageState('cart')} size="large"> */}
    //         <Grid container justify="center" alignItems="center">
    //           <Grid item xs={10} md={6}>
    //             <StyledButton
    //               fullWidth
    //               variant="contained"
    //               color="primary"
    //               onClick={() => setPageState('cart')}
    //               // disabled={viewMode === 'close'}
    //             >
    //               <Typography align="center" variant="subtitle1">
    //                 カートを確認
    //               </Typography>
    //             </StyledButton>
    //           </Grid>
    //         </Grid>
    //       </Box>

    //       <CaravanBannerArea />
    //       {/* </Button> */}
    //     </>
    //   );
    // }

    // グッズ販売エリアクローズ用
    if (pageState === 'products') {
      return (
        <>
          <Box pb={2} style={{ backgroundColor: '#fcf6ca' }} mt={2}>
            <StyledTitle>
              <Typography variant="h2" color="inherit" style={{ marginTop: 10 }}>
                グッズ販売は終了しました
              </Typography>
            </StyledTitle>
          </Box>
          <CaravanBannerArea />
        </>
      );
    }

    if (pageState === 'cart') {
      return (
        <>
          <div style={{ paddingTop: 10 }}>
            <AppBar position="static">
              <Toolbar>
                {/* <StyledTitle>onライン劇場グッズ！</StyledTitle> */}
                <StyledTitle>
                  <Typography variant="subtitle1" color="inherit" align="center">
                    カート確認画面
                  </Typography>
                </StyledTitle>
              </Toolbar>
            </AppBar>
          </div>
          <Cart
            previousPagingFunction={BackToProductsPage}
            goodsCart={isGoodsCartDataLoading ? undefined : (goodsCartData?.getGoodsCart as GoodsCart)}
            isLoading={listCollectionDataLoading}
            Items={Items as itemList[]}
          />
        </>
      );
    }

    if (pageState === 'notDisplayProducts') {
      return (
        <>
          <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/aircaravan2021"
              // onClick={() => setPageState('products')}
              onClick={BackToProductsPage}
            >
              動画ページへ戻る
            </Button>
          </div>
        </>
      );
    }

    if (!isPCLayout && pageState === 'twitter') {
      return (
        <>
          <div style={{ paddingTop: 10 }}>
            <AppBar position="static">
              <Toolbar>
                {/* <StyledTitle>onライン劇場グッズ！</StyledTitle> */}
                <StyledTitle>
                  <Typography variant="subtitle1" color="inherit" align="center">
                    ＃どうキャラ2021 で投稿しよう！
                  </Typography>
                </StyledTitle>
              </Toolbar>
            </AppBar>
          </div>
          <Grid container justify="center" alignItems="center">
            {/* <Grid item xs={12}>
              <TwitterArea isPCLayout={isPCLayout} maxHeightForTwitterArea={350} />
            </Grid> */}
            {TwitterTimelineDisplayFlg === '0' ? (
              <Grid item xs={12}>
                <TwitterArea isPCLayout={isPCLayout} maxHeightForTwitterArea={350} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={8} style={{ marginTop: 15, padding: 10 }}>
                {/* <Box border={1} borderColor="#CDF3F9" p={1} height="100%"> */}
                <Timeline
                  dataSource={{
                    sourceType: 'profile',
                    screenName: 'SD_UNITE2013',
                  }}
                  options={{
                    height: '400',
                    // width: '100%',
                  }}
                />
                {/* </Box> */}
              </Grid>
            )}
          </Grid>
        </>
      );
    }

    if (isPCLayout && pageState === 'twitter') {
      setPageState('products');
    }

    return <></>;
  };

  // Graphqlがエラー
  // BaseLayout内ではないどこかでエラーハンドリングすること！
  // if (goodsCartDataError || goodsCartIdMutationError || isAPIGatewayError)
  //   return <Message messageCode="ERR_000301" className={classes.center} />;
  /*
  if (isAuthenticated()) {
    if (goodsCartDataError || goodsCartIdMutationError) {
      return <Message messageCode="ERR_000301" className={classes.center} />;
    }
  } else if (isAPIGatewayError) {
    return <Message messageCode="ERR_000301" className={classes.center} />;
  }
  */
  // ------------------------------LiveSalesArea(Container)からの移植終わり-------------------------------------

  return (
    <ThemeProvider theme={theme}>
      {/* <ThemeProvider theme={(outerTheme) => ({ theme, ...outerTheme })}> */}
      {/* <div className={classes.root}> */}
      <CssBaseline />
      {/* <ThemeProvider theme={(outerTheme) => ({ theme, ...outerTheme })}> */}
      {/* </ThemeProvider> */}
      <>
        <LiveSalesArea
          isLoading={
            // listCollectionDataLoading || isGoodsCartIdLoading || !isLocalCartIdStorageSetting || isAPIGatewayLoading
            listCollectionDataLoading || !isLocalCartIdStorageSetting || isAPIGatewayLoading
          }
          // itemCount={
          //   goodsCartData && (Items?.length ?? 0) > 0 && viewMode && viewMode !== 'close'
          //     ? (Items?.length as number)
          //     : 0
          // }
          itemCount={goodsCartData && (Items?.length ?? 0) > 0 ? (Items?.length as number) : 0}
          // logoOnClickFunction={() => setPageState('products')}
          logoOnClickFunction={BackToProductsPage}
          cartButtonOnClickFunction={() => setPageState('cart')}
          twitterButtonOnClickFunction={handleClickTwitterIcon}
          // title={title}
          isPCLayout={isPCLayout}
          // viewMode={viewMode}
          isTwitter={isTwitter}
          loginOnClickFunction={login}
          myPageIconClickFunction={handleClickMypageIcon}
          isIconActive={isIconActive}
        >
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <GlobalCSS>
              <Wrap>
                <Container>
                  <Section>
                    <InnerBorder>
                      {children}
                      {salesArea()}
                      <Zoom>
                        <>
                          <Backdrop open={open} />
                          <SpeedDial
                            ariaLabel="SpeedDial tooltip example"
                            className={classes.speedDial}
                            // hidden={hidden}
                            icon={<SpeedDialIcon />}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            open={open}
                          >
                            {actions.map((action) => (
                              <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                delay={3}
                                onClick={handleClose}
                              />
                            ))}
                          </SpeedDial>
                        </>
                      </Zoom>
                    </InnerBorder>
                  </Section>
                </Container>
                {/**
                 *
                <div className={classes.footer}>
                  <LinkHref className={classes.footerText} href="https://www.htb.co.jp">
                    © HTB 北海道テレビ放送株式会社
                  </LinkHref>
                </div>
                 */}
              </Wrap>
            </GlobalCSS>
          </main>
        </LiveSalesArea>
        <FormDrawerVertical
          cartId={cartId}
          refreshCartId={() => {
            setCartId('dummy');
          }}
        />
      </>

      <div className={classes.footer}>
        <LinkHref className={classes.footerText} href="https://www.htb.co.jp">
          © HTB 北海道テレビ放送株式会社
        </LinkHref>
      </div>
    </ThemeProvider>
  );
};

export default BaseLayout;
