import { Grid, Typography, Box } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
// import styled from 'styled-components';
import { getAuthProfile, isAuthenticated } from 'Auth0LockContext';
import Breadcrumbs from 'HTB-Videos/components/Common/Breadcrumbs';
import DRMplayer from 'HTB-Videos/components/Common/DRMplayer';
import GnzoPlayer from 'HTB-Videos/components/Common/GnzoPlayer';
import Loading from 'HTB-Videos/components/Common/Loading';
import THEOPlayer from 'HTB-Videos/components/Common/THEOPlayer';
import LiveSalesArea from 'HTB-Videos/components/LiveSalesArea/Container/LiveSalesArea';
import useGetChildContent from 'HTB-Videos/hooks/useGetChildContent';
import useGetParentContent from 'HTB-Videos/hooks/useGetParentContent';
import useGetRegionalRestrictionContent from 'HTB-Videos/hooks/useGetRegionalRestrictionContent';
import { useGetOrderQuery } from 'graphql/appsync/generated';

// const Container = styled.div`
//   max-width: 840px;
//   margin: 0px auto;
// `;

/**
 * 動作再生ページコンポーネント
 */
const Player: FC = () => {
  const { parentId, childId } = useParams();
  const navigate = useNavigate();

  const {
    data: orderData,
    loading: isGetOrderLoading,
    error: isGetOrderError,
  } = useGetOrderQuery({
    variables: { id: `${getAuthProfile().sub ?? ''}_${childId}` },
  });

  const { parentContent, isLoading: isGetParentLoading, isError: isGetParentError } = useGetParentContent(parentId);
  const { isStatusCode403, isStatusLoading } = useGetRegionalRestrictionContent();

  const {
    childContent,
    viewMode,
    isMultiAngleContent,
    isLoading: isGetChildLoading,
    isError: isGetChildError,
  } = useGetChildContent(childId);

  useEffect(() => {
    // 商品を購入していない場合トップページへリダイレクト
    if (!isGetOrderLoading && orderData?.getOrder?.buyState !== 'Bought') {
      navigate('/');
    }
  }, [isGetOrderLoading, navigate, orderData]);

  useEffect(() => {
    // 未ログイン状態の場合トップページへリダイレクト
    if (!isAuthenticated()) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    // 過去作アーカイブではない＋ライブ中でもアーカイブ配信中でも無い場合はトップページへリダイレクト
    if (childContent?.acf.isPastContent === false && viewMode && viewMode !== 'live' && viewMode !== 'archive') {
      navigate('/');
    }
  }, [childContent?.acf.isPastContent, navigate, viewMode]);

  return (
    <>
      {/* パンクズリスト */}
      <Breadcrumbs
        breads={[
          {
            to: '/',
            icon: <HomeIcon />,
            text: 'HOME',
          },
          {
            to: `/TicketSalesPage/${parentId}`,
            icon: <ListAltIcon />,
            text: childContent?.title.rendered ?? '',
          },
          {
            icon: <OndemandVideoIcon />,
            text: '視聴ページ',
          },
        ]}
      />
      <br />
      {(isGetChildError || isGetParentError || isGetOrderError) && (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" align="center" color="error">
              エラーが発生しました
              <br />
            </Typography>
          </Grid>
        </Grid>
      )}
      {isGetChildLoading || isGetParentLoading || isGetOrderLoading || isStatusLoading ? (
        <>
          <Loading loadingCode="Load_002" />
        </>
      ) : (
        <Grid container spacing={3}>
          {childContent && (
            <>
              {/* <Container> */}
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  // className={classes.title}
                  variant="h2"
                  align="center"
                  color="inherit"
                >
                  {childContent?.title.rendered}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {isMultiAngleContent ? (
                  <GnzoPlayer source={childContent?.acf?.MultiAngleSrcURL ?? ''} geoStatusCode403={isStatusCode403} />
                ) : (
                  <>
                    {childContent.acf.childIsDRM ? (
                      <DRMplayer
                        srcDASH={childContent?.acf?.childSrcDASH ?? ''}
                        srcHLS={childContent?.acf?.childSrcHLS}
                        viewMode={viewMode}
                      />
                    ) : (
                      <THEOPlayer
                        source={{
                          sources: [
                            {
                              src: childContent.acf.childSrcHLS,
                              type: 'application/x-mpegurl',
                            },
                          ],
                          poster: '',
                        }}
                        viewMode={viewMode}
                        onPlay={() => {
                          // console.log('The player has started playing.');
                        }}
                      />
                    )}
                  </>
                )}
              </Grid>

              {!childContent?.acf.isPastContent && parentContent?.acf.viewMode === 'live' && (
                <Box m={2} pb={1} style={{ wordBreak: 'break-word' }}>
                  <Typography variant="body2" color="inherit">
                    ※イベントの進行により開始時間が遅くなる場合があります。
                    <br />
                    ※開始時間が遅れた場合でもイベント終了までお伝えします。
                    <br />
                    ※ライブ配信終了後、アーカイブ配信を実施します。
                  </Typography>
                </Box>
              )}

              {/* </Container> */}
              <Grid item xs={12}>
                <LiveSalesArea title={childContent?.title?.rendered ?? ''} viewMode={viewMode} />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default Player;
