import axios from 'axios';
import { useState } from 'react';
import { KintoneCollection } from 'graphql/appsync/generated';

/**
 * 引数で指定したwpIdに属するコレクション情報を取得するhooks
 */
const useLazyGetKintoneCollections = (
  wpId: string,
): {
  getKintoneCollections: VoidFunction;
  kintoneCollection: KintoneCollection[] | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [kintoneCollection, setKintoneCollection] = useState<KintoneCollection[]>([]);

  const getKintoneCollections = () => {
    const getContent = async () => {
      setIsErrorState(false);
      setIsLoadingState(true);
      try {
        const Response = await axios.get(process.env.REACT_APP_GET_COLLECTIONS_FROM_APIGATEWAY_URL ?? '', {
          params: {
            wpId,
          },
        });
        setKintoneCollection(Response.data);
      } catch (err) {
        setIsErrorState(true);
      }
      setIsLoadingState(false);
    };

    void getContent();
  };

  return {
    getKintoneCollections,
    kintoneCollection,
    isLoading: isLoadingState,
    isError: isErrorState,
  };
};

export default useLazyGetKintoneCollections;
