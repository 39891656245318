import React, { FC, useContext } from 'react';
import { useLocation } from 'react-router';
import Auth0LockContext, { getAuthProfile, isAuthenticated } from 'Auth0LockContext';
import AddToCartButton from 'HTB-Videos/components/LiveSalesArea/pages/ProductList/AddToCartButton';
import { useUpdateGoodsCartMutation, Sales, Inventory, KintoneProducts, GoodsCart } from 'graphql/appsync/generated';

type Props = {
  /**
   * カートに追加する商品のvariant情報
   */
  product: KintoneProducts | undefined;
  /**
   * カート情報
   */
  goodsCartData: GoodsCart;
};
/**
 * goodsCartテーブルitemsIdListの要素の型
 */
export type itemList = {
  /**
   * goodsCartテーブルのID
   */
  id: string;
  /**
   * 個数
   */
  count: number;
  /**
   * 商品名
   */
  title: string;
  /**
   * サムネイルURL
   */
  thumbnailUrl: string;
  /**
   * 価格（税込）
   */
  cost?: number;
};

const EnhancedAddToCartButton: FC<Props> = ({ product, goodsCartData }) => {
  const auth0Lock = useContext(Auth0LockContext);
  // const { parentId } = useParams();
  const { pathname } = useLocation();

  // カートに追加ボタンの表示非表示のフラグ制御
  const isCartButtonActive = () => {
    if (
      (product && (product.salesState as Sales) === 'onSaleStop') ||
      (product && (product.salesState as Sales) === 'ready') ||
      (product && (product.inventoryState as Inventory) === 'outOfStock')
    ) {
      return false;
    }

    return true;
  };

  // カートに表示するボタンのテキスト内容
  const cartButtonText = () => {
    if (
      product &&
      (product.salesState as Sales) === 'onSale' &&
      (product.inventoryState as Inventory) === 'outOfStock'
    ) {
      return '売り切れ';
    }
    if (product && (product.salesState as Sales) === 'onSaleStop') {
      return '販売終了';
    }
    if (product && (product.salesState as Sales) === 'ready') {
      return '準備中';
    }

    return 'カートに追加';
  };

  // GoodsCartを更新するhooks
  const [goodsCartItemsUpdateMutation, { loading: isGoodsCartItemsUpdateLoading }] = useUpdateGoodsCartMutation();

  // goodsCartテーブル内Items内容を更新する処理
  const cartItemUpdate = async () => {
    if (!isAuthenticated()) {
      auth0Lock.client.show({
        rememberLastLogin: false,
        auth: {
          params: {
            state: `${pathname}`,
          },
        },
      });
    } else {
      // カートに入れるで追加された商品を格納
      const addItem: itemList = {
        id: product?.id ?? '',
        count: 1,
        title: `${product?.productTitle ?? ''} ${product?.variation ?? ''}`,
        thumbnailUrl: product?.thumbnail ?? '',
        cost: product?.taxIncludePrice,
      };

      // カートに入れるで追加された商品をJSONパースする。無いときは空の配列にする
      const beforeAddItem = goodsCartData.items ? (JSON.parse(goodsCartData?.items ?? '') as itemList[]) : []; // カートに入れた商品をitemListに書き込む処理のロジック部分

      const afterAddItem =
        // カートに同じ商品がすでにあったら数量を+1する
        (beforeAddItem.length ?? 0) > 0 && beforeAddItem.find((item) => item.id === addItem.id)
          ? beforeAddItem.map((data) => {
              if (data.id === addItem.id) {
                return {
                  id: data.id,
                  count: data.count + 1,
                  title: data.title,
                  thumbnailUrl: data.thumbnailUrl,
                  cost: data.cost,
                };
              }

              return data;
            })
          : [...beforeAddItem, addItem];

      // 商品追加したItemsをJSON文字列に変換
      const itemListJson = JSON.stringify(afterAddItem);

      // goodsCartテーブルItems情報を更新
      await goodsCartItemsUpdateMutation({
        variables: {
          input: {
            id: localStorage.getItem('goodsCartId') ?? '',
            userId: getAuthProfile().sub ?? '',
            items: itemListJson,
          },
        },
      });
      // if (onEdit) void onEdit();
    }
  };

  return (
    <AddToCartButton
      onClick={cartItemUpdate}
      isLoading={isGoodsCartItemsUpdateLoading}
      isAvailableForSale={isCartButtonActive()}
      isAvailable={!!product}
      cartButtonText={cartButtonText()}
    />
  );
};
export default EnhancedAddToCartButton;
