import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import React, { FC, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { getAuthProfile, isAuthenticated } from 'Auth0LockContext';
import AirBaseLayout from 'HTB-Aircaravan2021/layouts/BaseLayout';
// import Airtheme from 'HTB-Aircaravan2021/layouts/MuiTheme';
import AirCommercial from 'HTB-Aircaravan2021/pages/Commercial';
import AirCompany from 'HTB-Aircaravan2021/pages/Company';
import AirCopyright from 'HTB-Aircaravan2021/pages/Copyright';
import AirEnvironment from 'HTB-Aircaravan2021/pages/Environment';
import AirEventPage from 'HTB-Aircaravan2021/pages/EventPage';

import AirMyPage from 'HTB-Aircaravan2021/pages/MyPage';
// import AirPlayer from 'HTB-Aircaravan2021/pages/Player';
import AirPrivacyPolicy from 'HTB-Aircaravan2021/pages/PrivacyPolicy';
import AirQandA from 'HTB-Aircaravan2021/pages/QandA';
import AirSentryTest from 'HTB-Aircaravan2021/pages/SentryTest';
import AirTermsofuse from 'HTB-Aircaravan2021/pages/Termsofuse';
// import AirYouTube from 'HTB-Aircaravan2021/pages/YouTube';
// import AirIndex from 'HTB-Aircaravan2021/pages/index';
import BaseLayout from 'HTB-Videos/layouts/BaseLayout';
import theme from 'HTB-Videos/layouts/MuiTheme';
import Commercial from 'HTB-Videos/pages/Commercial';
import Company from 'HTB-Videos/pages/Company';
import Copyright from 'HTB-Videos/pages/Copyright';
// import DeliveryFee from 'components/pages/DeliveryFee';
import Environment from 'HTB-Videos/pages/Environment';
import EnvironmentPMF from 'HTB-Videos/pages/EnvironmentPMF';
import EventPage from 'HTB-Videos/pages/EventPage';
import MyPage from 'HTB-Videos/pages/MyPage';
import Player from 'HTB-Videos/pages/Player';
import PrivacyPolicy from 'HTB-Videos/pages/PrivacyPolicy';
// import PrivacyPolicyHTB from 'pages/PrivacyPolicyHTB';
import QandA from 'HTB-Videos/pages/QandA';
import SentryTest from 'HTB-Videos/pages/SentryTest';
import Termsofuse from 'HTB-Videos/pages/Termsofuse';
import TicketSalesPage from 'HTB-Videos/pages/TicketSalesPage';
import YouTube from 'HTB-Videos/pages/YouTube';
import Index from 'HTB-Videos/pages/index';
import RefetchQueriesProvider from 'RefetchQueriesProvider';
import Logger from 'utils/Logger';

Sentry.init({
  dsn: 'https://0f72329b55ec4df58527870c31c11e91@o577387.ingest.sentry.io/5732089',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: process.env.REACT_APP_GIT_SHA,
});

const shopifyLink = new HttpLink({
  uri: `https://${process.env.REACT_APP_SHOPIFY_SHOP_DOMAIN ?? ''}/api/2021-01/graphql.json`,
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_SHOPIFY_STORE_FRONT_ACCESS_TOKEN ?? '',
  },
});

const url = process.env.REACT_APP_APPSYNC_ENDPOINT ?? '';
const region = process.env.REACT_APP_APPSYNC_REGION ?? '';
const auth: AuthOptions = {
  type: AUTH_TYPE.OPENID_CONNECT,
  jwtToken: () => localStorage.getItem('id_token') ?? '',
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }),
]);

export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: ApolloLink.split((operation) => operation.getContext().clientName === 'shopify', shopifyLink, link),
  cache: new InMemoryCache(),
});

const queryClient = new QueryClient();

const App: FC = () => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    if (!hash) window.scrollTo(0, 0);
  }, [hash, pathname]);

  useEffect(() => {
    if (isAuthenticated()) {
      Logger.setContext({
        user: {
          email: getAuthProfile().email,
          id: getAuthProfile().aud,
          username: getAuthProfile().name,
          picture: getAuthProfile().picture,
        },
        tags: {
          sub: getAuthProfile().sub ?? '',
        },
      });
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        {/* TODO:エアキャラバン2021用対応なので、イベント終了後に復旧すること */}
        {/* <RefetchQueriesProvider> */}
        <ThemeProvider theme={theme}>
          <Routes>
            <Route
              path="/"
              element={
                <BaseLayout>
                  <Index />
                </BaseLayout>
              }
            />
            <Route
              path="/TicketSalesPage/:parentId"
              element={
                <BaseLayout>
                  <TicketSalesPage />
                </BaseLayout>
              }
            />
            <Route
              path="/EventPage/:parentId"
              element={
                <BaseLayout>
                  <EventPage />
                </BaseLayout>
              }
            />
            <Route
              path="/Player/:parentId/:childId"
              element={
                <BaseLayout>
                  <Player />
                </BaseLayout>
              }
            />
            <Route
              path="/YouTube/:playlistId/:videoId"
              element={
                <BaseLayout>
                  <YouTube />
                </BaseLayout>
              }
            />
            <Route
              path="/*"
              element={
                <BaseLayout>
                  <Navigate to="/" />
                </BaseLayout>
              }
            />
            <Route
              path="/qa"
              element={
                <BaseLayout>
                  <QandA />
                </BaseLayout>
              }
            />
            <Route
              path="/MyPage"
              element={
                <BaseLayout>
                  <MyPage />
                </BaseLayout>
              }
            />
            <Route
              path="/Company"
              element={
                <BaseLayout>
                  <Company />
                </BaseLayout>
              }
            />
            <Route
              path="/PrivacyPolicy"
              element={
                <BaseLayout>
                  <PrivacyPolicy />
                </BaseLayout>
              }
            />
            {/* <Route path="/PrivacyPolicyHTB" element={<PrivacyPolicyHTB />} /> */}
            <Route
              path="/Termsofuse"
              element={
                <BaseLayout>
                  <Termsofuse />
                </BaseLayout>
              }
            />
            <Route
              path="/Environment"
              element={
                <BaseLayout>
                  <Environment />
                </BaseLayout>
              }
            />
            <Route
              path="/EnvironmentPMF"
              element={
                <BaseLayout>
                  <EnvironmentPMF />
                </BaseLayout>
              }
            />
            {/* <Route path="/DeliveryFee" element={<DeliveryFee />} /> */}
            <Route
              path="/Copyright"
              element={
                <BaseLayout>
                  <Copyright />
                </BaseLayout>
              }
            />
            <Route
              path="/businessDeal"
              element={
                <BaseLayout>
                  <Commercial />
                </BaseLayout>
              }
            />
            <Route
              path="/SentryTest"
              element={
                <BaseLayout>
                  <SentryTest />
                </BaseLayout>
              }
            />
            <Route
              path="/*"
              element={
                <BaseLayout>
                  <Navigate to="/" />
                </BaseLayout>
              }
            />
            {/* 以下エアキャラバン */}
            <Route
              path="/aircaravan2021"
              element={
                <RefetchQueriesProvider>
                  <AirBaseLayout>
                    <AirEventPage />
                  </AirBaseLayout>
                </RefetchQueriesProvider>
              }
            />
            <Route
              path="/aircaravan2021/qa"
              element={
                <AirBaseLayout>
                  <AirQandA />
                  <AirEnvironment />
                </AirBaseLayout>
              }
            />
            <Route
              path="/aircaravan2021/MyPage"
              element={
                <AirBaseLayout>
                  <AirMyPage />
                </AirBaseLayout>
              }
            />
            {/* <Route
              path="/aircaravan2021/Company"
              element={
                <AirBaseLayout>
                  <AirCompany />
                </AirBaseLayout>
              }
            /> */}
            <Route
              path="/aircaravan2021/PrivacyPolicy"
              element={
                <AirBaseLayout>
                  <AirPrivacyPolicy />
                  <AirCompany />
                </AirBaseLayout>
              }
            />
            <Route
              path="/aircaravan2021/Termsofuse"
              element={
                <AirBaseLayout>
                  <AirTermsofuse />
                </AirBaseLayout>
              }
            />
            {/* <Route
              path="/aircaravan2021/Environment"
              element={
                <AirBaseLayout>
                  <AirEnvironment />
                </AirBaseLayout>
              }
            /> */}
            <Route
              path="/aircaravan2021/Copyright"
              element={
                <AirBaseLayout>
                  <AirCopyright />
                </AirBaseLayout>
              }
            />
            <Route
              path="/aircaravan2021/businessDeal"
              element={
                <AirBaseLayout>
                  <AirCommercial />
                </AirBaseLayout>
              }
            />
            <Route
              path="/aircaravan2021/SentryTest"
              element={
                <AirBaseLayout>
                  <AirSentryTest />
                </AirBaseLayout>
              }
            />
          </Routes>
        </ThemeProvider>
        {/* TODO:エアキャラバン2021用対応なので、イベント終了後に復旧すること */}
        {/* </RefetchQueriesProvider> */}
      </QueryClientProvider>
    </ApolloProvider>
  );
};

export default App;
