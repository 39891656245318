import { Typography, FormControl, RadioGroup, Grid, Button } from '@material-ui/core';
import axios from 'axios';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { getAuthProfile } from 'Auth0LockContext';
import Loading from 'HTB-Aircaravan2021/components/Common/Loading';
import Message from 'HTB-Aircaravan2021/components/Common/Message';
import CardList from 'HTB-Aircaravan2021/components/MyPage/CardList';
import UserStyle from 'HTB-Aircaravan2021/components/MyPage/UserStyle';
import { User, Card, useListSortCardsQuery, useUpdateGoodsCartMutation } from 'graphql/appsync/generated';
import { MessageCode } from 'utils/Message';

type Props = {
  /**
   * 次へボタンクリック時の処理
   */
  onClickNext: VoidFunction;
  /**
   * 更新対象のユーザー情報
   */
  user: User;
  /**
   * Cardオブジェクト格納用
   */
  setCard: (card: Card | undefined) => void;
  /**
   * temporaryGoodsOrderIdを設定する処理
   */
  setTemporaryGoodsOrderId: (id: string) => void;
  /**
   * 決済処理を進めるカートID
   */
  cartId: string;
};

interface resJson {
  goodsCartId: string;
  paymentIntentId: string;
}

// 購入に進む系のボタンの色はオレンジ
const StyledButton = styled(Button)`
  background-color: #ff9100 !important;
`;

const CardSelect: FC<Props> = ({ onClickNext, user, setCard, setTemporaryGoodsOrderId, cartId }) => {
  // カード選択時のCardTableのId
  const [selectCardId, setSelectCardId] = useState('');
  // エラーコード
  const [errorCode, setErrorCode] = useState<MessageCode | undefined>();
  // StepFunctionsのLoading状態
  const [isSFLoading, setIsSFLoading] = useState(false);
  // StepFunctionsのエラー回数カウント
  const [SFErrorCount, setSFErrorCount] = useState(0);

  const { data: listCardData } = useListSortCardsQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
    },
  });

  const [updateGoodsCartMutation, { loading: updateCartLoading, error: updateCartError }] =
    useUpdateGoodsCartMutation();

  // 次へボタン押下時の処理
  const onSubmit = () => {
    setIsSFLoading(true);
    const submit = async () => {
      // 1. GoodsCartテーブルにカートIDを書き込み
      await updateGoodsCartMutation({
        variables: {
          input: {
            // id: localStorage.getItem('goodsCartId') ?? '',
            id: cartId,
            cardId: selectCardId,
            orderType: 'Kureka',
          },
        },
      }).then(() => {
        if (updateCartError) setErrorCode('ERR_000301');
      });

      // 2. 送料・合計金額計算StepFunctions起動
      try {
        await axios
          .post(
            process.env.REACT_APP_GOODS_PRIECE_START_EXECUTION_URL ?? '',
            {
              // input: { goodsCartId: localStorage.getItem('goodsCartId') ?? '' },
              input: { goodsCartId: cartId },
              stateMachineArn: process.env.REACT_APP_GOODS_PRIECE_STEP_FUNCTIONS_ARN,
            },
            {
              headers: {
                Authorization: localStorage.getItem('id_token'),
              },
            },
          )
          .then((response) => {
            if (response.status !== 200) {
              // データ送信失敗
              // failureStartApi();
              setErrorCode('ERR_000002');

              return;
            }
            // 初期化
            setSFErrorCount(0);

            // タイマーのsetTimeoutに変更
            setTimeout(() => {
              void getDataPolling(response.data, process.env.REACT_APP_GOODS_PRIECE_DESCRIBE_EXECUTION_URL ?? '');
            }, 2000);
          });
        // データ送信失敗
      } catch (e) {
        // failureStartApi();
        setErrorCode('ERR_000002');
      }
    };

    void submit();
  };

  /**
   * レスポンス受け取り用関数
   */
  const getDataPolling = async (responseData: unknown, url: string) => {
    /*
    type Response = {
      status: string;
      output: {
        goodsCartId: string;
        paymentIntentId: string;
      };
    };
    */
    type Response = {
      status: string;
      output: string;
    };

    try {
      // await apolloClient.cache.reset();
      await axios.post<Response>(url, responseData).then((response) => {
        if (response.status !== 200) {
          // 成功以外を返してもループ
          // 1分以上エラーでリトライした場合
          if (SFErrorCount > 12) {
            return;
          }
          setSFErrorCount((nowCount) => nowCount + 1);

          // 再起
          setTimeout(() => {
            void getDataPolling(responseData, url);
          }, 5000);
        } else if (response.data.status === 'SUCCEEDED') {
          // ステータスコードによる分岐あり
          // 成功、注文完了画面へ
          const jsonData = JSON.parse(response.data.output) as resJson;
          // console.log(jsonData.paymentIntentId);
          void setTemporaryGoodsOrderId(jsonData.paymentIntentId);
          void onClickNext();
        } else if (response.data.status === 'FAILED') {
          // 失敗バックエンドでエラー発生
          setErrorCode('ERR_000002');
        } else if (response.data.status === 'RUNNING') {
          // RUNNING
          setTimeout(() => {
            void getDataPolling(responseData, url);
          }, 1000);
        } else {
          // 中断・タイムアウト
          setErrorCode('ERR_000002');
        }
      });
    } catch (e) {
      // 1分以上エラーでリトライした場合
      if (SFErrorCount > 12) {
        setErrorCode('ERR_000002');

        return;
      }
      void setSFErrorCount((nowCount) => nowCount + 1);

      // 再起
      setTimeout(() => {
        void getDataPolling(responseData, url);
      }, 5000);
    }
  };

  return (
    <>
      {errorCode && (
        <>
          <Message messageCode={errorCode} />
        </>
      )}
      {!errorCode && (updateCartLoading || isSFLoading) && <Loading loadingCode="Load_003" />}
      {!errorCode && !updateCartLoading && !isSFLoading && (
        <>
          <Typography>請求書送付先</Typography>
          <UserStyle user={user} />
          <br />
          <Typography>クレジットカード</Typography>
          <FormControl fullWidth component="label">
            <RadioGroup
              value={selectCardId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectCardId((e.target as HTMLInputElement).value)
              }
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {listCardData?.listSortCards?.items?.map((card) => (
                    <Grid container spacing={3} alignItems="center" justify="center">
                      <Grid item xs={12}>
                        <CardList card={card ?? undefined} />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
          <br />
          <br />
          <Grid container alignItems="center" justify="center">
            <Grid item xs={8} md={6}>
              <StyledButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setCard(
                    listCardData?.listSortCards?.items?.find((item) => item && item.id === selectCardId) ?? undefined,
                  );
                  onSubmit();
                }}
                disabled={!selectCardId}
              >
                <Typography align="center" variant="subtitle1">
                  次へ
                </Typography>
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          <br />
        </>
      )}
    </>
  );
};

export default CardSelect;
