import { createContext } from 'react';
import { KintoneProducts, KintoneCollection } from 'graphql/appsync/generated';

export type Query = {
  name: 'KintoneProducts' | 'CreateKintoneCollection' | 'DeleteKintoneCollection';
  // id?: string;
  data?: KintoneProducts | KintoneCollection | undefined;
};

type Context = {
  queries: Query[];
  removeQuery: (queries: Query) => void;
};

/**
 * refetchしてデータ最新化すべきQueryの名称を返す
 */
const refetchQueriesContext = createContext<Context>({
  queries: [],
  removeQuery: () => {
    // do nothing
  },
});
export default refetchQueriesContext;
