import {
  Card,
  CardMedia,
  // CardContent,
  Typography,
  Grid,
  makeStyles,
  ButtonBase,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Auth0LockContext from 'Auth0LockContext';
import Loading from 'HTB-Videos/components/Common/Loading';
import WPYoutubePlaylist, { WPYoutubePlaylistData } from 'HTB-Videos/components/Youtube/WPYoutubePlaylistAPI';
import YouTubeRow from 'HTB-Videos/components/Youtube/YouTubeRow';
import { wpParentContentData } from 'HTB-Videos/utils/WPRequest';
import WPContent from '../components/Common/WPContentsAxios';

// material-uiで使うcssクラス定義
const useStyles = makeStyles(() => ({
  // サムネ画像の比率
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  // クリック時用
  cardButton: {
    display: 'block',
    textAlign: 'initial',
  },
}));

// タイトル表示用css
const StyledTitle = styled(Typography)`
  border-bottom: 2px solid;
  padding-bottom: 0.3rem;
  margin-top: 3vw;
`;

// YouTube表示ライン用css
const StyledLargeTitle = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 4vw;
  margin-bottom: 1.5vw;

  &::before,
  &::after {
    content: '';
    height: 1px;
    flex-grow: 1;
    // background-color: #666;
    background-color: #666;
  }

  &::before {
    margin-right: 1rem;
  }

  &::after {
    margin-left: 1rem;
  }
`;

const Index: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const Auth0Lock = useContext(Auth0LockContext);

  const {
    data: youtubePlaylists,
    isLoading: isGetYoutubeLoading,
    isError: isGetYoutubeError,
  } = useQuery<WPYoutubePlaylistData[]>(
    ['getYoutubePlayList', 'all'],
    async () => {
      const { data } = await WPYoutubePlaylist.get<WPYoutubePlaylistData[]>('', {
        params: {
          _fields: 'id,title,parent,acf',
          parent: 0,
        },
      });

      return data;
    },
    { staleTime: 300000 },
  );

  // ReactQueryで親データ一覧取得
  const {
    data: parentContents,
    isLoading,
    isError,
  } = useQuery<wpParentContentData[]>(['getParentContents', 'all'], async () => {
    const { data } = await WPContent.get<wpParentContentData[]>('', {
      params: {
        _fields: 'id,title,parent,acf',
        parent: 0,
      },
    });

    return data;
  });

  // ログイン処理後、商品ページへ移動させる処理
  useEffect(() => {
    // '/'の場合はナビゲートしない
    if (Auth0Lock.lockActionId && Auth0Lock.lockActionId.length > 1) {
      void Auth0Lock.setLockActionId('');
      navigate(Auth0Lock.lockActionId);
    }
    /*
    if (Auth0Lock.lockActionId.indexOf('/TicketSalesPage/parentId:') === 0) {
      void Auth0Lock.setLockActionId('');
      navigate(`/TicketSalesPage/${Auth0Lock.lockActionId.replace('/TicketSalesPage/parentId:', '')}`);
    } else if (Auth0Lock.lockActionId.indexOf('/EventPage/parentId:') === 0) {
      void Auth0Lock.setLockActionId('');
      navigate(`/EventPage/${Auth0Lock.lockActionId.replace('/EventPage/parentId:', '')}`);
    }
    */
  }, [Auth0Lock, navigate]);

  const [isEventLoading, setIsEventLoading] = useState(false);
  const [isEventError, setIsEventError] = useState(false);
  const [eventContents, setEventContents] = useState<wpParentContentData[]>();

  // イベントデータをWPから取得
  useEffect(() => {
    const getEventContents = async () => {
      setIsEventError(false);
      setIsEventLoading(true);
      try {
        const response = await axios.get(
          `https://dxjmemwogeqvm.cloudfront.net/wp-json/wp/v2/${process.env.REACT_APP_WP_EVENT ?? ''}`,
          {
            params: {
              _fields: 'acf,id,title',
              parent: 0,
            },
          },
        );
        setEventContents(response.data ?? '');
      } catch (err) {
        setIsEventError(true);
      }
      setIsEventLoading(false);
    };
    void getEventContents();
  }, []);

  const [isAircaravanLoading, setIsAircaravanLoading] = useState(false);
  const [isAircaravanError, setIsAircaravanError] = useState(false);
  const [airCaravan, setAircaravan] = useState<wpParentContentData[]>();

  // エアキャラバンデータをWPから取得
  useEffect(() => {
    const getAircaravan = async () => {
      setIsAircaravanError(false);
      setIsAircaravanLoading(true);
      try {
        const response = await axios.get(
          `https://dxjmemwogeqvm.cloudfront.net/wp-json/wp/v2/${process.env.REACT_APP_WP_AIRCARAVAN ?? ''}`,
          {
            params: {
              _fields: 'acf,id,title',
              parent: 0,
            },
          },
        );
        setAircaravan(response.data ?? '');
      } catch (err) {
        setIsAircaravanError(true);
      }
      setIsAircaravanLoading(false);
    };
    void getAircaravan();
  }, []);

  return (
    <>
      {isAircaravanError && (
        <>
          <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
            エアキャラバン
          </StyledTitle>
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography gutterBottom variant="body1" align="center" color="error">
                エラーが発生しました
                <br />
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      {isAircaravanLoading ? (
        <>
          <div style={{ padding: '1rem' }}>
            <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
              エアキャラバン
            </StyledTitle>
            {/* <div style={{ padding: '1rem' }}> */}
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4} md={3}>
                <Skeleton animation="wave" variant="rect" className={classes.media} />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div style={{ padding: '1rem' }}>
            <Grid container spacing={1}>
              {airCaravan && airCaravan.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
                      エアキャラバン
                    </StyledTitle>
                  </Grid>
                  {airCaravan.map((content) => (
                    <Grid item xs={6} sm={4} md={3}>
                      <Card>
                        <ButtonBase component={Link} to="/aircaravan2021" className={classes.cardButton}>
                          <CardMedia
                            image={content.acf.thumbnailURL}
                            title={content.title.rendered}
                            className={classes.media}
                          />
                        </ButtonBase>
                      </Card>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </div>
        </>
      )}

      {isError && (
        <>
          <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
            HTB onライン劇場
          </StyledTitle>
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography gutterBottom variant="body1" align="center" color="error">
                エラーが発生しました
                <br />
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      {isLoading ? (
        <>
          <div style={{ padding: '1rem' }}>
            <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
              HTB onライン劇場
            </StyledTitle>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4} md={3}>
                <Skeleton animation="wave" variant="rect" className={classes.media} />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Skeleton animation="wave" variant="rect" className={classes.media} />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          {/* <Grid container spacing={1} alignItems="center" justify="center"> */}
          <div style={{ padding: '1rem' }}>
            <Grid container spacing={1}>
              {parentContents && parentContents.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
                      HTB onライン劇場
                    </StyledTitle>
                  </Grid>

                  {parentContents.map((content) => (
                    <Grid item xs={6} sm={4} md={3}>
                      <Card>
                        <ButtonBase
                          component={Link}
                          to={`/TicketSalesPage/${content.id}`}
                          className={classes.cardButton}
                        >
                          <CardMedia
                            image={content.acf.thumbnailURL}
                            title={content.title?.rendered}
                            className={classes.media}
                          />
                        </ButtonBase>
                      </Card>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </div>
        </>
      )}

      {isEventError && (
        <>
          <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
            HTB イベント
          </StyledTitle>
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography gutterBottom variant="body1" align="center" color="error">
                エラーが発生しました
                <br />
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      {isEventLoading ? (
        <>
          <div style={{ padding: '1rem' }}>
            <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
              HTB イベント
            </StyledTitle>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4} md={3}>
                <Skeleton animation="wave" variant="rect" className={classes.media} />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Skeleton animation="wave" variant="rect" className={classes.media} />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          {/* <Grid container spacing={1} alignItems="center" justify="center"> */}
          {/* <Grid container spacing={1}> */}
          <div style={{ padding: '1rem' }}>
            <Grid container spacing={1}>
              {eventContents && eventContents.length > 0 && (
                <>
                  {/* <div style={{ padding: '0.5rem', marginBottom: '3vw' }}> */}

                  <Grid item xs={12}>
                    <StyledTitle variant="subtitle1" color="inherit" align="left" gutterBottom>
                      HTB イベント
                    </StyledTitle>
                  </Grid>

                  {eventContents.map((content) => (
                    <Grid item xs={6} sm={4} md={3}>
                      <Card>
                        <ButtonBase component={Link} to={`/EventPage/${content.id}`} className={classes.cardButton}>
                          <CardMedia
                            image={content.acf.thumbnailURL}
                            title={content.title?.rendered}
                            className={classes.media}
                          />
                        </ButtonBase>
                      </Card>
                    </Grid>
                  ))}

                  {/* </div> */}
                </>
              )}
            </Grid>
          </div>
        </>
      )}

      {isGetYoutubeError && (
        <Grid container spacing={1} alignItems="center" justify="center">
          <Grid item xs={12}>
            <StyledLargeTitle variant="h2" color="inherit">
              HTB 公式YouTube動画
            </StyledLargeTitle>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" align="center" color="error">
              エラーが発生しました
              <br />
            </Typography>
          </Grid>
        </Grid>
      )}
      {isGetYoutubeLoading ? (
        <>
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={12}>
              <StyledLargeTitle variant="h2" color="inherit">
                HTB 公式YouTube動画
              </StyledLargeTitle>
            </Grid>
            <Loading loadingCode="Load_002" />
          </Grid>
        </>
      ) : (
        <Grid container spacing={1} alignItems="center" justify="center">
          <Grid item xs={12}>
            <StyledLargeTitle variant="h2" color="inherit">
              HTB 公式YouTube動画
            </StyledLargeTitle>
          </Grid>
          {youtubePlaylists && (
            <>
              {youtubePlaylists.map((list) => (
                <Grid item xs={12}>
                  <YouTubeRow
                    title={list.title?.rendered}
                    playlistId={list.acf?.youtubePlaylistId}
                    Num={list.acf?.youtubeDisplayResults}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default Index;
