/**
 * 都道府県名のリスト
 */
const ProvinceList = [
  { id: 'JP-01', name: '北海道', roman: 'Hokkaidō' },
  { id: 'JP-02', name: '青森県', roman: 'Aomori' },
  { id: 'JP-03', name: '岩手県', roman: 'Iwate' },
  { id: 'JP-04', name: '宮城県', roman: 'Miyagi' },
  { id: 'JP-05', name: '秋田県', roman: 'Akita' },
  { id: 'JP-06', name: '山形県', roman: 'Yamagata' },
  { id: 'JP-07', name: '福島県', roman: 'Fukushima' },
  { id: 'JP-08', name: '茨城県', roman: 'Ibaragi' },
  { id: 'JP-09', name: '栃木県', roman: 'Tochigi' },
  { id: 'JP-10', name: '群馬県', roman: 'Gunma' },
  { id: 'JP-11', name: '埼玉県', roman: 'Saitama' },
  { id: 'JP-12', name: '千葉県', roman: 'Chiba' },
  { id: 'JP-13', name: '東京都', roman: 'Tōkyō' },
  { id: 'JP-14', name: '神奈川県', roman: 'Kanagawa' },
  { id: 'JP-15', name: '新潟県', roman: 'Niigata' },
  { id: 'JP-16', name: '富山県', roman: 'Toyama' },
  { id: 'JP-17', name: '石川県', roman: 'Ishikawa' },
  { id: 'JP-18', name: '福井県', roman: 'Fukui' },
  { id: 'JP-19', name: '山梨県', roman: 'Yamanashi' },
  { id: 'JP-20', name: '長野県', roman: 'Nagano' },
  { id: 'JP-21', name: '岐阜県', roman: 'Gifu' },
  { id: 'JP-22', name: '静岡県', roman: 'Shizuoka' },
  { id: 'JP-23', name: '愛知県', roman: 'Aichi' },
  { id: 'JP-24', name: '三重県', roman: 'Mie' },
  { id: 'JP-25', name: '滋賀県', roman: 'Shiga' },
  { id: 'JP-26', name: '京都府', roman: 'Kyōto' },
  { id: 'JP-27', name: '大阪府', roman: 'Ōsaka' },
  { id: 'JP-28', name: '兵庫県', roman: 'Hyōgo' },
  { id: 'JP-29', name: '奈良県', roman: 'Nara' },
  { id: 'JP-30', name: '和歌山県', roman: 'Wakayama' },
  { id: 'JP-31', name: '鳥取県', roman: 'Tottori' },
  { id: 'JP-32', name: '島根県', roman: 'Shimane' },
  { id: 'JP-33', name: '岡山県', roman: 'Okayama' },
  { id: 'JP-34', name: '広島県', roman: 'Hiroshima' },
  { id: 'JP-35', name: '山口県', roman: 'Yamaguchi' },
  { id: 'JP-36', name: '徳島県', roman: 'Tokushima' },
  { id: 'JP-37', name: '香川県', roman: 'Kagawa' },
  { id: 'JP-38', name: '愛媛県', roman: 'Ehime' },
  { id: 'JP-39', name: '高知県', roman: 'Kōchi' },
  { id: 'JP-40', name: '福岡県', roman: 'Fukuoka' },
  { id: 'JP-41', name: '佐賀県', roman: 'Saga' },
  { id: 'JP-42', name: '長崎県', roman: 'Nagasaki' },
  { id: 'JP-43', name: '熊本県', roman: 'Kumamoto' },
  { id: 'JP-44', name: '大分県', roman: 'Ōita' },
  { id: 'JP-45', name: '宮崎県', roman: 'Miyazaki' },
  { id: 'JP-46', name: '鹿児島県', roman: 'Kagoshima' },
  { id: 'JP-47', name: '沖縄県', roman: 'Okinawa' },
];

export default ProvinceList;
