import { useEffect, useState } from 'react';
import { getAuthProfile } from 'Auth0LockContext';
import { wpChildContentData } from 'HTB-Videos/utils/WPRequest';
import { Order, useListSortOrdersQuery } from 'graphql/appsync/generated';
import WPContent from '../components/Common/WPContentsAxios';

// 返却する子データの購入情報の型
export type Item = Pick<
  Order,
  | 'article'
  | 'buyState'
  | 'cost'
  | 'createdAt'
  | 'id'
  | 'itemId'
  | 'thumbnailUrl'
  | 'title'
  | 'updatedAt'
  | 'userId'
>;

/**
 * 親IDを引数で受け取り、子データの購入情報を返却するhooks
 */
const useGetPurchasedContents = (
  parentId: string,
): {
  data: Item[];
  isLoading: boolean;
  isError: boolean;
  refetch: VoidFunction;
} => {
  const {
    data: listOrderData,
    refetch: listOrderRefetch,
  } = useListSortOrdersQuery({
    variables: {
      userId: getAuthProfile().sub,
      sortDirection: 'DESC',
      filter: {
        buyState: {
          ne: 'NotBought',
        },
      },
    },
  });
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [childContents, setChildContents] = useState<wpChildContentData[]>([]);
  const [purchasedList, setPurchasedList] = useState<Item[]>([]);

  useEffect(() => {
    const getContent = async () => {
      setIsErrorState(false);
      setIsLoadingState(true);
      try {
        const childResponse = await WPContent.get(``, {
          params: {
            parent: `${parentId}`,
            _fields: 'acf,id,parent',
          },
        });

        setChildContents(childResponse.data);
      } catch (err) {
        setIsErrorState(true);
      }
      setIsLoadingState(false);
    };

    void getContent();
  }, [parentId]);

  useEffect(() => {
    if ((listOrderData?.listSortOrders?.items?.length ?? 0) < 1) {
      // 購入データが1件も無い場合
      setPurchasedList([]);
    } else {
      setPurchasedList(
        listOrderData?.listSortOrders?.items?.filter((item) =>
          item
            ? childContents
                .map((child) => child.id.toString())
                .includes(item?.itemId)
            : false,
        ) as Item[],
      );
    }
  }, [childContents, listOrderData?.listSortOrders?.items]);

  return {
    data: purchasedList,
    isLoading: isLoadingState,
    isError: isErrorState,
    refetch: listOrderRefetch,
  };
};

export default useGetPurchasedContents;
