import ButtonBase from '@material-ui/core/ButtonBase';
// import CardMedia from '@material-ui/core/CardMedia';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
// import styled from 'styled-components';
import { Card, CardBrand } from 'graphql/appsync/generated';
import imgAmex from '../../images/ico_amex.gif';
import imgDiners from '../../images/ico_diners.gif';
import imgDiscover from '../../images/ico_discover.gif';
import imgJcb from '../../images/ico_jcb.gif';
import imgMastercard from '../../images/ico_master.gif';
import imgVisa from '../../images/ico_visa.gif';

type CardBrandNote = Record<CardBrand, string>;

type Props = {
  /**
   * 更新対象
   */
  card: Card | undefined;
  /**
   * プレビューモード（フォームが非表示）
   */
  previewMode?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 800,
    },
    image: {
      width: 64,
      height: 36,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    // subtitle: {
    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: '0.9rem',
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '1.1rem',
    //   },
    // },
  }),
);

// const StyledForceBreakword = styled.div`
//   h6 {
//     word-break: break-all;
//   }
// `;

const CardList: FC<Props> = ({ card, previewMode }) => {
  const classes = useStyles();

  const cardBrandImagePath: CardBrandNote = {
    Visa: imgVisa,
    Mastercard: imgMastercard,
    AMEX: imgAmex,
    JCB: imgJcb,
    Diners: imgDiners,
    Discover: imgDiscover,
  };

  return (
    <>
      {card && (
        <div className={classes.root}>
          <Paper className={classes.paper} key={card.id} elevation={3}>
            <Grid container alignItems="center">
              <Grid item>{!previewMode && <FormControlLabel value={card.id} control={<Radio />} label="" />}</Grid>
              <Grid item xs container spacing={2} alignItems="center">
                <Grid item>
                  <ButtonBase className={classes.image}>
                    <img className={classes.img} alt={card.brand} src={cardBrandImagePath[card.brand]} />
                  </ButtonBase>
                </Grid>
                <Grid container spacing={2} wrap="nowrap">
                  <Grid item xs direction="column" style={{ width: 0 }}>
                    <Grid item zeroMinWidth>
                      <Typography
                        noWrap
                        gutterBottom
                        variant="body1"
                        // className={classes.subtitle}
                      >
                        XXXX XXXX XXXX {card.cardNo}
                        <br />
                        {card.name}
                        <br />
                        有効期限 {card.deadline}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </>
  );
};

export default CardList;
